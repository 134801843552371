import useHotelSearchResultStore from "@hotelspoint/store/src/useHotelSearchResultStore.ts";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import { type HotelSearchPayload, HotelService } from "../../services";

const useHotelAvailability = (params: HotelSearchPayload) => {
  const setResult = useHotelSearchResultStore(state => state.setResponse);
  const setLoading = useHotelSearchResultStore(state => state.setLoading);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["hotel_availability", params],
    queryFn: () => HotelService.searchHotel(params),
  });

  useEffect(() => {
    if (data?.id) {
      setResult(data);
    }
  }, [data, setResult]);

  useEffect(() => {
    setLoading(isLoading || isFetching);
  }, [isLoading, isFetching, setLoading]);
};

export default useHotelAvailability;
