import {
  IconBed,
  IconCar,
  IconChecklist,
  IconTransfer,
  IconTrekking,
} from "@tabler/icons-react";

import { IconType, NavItemBase } from "../../common";

export interface NavItem extends NavItemBase {
  icon?: IconType;
  isDisabled?: boolean;
}

export default [
  {
    icon: IconBed,
    href: "/hotels",
    title: "searchHotels.pageTitle",
  },
  {
    icon: IconTransfer,
    href: "/search/transfers",
    title: "searchTransfers.pageTitle",
  },
  {
    icon: IconTrekking,
    href: "/search/activities",
    title: "searchActivities.pageTitle",
  },
  {
    icon: IconChecklist,
    href: "/search/packages",
    title: "searchPackages.pageTitle",
  },
  {
    icon: IconCar,
    href: "/search/car-rentals",
    title: "searchCarRentals.pageTitle",
    isDisabled: true,
  },
];
