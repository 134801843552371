import { pxToRem, spacing } from "@hotelspoint/theme";
import { IconStar } from "@tabler/icons-react";
import { styled } from "styled-components";
import { ifProp, theme } from "styled-tools";

import Button from "../../elements/Button";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableWrapper = styled.div`
  margin-top: ${spacing(2)}px;
`;

export const Headline = styled.h6`
  font-weight: 600;
`;

export const Divider = styled.hr`
  margin: ${spacing(2.5)}px 0;
  background-color: rgba(0, 0, 0, 0.15);
`;

export const PriceWrap = styled.div`
  display: flex;
  align-items: flex-end;
  column-gap: ${spacing(1)}px;
`;

export const Emphasized = styled.em`
  font-size: ${pxToRem(14)};
`;

export const Price = styled.span`
  font-weight: 600;
  font-size: ${pxToRem(18)};
`;

export const CellRoom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${spacing(0.5)}px;
`;

export const CellPrice = styled.p`
  font-size: ${pxToRem(16)};
  font-weight: 600;
  white-space: nowrap;
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const RoomWrapper = styled.div`
  line-height: normal;
`;

export const PriceWrapper = styled.span`
  font-size: ${pxToRem(20)};
  font-weight: 500;
`;

export const CancellationWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(1)}px;
  white-space: nowrap;
`;

export const ButtonBook = styled(Button)`
  width: 96px;
  height: 30px;
  max-width: unset;
`;

export const ButtonFavorite = styled(Button)`
  height: 30px;
  padding: 0 ${spacing(1)}px;
`;

export const ButtonPolicy = styled(ButtonBook)``;

export const IconFavorite = styled(IconStar)<{ $isActive: boolean }>`
  min-width: 16px;
  fill: ${ifProp("$isActive", theme("palette.black"))};
  stroke: ${ifProp("$isActive", theme("palette.black"))};
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(1)}px;
`;

export const DashedStatusText = styled.div`
  line-height: normal;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.5);
`;

export const MealName = styled.span`
  line-height: normal;
`;
