import { useHotelRateFees } from "@hotelspoint/api";
import { zIndex } from "@hotelspoint/theme";
import { RateType } from "@hotelspoint/types";
import { getRateTypeName } from "@hotelspoint/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { StatusFlavour } from "../../../common";
import StatusText from "../../../elements/StatusText";
import Tooltip from "../../Tooltip";
import * as S from "../HotelRoomsTable.styled";

interface UnknownCancellationButtonProps {
  searchId?: number;
  hotelId?: number;
  rateId: number;
}

const UnknownCancellationButton = ({
  searchId,
  hotelId,
  rateId,
}: UnknownCancellationButtonProps) => {
  const { t } = useTranslation();

  const [isEnabled, setIsEnabled] = useState(false);

  const [hotelRateFees, isLoadingHotelRateFees] = useHotelRateFees(
    isEnabled ? searchId : undefined,
    isEnabled ? hotelId : undefined,
    isEnabled ? rateId : undefined,
  );

  return (
    <>
      {hotelRateFees && !isLoadingHotelRateFees ? (
        <Tooltip>
          <Tooltip.Trigger>
            <S.DashedStatusText>
              <StatusText
                status={
                  hotelRateFees.rateType === RateType.Refundable
                    ? StatusFlavour.Success
                    : StatusFlavour.Error
                }
              >
                {t(getRateTypeName(hotelRateFees.rateType))}
              </StatusText>
            </S.DashedStatusText>
          </Tooltip.Trigger>
          <Tooltip.Content style={{ zIndex: zIndex.cancellationFeesTooltip }}>
            {hotelRateFees.rateType === RateType.Refundable
              ? t(
                  "searchHotelResults.cancellationFeesTooltip.freeCancelationUntil",
                  {
                    date: hotelRateFees?.deadline,
                  },
                )
              : t("searchHotelResults.cancellationFeesTooltip.nonRefundable")}
          </Tooltip.Content>
        </Tooltip>
      ) : (
        <S.ButtonPolicy
          variant="primary"
          isLoading={isLoadingHotelRateFees}
          onClick={() => setIsEnabled(true)}
        >
          {t("searchHotelResults.hotelRoomsModal.viewPolicy")}
        </S.ButtonPolicy>
      )}
    </>
  );
};

export default UnknownCancellationButton;
