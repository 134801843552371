import {
  handleError,
  SubAgencyStatementStatus,
  useUpdateSubAgencyStatement,
} from "@hotelspoint/api";
import { Button } from "@hotelspoint/components";
import { type RowSelectionState } from "@tanstack/react-table";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import * as S from "./ProfileSubAgenciesStatementsItemsActions.styled";

interface ProfileSubAgenciesStatementsItemsActionsProps {
  id: number;
  rowSelection: RowSelectionState;
  setRowSelection: Dispatch<SetStateAction<RowSelectionState>>;
}

const ProfileSubAgenciesStatementsItemsActions = ({
  id,
  rowSelection,
  setRowSelection,
}: ProfileSubAgenciesStatementsItemsActionsProps) => {
  const { t } = useTranslation();

  const [targetStatus, setTargetStatus] =
    useState<SubAgencyStatementStatus | null>(null);

  const [updateStatement, isUpdatingStatement] =
    useUpdateSubAgencyStatement(id);

  const handlePaidStatus = useCallback(
    async (status: SubAgencyStatementStatus) => {
      try {
        if (Object.keys(rowSelection).length === 0) {
          toast.warning(t("toast.profileSubAgenciesStatement.noSelection"));
          return;
        }

        setTargetStatus(status);

        const ids = Object.keys(rowSelection).map(Number);

        if (status === SubAgencyStatementStatus.Paid) {
          await updateStatement({
            paidIds: ids,
          });
        } else {
          await updateStatement({
            notPaidIds: ids,
          });
        }

        toast.success(t("toast.profileSubAgenciesStatement.update"));
      } catch (error: any) {
        handleError({ t, error });
      } finally {
        setRowSelection({});
        setTargetStatus(null);
      }
    },
    [rowSelection, setRowSelection, t, updateStatement],
  );

  return (
    <S.Wrapper>
      <Button
        type="submit"
        variant="success"
        isLoading={
          isUpdatingStatement && targetStatus === SubAgencyStatementStatus.Paid
        }
        isDisabled={
          isUpdatingStatement &&
          targetStatus === SubAgencyStatementStatus.Pending
        }
        onClick={() => handlePaidStatus(SubAgencyStatementStatus.Paid)}
      >
        {t("profileSubAgenciesStatement.actions.setAsPaid")}
      </Button>
      <Button
        type="submit"
        variant="secondary"
        isLoading={
          isUpdatingStatement &&
          targetStatus === SubAgencyStatementStatus.Pending
        }
        isDisabled={
          isUpdatingStatement && targetStatus === SubAgencyStatementStatus.Paid
        }
        onClick={() => handlePaidStatus(SubAgencyStatementStatus.Pending)}
      >
        {t("profileSubAgenciesStatement.actions.setAsPending")}
      </Button>
    </S.Wrapper>
  );
};

export default ProfileSubAgenciesStatementsItemsActions;
