import { useHotelDetails } from "@hotelspoint/api";
import { useModalContainerStore } from "@hotelspoint/store";
import { HotelRate, RateType } from "@hotelspoint/types";
import {
  getImagePathFromCDN,
  getRateTypeName,
  ImageType,
} from "@hotelspoint/utils";
import { IconCircleCheckFilled } from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import Button from "../../elements/Button";
import ButtonFavorite from "../../elements/ButtonFavorite";
import Checklist from "../../elements/Checklist";
import NumericRating from "../../elements/NumericRating";
import Rating from "../../elements/Rating";
import Price from "../../misc/Price";
import GalleryOverlay from "../GalleryOverlay";
import HotelDetailsModal from "../HotelDetailsModal";
import thumbPlaceholder from "../HotelListing/assets/thumb-placeholder.jpg";
import HotelRoomsModal, { TableType } from "../HotelRoomsModal";
import MarkupPrice from "../MarkupPrice";
import * as S from "./HotelListingSmall.styled";

interface HotelListingSmallProps {
  id: number;
  searchId: number;
  isFavorite: boolean;
  thumbnail: string | null;
  name: string;
  rating: number;
  bookingRating?: number;
  roomName: string;
  rate: HotelRate;
  handleFavorite: (state: boolean) => void;
}

const HotelListingSmall = ({
  id,
  searchId,
  isFavorite,
  thumbnail,
  name,
  rating,
  bookingRating,
  roomName,
  rate,
  handleFavorite,
}: HotelListingSmallProps) => {
  const { t } = useTranslation();

  const [hotelDetails, isLoadingHotelDetails] = useHotelDetails(id);

  const openModal = useModalContainerStore(state => state.openModal);

  const { search: locationSearch } = useLocation();

  const rateOptions = useMemo(
    () => [
      {
        id: `${id}:rate_meal`,
        title: rate.mealTypeName,
        available: true,
      },
      {
        id: `${id}:rate_type`,
        title: t(getRateTypeName(rate.rateType)),
        available: rate.rateType === RateType.Refundable,
      },
    ],
    [t, id, rate.mealTypeName, rate.rateType],
  );

  const search = useMemo(() => {
    const params = new URLSearchParams(locationSearch);
    params.delete("view");
    params.delete("useCache");

    return params.toString();
  }, [locationSearch]);

  return (
    <S.Wrapper $elevation={3}>
      <S.ThumbnailWrapper>
        <S.Thumbnail
          onClick={() =>
            openModal({
              title: t("components.hotelListingSmall.gallery"),
              content: (
                <GalleryOverlay
                  images={hotelDetails?.images ?? []}
                  isLoading={isLoadingHotelDetails}
                  imageType={ImageType.Hotel}
                  initialSlideIndex={0}
                />
              ),
            })
          }
          $src={
            thumbnail
              ? getImagePathFromCDN(thumbnail as string, ImageType.Hotel)
              : thumbPlaceholder
          }
        />
        <S.ThumbnailActions>
          <ButtonFavorite
            active={isFavorite}
            onClick={() => handleFavorite(isFavorite)}
          />
        </S.ThumbnailActions>
      </S.ThumbnailWrapper>
      <S.Content>
        <S.Header>
          <S.HeaderTitle>
            <S.HotelName>
              <S.TitleLink
                target="_blank"
                to={{ pathname: `/hotels/search/${id}`, search }}
              >
                {name}
              </S.TitleLink>
            </S.HotelName>
            <Rating value={rating} />
          </S.HeaderTitle>
          {bookingRating && <NumericRating value={bookingRating} size={22} />}
        </S.Header>
        <S.RoomName>{roomName}</S.RoomName>
        <Checklist
          items={rateOptions}
          orientation="horizontal"
          components={{
            unchecked: IconCircleCheckFilled,
          }}
        />
        <S.Footer>
          <S.MarkupPriceWrapper>
            <MarkupPrice
              value={{
                total: rate.price,
                net: rate.priceNet,
              }}
            >
              <S.Emphasized>
                {t("components.hotelListing.price.from")}
              </S.Emphasized>
              <S.PriceWrapper>
                <Price value={rate.price} />
              </S.PriceWrapper>
            </MarkupPrice>
          </S.MarkupPriceWrapper>

          <S.ButtonWrapper>
            <Button
              small
              variant="outlined"
              onClick={() =>
                openModal({
                  title: t("components.hotelListingSmall.showDetails"),
                  content: <HotelDetailsModal id={id} />,
                })
              }
            >
              {t("components.hotelListingSmall.showDetails")}
            </Button>
            <Button
              small
              variant="tertiary"
              onClick={() =>
                openModal({
                  title: t("components.hotelListingSmall.showRooms"),
                  content: (
                    <HotelRoomsModal
                      hotelId={id}
                      searchId={searchId}
                      tableType={TableType.HOTELS}
                    />
                  ),
                })
              }
            >
              {t("components.hotelListingSmall.showRooms")}
            </Button>
          </S.ButtonWrapper>
        </S.Footer>
      </S.Content>
    </S.Wrapper>
  );
};

export default HotelListingSmall;
