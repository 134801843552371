import {
  handleError,
  PaymentStatus,
  PaymentType,
  ReservationStatus,
  useCreatePayment,
  useFetchAgency,
  useReservation,
  useUploadPaymentFile,
} from "@hotelspoint/api";
import {
  Button,
  Chip,
  Dialog,
  Form,
  FormContext,
  LinkExternal,
  Modal,
  PaymentOptions,
} from "@hotelspoint/components";
import {
  entity2Form,
  form2Entity,
  FormValues,
  redirectCardPayment,
  validationSchema,
} from "@hotelspoint/components/src/composites/PaymentOptions";
import { useUserAgencyStore, useUserTokenStore } from "@hotelspoint/store";
import {
  createFormData,
  formatDate,
  getApiUrl,
  getPaymentStatusColor,
  getPaymentStatusName,
  getReservationStatusColor,
  getReservationStatusName,
} from "@hotelspoint/utils";
import { useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import ProfileReservationSendVoucher from "../ProfileReservationSendVoucher";
import * as S from "./ProfileReservationSummary.styled";

interface ProfileReservationSummaryProps {
  id: number;
}

const ProfileReservationSummary = ({ id }: ProfileReservationSummaryProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);

  const token = useUserTokenStore(state => state.token);
  const { agency, set: setAgency } = useUserAgencyStore(state => state);

  const [reservation, isLoading] = useReservation(id);

  const [uploadFile, isUploadingFile] = useUploadPaymentFile();
  const [createPayment, isCreatingPayment] = useCreatePayment();
  const [fetchAgency] = useFetchAgency();

  const isSubmitting = isUploadingFile || isCreatingPayment;

  const initialValues = useMemo(() => {
    return entity2Form({
      countryCode: agency?.countryCode as string,
    });
  }, [agency?.countryCode]);

  const onSubmit = async (values: FormValues) => {
    try {
      // Upload a payment file if needed
      let fileId;
      if (values.file) {
        const formData = createFormData(values.file);
        const payload = await uploadFile(formData);

        fileId = payload.fileId;
      }

      const payload = form2Entity({
        ...values,
        fileId,
      });

      const response = await createPayment({
        ...payload,
        reservationId: id,
      });

      // Create a form with the payment URL and params, and submit it
      if (response.paymentType === PaymentType.CreditCard) {
        redirectCardPayment(response.paymentUrl, response.paymentUrlParams);
      }

      // Update the credit state of the current agency
      if (response.paymentType === PaymentType.CreditLine) {
        const newAgency = await fetchAgency(agency?.id as number);
        setAgency(newAgency);
      }

      setIsPaymentDialogOpen(false);
    } catch (err: any) {
      handleError({ t, error: err });
    } finally {
      // Invalidate the reservation query on payment creation
      queryClient.invalidateQueries({
        queryKey: ["reservations", id],
      });
    }
  };

  return (
    <>
      <Dialog open={isPaymentDialogOpen} onOpenChange={setIsPaymentDialogOpen}>
        <Dialog.Content>
          <Modal
            title={t("profileReservation.actions.makePayment.modal.title")}
            onClose={() => setIsPaymentDialogOpen(false)}
          >
            <Form
              defaultValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize
            >
              <PaymentOptions
                amount={reservation?.itemData?.priceNet as number}
                isDisabled={isSubmitting}
              />
              <S.Actions>
                <Button
                  type="submit"
                  variant="outlined"
                  onClick={() => setIsPaymentDialogOpen(false)}
                >
                  {t("components.paymentOptions.cancel")}
                </Button>
                <FormContext<FormValues>
                  render={({ handleSubmit }) => (
                    <Button
                      type="submit"
                      variant="tertiary"
                      isLoading={isCreatingPayment}
                      onClick={handleSubmit(onSubmit)}
                    >
                      {t("components.paymentOptions.confirm")}
                    </Button>
                  )}
                />
              </S.Actions>
            </Form>
          </Modal>
        </Dialog.Content>
      </Dialog>
      <S.VouchersWrapper>
        <div>
          <S.Title>{t("profileReservation.metricCards.bookingNumber")}</S.Title>
          <h5>{isLoading ? <Skeleton /> : id}</h5>
        </div>
        <S.VerticalDivider />
        <div>
          <S.Title>{t("profileReservation.metricCards.vouchers")}</S.Title>
          <S.VoucherActions>
            <LinkExternal
              to={getApiUrl(`reservations/${id}/vouchers/preview`, {
                token,
              })}
            >
              <Button small variant="outlined" isLoading={isLoading}>
                {t("profileReservation.actions.vouchers.download")}
              </Button>
            </LinkExternal>
            <ProfileReservationSendVoucher id={id} />
          </S.VoucherActions>
        </div>
      </S.VouchersWrapper>
      <S.Divider />
      <S.InformationWrapper>
        <S.InformationBlockWrapper>
          <S.InformationBlock>
            <S.InformationBlockRow>
              <p>{t("profileReservation.bookingStatus")}</p>
              {isLoading ? (
                <S.FlexGrow>
                  <Skeleton />
                </S.FlexGrow>
              ) : (
                <Chip
                  $color={getReservationStatusColor(
                    reservation?.status as ReservationStatus,
                  )}
                >
                  {t(
                    getReservationStatusName(
                      reservation?.status as ReservationStatus,
                    ),
                  )}
                </Chip>
              )}
            </S.InformationBlockRow>
            <S.InformationBlockRow>
              <p>{t("profileReservation.deadline")}</p>
              {isLoading ? (
                <S.FlexGrow>
                  <Skeleton />
                </S.FlexGrow>
              ) : (
                <p>{formatDate(reservation?.dateDeadline as string)}</p>
              )}
            </S.InformationBlockRow>
          </S.InformationBlock>
          <S.InformationBlock>
            <S.InformationBlockRow>
              <p>{t("profileReservation.paymentStatus")}</p>
              {isLoading ? (
                <S.FlexGrow>
                  <Skeleton />
                </S.FlexGrow>
              ) : (
                <Chip
                  $color={getPaymentStatusColor(
                    reservation?.paymentStatus as PaymentStatus,
                  )}
                >
                  {t(
                    getPaymentStatusName(
                      reservation?.paymentStatus as PaymentStatus,
                    ),
                  )}
                </Chip>
              )}
            </S.InformationBlockRow>
            <S.InformationBlockRow>
              <p>{t("profileReservation.paymentDeadline")}</p>
              {isLoading ? (
                <S.FlexGrow>
                  <Skeleton />
                </S.FlexGrow>
              ) : (
                <p>{formatDate(reservation?.dateDeadline as string)}</p>
              )}
              {/* when Dido is ready this should be changed with payment deadline */}
            </S.InformationBlockRow>
          </S.InformationBlock>
        </S.InformationBlockWrapper>
        <Button
          variant="success"
          onClick={() => setIsPaymentDialogOpen(true)}
          isLoading={isLoading}
          isDisabled={reservation?.paymentStatus === PaymentStatus.Confirmed}
        >
          {t("profileReservation.actions.makePayment.button")}
        </Button>
      </S.InformationWrapper>
      <S.Divider />
    </>
  );
};

export default ProfileReservationSummary;
