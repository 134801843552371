import {
  handleError,
  useAgency,
  useCreateUser,
  useUpdateUser,
} from "@hotelspoint/api";
import {
  Button,
  Dialog,
  Form,
  FormAdapter,
  FormContext,
  FormInput,
  FormLayout,
  FormSwitch,
  Modal,
  ModalSize,
} from "@hotelspoint/components";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  defaultValues,
  form2Entity,
  form2EntityUpdate,
  FormValues,
  validationSchema,
} from "./ProfileSubAgencySettingsAgentDialog.util";

interface ProfileSubAgencySettingsAgentDialogProps {
  id: number;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  agentId: number | null;
}

const ProfileSubAgencySettingsAgentDialog = ({
  id,
  isOpen,
  setIsOpen,
  agentId,
}: ProfileSubAgencySettingsAgentDialogProps) => {
  const { t } = useTranslation();

  const [createUser, isCreatingUser] = useCreateUser();
  const [updateUser, isUpdatingUser] = useUpdateUser(agentId as number);
  const [agency] = useAgency(id);

  const user = useMemo(() => {
    return agentId ? agency?.agents.find(agent => agent.id === agentId) : null;
  }, [agentId, agency]);

  const isDisabled = isCreatingUser || isUpdatingUser;

  const queryClient = useQueryClient();

  const formValues = useMemo(() => {
    return user ? { ...user } : defaultValues;
  }, [user]);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        if (!user?.id) {
          const payload = {
            ...form2Entity(formValues),
            agencyId: id,
          };

          await createUser(payload);
          setIsOpen(false);

          // Invalidate the agency query cache
          queryClient.invalidateQueries({
            queryKey: ["agencies", id],
          });

          toast.success(t("toast.profileSubAgencySettings.addAgent"));
        } else {
          const payload = form2EntityUpdate(formValues);

          await updateUser(payload);
          setIsOpen(false);

          // Invalidate the agency query cache
          queryClient.invalidateQueries({
            queryKey: ["agencies", id],
          });

          toast.success(t("toast.profileSubAgencySettings.updateAgent"));
        }
      } catch (error: any) {
        handleError({ t, error });
      }
    },
    [user?.id, id, createUser, setIsOpen, queryClient, t, updateUser],
  );

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <Form
      defaultValues={formValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <Dialog.Content>
          <Modal
            title={t("profileSubAgencySettings.actions.modal.title")}
            size={ModalSize.Small}
            onClose={() => setIsOpen(false)}
          >
            <FormLayout>
              <FormAdapter
                name="name"
                label={t("profileSubAgencySettings.actions.modal.name.label")}
              >
                {props => (
                  <FormInput
                    {...props}
                    placeholder={t(
                      "profileSubAgencySettings.actions.modal.name.placeholder",
                    )}
                    disabled={isDisabled}
                  />
                )}
              </FormAdapter>
              <FormAdapter
                name="email"
                label={t("profileSubAgencySettings.actions.modal.email.label")}
              >
                {props => (
                  <FormInput
                    {...props}
                    placeholder={t(
                      "profileSubAgencySettings.actions.modal.email.placeholder",
                    )}
                    disabled={isDisabled}
                  />
                )}
              </FormAdapter>
              <FormAdapter
                name="isActive"
                label={t(
                  "profileSubAgencySettings.actions.modal.isActive.label",
                )}
              >
                {props => <FormSwitch {...props} disabled={isDisabled} />}
              </FormAdapter>
            </FormLayout>
            <Modal.Actions>
              <Button variant="outlined" onClick={handleCancel}>
                {t("profileSubAgencySettings.actions.modal.cancel")}
              </Button>
              <FormContext<FormValues>
                render={({ handleSubmit }) => (
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isCreatingUser}
                  >
                    {t("profileSubAgencySettings.actions.modal.confirm")}
                  </Button>
                )}
              />
            </Modal.Actions>
          </Modal>
        </Dialog.Content>
      </Dialog>
    </Form>
  );
};

export default ProfileSubAgencySettingsAgentDialog;
