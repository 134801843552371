import { AgencyCreatePayload } from "@hotelspoint/api";
import { Agency } from "@hotelspoint/types";
import {
  SCHEMA_COUNTRY,
  SCHEMA_EMAIL,
  SCHEMA_NAMES,
  SCHEMA_PHONE,
} from "@hotelspoint/utils";
import { InferType, mixed, number, object } from "yup";

export const validationSchema = object({
  email: SCHEMA_EMAIL,
  name: SCHEMA_NAMES,
  companyManager: SCHEMA_NAMES,
  companyPhone: SCHEMA_PHONE,
  countryCode: SCHEMA_COUNTRY,
  markupHotelsOperator: number()
    .typeError(
      "profileSubAgenciesRegister.form.markupHotels.validation.required",
    )
    .required(
      "profileSubAgenciesRegister.form.markupHotels.validation.required",
    ),
  markupTransfersOperator: number()
    .typeError(
      "profileSubAgenciesRegister.form.markupTransfers.validation.required",
    )
    .required(
      "profileSubAgenciesRegister.form.markupTransfers.validation.required",
    ),
  markupActivitiesOperator: number()
    .typeError(
      "profileSubAgenciesRegister.form.markupActivities.validation.required",
    )
    .required(
      "profileSubAgenciesRegister.form.markupActivities.validation.required",
    ),
  creditLimit: number().required(
    "profileSubAgenciesRegister.form.creditLimit.validation.required",
  ),
  // Read-only
  creditUsed: mixed().nullable(),
  creditBalance: mixed().nullable(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  email: "",
  name: "",
  companyManager: "",
  companyPhone: "",
  countryCode: "",
  markupHotelsOperator: 5,
  markupTransfersOperator: 5,
  markupActivitiesOperator: 5,
  creditLimit: 0,
  // Read-only
  creditUsed: 0,
  creditBalance: 0,
};

export const form2Entity = (formValues: FormValues): AgencyCreatePayload => ({
  email: formValues.email,
  name: formValues.name,
  companyManager: formValues.companyManager,
  companyPhone: formValues.companyPhone,
  countryCode: `${formValues.countryCode}`.toUpperCase(),
  markupHotelsOperator: formValues.markupHotelsOperator,
  markupTransfersOperator: formValues.markupTransfersOperator,
  markupActivitiesOperator: formValues.markupActivitiesOperator,
  credit: {
    limit: formValues.creditLimit,
  },
});

export const entity2Form = (entity: Agency) => {
  return {
    email: entity.email ?? "",
    name: entity.name ?? "",
    companyManager: entity.companyManager ?? "",
    companyPhone: entity.companyPhone ?? "",
    countryCode: `${entity.countryCode}`.toLowerCase(),
    markupHotelsOperator: entity.markupHotelsOperator ?? 5,
    markupTransfersOperator: entity.markupTransfersOperator ?? 5,
    markupActivitiesOperator: entity.markupActivitiesOperator ?? 5,
    creditUsed: entity.credit.used ?? 0,
    creditBalance: entity.credit.balance ?? 0,
    creditLimit: entity.credit.limit ?? 0,
  };
};

export const form2EntityUpdate = (formValues: FormValues) => ({
  email: formValues.email,
  name: formValues.name,
  companyManager: formValues.companyManager,
  companyPhone: formValues.companyPhone,
  countryCode: `${formValues.countryCode}`.toUpperCase(),
  markupHotelsOperator: formValues.markupHotelsOperator,
  markupTransfersOperator: formValues.markupTransfersOperator,
  markupActivitiesOperator: formValues.markupActivitiesOperator,
  credit: {
    limit: formValues.creditLimit,
  },
});
