import { flip, offset } from "@floating-ui/react";
import {
  handleError,
  Place,
  useAddHotelToFavorites,
  useRemoveHotelFromFavorites,
} from "@hotelspoint/api";
import useHotelsSearchResultStore from "@hotelspoint/store/src/useHotelsSearchResultStore.ts";
import { mediaQuery } from "@hotelspoint/theme";
import { HotelSearch, HotelSearchMap } from "@hotelspoint/types";
import { useMediaQuery } from "@hotelspoint/utils";
import { IconAdjustmentsHorizontal, IconX } from "@tabler/icons-react";
import { type BBox } from "geojson";
import { useCallback, useMemo, useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { MapRef, Marker } from "react-map-gl";
import useSupercluster from "use-supercluster";

import HotelListingSmall from "../../composites/HotelListingSmall";
import Popover from "../../composites/Popover";
import Button from "../../elements/Button";
import { VisibleMobile, VisibleTablet } from "../../misc";
import Price from "../../misc/Price";
import { MAP_ANIMATION_DURATION, MAP_CLUSTER_RADIUS } from "../constants";
import Map from "../Map";
import MapCluster from "../MapCluster";
import { useMapContext } from "../MapContext";
import MapLabel from "../MapLabel";
import * as S from "./HotelMap.styled";

export const MAP_ZOOM = {
  DEFAULT: 12,
  POI: 16,
  MAX: 20,
};

interface HotelMapOnSelectProps {
  longitude: number;
  latitude: number;
  zoom: number;
}

interface HotelMapProps {
  hotels: HotelSearch[];
  initialPlace: Place;
  controls?: React.ReactNode;
  activeHotelId: number | null;
  handleClose?: () => void;
  toggleFilters?: () => void;
}

const HotelMap = ({
  hotels,
  initialPlace,
  controls,
  activeHotelId,
  handleClose,
  toggleFilters,
}: HotelMapProps) => {
  const { t } = useTranslation();

  const { mapInstance, setMapInstance } = useMapContext();

  const isTablet = useMediaQuery(mediaQuery.tablet);
  const isDesktop = useMediaQuery(mediaQuery.desktop);

  const [hotelId, setHotelId] = useState<number | null>(activeHotelId);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(!!activeHotelId);

  const searchId = useHotelsSearchResultStore(state => state.id);

  const [viewState, setViewState] = useState({
    longitude: initialPlace?.position.longitude,
    latitude: initialPlace?.position.latitude,
    zoom: MAP_ZOOM.DEFAULT,
  });

  const [addToFavorites] = useAddHotelToFavorites();
  const [removeFromFavorites] = useRemoveHotelFromFavorites();

  const transformHotelData = useMemo(() => {
    return {
      type: "HotelCollection",
      features: hotels.map(hotel => ({
        type: "Feature",
        properties: {
          cluster: false,
          ...hotel,
        },
        geometry: {
          type: "Point",
          coordinates: [hotel.longitude, hotel.latitude],
        },
      })),
    };
  }, [hotels]);

  const { clusters, supercluster } = useSupercluster({
    points: transformHotelData.features
      ? (transformHotelData.features as HotelSearchMap[])
      : [],
    bounds: mapInstance?.getMap().getBounds().toArray().flat() as BBox,
    zoom: viewState.zoom,
    options: {
      radius: MAP_CLUSTER_RADIUS,
      maxZoom: MAP_ZOOM.MAX,
    },
  });

  const handleFavoriteChange =
    (hotelId: number) => async (isFavorite: boolean) => {
      try {
        if (isFavorite) {
          await removeFromFavorites(hotelId);
        } else {
          await addToFavorites(hotelId);
        }
      } catch (error: any) {
        handleError({ t, error });
      }
    };

  const mapRef = useCallback(
    (node: MapRef) => {
      if (node !== null) {
        setMapInstance(node);

        const mapInstance = node.getMap();
        mapInstance.on("style.load", () => {
          mapInstance.setFilter("poi-label", [
            "all",
            ["!=", "category_en", "Hotel"],
            ["!=", "category_en", "Restaurant"],
            ["!=", "category_en", "Cafe"],
            ["!=", "category_en", "Supermarket"],
            ["!=", "category_en", "Bar"],
            ["!=", "category_en", "Pub"],
            ["!=", "category_en", "Clothing"],
            ["!=", "category_en", "Clothes Store"],
          ]);
        });
      }
    },
    [setMapInstance],
  );

  const onSelect = useCallback(
    ({ longitude, latitude, zoom }: HotelMapOnSelectProps) => {
      mapInstance?.flyTo({
        center: [longitude, latitude],
        zoom,
        duration: MAP_ANIMATION_DURATION,
      });
    },
    [mapInstance],
  );

  const onClusterClick = useCallback(
    (id: number, longitude: number, latitude: number) => {
      if (!supercluster) return;

      const expansionZoom = Math.min(
        supercluster.getClusterExpansionZoom(id),
        20,
      );

      onSelect({ longitude, latitude, zoom: expansionZoom });
    },
    [onSelect, supercluster],
  );

  return (
    <>
      <S.UpperControls>
        {toggleFilters && (
          <VisibleMobile>
            <Button variant="outlined" onClick={() => toggleFilters()}>
              <IconAdjustmentsHorizontal size={16} />
              <span>{t("components.mapLayout.filters.title")}</span>
            </Button>
          </VisibleMobile>
        )}
        <VisibleTablet>
          <S.UpperMapControlsWrapper>
            {controls}
            {handleClose && (
              <Button
                variant="outlined"
                onClick={handleClose}
                style={{ padding: "0 16px" }}
              >
                {isDesktop && <span>{t("components.mapLayout.close")}</span>}
                <IconX size={16} />
              </Button>
            )}
          </S.UpperMapControlsWrapper>
        </VisibleTablet>
      </S.UpperControls>
      <Map
        ref={mapRef}
        initialViewState={{ ...viewState }}
        onMove={evt => setViewState(evt.viewState)}
        maxZoom={MAP_ZOOM.MAX}
        style={{ height: "100vh" }}
      >
        {clusters?.map(cluster => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } =
            cluster.properties;

          if (isCluster) {
            return (
              <MapCluster
                key={cluster.id}
                latitude={latitude}
                longitude={longitude}
                count={pointCount as number}
                totalCount={transformHotelData.features?.length as number}
                onClick={() =>
                  onClusterClick(cluster.id as number, longitude, latitude)
                }
              />
            );
          }
          return (
            <Marker
              key={cluster.properties.id}
              latitude={latitude}
              longitude={longitude}
            >
              <Popover
                open={isPopoverOpen && hotelId === cluster.properties.id}
                onOpenChange={value => {
                  setIsPopoverOpen(value);

                  if (!value) {
                    setHotelId(null);
                  }
                }}
                placement="top"
                middleware={[
                  offset(8),
                  flip({
                    fallbackAxisSideDirection: "end",
                  }),
                ]}
              >
                <Popover.Trigger
                  onClick={() => {
                    setHotelId(cluster.properties.id);
                  }}
                >
                  <MapLabel isActive={hotelId === cluster.properties.id}>
                    <Price
                      value={cluster.properties.cheapestRate.rooms[0].price}
                      enablePriceRounding
                    />
                  </MapLabel>
                </Popover.Trigger>
                <Popover.Content
                  style={
                    isTablet
                      ? {}
                      : {
                          position: "fixed",
                          top: "unset",
                          bottom: "16px",
                          left: "50%",
                          transform: `translateX(-50%)`,
                        }
                  }
                >
                  <HotelListingSmall
                    id={cluster.properties.id}
                    searchId={searchId as number}
                    handleFavorite={handleFavoriteChange(cluster.properties.id)}
                    isFavorite={cluster.properties.isFavorite}
                    thumbnail={cluster.properties.thumbnail}
                    name={cluster.properties.name}
                    rating={cluster.properties.rating}
                    roomName={cluster.properties.cheapestRate.rooms[0].roomName}
                    rate={cluster.properties.cheapestRate}
                  />
                </Popover.Content>
              </Popover>
            </Marker>
          );
        })}
      </Map>
    </>
  );
};

export default HotelMap;
