import { PlaceType } from "@hotelspoint/types";
import { isDateBefore } from "@hotelspoint/utils";
import {
  IconCalendar,
  IconManFilled,
  IconMoon,
  IconPlaneInflight,
  IconUserCog,
  IconUsers,
} from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import PlaceTypeIcon from "../PlaceTypeIcon";
import Tooltip from "../Tooltip";
import * as S from "./PackageSearchCard.styled";

interface PackageSearchCardProps {
  name: string;
  program: string;
  date: string;
  nights: number;
  origin: string;
  adults: number;
  children: number;
}

const PackageSearchCard = ({
  name,
  program,
  date,
  nights,
  origin,
  adults,
  children,
}: PackageSearchCardProps) => {
  const { t } = useTranslation();

  const dateToday = useMemo(() => new Date(), []);
  const isCheckInOutdated = useMemo(
    () => isDateBefore(new Date(date), dateToday),
    [date, dateToday],
  );

  return (
    <S.Wrapper>
      <S.Detail>
        <PlaceTypeIcon type={PlaceType.Destination} iconProps={{ size: 20 }} />
        <S.Title title={name}>{name}</S.Title>
      </S.Detail>
      <S.Detail>
        <IconUserCog size={20} />
        <S.Title title={program}>{program}</S.Title>
      </S.Detail>
      <S.FlexWrapper>
        <S.Detail>
          <IconCalendar size={20} />
          {isCheckInOutdated ? (
            <Tooltip placement="right">
              <Tooltip.Trigger>
                <s>{date}</s>
              </Tooltip.Trigger>
              <Tooltip.Content>
                {t("components.packageSearchCard.expiredDate")}
              </Tooltip.Content>
            </Tooltip>
          ) : (
            <span>{date}</span>
          )}
        </S.Detail>
        <S.Detail>
          <IconMoon size={20} />
          <span>{nights}</span>
        </S.Detail>
      </S.FlexWrapper>
      <S.FlexWrapper>
        <S.Detail>
          <IconPlaneInflight size={20} />
          <span>{origin}</span>
        </S.Detail>
        <S.Detail>
          <IconUsers size={20} />
          <span>{adults}</span>
          {children !== 0 && (
            <>
              <IconManFilled size={18} />
              <span>{children}</span>
            </>
          )}
        </S.Detail>
      </S.FlexWrapper>
    </S.Wrapper>
  );
};

export default PackageSearchCard;
