import {
  Button,
  HotelBaseInfo,
  HotelDetailsModal,
} from "@hotelspoint/components";
import { useModalContainerStore } from "@hotelspoint/store";
import { useTranslation } from "react-i18next";

import * as S from "./ProfileOfferHotelDetails.styled";

interface ProfileOfferHotelDetailsProps {
  id: number;
}

const ProfileOfferHotelDetails = ({ id }: ProfileOfferHotelDetailsProps) => {
  const { t } = useTranslation();

  const openModal = useModalContainerStore(state => state.openModal);

  return (
    <S.DetailsWrapper>
      <div>
        <S.Heading>{t("profileOffer.hotel")}</S.Heading>
        <HotelBaseInfo id={id} />
      </div>
      <Button
        variant="outlined"
        onClick={() =>
          openModal({
            title: t("profileOffer.hotelDetails"),
            content: <HotelDetailsModal id={id} />,
          })
        }
      >
        {t("profileOffer.hotelDetails")}
      </Button>
    </S.DetailsWrapper>
  );
};

export default ProfileOfferHotelDetails;
