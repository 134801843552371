import { HotelSearchFilters, HotelsFilterParams } from "@hotelspoint/api";
import { array, boolean, InferType, number, object, string } from "yup";
export const validationSchema = object({
  name: string(),
  room: string(),
  price: array().of(number()),
  priceRange: array().of(number()),
  rating: array().of(boolean()),
  ratingFilter: array().of(object({ id: number(), count: number() })),
  meal: array().of(boolean()),
  mealFilter: array().of(
    object({ id: number(), name: string(), count: number() }),
  ),
  rate: array().of(boolean()),
  rateFilter: array().of(
    object({ id: number(), name: string(), count: number() }),
  ),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  name: "",
  room: "",
  price: [],
  priceRange: [],
  rating: [],
  ratingFilter: [],
  meal: [],
  mealFilter: [],
  rate: [],
  rateFilter: [],
};

export const entity2Form = (
  entity: HotelSearchFilters,
  filters: HotelsFilterParams,
) => {
  const { price, rating, meal, rate } = entity;

  return {
    name: filters?.name ?? undefined,
    room: filters?.room ?? undefined,

    price: filters?.price ? filters.price : [price.min, price.max],
    priceRange: [price.min, price.max],

    rating: filters?.rating
      ? rating?.map(({ id }) => filters.rating?.includes(id))
      : rating?.map(() => false),
    ratingFilter: rating?.sort((a, b) => b.id - a.id),

    meal: filters?.meal
      ? meal?.map(({ id }) => filters.meal?.includes(id))
      : meal?.map(() => false),
    mealFilter: meal,

    rate: filters?.rate
      ? rate?.map(({ id }) => filters.rate?.includes(id))
      : rate?.map(() => false),
    rateFilter: rate,
  };
};

export const form2Entity = (formValues: FormValues) => {
  const {
    name,
    room,
    price,
    rating,
    ratingFilter,
    meal,
    mealFilter,
    rate,
    rateFilter,
  } = formValues;
  const [min, max] = price ?? [];

  return {
    name,
    room,
    price: [min as number, max as number],
    rating: rating
      ?.map((value, index) =>
        value && ratingFilter?.[index] ? ratingFilter[index].id : null,
      )
      .filter(Boolean),
    meal: meal
      ?.map((value, index) =>
        value && mealFilter?.[index] ? mealFilter[index].id : null,
      )
      .filter(Boolean),
    rate: rate
      ?.map((value, index) =>
        value && rateFilter?.[index] ? rateFilter[index].id : null,
      )
      .filter(Boolean),
  };
};
