import { differenceInDays, differenceInMinutes, format } from "date-fns";

export {
  addDays,
  eachDayOfInterval,
  endOfWeek,
  lastDayOfMonth as getLastDayOfMonth,
  isBefore as isDateBefore,
  isToday as isDateToday,
  isValid as isDateValid,
  isSameDay,
  startOfWeek,
  subDays,
} from "date-fns";

import { DateRange, DateType } from "./types";

// Utility supports 24H format only (00:00 - 23:59)
export const isTimeValid = (value: string) => {
  return new RegExp(/^([01][0-9]|2[0-3]):([0-5][0-9])$/).test(value);
};

export const daysBetween = ({ from, to }: DateRange) => {
  return differenceInDays(to, from);
};

export const timeBetween = (from: Date, to: Date) => {
  return differenceInMinutes(to, from);
};

export const getFirstDayOfMonth = (date: DateType) => {
  return format(date, "yyyy-MM-01");
};

export const getPreviousMonthDate = (date: Date) => {
  const currentDate = new Date(date);
  const previousMonth = new Date(currentDate.getTime());

  previousMonth.setDate(0);

  return previousMonth;
};

export const setTimeInDate = (date: Date, time: string) => {
  if (!date || !time) return "";

  const [hours, minutes] = time.split(":");

  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    Number(hours),
    Number(minutes),
  );
};
