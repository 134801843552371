import { Transfer } from "@hotelspoint/api";
import { useTranslation } from "react-i18next";

import * as S from "./SearchTransferBookContent.styled";
import SearchTransferBookForm from "./SearchTransferBookForm";

interface SearchTransferBookContentProps {
  searchId: number;
  rate?: Transfer;
  isLoading: boolean;
}

const SearchTransferBookContent = ({
  searchId,
  rate,
  isLoading,
}: SearchTransferBookContentProps) => {
  const { t } = useTranslation();

  if (!rate) {
    return null;
  }

  return (
    <S.Card>
      <S.Title>{t("searchTransferBook.content.passengers")}</S.Title>
      <SearchTransferBookForm
        rate={rate}
        searchId={searchId}
        isLoading={isLoading}
      />
    </S.Card>
  );
};

export default SearchTransferBookContent;
