import { ReservationNames, useReservation } from "@hotelspoint/api";
import {
  LoaderBlock,
  Price,
  StatusBlock,
  StatusFlavour,
} from "@hotelspoint/components";
import { IconManFilled, IconUserFilled } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

import ProfileReservationSummary from "../ProfileReservationSummary";
import * as S from "./ProfileReservationBaseInfo.styled";

interface ProfileReservationBaseInfoProps {
  id: number;
}

const filterByRoomId = (names: ReservationNames[], targetRoomId: number) => {
  return names.filter(name => name.roomId === targetRoomId);
};

const ProfileReservationBaseInfo = ({
  id,
}: ProfileReservationBaseInfoProps) => {
  const { t } = useTranslation();

  const [reservation, isLoading] = useReservation(id);

  return (
    <S.Container>
      <S.Card>
        <ProfileReservationSummary id={id} />
        <div>
          {isLoading ? (
            <LoaderBlock />
          ) : (
            reservation?.itemData?.rooms.map((room, roomKey) => (
              <S.RoomGuestsWrapper key={roomKey}>
                <S.Room>
                  <S.Title>
                    {t("profileReservation.table.roomName.label")}
                  </S.Title>
                  <S.RoomNameWrapper>
                    <S.RoomName>
                      {room.numRooms}x {room.roomName}
                    </S.RoomName>
                    <S.RoomIconsWrapper>
                      {Array.from({ length: room.adults }).map(
                        (_, adultIndex) => (
                          <IconUserFilled key={adultIndex} size={18} />
                        ),
                      )}
                      {room.children.map((_, childrenIndex) => (
                        <IconManFilled key={childrenIndex} size={18} />
                      ))}
                    </S.RoomIconsWrapper>
                  </S.RoomNameWrapper>
                  <S.MealTitle>
                    {reservation?.itemData.mealTypeName}
                  </S.MealTitle>
                  {Array.isArray(room.promotions) ? (
                    <S.StatusBlockWrapper>
                      {room.promotions.map((promotion, promotionIndex) => (
                        <StatusBlock
                          key={promotionIndex}
                          status={StatusFlavour.Info}
                        >
                          <S.StatusBlockHeader>
                            {t("searchHotelBook.content.promoOffer")}
                          </S.StatusBlockHeader>
                          <S.StatusBlockCopy>
                            {promotion.name}
                            {promotion.amount && (
                              <>
                                : <Price value={promotion.amount} />
                              </>
                            )}
                          </S.StatusBlockCopy>
                        </StatusBlock>
                      ))}
                    </S.StatusBlockWrapper>
                  ) : (
                    <p>-</p>
                  )}
                </S.Room>
                <S.VerticalDivider />
                <S.Guests>
                  {filterByRoomId(reservation?.itemData?.names, room.id).map(
                    (name, nameKey) => (
                      <div key={nameKey}>
                        {room.numRooms !== 1 ? (
                          <S.Title>
                            {t("profileReservation.guestInRoom", {
                              count: name.adults.length,
                              room: nameKey + 1,
                            })}
                          </S.Title>
                        ) : (
                          <S.Title>
                            {t("profileReservation.guest", {
                              count: name.adults.length,
                            })}
                          </S.Title>
                        )}
                        {name.adults.map((adult, adultKey) => (
                          <S.GuestsWrapper key={adultKey}>
                            <S.GuestName>
                              {adult.name} {adult.surname}
                            </S.GuestName>
                            <IconUserFilled size={18} />
                          </S.GuestsWrapper>
                        ))}
                        {name.children.length !== 0 &&
                          name.children.map((child, childKey) => (
                            <S.GuestsWrapper key={childKey}>
                              <S.GuestName>
                                {child.name} {child.surname}
                              </S.GuestName>
                              <IconManFilled size={18} />
                            </S.GuestsWrapper>
                          ))}
                      </div>
                    ),
                  )}
                </S.Guests>
              </S.RoomGuestsWrapper>
            ))
          )}
        </div>
      </S.Card>
    </S.Container>
  );
};

export default ProfileReservationBaseInfo;
