import { ActivityContent } from "@hotelspoint/api";
import { useModalContainerStore } from "@hotelspoint/store";
import { ImageType } from "@hotelspoint/utils";
import {
  IconCalendar,
  IconClock,
  IconLanguage,
  IconMapPin,
  IconTicket,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import Button from "../../elements/Button";
import ActivityDetailsModal from "../ActivityDetailsModal";
import * as S from "./ActivityBaseInfo.styled";

interface ActivityBaseInfoProps {
  name: string;
  place: string;
  session?: string | null;
  languages?: string | null;
  date: string;
  optionName?: string;
  images: string[];
  location?: string;
  price?: number;
  priceNet?: number;
  content: ActivityContent[];
  isLoading: boolean;
}

const ActivityBaseInfo = ({
  name,
  place,
  session,
  languages,
  date,
  optionName,
  images,
  location,
  price,
  priceNet,
  content,
  isLoading,
}: ActivityBaseInfoProps) => {
  const { t } = useTranslation();

  const openModal = useModalContainerStore(state => state.openModal);

  return (
    <S.Card>
      <S.Title>{isLoading ? <Skeleton /> : name}</S.Title>
      <S.List>
        <S.ListItem>
          {isLoading ? (
            <Skeleton />
          ) : (
            <>
              <IconMapPin size={14} />
              {place}
            </>
          )}
        </S.ListItem>
        {session && (
          <S.ListItem>
            <IconClock size={14} />
            {session}
          </S.ListItem>
        )}
        {languages && (
          <S.ListItem>
            <IconLanguage size={14} />
            {languages}
          </S.ListItem>
        )}
        <S.ListItem>
          <IconCalendar size={14} />
          {date}
        </S.ListItem>
        {!isLoading && optionName && (
          <S.ListItem>
            <IconTicket size={14} />
            {optionName}
          </S.ListItem>
        )}
      </S.List>
      <div>
        {isLoading ? (
          <Skeleton width={150} height={40} />
        ) : (
          <Button
            variant="outlined"
            onClick={() =>
              openModal({
                title: t("searchActivityBook.sidebar.activityDetails"),
                content: (
                  <ActivityDetailsModal
                    images={images}
                    name={name}
                    location={location as string}
                    price={price as number}
                    priceNet={priceNet as number}
                    imageType={ImageType.Activity}
                    content={content}
                    isLoading={isLoading}
                  />
                ),
                closeButton: true,
              })
            }
          >
            {t("searchActivityBook.sidebar.activityDetails")}
          </Button>
        )}
      </div>
    </S.Card>
  );
};

export default ActivityBaseInfo;
