import { useMutation, useQueryClient } from "@tanstack/react-query";

import { AgencyCreatePayload, AgencyService } from "../../services";

const useCreateAgency = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["agencies", "create"],
    mutationFn: (payload: AgencyCreatePayload) => {
      return AgencyService.create(payload);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ["agencies"],
      });
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useCreateAgency;
