import {
  handleError,
  useFetchAgencies,
  useUpdateUser,
  useUser,
} from "@hotelspoint/api";
import {
  Box,
  Button,
  Chip,
  Flex,
  Form,
  FormAdapter,
  FormContext,
  FormInput,
  FormSelect,
  FormSwitch,
  LoaderBlock,
  Panel,
} from "@hotelspoint/components";
import { FormAsyncSelect } from "@hotelspoint/components";
import { USER_ROLE_OPTIONS } from "@hotelspoint/constants";
import {
  getUserRoleColor,
  mapEntityToOption,
  useDocumentTitle,
} from "@hotelspoint/utils";
import { useCallback, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  defaultValues,
  entity2Form,
  form2Entity,
  FormValues,
  validationSchema,
} from "./AdminAgent.util";

interface AdminAgentParams extends Record<string, string> {
  id: string;
}

interface AdminAgentInnerProps {
  id: number;
}

const AdminAgentInner = ({ id }: AdminAgentInnerProps) => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminAgent.pageTitle"));

  const [agency] = useWatch({ name: ["agency"] });

  const [updateUser, isUpdatingUser] = useUpdateUser(id);
  const [fetchAgencies, isFetchingAgencies] = useFetchAgencies();

  const roleOptions = useMemo(
    () =>
      USER_ROLE_OPTIONS.map(role => ({
        ...role,
        label: (
          <Chip $color={getUserRoleColor(role.value)}>{t(role.label)}</Chip>
        ),
      })),
    [t],
  );

  const defaultAgencyOptions = useMemo(() => {
    if (!agency) return [];

    return [
      {
        value: agency.value,
        label: agency.label,
      },
    ];
  }, [agency]);

  const handleLoadAgencyOptions = useCallback(
    (name: string, callback: any) => {
      fetchAgencies({ name }).then(response => {
        if (response && Array.isArray(response.results)) {
          callback(response.results.map(mapEntityToOption));
        }
      });
    },
    [fetchAgencies],
  );

  const onSubmit = async (formValues: FormValues) => {
    try {
      const payload = form2Entity(formValues);
      await updateUser(payload);

      toast.success(t("toast.adminAgent"));
    } catch (error: any) {
      handleError({ t, error });
    }
  };

  return (
    <Panel title={t("adminAgent.pageTitle")}>
      <Flex mx={[0, 0, -1, -1]}>
        <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter name="name" label={t("adminAgent.name.label")}>
            {props => <FormInput {...props} disabled={isUpdatingUser} />}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter name="email" label={t("adminAgent.email.label")}>
            {props => <FormInput {...props} disabled={isUpdatingUser} />}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter name="agency" label={t("adminAgent.agency.label")}>
            {props => (
              <FormAsyncSelect
                {...props}
                defaultOptions={defaultAgencyOptions}
                isDisabled={isUpdatingUser}
                isLoading={isFetchingAgencies}
                loadOptions={handleLoadAgencyOptions}
                noOptionsMessage={() => t("adminAgent.agency.noOptions")}
                loadingMessage={() => t("adminAgent.agency.loading")}
              />
            )}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter name="roleId" label={t("adminAgent.roleId.label")}>
            {props => (
              <FormSelect
                {...props}
                disabled={isUpdatingUser}
                options={roleOptions}
              />
            )}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter name="isActive" label={t("adminAgent.isActive.label")}>
            {props => <FormSwitch {...props} disabled={isUpdatingUser} />}
          </FormAdapter>
        </Box>
      </Flex>
      <FormContext<FormValues>
        render={({ handleSubmit }) => (
          <Button
            type="submit"
            variant="secondary"
            isLoading={isUpdatingUser}
            onClick={handleSubmit(onSubmit)}
          >
            {t("adminAgent.submit")}
          </Button>
        )}
      />
    </Panel>
  );
};

const AdminAgent = () => {
  const { id } = useParams<AdminAgentParams>();
  const userId = Number(id);

  const [user, isLoadingUser] = useUser(userId);

  const formValues = useMemo(() => {
    if (!user) return defaultValues;

    return entity2Form(user);
  }, [user]);

  if (!userId) {
    return <Navigate to="/404" replace={true} />;
  }

  if (isLoadingUser) {
    return <LoaderBlock />;
  }

  return (
    <Form<FormValues>
      defaultValues={formValues}
      validationSchema={validationSchema}
    >
      {formMethods => <AdminAgentInner {...formMethods} id={userId} />}
    </Form>
  );
};

export default AdminAgent;
