import { TransferSearchFiltersState } from "@hotelspoint/api";
import { array, boolean, InferType, number, object, string } from "yup";

const filterSchema = object({
  id: string().required(),
  name: string().required(),
  count: number().required(),
});

export const validationSchema = object({
  price: array().of(number()),
  priceRange: array().of(number()),
  category: array().of(boolean()),
  categoryFilter: array().of(filterSchema),
  vehicle: array().of(boolean()),
  vehicleFilter: array().of(filterSchema),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  price: [],
  priceRange: [],
  category: [],
  categoryFilter: [],
  vehicle: [],
  vehicleFilter: [],
};

export const entity2Form = (entity: TransferSearchFiltersState) => {
  const { price, category, vehicle } = entity;

  return {
    price: [price.min, price.max],
    priceRange: [price.min, price.max],
    category: category?.map(() => false),
    categoryFilter: category,
    vehicle: vehicle?.map(() => false),
    vehicleFilter: vehicle,
  };
};

export const form2Entity = (formValues: FormValues) => {
  const { category, categoryFilter, vehicle, vehicleFilter, price } =
    formValues;
  const [min, max] = price ?? [];

  return {
    price: [min as number, max as number],
    category: category
      ?.map((value, index) =>
        value && categoryFilter?.[index] ? categoryFilter[index].id : null,
      )
      .filter(Boolean),
    vehicle: vehicle
      ?.map((value, index) =>
        value && vehicleFilter?.[index] ? vehicleFilter[index].id : null,
      )
      .filter(Boolean),
  };
};
