import useHotelAvailability from "@hotelspoint/api/src/hooks/hotel/useHotelAvailability.ts";
import {
  BreadcrumbContainer,
  Container,
  PageSpacer,
  PageSplitLayout,
  PageWrapper,
  StatusBlock,
  StatusFlavour,
  TableSkeleton,
} from "@hotelspoint/components";
import useHotelSearchResultStore from "@hotelspoint/store/src/useHotelSearchResultStore.ts";
import { HotelRoomGuests, PlaceType } from "@hotelspoint/types";
import { t } from "i18next";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import { HotelRoomsTable } from "../components";
import SearchHotelsFilters from "../SearchHotelsResults/SearchHotelsFilters/SearchHotelsFilters.tsx";
import useHotelsFilterParams from "../SearchHotelsResults/SearchHotelsFilters/useHotelsFilterParams.ts";
import SearchHotelsForm from "../SearchHotelsResults/SearchHotelsForm";
import { SearchHotelsViewState } from "../types.ts";
import useHotelSearchParams from "../useHotelSearchParams";
import SearchHotelBreadcrumbs from "./SearchHotelBreadcrumbs";
import SearchHotelDetails from "./SearchHotelDetails";
import * as S from "./SearchHotelPage.styled";

interface SearchHotelPageParams extends Record<string, string> {
  id: string;
}

const SearchHotelPage = () => {
  const { id } = useParams<SearchHotelPageParams>();
  const hotelId = Number(id);

  const [query] = useHotelSearchParams();
  const [filters, setFilters] = useHotelsFilterParams();

  const searchId = useHotelSearchResultStore(state => state.id);
  const rooms = useHotelSearchResultStore(state => state.rooms);
  const totalRates = useHotelSearchResultStore(state => state.total);
  const filtersState = useHotelSearchResultStore(state => state.filters);
  const isLoading = useHotelSearchResultStore(state => state.isLoading);
  const isCompleted = useHotelSearchResultStore(state => state.isCompleted);
  const resetResult = useHotelSearchResultStore(state => state.reset);

  // Reset state on unmount
  useEffect(() => {
    return () => {
      resetResult();
    };
  }, [resetResult]);

  const filterUndefinedValues = useMemo(() => {
    return Object.fromEntries(
      Object.entries(filters).filter(([, value]) => value !== undefined),
    );
  }, [filters]);

  useHotelAvailability({
    placeId: hotelId,
    placeType: PlaceType.Hotel,
    checkIn: query.checkIn as string,
    checkOut: query.checkOut as string,
    nationality: query.nationality as string,
    rooms: query.rooms as HotelRoomGuests[],
    useAsync: false,
    ...filterUndefinedValues,
  });

  const canShowFilters = useMemo(() => {
    return (
      totalRates !== 0 &&
      isCompleted &&
      query.view !== SearchHotelsViewState.Map
    );
  }, [totalRates, isCompleted, query.view]);

  return (
    <>
      <PageWrapper>
        <Container>
          <PageSpacer>
            <BreadcrumbContainer>
              <SearchHotelBreadcrumbs />
            </BreadcrumbContainer>
            <PageSplitLayout>
              <PageSplitLayout.Sidebar>
                <S.Wrapper>
                  <SearchHotelsForm isLoading={isLoading} />
                </S.Wrapper>
                {canShowFilters && (
                  <div>
                    <h5 style={{ marginBottom: 12 }}>
                      {t("searchHotelResults.filters.title")}
                    </h5>
                    <S.SidebarCard>
                      <div>
                        <SearchHotelsFilters
                          context={PlaceType.Hotel}
                          value={filtersState}
                          onChange={value => setFilters(value)}
                        />
                      </div>
                    </S.SidebarCard>
                  </div>
                )}
              </PageSplitLayout.Sidebar>
              <PageSplitLayout.Content>
                <S.Wrapper>
                  <SearchHotelDetails hotelId={hotelId} />
                  <S.Divider />
                  {isLoading ? (
                    <TableSkeleton />
                  ) : totalRates === 0 ? (
                    <StatusBlock status={StatusFlavour.Warning}>
                      {t("searchHotels.noAvailableRooms")}
                    </StatusBlock>
                  ) : (
                    <HotelRoomsTable
                      searchId={searchId as number}
                      hotelId={hotelId}
                      rooms={rooms}
                    />
                  )}
                </S.Wrapper>
              </PageSplitLayout.Content>
            </PageSplitLayout>
          </PageSpacer>
        </Container>
      </PageWrapper>
    </>
  );
};

export default SearchHotelPage;
