import { borderRadius, pxToRem } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { theme } from "styled-tools";

export const DayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Price = styled.span`
  font-size: ${pxToRem(13)};
`;

export const LegendWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: ${pxToRem(24)};
`;

export const LegendItem = styled.p`
  position: relative;
  line-height: 1;
  &:before {
    content: "";
    position: absolute;
    left: -12px;
    top: 50%;
    transform: translateY(-50%);
    width: ${pxToRem(8)};
    height: ${pxToRem(8)};
    border-radius: ${borderRadius.circle};
  }
`;

export const LegendItemAvailable = styled(LegendItem)`
  color: ${theme("palette.bush")};
  &:before {
    background-color: ${theme("palette.bush")};
  }
`;

export const LegendItemLimited = styled(LegendItem)`
  color: #e1d512;
  &:before {
    background-color: #e1d512;
  }
`;

export const LegendItemNotAvailable = styled(LegendItem)`
  color: #ff1a1a;
  &:before {
    background-color: #ff1a1a;
  }
`;
