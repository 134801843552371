import {
  ApiResponseSearchResultsPartial,
  ApiResponseSuccessResult,
  Coordinates,
  Hotel,
  HotelRate,
  HotelRateCancellation,
  HotelRoom,
  HotelRoomGuests,
  HotelSearch,
  HotelSearchQuery,
  PaginationOptions,
  PlaceType,
} from "@hotelspoint/types";

import FetchService from "./base/FetchService";
import { Place } from "./PlaceService";

const HOTEL_API_PATH = "hotels";

export interface HotelCountByPlaceParams {
  placeId?: number;
  placeType?: PlaceType;
}

export interface HotelCountByPlaceResponse {
  total: number;
}

export interface HotelSearchPayload extends HotelSearchQuery {
  useCache?: boolean;
  useAsync?: boolean;

  name?: string;
  room?: string;
  price?: number[];
  rating?: SearchFilter[];
  meal?: SearchFilterName[];
  rate?: SearchFilterName[];
}

export interface MapHotelSearch extends HotelSearch {
  position: Coordinates;
}

export interface HotelSearchResponse
  extends ApiResponseSearchResultsPartial<HotelRoom> {
  filters: HotelSearchFilters;
}

export interface HotelsSearchResponse
  extends ApiResponseSearchResultsPartial<HotelSearch> {
  filters: HotelSearchFilters;
}

export interface HotelMapSearchResponse
  extends ApiResponseSearchResultsPartial<MapHotelSearch> {
  filters: HotelSearchFilters;
}

export interface HotelSearchInfoResponse {
  id: number;
  search: {
    place: Place;
    checkIn: string;
    checkOut: string;
    nationality: string;
    rooms: HotelRoomGuests[];
  };
  isActive: boolean;
  isCompleted: boolean;
}

export interface HotelRoomsResponse {
  hotelId: number;
  searchId: number;
  rooms: HotelRoom[];
}

export interface HotelRateResponse extends HotelRate {
  allowLeaderOnly: boolean;
  cancellation: HotelRateCancellation;
  warnings?: string[];
  remarks: string[];
}

export interface HotelRateFeesResponse extends HotelRateCancellation {}

export enum HotelRateStatusChangeType {
  Price = "price",
  Cancellation = "cancellation",
  HotelName = "hotelName",
}

export type HotelRateStatusChangeValue =
  | string
  | {
      before: number | string;
      after: number | string;
    };

export interface HotelRateStatusResponse {
  isChanged: boolean;
  isAvailable: boolean;

  // Changes in the rate
  [HotelRateStatusChangeType.Price]?: {
    before: number;
    after: number;
  };
  [HotelRateStatusChangeType.Cancellation]?: {
    before: string;
    after: string;
  };
  [HotelRateStatusChangeType.HotelName]?: string;
}

export interface SearchFilter {
  id: number;
  count: number;
}

export interface SearchFilterName extends SearchFilter {
  name: string;
}

export interface HotelsFilterParams {
  name?: string;
  room?: string;
  price?: number[];
  rating?: number[];
  meal?: number[];
  rate?: number[];
}

export interface HotelSearchFilters {
  name?: string;
  room?: string;
  price: {
    min: number;
    max: number;
  };
  rating: SearchFilter[];
  meal: SearchFilterName[];
  rate: SearchFilterName[];
}

export interface HotelScore {
  score: number;
  count: number;
}

export interface HotelReviewer {
  name: string;
  country: string;
}

export type HotelReviewsResponse = HotelReviews[];

export interface HotelReviews {
  date: string;
  language: string;
  negative?: string;
  positive?: string;
  reviewer: HotelReviewer;
  score: number;
  summary?: string;
}

export interface HotelReviewsScoreResponse extends ApiResponseSuccessResult {
  cleanliness: HotelScore;
  comfort: HotelScore;
  facilities: HotelScore;
  freeWifi: HotelScore;
  location: HotelScore;
  staff: HotelScore;
  valueForMoney: HotelScore;
}

const HotelService = {
  getCountByPlace: (params?: HotelCountByPlaceParams) => {
    return FetchService.get<HotelCountByPlaceResponse>(
      `${HOTEL_API_PATH}/count`,
      params,
    );
  },
  search: (payload: HotelSearchPayload) => {
    return FetchService.post<HotelsSearchResponse>(
      `${HOTEL_API_PATH}/availability`,
      payload,
    );
  },
  searchHotel: (payload: HotelSearchPayload) => {
    return FetchService.post<HotelSearchResponse>(
      `${HOTEL_API_PATH}/${payload.placeId}/availability`,
      payload,
    );
  },
  getResultBySearchId: (searchId: number, params?: PaginationOptions) => {
    return FetchService.get<HotelsSearchResponse>(
      `${HOTEL_API_PATH}/availability/${searchId}/hotels`,
      params,
    );
  },
  getMapResultBySearchId: (searchId: number, params?: HotelSearchFilters) => {
    return FetchService.get<HotelMapSearchResponse>(
      `${HOTEL_API_PATH}/availability/${searchId}/hotels/map`,
      params,
    );
  },
  getSearchInfo: (searchId: number) => {
    return FetchService.get<HotelSearchInfoResponse>(
      `${HOTEL_API_PATH}/availability/${searchId}`,
    );
  },
  getDetails: (id: number) => {
    return FetchService.get<Hotel>(`${HOTEL_API_PATH}/${id}`);
  },
  getRooms: (searchId: number, hotelId: number) => {
    return FetchService.get<HotelRoomsResponse>(
      `${HOTEL_API_PATH}/availability/${searchId}/hotels/${hotelId}/rooms`,
    );
  },
  getRate: (searchId: number, hotelId: number, rateId: number) => {
    return FetchService.get<HotelRateResponse>(
      `${HOTEL_API_PATH}/availability/${searchId}/hotels/${hotelId}/rates/${rateId}`,
    );
  },
  getRateFees: (searchId: number, hotelId: number, rateId: number) => {
    return FetchService.get<HotelRateFeesResponse>(
      `${HOTEL_API_PATH}/availability/${searchId}/hotels/${hotelId}/rates/${rateId}/fees`,
    );
  },
  getRateStatus: (searchId: number, hotelId: number, rateId: number) => {
    return FetchService.get<HotelRateStatusResponse>(
      `${HOTEL_API_PATH}/availability/${searchId}/hotels/${hotelId}/rates/${rateId}/check`,
    );
  },
  getReviews: (id: number) => {
    return FetchService.get<HotelReviewsResponse>(
      `${HOTEL_API_PATH}/${id}/reviews`,
    );
  },
  getReviewsScore: (id: number) => {
    return FetchService.get<HotelReviewsScoreResponse>(
      `${HOTEL_API_PATH}/${id}/reviews/scores`,
    );
  },
  addToFavorites: (id: number) => {
    return FetchService.post<ApiResponseSuccessResult>(
      `${HOTEL_API_PATH}/${id}/favorite`,
    );
  },
  removeFromFavorites: (id: number) => {
    return FetchService.delete<ApiResponseSuccessResult>(
      `${HOTEL_API_PATH}/${id}/favorite`,
    );
  },
};

export default HotelService;
