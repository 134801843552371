import {
  Button,
  CancelationPolicy,
  FormAdapter,
  FormChildrenMethods,
  FormInput,
  HotelStayDurationDetails,
  Panel,
  Price,
} from "@hotelspoint/components";
import { useUserAgencyStore } from "@hotelspoint/store";
import { OfferGroup, OfferItem, UserCurrency } from "@hotelspoint/types";
import { IconUser } from "@tabler/icons-react";
import groupBy from "lodash/groupBy";
import qs from "query-string";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { FormValues } from "../ProfileOfferForm/ProfileOfferForm.util";
import ProfileOfferHotelDetails from "./ProfileOfferHotelDetails";
import * as S from "./ProfileOfferPanel.styled";
import ProfileOfferPanelDeleteRate from "./ProfileOfferPanelDeleteRate";

interface ProfileOfferPanelProps extends FormChildrenMethods<FormValues> {
  offerId: number;
  offerItems: OfferItem[];
}

const ProfileOfferPanel = ({ offerId, offerItems }: ProfileOfferPanelProps) => {
  const { t } = useTranslation();

  const agency = useUserAgencyStore(state => state.agency);

  const groupedOffers = useMemo(() => {
    if (!offerItems) return [];

    const groupedByItemId = groupBy(offerItems, "itemId");

    return Object.entries(groupedByItemId).map(([itemId, items]) => {
      const groupedByCheckInOut = groupBy(items, hotel => {
        const { checkIn, checkOut } = hotel.itemData.search;
        return `${checkIn}:${checkOut}`;
      });

      const groupedItemsArray = Object.entries(groupedByCheckInOut).map(
        ([checkInOut, items]) => {
          const [checkIn, checkOut] = checkInOut.split(":");
          return {
            checkIn,
            checkOut,
            items,
          };
        },
      );

      return {
        itemId,
        groupedItems: groupedItemsArray,
      };
    });
  }, [offerItems]);

  const getSearchParams = useCallback(
    (dateOffer: OfferGroup, offerItem: OfferItem) => {
      const room = offerItem.itemData.rooms.map(room => {
        const { adults, children } = room;

        return {
          adults,
          children: children.length === 0 ? [] : children,
        };
      });

      return qs.stringify({
        checkIn: dateOffer.checkIn,
        checkOut: dateOffer.checkOut,
        nationality: `${agency?.countryCode}`.toLowerCase(),
        rooms: JSON.stringify(room),
      });
    },
    [agency?.countryCode],
  );

  return (
    <>
      {groupedOffers.map((offerGroup, offerGroupIndex) => (
        <div key={offerGroup.itemId} style={{ marginBottom: 32 }}>
          <Panel title={`${t("profileOffer.offer")} ${offerGroupIndex + 1}`}>
            <ProfileOfferHotelDetails id={Number(offerGroup.itemId)} />
            {offerGroup.groupedItems.map((offerGroup, offerGroupIndex) => (
              <div key={offerGroupIndex}>
                <S.StayWrapper>
                  <HotelStayDurationDetails
                    checkIn={offerGroup.checkIn}
                    checkOut={offerGroup.checkOut}
                  />
                </S.StayWrapper>
                {offerGroup.items.map(offerItem => (
                  <S.RatesWrapper key={offerItem.id}>
                    <S.THeadWrapper>
                      <S.NameCell>
                        <S.Heading>{t("profileOffer.room")}</S.Heading>
                      </S.NameCell>
                      <S.PersonsCell />
                      <S.PricePolicyCell>
                        <S.Heading>{t("profileOffer.netPrice")}</S.Heading>
                      </S.PricePolicyCell>
                      <S.ActionsCell>
                        <S.Heading>{t("profileOffer.clientPrice")}</S.Heading>
                      </S.ActionsCell>
                    </S.THeadWrapper>
                    {offerItem.itemData.rooms.map((room, roomIndex) => (
                      <S.RoomWrapper key={roomIndex}>
                        <S.NameCell>
                          <S.TabletHeading>
                            {t("profileOffer.room")}
                          </S.TabletHeading>
                          <S.BoldHeadline>
                            {[room.numRooms, "x", room.roomName].join(" ")}
                          </S.BoldHeadline>
                        </S.NameCell>
                        <S.PersonsCell>
                          <S.PersonsWrapper>
                            {Array.from({ length: room.adults }).map(
                              (_, index) => (
                                <IconUser key={index} size={18} />
                              ),
                            )}
                          </S.PersonsWrapper>
                        </S.PersonsCell>
                        <S.PricePolicyCell>
                          <S.TabletHeading>
                            {t("profileOffer.netPrice")}
                          </S.TabletHeading>
                          <S.BoldHeadline>
                            <Price value={room.priceNet} />
                          </S.BoldHeadline>
                        </S.PricePolicyCell>
                        <S.ActionsCell>
                          <S.TabletHeading>
                            {t("profileOffer.clientPrice")}
                          </S.TabletHeading>
                          <FormAdapter
                            name={`prices.${offerItem.id}.${room.id}`}
                          >
                            {props => {
                              return (
                                <FormInput
                                  {...props}
                                  endAdornment={UserCurrency.EUR}
                                  adornmentStyle="filled"
                                  placeholder="Price"
                                />
                              );
                            }}
                          </FormAdapter>
                        </S.ActionsCell>
                      </S.RoomWrapper>
                    ))}
                    <S.RoomWrapper>
                      <S.NameCell>
                        <S.Heading>{t("profileOffer.meals")}</S.Heading>
                        <p>{offerItem.itemData.mealName}</p>
                      </S.NameCell>
                      <S.PersonsCell></S.PersonsCell>
                      <S.PricePolicyCell>
                        <S.Heading>
                          {t("profileOffer.cancelationPolicy")}
                        </S.Heading>
                        <CancelationPolicy
                          rateType={offerItem.itemData.rateType}
                          deadline={offerItem.itemData.cancellation?.deadline}
                        />
                      </S.PricePolicyCell>
                      <S.ActionsCell>
                        <S.Heading>{t("profileOffer.actions")}</S.Heading>
                        <S.ActionsWrapper>
                          <Link
                            style={{ width: "100%" }}
                            target="_blank"
                            to={{
                              pathname: `/hotels/search/${offerItem.itemId}`,
                              search: getSearchParams(offerGroup, offerItem),
                            }}
                          >
                            <Button
                              variant="outlined"
                              fullWidth
                              style={{ justifyContent: "center" }}
                            >
                              {t("profileOffer.search")}
                            </Button>
                          </Link>
                          <ProfileOfferPanelDeleteRate
                            id={offerId}
                            offerItemId={offerItem.id}
                          />
                        </S.ActionsWrapper>
                      </S.ActionsCell>
                    </S.RoomWrapper>
                  </S.RatesWrapper>
                ))}
              </div>
            ))}
          </Panel>
        </div>
      ))}
    </>
  );
};

export default ProfileOfferPanel;
