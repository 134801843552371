import { mediaQuery } from "@hotelspoint/theme";
import { useMediaQuery } from "@hotelspoint/utils";
import { IconX } from "@tabler/icons-react";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import Drawer from "../../composites/Drawer";
import { DrawerPlacement, DrawerSize } from "../../composites/Drawer/types";
import Button from "../../elements/Button";
import VisibleMobile from "../../misc/VisibleMobile";
import VisibleTablet from "../../misc/VisibleTablet";
import * as S from "./MapLayout.styled";

interface MapLayoutProps {
  children: React.ReactNode;
  filters: React.ReactNode;
  isFiltersOpen: boolean;
  toggleFilters: () => void;
  handleClose: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

const MapLayout = ({
  children,
  filters,
  isFiltersOpen,
  toggleFilters,
  handleClose,
  onKeyDown,
}: MapLayoutProps) => {
  const { t } = useTranslation();

  const isTablet = useMediaQuery(mediaQuery.tablet);

  const theme = useTheme();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  return (
    <S.Container ref={ref} onKeyDown={onKeyDown} tabIndex={0}>
      <VisibleMobile>
        <S.Header>
          <S.Title>
            {isFiltersOpen
              ? t("components.mapLayout.filters.title")
              : t("components.mapLayout.title")}
          </S.Title>
          <Button
            variant="outlined"
            onClick={() => (isFiltersOpen ? toggleFilters() : handleClose())}
            small
            style={{ padding: "0 8px" }}
          >
            <IconX size={18} />
          </Button>
        </S.Header>
      </VisibleMobile>
      <S.InnerWrapper>
        {!isTablet && (
          <Drawer
            isOpen={isFiltersOpen}
            placement={DrawerPlacement.Left}
            size={DrawerSize.FullPage}
            style={{
              top: theme.custom.header.heightS + theme.custom.mapView.header,
              paddingBottom:
                theme.custom.header.heightS + theme.custom.mapView.header,
            }}
          >
            <S.FilterWrapper>
              {filters}
              <Button
                variant="secondary"
                onClick={() => toggleFilters()}
                fullWidth
              >
                {t("searchHotelResults.filters.applyFilters")}
              </Button>
            </S.FilterWrapper>
          </Drawer>
        )}
        <VisibleTablet>
          <S.Sidebar>{isTablet && filters}</S.Sidebar>
        </VisibleTablet>
        <S.Content>
          <div>{children}</div>
        </S.Content>
      </S.InnerWrapper>
    </S.Container>
  );
};

export default MapLayout;
