import {
  useAgency,
  useFetchAgencies,
  useFetchUsers,
  useUser,
} from "@hotelspoint/api";
import {
  Box,
  Button,
  Flex,
  Form,
  FormAdapter,
  FormAsyncSelect,
  FormClearableInput,
  FormContext,
  FormDatePickerSingle,
  FormLayout,
} from "@hotelspoint/components";
import { mapEntityToOption } from "@hotelspoint/utils";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import useAdminActivitySearchesParams from "../useAdminActivitySearchesParams";
import {
  entity2Form,
  form2Entity,
  FormValues,
} from "./AdminActivitySearchesFilters.form";

interface AdminActivitySearchesFiltersProps {
  isLoading: boolean;
}

const AdminActivitySearchesFilters = ({
  isLoading,
}: AdminActivitySearchesFiltersProps) => {
  const { t } = useTranslation();

  const [query, setQuery] = useAdminActivitySearchesParams();

  const [fetchAgencies, isFetchingAgencies] = useFetchAgencies();
  const [agency, isLoadingAgency] = useAgency(Number(query?.agencyId));

  const [fetchUsers, isFetchingUsers] = useFetchUsers();
  const [user, isLoadingUser] = useUser(Number(query?.userId));

  const defaultUserOptions = useMemo(() => {
    if (!user) return [];

    return [
      {
        value: user.id,
        label: user.name,
      },
    ];
  }, [user]);

  const defaultAgencyOptions = useMemo(() => {
    if (!agency) return [];

    return [
      {
        value: agency.id,
        label: agency.name,
      },
    ];
  }, [agency]);

  const handleLoadAgencyOptions = useCallback(
    (name: string, callback: any) => {
      fetchAgencies({ name }).then(response => {
        if (response && Array.isArray(response.results)) {
          callback(response.results.map(mapEntityToOption));
        }
      });
    },
    [fetchAgencies],
  );

  const handleLoadUserOptions = useCallback(
    (name: string, callback: any) => {
      fetchUsers({ name }).then(response => {
        if (response && Array.isArray(response.results)) {
          callback(response.results.map(mapEntityToOption));
        }
      });
    },
    [fetchUsers],
  );

  const formValues = useMemo(() => {
    return entity2Form(query);
  }, [query]);

  const onSubmit = (formValues: FormValues) => {
    const payload = form2Entity(formValues);

    setQuery(payload);
  };

  return (
    <Form defaultValues={formValues}>
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="id"
              label={t("adminActivitySearches.filters.id.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t(
                    "adminActivitySearches.filters.id.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="userId"
              label={t("adminActivitySearches.filters.userId.label")}
            >
              {props => (
                <FormAsyncSelect
                  {...props}
                  placeholder={t(
                    "adminActivitySearches.filters.userId.placeholder",
                  )}
                  defaultOptions={defaultUserOptions}
                  loadOptions={handleLoadUserOptions}
                  isLoading={isLoadingUser || isFetchingUsers}
                  isClearable
                  noOptionsMessage={() =>
                    t("adminActivitySearches.filters.userId.noOptions")
                  }
                  loadingMessage={() =>
                    t("adminActivitySearches.filters.userId.loading")
                  }
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="agencyId"
              label={t("adminActivitySearches.filters.agencyId.label")}
            >
              {props => (
                <FormAsyncSelect
                  {...props}
                  placeholder={t(
                    "adminActivitySearches.filters.agencyId.placeholder",
                  )}
                  defaultOptions={defaultAgencyOptions}
                  loadOptions={handleLoadAgencyOptions}
                  isLoading={isLoadingAgency || isFetchingAgencies}
                  isClearable
                  noOptionsMessage={() =>
                    t("adminActivitySearches.filters.agencyId.noOptions")
                  }
                  loadingMessage={() =>
                    t("adminActivitySearches.filters.agencyId.loading")
                  }
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="dateFrom"
              label={t("adminActivitySearches.filters.dateFrom.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "adminActivitySearches.filters.dateFrom.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="dateTo"
              label={t("adminActivitySearches.filters.dateTo.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "adminActivitySearches.filters.dateTo.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
        </Flex>
        <FormContext<FormValues>
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="secondary"
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              {t("adminActivitySearches.filters.submit")}
            </Button>
          )}
        />
      </FormLayout>
    </Form>
  );
};

export default AdminActivitySearchesFilters;
