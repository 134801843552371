import { create } from "zustand";

interface HotelMapStore {
  hotelId: number | null;
  set: (hotelId: number | null) => void;
  reset: () => void;
}

const useHotelMapStore = create<HotelMapStore>(set => ({
  hotelId: null,
  set: hotelId => set({ hotelId }),
  reset: () => set({ hotelId: null }),
}));

export default useHotelMapStore;
