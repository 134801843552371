import {
  type HotelSearchPayload,
  HotelService,
  HotelsSearchResponse,
} from "@hotelspoint/api";
import useHotelsSearchResultStore from "@hotelspoint/store/src/useHotelsSearchResultStore.ts";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

const useHotelsAvailabilitySearch = (params: HotelSearchPayload) => {
  const searchId = useHotelsSearchResultStore(state => state.id);
  const setResult = useHotelsSearchResultStore(state => state.setResponse);
  const setLoading = useHotelsSearchResultStore(state => state.setLoading);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["hotels_availability", params],
    queryFn: () => HotelService.search(params),
    enabled: searchId === null,
  });

  useEffect(() => {
    if (data?.id) {
      setResult(data as HotelsSearchResponse);
    }
  }, [data, setResult]);

  useEffect(() => {
    if (searchId === null) {
      setLoading(isLoading || isFetching);
    }
  }, [searchId, isLoading, isFetching, setLoading]);
};

export default useHotelsAvailabilitySearch;
