import {
  IconLoading,
  Price,
  StatusFlavour,
  StatusText,
  TableCell,
} from "@hotelspoint/components";
import {
  HotelRate,
  OfferItem,
  RateType,
  ReservationType,
} from "@hotelspoint/types";
import { getRateTypeName } from "@hotelspoint/utils";
import { IconCar } from "@tabler/icons-react";
import { createColumnHelper } from "@tanstack/react-table";
import { type TFunction } from "i18next";

import CancellationFeesTooltip from "./CancellationFeesTooltip";
import * as S from "./HotelRoomsTable.styled";
import UnknownCancellationButton from "./UnknownCancellationButton";

const columnHelper = createColumnHelper<HotelRate>();

interface GetColumnsParams {
  t: TFunction;
  searchId: number;
  hotelId: number;
  targetRateId: number | null;
  unavailableRateIds: number[];
  offerItems: OfferItem[];
  handleCheckRateStatus: (rateId: number) => void;
  handleAddToOffer: (rateId: number) => void;
  handleRemoveFromOffer: (rateId: number, offerItemId: number) => void;
  isCheckingRateStatus: boolean;
  isAddingRate: boolean;
  isDeletingItem: boolean;
}

const getColumns = ({
  t,
  searchId,
  hotelId,
  targetRateId,
  unavailableRateIds,
  offerItems,
  handleCheckRateStatus,
  handleAddToOffer,
  handleRemoveFromOffer,
  isCheckingRateStatus,
  isAddingRate,
  isDeletingItem,
}: GetColumnsParams) => [
  columnHelper.accessor("rooms", {
    header: t("searchHotelResults.hotelRoomsModal.columns.rooms"),
    cell: props => {
      const rooms = props.getValue();
      const hasTransfers = props.row.original.hasTransfers;

      return rooms.map(room => (
        <S.CellRoom key={room.id}>
          <S.RoomWrapper>
            {[room.numRooms, "x", room.roomName].join(" ")}
            {hasTransfers && (
              <S.FlexWrapper>
                <IconCar size={18} />
                <p>
                  {t("searchHotelResults.hotelRoomsModal.transferIncluded")}
                </p>
              </S.FlexWrapper>
            )}
          </S.RoomWrapper>
        </S.CellRoom>
      ));
    },
  }),
  columnHelper.accessor("mealName", {
    header: t("searchHotelResults.hotelRoomsModal.columns.mealName"),
    cell: props => {
      return <S.MealName>{props.getValue()}</S.MealName>;
    },
  }),
  columnHelper.accessor("rateType", {
    header: t("searchHotelResults.hotelRoomsModal.columns.rateType"),
    cell: props => {
      const rateType = props.getValue();
      const rateId = props.row.original.id;

      const isRefundable = rateType === RateType.Refundable;
      const isRateUnavailable = unavailableRateIds.includes(rateId);

      return (
        <S.CancellationWrapper>
          {rateType === RateType.Unknown ? (
            <UnknownCancellationButton
              searchId={searchId}
              hotelId={hotelId}
              rateId={rateId}
            />
          ) : (
            <CancellationFeesTooltip
              searchId={searchId}
              hotelId={hotelId}
              rateId={rateId}
              refundable={isRefundable}
            >
              <S.DashedStatusText>
                <StatusText
                  status={
                    isRefundable ? StatusFlavour.Success : StatusFlavour.Error
                  }
                >
                  {isRateUnavailable
                    ? t(getRateTypeName(RateType.NotAvailable))
                    : t(getRateTypeName(rateType))}
                </StatusText>
              </S.DashedStatusText>
            </CancellationFeesTooltip>
          )}
        </S.CancellationWrapper>
      );
    },
  }),
  columnHelper.accessor("price", {
    header: t("searchHotelResults.hotelRoomsModal.columns.price"),
    cell: props => (
      <S.CellPrice>
        <Price value={props.getValue()} />
      </S.CellPrice>
    ),
  }),
  columnHelper.display({
    id: "actions",
    cell: props => {
      const rateId = props.row.original.id;

      const activeOfferItem = offerItems.find(item => {
        return (
          item.itemType === ReservationType.Hotel &&
          item.searchId === searchId &&
          item.itemId === hotelId &&
          item.rateId === rateId
        );
      });

      const isRateTargeted = rateId === targetRateId;
      const isRateActive = !!activeOfferItem;
      const isRateUnavailable = unavailableRateIds.includes(rateId);

      return (
        <TableCell>
          <S.ButtonBook
            variant="tertiary"
            isLoading={isRateTargeted && isCheckingRateStatus}
            onClick={() => handleCheckRateStatus(rateId)}
            isDisabled={isRateUnavailable}
          >
            {t("searchHotelResults.hotelRoomsModal.columns.actions.book")}
          </S.ButtonBook>
          <S.ButtonFavorite
            variant="secondary"
            onClick={() =>
              isRateActive
                ? handleRemoveFromOffer(rateId, activeOfferItem?.id)
                : handleAddToOffer(rateId)
            }
            isDisabled={isRateUnavailable}
          >
            {isRateTargeted && (isAddingRate || isDeletingItem) ? (
              <IconLoading size={16} />
            ) : (
              <S.IconFavorite size={16} $isActive={isRateActive} />
            )}
          </S.ButtonFavorite>
        </TableCell>
      );
    },
  }),
];

export default getColumns;
