import {
  ActivitiesFilterParams,
  ActivitySearchFilterName,
  ActivitySearchFilters,
} from "@hotelspoint/api";
import {
  Form,
  FormAdapter,
  FormCheckbox,
  FormChildrenMethods,
  FormClearableInput,
  FormInputRange,
  FormLabel,
  RangeSlider,
} from "@hotelspoint/components";
import { DEBOUNCE_DELAY_INPUT } from "@hotelspoint/constants";
import { IconCurrencyEuro, IconSearch } from "@tabler/icons-react";
import { useEffect, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";

import {
  defaultValues,
  entity2Form,
  form2Entity,
  FormValues,
} from "./SearchActivitiesFilters.form";
import * as S from "./SearchActivitiesFilters.styled";
import useActivitiesFilterParams from "./useActivitiesFilterParams";

interface SearchActivitiesFiltersProps {
  value: ActivitySearchFilters | null;
  onChange: (value: ActivitySearchFilters) => void;
}

interface SearchActivitiesFiltersInnerProps
  extends FormChildrenMethods<FormValues>,
    SearchActivitiesFiltersProps {}

const SearchActivitiesFiltersInner = ({
  watch,
  onChange,
}: SearchActivitiesFiltersInnerProps) => {
  const { t } = useTranslation();

  const [priceRange, categoryFilter] = useWatch<FormValues>({
    name: ["priceRange", "categoryFilter"],
  }) as [number[], ActivitySearchFilterName[]];

  const [priceMin, priceMax] = priceRange as number[];

  const debouncedCb = useDebouncedCallback(values => {
    const payload = form2Entity(values);
    // @ts-expect-error - Problem with the types
    onChange(payload);
  }, DEBOUNCE_DELAY_INPUT);

  useEffect(() => {
    const subscription = watch(debouncedCb);

    return () => subscription.unsubscribe();
  }, [debouncedCb, watch]);

  return (
    <S.Wrapper>
      <FormAdapter
        name="name"
        label={t("searchActivitiesResults.filters.activityName.label")}
      >
        {props => (
          <FormClearableInput
            {...props}
            startAdornment={<IconSearch size={18} />}
            placeholder={t(
              "searchActivitiesResults.filters.activityName.placeholder",
            )}
          />
        )}
      </FormAdapter>
      <S.Divider />
      <FormAdapter
        name="price"
        label={t("searchActivitiesResults.filters.price.label")}
      >
        {props => (
          <>
            <FormInputRange
              {...props}
              startAdornment={<IconCurrencyEuro size={18} />}
            />
            <S.RangeSliderWrapper>
              <RangeSlider
                range
                min={priceMin}
                max={priceMax}
                step={1}
                allowCross={false}
                value={props.value}
                onChange={range => props.onChange(range)}
              />
            </S.RangeSliderWrapper>
          </>
        )}
      </FormAdapter>
      <S.Divider />
      <S.CheckboxWrapper>
        <FormLabel id="category">
          {t("searchActivitiesResults.filters.category.label")}
        </FormLabel>
        {categoryFilter?.map(
          (category: ActivitySearchFilterName, index: number) => (
            <FormAdapter
              key={category.id}
              name={`category.${index}`}
              label={
                <S.CheckboxLabelWrapper>
                  <S.CheckboxName>{category.name}</S.CheckboxName>
                  <S.CheckboxCount>{category.count}</S.CheckboxCount>
                </S.CheckboxLabelWrapper>
              }
              formControlProps={{
                labelPosition: "inline-end",
                style: { flex: 1 },
              }}
            >
              {props => <FormCheckbox {...props} />}
            </FormAdapter>
          ),
        )}
      </S.CheckboxWrapper>
    </S.Wrapper>
  );
};

const SearchActivitiesFilters = (props: SearchActivitiesFiltersProps) => {
  const { value } = props;
  const [filters] = useActivitiesFilterParams() as [
    ActivitiesFilterParams,
    (filters: ActivitiesFilterParams) => void,
  ];
  const formValues = useMemo(() => {
    if (!value) return defaultValues;

    return entity2Form(value, filters);
  }, [filters, value]);

  return (
    <Form defaultValues={formValues} showDevTool>
      {formMethods => (
        <SearchActivitiesFiltersInner {...formMethods} {...props} />
      )}
    </Form>
  );
};

export default SearchActivitiesFilters;
