import { NamesType, Reservation } from "@hotelspoint/api";
import { Agency, PlaceType, ReservationType } from "@hotelspoint/types";
import { formatDateIso } from "@hotelspoint/utils";
import { array, date, InferType, mixed, number, object, string } from "yup";

const activityTicketSchema = object({
  key: string(),
  name: string().required(
    "adminReservation.form.ticketName.validation.required",
  ),
  numTickets: number().required(
    "adminReservation.form.numTickets.validation.required",
  ),
  price: number().required(
    "adminReservation.form.salePrice.validation.required",
  ),
  priceBase: number().required(
    "adminReservation.form.activityPriceBase.validation.required",
  ),
  priceNet: number().required(
    "adminReservation.form.activityPriceNet.validation.required",
  ),
}).required();

const activityPassengerSchema = object({
  name: string().required(
    "adminReservation.form.passengerName.validation.required",
  ),
  surname: string().required(
    "adminReservation.form.passengerSurname.validation.required",
  ),
  title: number().required(
    "adminReservation.form.passengerTitle.validation.required",
  ),
  birthday: date(),
  ages: number(),
}).required();

const transferPassengerSchema = object({
  name: string().required(
    "adminReservation.form.firstName.validation.required",
  ),
  surname: string().required(
    "adminReservation.form.lastName.validation.required",
  ),
  title: number().required("adminReservation.form.title.validation.required"),
  ages: number().nullable(),
}).required();

export const validationSchema = object({
  // Misc
  type: number(),
  originalData: object().nullable(),
  hotelPlace: object({
    id: number().nullable(),
    type: mixed<PlaceType>().oneOf(Object.values(PlaceType)),
  }).nullable(),

  // General
  id: number(),
  confirmationId: string().nullable(),
  status: number(),
  paymentStatus: number(),
  agency: object({
    value: number(),
    label: string(),
  }).nullable(),
  user: object({
    value: number(),
    label: string(),
  }).nullable(),
  clientName: string(),
  confirmationAssigneeId: number(),
  dateFrom: date(),
  dateTo: date(),
  rateType: number(),
  dateDeadline: date(),
  priceBase: number().nullable(),
  priceNet: number(),
  price: number(),
  agencyMarkup: number(),
  voucherCode: string().nullable(),
  supplierId: number().nullable(),
  dateSupplierDeadline: date().nullable(),
  confirmationSupplierStatus: number().nullable(),
  remarks: array().of(string()),

  // Hotels
  hotelName: string(),
  mealType: number(),
  confirmationHotelStatus: number().nullable(),
  // Activities
  activityName: string().when("type", {
    is: (val: number) => val === ReservationType.Activity,
    then: schema =>
      schema.required("adminReservation.form.activityName.validation.required"),
  }),
  option: string().when("type", {
    is: (val: number) => val === ReservationType.Activity,
    then: schema =>
      schema.required("adminReservation.form.option.validation.required"),
  }),
  location: string().when("type", {
    is: (val: number) => val === ReservationType.Activity,
    then: schema =>
      schema.required("adminReservation.form.location.validation.required"),
  }),
  provider: string(),
  session: string().nullable(),
  language: string().nullable(),
  title: number().when("type", {
    is: (val: number) => val === ReservationType.Activity,
    then: schema =>
      schema.required("adminReservation.form.title.validation.required"),
  }),
  contactName: string().when("type", {
    is: (val: number) => val === ReservationType.Activity,
    then: schema =>
      schema.required("adminReservation.form.contactName.validation.required"),
  }),
  contactSurname: string().when("type", {
    is: (val: number) => val === ReservationType.Activity,
    then: schema =>
      schema.required(
        "adminReservation.form.contactSurname.validation.required",
      ),
  }),
  contactPhoneCode: string().when("type", {
    is: (val: number) => val === ReservationType.Activity,
    then: schema =>
      schema.required(
        "adminReservation.form.contactPhoneCode.validation.required",
      ),
  }),
  contactPhoneNumber: string().when("type", {
    is: (val: number) => val === ReservationType.Activity,
    then: schema =>
      schema.required(
        "adminReservation.form.contactPhoneNumber.validation.required",
      ),
  }),
  contactEmail: string().when("type", {
    is: (val: number) => val === ReservationType.Activity,
    then: schema =>
      schema.required("adminReservation.form.contactEmail.validation.required"),
  }),
  tickets: array()
    .of(activityTicketSchema)
    .when("type", {
      is: (val: number) => val === ReservationType.Activity,
      then: schema => schema.required(),
    }),
  passengers: array()
    .of(array().of(activityPassengerSchema))
    .when("type", {
      is: (val: number) => val === ReservationType.Activity,
      then: schema => schema.required(),
    }),
  // @todo: Transfers
  tripType: number(),
  adults: number(),
  children: number().nullable(),
  pickUp: string(),
  dropOff: string(),
  transferType: string(),
  transferName: string(),
  vehicle: string(),
  flightArrival: mixed(),
  flightDeparture: string().nullable(),
  confirmationOutboundTransferStatus: number().nullable(),
  confirmationReturnTransferStatus: number().nullable(),
  outboundDate: date().when("type", {
    is: (val: number) => val === ReservationType.Transfer,
    then: schema =>
      schema.required("adminReservation.form.outboundDate.validation.required"),
  }),
  outboundTime: string().when("type", {
    is: (val: number) => val === ReservationType.Transfer,
    then: schema =>
      schema.required("adminReservation.form.outboundTime.validation.required"),
  }),
  pickUpDate: date().when("type", {
    is: (val: number) => val === ReservationType.Transfer,
    then: schema =>
      schema.required("adminReservation.form.pickUpDate.validation.required"),
  }),
  pickUpTime: string().when("type", {
    is: (val: number) => val === ReservationType.Transfer,
    then: schema =>
      schema.required("adminReservation.form.pickUpTime.validation.required"),
  }),
  outboundRemarks: array().of(string()),
  returnDate: date().nullable(),
  returnTime: string().nullable(),
  dropOffDate: date().nullable(),
  dropOffTime: string().nullable(),
  returnRemarks: array().of(string()).nullable(),
  namesType: mixed<NamesType>().oneOf(
    Object.values(NamesType).map(e => e as NamesType),
  ),
  passengerAdults: array().when("namesType", {
    is: NamesType.AllPassengers,
    then: schema => schema.of(transferPassengerSchema).required(),
  }),
  passengerChildren: array().when("namesType", {
    is: NamesType.AllPassengers,
    then: schema => schema.of(transferPassengerSchema).required(),
  }),

  minPassengers: number(),
  maxPassengers: number(),
  phoneCode: string(),
  phoneNumber: string(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  // Misc
  type: undefined,
  originalData: null,
  hotelPlace: null,

  // General
  id: undefined,
  confirmationId: "",
  status: undefined,
  paymentStatus: undefined,
  agency: null,
  user: null,
  clientName: "",
  confirmationAssigneeId: undefined,
  dateFrom: undefined,
  dateTo: undefined,
  rateType: undefined,
  dateDeadline: undefined,
  priceBase: undefined,
  priceNet: undefined,
  price: undefined,
  agencyMarkup: undefined,
  voucherCode: "",
  supplierId: undefined,
  dateSupplierDeadline: undefined,
  confirmationSupplierStatus: undefined,
  remarks: [],

  // Hotels
  hotelName: "",
  mealType: undefined,
  confirmationHotelStatus: undefined,

  // Activities
  activityName: "",
  option: "",
  location: "",
  provider: "",
  session: "",
  language: "",
  title: undefined,
  contactName: "",
  contactSurname: "",
  contactPhoneCode: "",
  contactPhoneNumber: "",
  contactEmail: "",
  tickets: [],
  passengers: [],

  // Transfers
  tripType: undefined,
  adults: undefined,
  children: undefined,
  pickUp: "",
  dropOff: "",
  transferType: undefined,
  transferName: "",
  vehicle: "",
  flightArrival: "",
  flightDeparture: "",
  confirmationOutboundTransferStatus: undefined,
  confirmationReturnTransferStatus: undefined,
  outboundDate: undefined,
  outboundTime: "",
  pickUpDate: undefined,
  pickUpTime: "",
  outboundRemarks: [],
  returnDate: undefined,
  returnTime: "",
  dropOffDate: undefined,
  dropOffTime: "",
  returnRemarks: [],
  passengerAdults: [],
  passengerChildren: [],
  namesType: NamesType.AllPassengers,
  minPassengers: undefined,
  maxPassengers: undefined,
  phoneCode: "+359",
  phoneNumber: "",
};

export const entity2Form = ({
  reservation,
  agency,
  hotelName,
}: {
  reservation: Reservation;
  agency: Agency;
  hotelName?: string;
}): FormValues => {
  const getAgencyMarkup = () => {
    switch (reservation.itemType) {
      case ReservationType.Hotel:
        return agency.markupHotels;

      case ReservationType.Activity:
        return agency.markupActivities;

      case ReservationType.Transfer:
        return agency.markupTransfers;

      // @todo: case ReservationType.RentACar

      default:
        return 0;
    }
  };

  return {
    // Misc
    type: reservation.itemType,
    originalData: reservation,
    hotelPlace: {
      id: reservation.itemId as number,
      type: PlaceType.Hotel,
    },

    // Read-only
    id: reservation.id,

    // General info
    confirmationId: reservation.confirmationId,
    status: reservation.status,
    paymentStatus: reservation.paymentStatus,
    agency: {
      value: reservation.agencyId,
      label: reservation.agencyName,
    },
    user: {
      value: reservation.userId,
      label: reservation.userName,
    },
    clientName: reservation.clientName,
    confirmationAssigneeId: reservation.confirmationAssigneeId,
    dateFrom: reservation.dateFrom ? new Date(reservation.dateFrom) : undefined,
    dateTo: reservation.dateTo ? new Date(reservation.dateTo) : undefined,
    rateType: reservation.itemData.rateType,
    dateDeadline: reservation.dateDeadline
      ? new Date(reservation.dateDeadline)
      : undefined,
    priceBase: reservation.priceBase,
    priceNet: reservation.priceNet,
    price: reservation.price,
    agencyMarkup: getAgencyMarkup(),
    voucherCode: reservation.voucherCode,
    supplierId: reservation.supplierId,
    dateSupplierDeadline: reservation.dateSupplierDeadline
      ? new Date(reservation.dateSupplierDeadline)
      : undefined,
    confirmationSupplierStatus: reservation.confirmationSupplierStatus,
    // @todo: old reservations are returned as empty string
    remarks:
      typeof reservation.itemData.remarks === "string"
        ? []
        : (reservation.itemData.remarks ?? []),

    // Hotels
    hotelName,
    mealType: reservation.itemData.mealType,
    confirmationHotelStatus: reservation.confirmationHotelStatus,

    // @todo: Activities
    activityName: reservation.itemData.name,
    option: reservation.itemData.option
      ? reservation.itemData.option.name
      : undefined,
    location: reservation.itemData.location,
    provider: reservation.itemData.provider,
    session: reservation.itemData.session,
    language: reservation.itemData.language,
    title: reservation.itemData.contacts?.title,
    contactName: reservation.itemData.contacts?.name,
    contactSurname: reservation.itemData.contacts?.surname,
    contactPhoneCode: reservation.itemData.contacts?.phoneCode,
    contactPhoneNumber: reservation.itemData.contacts?.phoneNumber,
    contactEmail: reservation.itemData.contacts?.email,
    tickets:
      reservation.itemData?.tickets?.length !== 0
        ? reservation.itemData.tickets
        : [],
    passengers:
      reservation.itemData?.passengers?.length !== 0
        ? reservation.itemData.passengers
        : [],

    // Transfers
    tripType: reservation.itemData.tripType,
    adults: reservation.itemData.adults,
    children: reservation.itemData.children?.length,
    pickUp: reservation.itemData.pickUp,
    dropOff: reservation.itemData.dropOff,
    transferType: reservation.itemData.type,
    transferName: reservation.itemData.name,
    vehicle: reservation.itemData.vehicle,
    flightArrival: reservation.itemData?.flights?.arrival,
    flightDeparture: reservation.itemData?.flights?.departure,
    confirmationOutboundTransferStatus:
      reservation.confirmationOutboundTransferStatus,
    confirmationReturnTransferStatus:
      reservation.confirmationReturnTransferStatus,
    outboundDate: reservation.itemData.outboundTransfer?.date
      ? new Date(reservation.itemData.outboundTransfer?.date)
      : undefined,
    outboundTime: reservation.itemData.outboundTransfer?.time,
    pickUpDate: reservation.itemData.outboundTransfer?.pickup?.date
      ? new Date(reservation.itemData.outboundTransfer?.pickup?.date)
      : undefined,
    pickUpTime: reservation.itemData.outboundTransfer?.pickup?.time,
    outboundRemarks:
      typeof reservation.itemData.outboundTransfer?.remarks === "string"
        ? []
        : (reservation.itemData.outboundTransfer?.remarks ?? []),
    returnDate: reservation.itemData.returnTransfer?.date
      ? new Date(reservation.itemData.returnTransfer?.date)
      : undefined,
    returnTime: reservation.itemData.returnTransfer?.time,
    dropOffDate: reservation.itemData.returnTransfer?.pickup.date
      ? new Date(reservation.itemData.returnTransfer?.pickup.date)
      : undefined,
    dropOffTime: reservation.itemData.returnTransfer?.pickup.time,
    returnRemarks:
      typeof reservation.itemData.returnTransfer?.remarks === "string"
        ? []
        : (reservation.itemData.returnTransfer?.remarks ?? []),
    passengerAdults: reservation.itemData.names?.adults ?? [],
    passengerChildren: reservation.itemData.names?.children ?? [],
    namesType: reservation.itemData.namesType,
    minPassengers: reservation.itemData.minPassengers,
    maxPassengers: reservation.itemData.maxPassengers,
    phoneCode: reservation.itemData.phoneCode,
    phoneNumber: reservation.itemData.phoneNumber,
  };
};

export const form2Entity = (
  formValues: FormValues,
  dateUpdated: string,
): any => {
  const reservation = formValues.originalData as Reservation;

  const basePayload = {
    ...reservation,
    confirmationId: formValues.confirmationId,
    status: formValues.status,
    paymentStatus: formValues.paymentStatus,
    agencyId: formValues.agency?.value,
    agencyName: formValues.agency?.label,
    userId: formValues.user?.value,
    userName: formValues.user?.label,
    clientName: formValues.clientName,
    confirmationAssigneeId: formValues.confirmationAssigneeId,
    dateFrom: formValues.dateFrom
      ? formatDateIso(formValues.dateFrom)
      : undefined,
    dateTo: formValues.dateTo ? formatDateIso(formValues.dateTo) : undefined,
    rateType: formValues.rateType,
    dateDeadline: formValues.dateDeadline
      ? formatDateIso(formValues.dateDeadline)
      : undefined,
    priceBase: formValues.priceBase,
    priceNet: formValues.priceNet,
    price: formValues.price,
    voucherCode: formValues.voucherCode,
    supplierId: formValues.supplierId,
    dateSupplierDeadline: formValues.dateSupplierDeadline
      ? formatDateIso(formValues.dateSupplierDeadline)
      : undefined,
    confirmationSupplierStatus: formValues.confirmationSupplierStatus,

    // Make sure we overwrite any attributes in itemData object
    itemData: {
      ...reservation.itemData,
      remarks: formValues.remarks,
    },

    // Set the timestamp to current time - API will use for optimistic updates
    dateUpdated,
  };

  // Hotels
  if (formValues.type === ReservationType.Hotel) {
    return {
      ...basePayload,
      itemId: formValues.hotelPlace?.id,
      confirmationHotelStatus: formValues.confirmationHotelStatus,
      itemData: {
        ...basePayload.itemData,
        mealType: formValues.mealType,
        // @todo: rooms
      },
    };
  }

  return basePayload;
};
