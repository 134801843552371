import {
  ApiResponseSuccessResult,
  ApiResponseSuccessResultList,
  HotelRoomRate,
  PaginationOptions,
  RateType,
  ReservationType,
} from "@hotelspoint/types";

import { Activity } from "./ActivityService";
import FetchService from "./base/FetchService";
import { PaymentCreateResponse, PaymentStatus } from "./PaymentService";
import { Transfer } from "./TransferService";

const RESERVATION_API_PATH = "reservations";

export enum ReservationStatus {
  CreatedReservation = 1,
  PendingConfirmation = 2,
  ConfirmedReservation = 4,
  PendingCancellation = 5,
  CancelledReservation = 6,
  FailedConfirmation = 9,
  CancelledWithFee = 10,
}

export enum ReservationVoucherStatus {
  Pending = 1,
  Ready = 2,
}

export enum ReservationHotelConfirmationStatus {
  NotSend = 1,
  EmailNotFound = 2,
  EmailSend = 3,
  EmailResend = 4,
  EmailReceived = 5,
  EmailNotReceived = 6,
  CallRequired = 7,
  Escalation1 = 8,
  Escalation2 = 9,
  Confirmed = 10,
}

export enum ReservationSupplierConfirmationStatus {
  NotSend = 1,
  EmailSend = 2,
  EmailReceived = 3,
  EmailNotReceived = 4,
  Escalation1 = 5,
  Escalation2 = 6,
  Confirmed = 7,
  Verified = 8,
  NotVerified = 9,
}

export enum ReservationTransferConfirmationStatus {
  NotConfirmed = 1,
  ConfirmedWithNumber = 2,
  ConfirmedWithoutNumber = 3,
  NoFlightTracking = 4,
}

export interface ReservationName {
  title: string;
  name: string;
  surname: string;
}

export interface ReservationNameChildren
  extends Omit<ReservationName, "title"> {
  age: number;
}

export interface ReservationFlights {
  arrival: {
    date: Date;
    number: string;
  };
  return: {
    date: Date;
    number: string;
  };
}

export interface ReservationTransferFlights {
  arrival: string;
  departure: string;
}

export interface ReservationNames {
  roomId: number;
  adults: ReservationName[];
  children: ReservationNameChildren[];
}

export interface ReservationHotelCreatePayload {
  type: ReservationType;
  rateId: number;
  hotelId: number;
  searchId: number;
  names: ReservationNames[];
  arrivalFlightNumber?: string;
  arrivalFlightDate?: string;
  returnFlightNumber?: string;
  returnFlightDate?: string;
  paymentType?: number;
  paymentCurrency?: string;
}

export interface ReservationTransferCreatePayload {
  type: ReservationType;
  rateId: number;
  searchId: number;
  adults: ReservationName[];
  children: ReservationNameChildren[];
  flights: ReservationTransferFlights;
  phoneCode: string;
  phoneNumber: string;
  paymentType?: number;
  paymentCurrency?: string;
}

export interface ReservationCreateResponse
  extends Omit<PaymentCreateResponse, "status"> {
  id: number;
  status: ReservationStatus;
}

// The API expects the whole reservation object to be sent for updates
export type ReservationUpdatePayload = Reservation;

export interface ReservationTickets {
  key: number;
  language: string[];
  name: string;
  numTickets: number;
  price: number;
  priceBase: number;
  priceNet: number;
  requirements: string[];
  sessions: string[];
  standalone: boolean;
  supplierData: {
    ages: number | null;
    name: string;
  };
}

export interface ReservationPrices {
  price: number;
  priceNet: number;
  priceBase: number;
  // @todo: this is yet to be implemented
  // priceOperator: number | null;
}

export interface ReservationItemDataBase extends ReservationPrices {
  id: number;
}

export interface ReservationItemDataHotel extends ReservationItemDataBase {
  mealName: string;
  mealType: number;
  mealTypeName: string;
  rateType: RateType;
  warnings: string[] | null;
  surcharges: string[] | null;
  rooms: HotelRoomRate[];
  names: ReservationNames[];
  flights: ReservationFlights;
  remarks: string | string[] | null;
  supplierId: number | null; // @todo: this is duplicated now, moved outside of itemData
  isExpedia: boolean;
  hasPromotion: boolean;
  hasPackaging: boolean;
  hasTransfers: boolean;
}

export interface ReservationItemDataTransfer
  extends ReservationItemDataBase,
    Transfer {}

export interface ReservationItemDataActivity
  extends ReservationItemDataBase,
    Activity {
  // @todo: these interfaces need to be cleaned up asap
  session: string | null;
  language: string | null;
  pickUpLocation?: {
    name: string;
    code: string;
  };
  questions: any[];
  passengers: any[];
  tickets: any[];
  options: any;
  option: {
    name: string;
  };
  contacts?: {
    name: string;
    surname: string;
    phoneCode: string;
    phoneNumber: string;
    email: string;
    title: number;
  };

  supplierId: number | null; // @todo: this is duplicated now, moved outside of itemData for hotel
  supplier: { name: string; vat: string } | null;
}

export type ReservationItemData = ReservationItemDataHotel &
  ReservationItemDataTransfer &
  ReservationItemDataActivity;

export interface Reservation extends ReservationPrices {
  id: number;
  itemId: number | null;
  itemType: ReservationType;
  itemData: ReservationItemData;
  userId: number;
  userName: string;
  agencyId: number;
  agencyName: string;
  supplierId: number | null;
  confirmationId: string | null;
  clientName: string;
  cityId: number | null;
  cityName: string | null;
  status: ReservationStatus;
  paymentStatus: PaymentStatus;
  commissionAmount: number | null;
  commissionStatus: any | null;
  voucherUrl: string | null;
  voucherCode: string | null;
  voucherStatus: ReservationVoucherStatus | null;
  confirmationAssigneeId: number;
  confirmationHotelStatus: ReservationHotelConfirmationStatus | null;
  confirmationSupplierStatus: ReservationSupplierConfirmationStatus | null;
  confirmationOutboundTransferStatus: ReservationTransferConfirmationStatus | null;
  confirmationReturnTransferStatus: ReservationTransferConfirmationStatus | null;
  countMessages: number;
  countNewMessages: number;
  countAlertMessages: number;
  dateFrom: string;
  dateTo: string;
  dateDeadline: string;
  dateSupplierDeadline: string | null;
  dateCancelled: string | null;
  dateUpdated: string | null;
  dateCreated: string;
  invoiceId: string | boolean;
}

export enum ReservationMessageFilter {
  All = 1,
  New = 2,
  Pending = 3,
  Progress = 4,
  Completed = 5,
  Alerts = 6,
}

export enum ReservationOrderBy {
  BookingDate = 1,
  DeadlineDate = 2,
  CheckInDate = 3,
  CancelledDate = 4,
}

// @todo: this enum does not belong here
export enum Titles {
  Mr = 1,
  Mrs = 2,
}

export interface ReservationListParams extends PaginationOptions {
  id?: number;
  dateFrom?: string;
  dateTo?: string;
  status?: ReservationStatus;
  deadlineFrom?: string;
  deadlineTo?: string;
  agencyId?: number;
  userId?: number;
  supplierId?: number;
  cityName?: string;
  checkInFrom?: string;
  checkInTo?: string;
  name?: string;
  checkOutFrom?: string;
  checkOutTo?: string;
  priceFrom?: number;
  priceTo?: number;
  paymentStatus?: PaymentStatus;
  messageFilter?: ReservationMessageFilter;
  type?: ReservationType;
  confirmed?: boolean;
  orderBy?: ReservationOrderBy;
  // @todo: ticket raised to change the attribute name to `assigneeId`
  assignee?: string;
  hotelConfirmationStatus?: ReservationHotelConfirmationStatus;
  supplierConfirmationStatus?: ReservationSupplierConfirmationStatus;
  confirmationOutboundTransferStatus?: ReservationTransferConfirmationStatus;
  confirmationReturnTransferStatus?: ReservationTransferConfirmationStatus;
}

export type ReservationListResponse = ApiResponseSuccessResultList<Reservation>;

export interface ReservationUpdateAssigneePayload {
  assigneeId: number;
}

export interface ReservationSendVoucherPayload {
  email: string;
}

const ReservationService = {
  create: (
    payload: ReservationHotelCreatePayload | ReservationTransferCreatePayload,
  ) => {
    return FetchService.post<ReservationCreateResponse>(
      RESERVATION_API_PATH,
      payload,
    );
  },
  update: (id: number, payload: ReservationUpdatePayload) => {
    return FetchService.patch<Reservation>(
      `${RESERVATION_API_PATH}/${id}`,
      payload,
    );
  },
  get: (id: number) => {
    return FetchService.get<Reservation>(`${RESERVATION_API_PATH}/${id}`);
  },
  cancel: (id: number) => {
    return FetchService.post<Reservation>(
      `${RESERVATION_API_PATH}/${id}/cancel`,
    );
  },
  getList: (params?: ReservationListParams) => {
    return FetchService.get<ReservationListResponse>(
      RESERVATION_API_PATH,
      params,
    );
  },
  updateAssignee: (id: number, payload: ReservationUpdateAssigneePayload) => {
    return FetchService.patch<ReservationListResponse>(
      `${RESERVATION_API_PATH}/${id}/updateAssignee`,
      payload,
    );
  },
  sendVoucher: (id: number, payload: ReservationSendVoucherPayload) => {
    return FetchService.post<ApiResponseSuccessResult>(
      `${RESERVATION_API_PATH}/${id}/vouchers/send`,
      payload,
    );
  },
};

export default ReservationService;
