import useTransferRate from "@hotelspoint/api/src/hooks/transfer/useTransferRate.ts";
import {
  Breadcrumb,
  BreadcrumbContainer,
  Button,
  Container,
  LoaderBlock,
  MarkupPrice,
  PageSpacer,
  PageSplitLayout,
  PageWrapper,
  Price,
  TransferContent,
} from "@hotelspoint/components";
import theme from "@hotelspoint/theme";
import {
  getImagePathFromCDN,
  getTransferTypeName,
  ImageType,
  useDocumentTitle,
} from "@hotelspoint/utils";
import {
  IconCar,
  IconCircleCheckFilled,
  IconSearch,
  IconUsersGroup,
} from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import SearchTransfersForm from "../SearchTransfersResults/SearchTransfersForm";
import thumbPlaceholder from "./assets/thumbPlaceholder.jpg";
import * as S from "./SearchTransferPage.styled";

interface SearchTransferPageParams extends Record<string, string> {
  rateId: string;
  searchId: string;
}

const SearchTransferPage = () => {
  const { t } = useTranslation();
  const { search: locationSearch } = useLocation();

  useDocumentTitle(t("searchTransferPage.pageTitle"));

  const navigate = useNavigate();
  const params = useParams<SearchTransferPageParams>();
  const rateId = Number(params.rateId);
  const searchId = Number(params.searchId);

  const [rate, isLoading] = useTransferRate(searchId, rateId);

  const search = useMemo(() => {
    const params = new URLSearchParams(locationSearch);
    params.delete("useCache");
    return params.toString();
  }, [locationSearch]);

  console.log(search);
  const breadcrumbs = useMemo(
    () => [
      {
        title: t("searchTransfers.pageTitle"),
        href: "/search/transfers",
        icon: () => <IconCar size={18} />,
      },
      {
        title: t("searchTransfersResults.search"),
        icon: () => <IconSearch size={18} />,
      },
    ],
    [t],
  );

  if (!searchId || !rateId) {
    return <Navigate to="/404" replace={true} />;
  }

  if (isLoading) {
    return <LoaderBlock />;
  }

  if (!rate) {
    return <Navigate to="/404" replace={true} />;
  }

  return (
    <PageWrapper>
      <Container>
        <PageSpacer>
          <BreadcrumbContainer>
            <Breadcrumb items={breadcrumbs} />
          </BreadcrumbContainer>
          <PageSplitLayout>
            <PageSplitLayout.Sidebar>
              <S.Wrapper>
                <SearchTransfersForm />
              </S.Wrapper>
            </PageSplitLayout.Sidebar>
            <PageSplitLayout.Content>
              <S.Wrapper>
                <S.WrapperHeader>
                  <S.WrapperDetails>
                    <S.Title>{rate.name}</S.Title>
                    {rate.vehicle}
                    <S.Passengers>
                      <IconUsersGroup size={16} />
                      <span>
                        {t("searchTransferPage.transferDetails.maxPassengers", {
                          count: rate.maxPassengers,
                        })}
                      </span>
                    </S.Passengers>
                    <S.Provider>{rate.provider}</S.Provider>
                  </S.WrapperDetails>
                  <S.ThumbnailWrapper>
                    <S.Thumbnail
                      $src={
                        rate.image
                          ? getImagePathFromCDN(
                              rate.image as string,
                              ImageType.Transfer,
                            )
                          : thumbPlaceholder
                      }
                    />
                  </S.ThumbnailWrapper>
                </S.WrapperHeader>
                <S.Divider />
                <TransferContent
                  outboundTransfer={rate.outboundTransfer}
                  returnTransfer={rate.returnTransfer}
                  isLoading={isLoading}
                />
                <S.Divider />
                <S.Footer>
                  <div>
                    <S.FooterDetails>
                      <S.FooterHeader>
                        {t(getTransferTypeName(rate.type))}
                      </S.FooterHeader>
                      <S.Item>
                        <IconCircleCheckFilled
                          size={16}
                          style={{ color: theme.palette.eucalyptus }}
                        />
                        <span>
                          {t(
                            "searchTransferPage.transferDetails.modificationTime",
                            {
                              hours: rate.modificationTime,
                            },
                          )}
                        </span>
                        <IconCircleCheckFilled
                          size={16}
                          style={{ color: theme.palette.eucalyptus }}
                        />
                        <span>
                          {t(
                            "searchTransferPage.transferDetails.cancellationTime",
                            {
                              hours: rate.cancellationTime,
                            },
                          )}
                        </span>
                      </S.Item>
                    </S.FooterDetails>
                    <S.PriceWrapper>
                      {t("searchTransferPage.transferDetails.totalPrice")}
                      <MarkupPrice
                        value={{
                          total: rate.price,
                          net: rate.priceNet,
                        }}
                      >
                        <S.PriceWrapper>
                          <Price value={rate.price} />
                        </S.PriceWrapper>
                      </MarkupPrice>
                    </S.PriceWrapper>
                  </div>
                  <div>
                    <Button
                      variant="tertiary"
                      fullWidth={true}
                      onClick={() =>
                        navigate({
                          pathname: `/search/transfers/${searchId}/${rateId}/book`,
                          search: search,
                        })
                      }
                    >
                      {t("searchTransferPage.transferDetails.book")}
                    </Button>
                  </div>
                </S.Footer>
              </S.Wrapper>
            </PageSplitLayout.Content>
          </PageSplitLayout>
        </PageSpacer>
      </Container>
    </PageWrapper>
  );
};

export default SearchTransferPage;
