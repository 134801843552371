import { PackageSearchQuery } from "@hotelspoint/types";

import createRecentSearchesStore from "./utils/createRecentSearchesStore";

const PERSIST_NAME = "@hp::recent_package_searches";

export interface RecentPackageSearchQuery extends PackageSearchQuery {
  meta: {
    destinationName: string;
    programName: string;
  };
}

const useRecentPackagesSearchesStore =
  createRecentSearchesStore<RecentPackageSearchQuery>({
    persistName: PERSIST_NAME,
  });

export default useRecentPackagesSearchesStore;
