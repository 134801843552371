import theme from "@hotelspoint/theme";

export const getFirstInitial = (name: string): string => {
  if (!name) return "";
  return name.charAt(0).toUpperCase();
};

export const getRandomColor = (): string => {
  const colors = [
    theme.palette.ocean,
    theme.palette.sky,
    theme.palette.silverChalice,
    theme.palette.eucalyptus,
    theme.palette.redBerry,
  ];
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};

export const getCountryName = (code: string, language: string = "en") => {
  const displayNames = new Intl.DisplayNames([language], { type: "region" });
  return displayNames.of(code.toUpperCase()) || code.toUpperCase();
};
