import {
  handleError,
  useFetchAgency,
  useFetchCurrentOffer,
  useFetchProfile,
  useLoginVerify,
} from "@hotelspoint/api";
import {
  Button,
  Form,
  FormAdapter,
  FormContext,
  FormInput,
  FormLayout,
} from "@hotelspoint/components";
import {
  useUserAgencyStore,
  useUserCurrentOfferStore,
  useUserProfileStore,
  useUserTokenStore,
  useUserVerifyTokenStore,
} from "@hotelspoint/store";
import qs from "query-string";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import * as S from "./LoginVerifyForm.styled";
import {
  defaultValues,
  type FormValues,
  validationSchema,
} from "./LoginVerifyForm.util";

const LoginVerifyForm = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = qs.parse(location.search);

  const [loginVerify, isUserLoggingIn] = useLoginVerify();
  const [fetchProfile] = useFetchProfile();
  const [fetchAgency] = useFetchAgency();
  const [fetchCurrentOffer] = useFetchCurrentOffer();

  const setToken = useUserTokenStore(state => state.set);
  const setProfile = useUserProfileStore(state => state.set);
  const setAgency = useUserAgencyStore(state => state.set);
  const setCurrentOffer = useUserCurrentOfferStore(state => state.set);

  const { token: tempToken, reset: resetTempToken } = useUserVerifyTokenStore(
    state => state,
  );

  const onSubmit = async (formValues: FormValues) => {
    try {
      const response = await loginVerify({
        ...formValues,
        token: tempToken as string,
      });

      if (response.result) {
        setToken(tempToken as string);
        resetTempToken();

        const profile = await fetchProfile();
        setProfile(profile);

        const agency = await fetchAgency(profile.agencyId);
        setAgency(agency);

        const offer = await fetchCurrentOffer();
        setCurrentOffer(offer);

        if (queryParams.redirectTo) {
          navigate(queryParams.redirectTo as string);
        } else {
          navigate("/hotels");
        }

        toast.dismiss();
      }
    } catch (error: any) {
      handleError({ t, error });
    }
  };

  return (
    <Form<FormValues>
      defaultValues={defaultValues}
      validationSchema={validationSchema}
    >
      <FormLayout>
        <S.Title>{t("home.loginVerify.title")}</S.Title>
        <FormAdapter name="otp" label={t("home.loginVerify.otp.label")}>
          {props => (
            <FormInput
              {...props}
              placeholder={t("home.loginVerify.otp.placeholder")}
              disabled={isUserLoggingIn}
            />
          )}
        </FormAdapter>
        <p>{t("home.loginVerify.description")}</p>
        <FormContext<FormValues>
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="secondary"
              fullWidth
              isLoading={isUserLoggingIn}
              onClick={handleSubmit(onSubmit)}
            >
              {t("home.loginVerify.submit")}
            </Button>
          )}
        />
      </FormLayout>
    </Form>
  );
};

export default LoginVerifyForm;
