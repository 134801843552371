import { Hotel } from "./hotel";

export enum ServicesType {
  Included = "INCLUDED",
  Optional = "OPTIONAL",
  Excluded = "EXCLUDED",
}

export enum OriginDatesStatus {
  Available = "OK",
  NotAvailable = "NA",
  LastPackages = "LP",
}

export enum PackageComponentType {
  Hotel = "hotel",
  Flight = "flight",
  Transfer = "transfer",
  Service = "service",
}

export enum FlightStatus {
  Ok = "ok",
}

export enum CabinType {
  FirstClass = "FIRST_CLASS",
}

export enum BaggageType {
  Checked = "CHECKED",
}
export interface PackageRoomGuests {
  adults: number;
  children: number[];
}
export interface Destination {
  id: number;
  name: string;
}
export interface DestinationGroup {
  group: string;
  destinations: Destination[];
}

export interface Itinerary {
  day: number;
  title: string;
  description: string;
}

export interface Services {
  id: number;
  name: string;
  category: string;
  type: string;
}

export interface Origin {
  code: string;
  name: string;
}

export interface OriginDates {
  [date: string]: {
    amount: string | null;
    status: string;
  };
}
export interface SearchFilter {
  id: number;
  count: number;
}

export interface SearchFilterName extends SearchFilter {
  name: string;
}

export interface BaggageItem {
  included: boolean;
  quantity: number;
  //todo: how many baggageType are there?
  baggageType: string;
  weightDetails: null | string;
}

export interface ComponentItemsOptionsBase {
  id: number;
  //todo: how many status are there?
  status: string;
  price: number;
  priceBase: number;
  priceNet: number;
  priceOperator: number;
}
export interface FlightSegment {
  number: string;
  cabinType: string;
  flightClass: string;
  marketingCompany: string;
  departure: {
    code: string;
    time: Date;
  };
  arrival: {
    code: string;
    time: Date;
  };
}

export interface FlightServiceOptions extends ComponentItemsOptionsBase {
  baggage: BaggageItem[];
  segments: FlightSegment[];
}

export interface TransferOptions extends ComponentItemsOptionsBase {
  name: string;
  details: string | null;
}

export interface ComponentItemsBase {
  id: number;
  name: string;
  dateFrom: string;
  dateTo: string;
}

export interface HotelComponentItems {
  count: number;
  total: number;
  hotels: Hotel[];
  filters: {
    price: {
      min: number;
      max: number;
    };
    meal: SearchFilterName[];
    rate: SearchFilterName[];
    rating: SearchFilter[];
    transfers: number;
    favorites: number;
  };
}

export interface FlightServiceComponentItems extends ComponentItemsBase {
  options: FlightServiceOptions[];
}

export interface TransferComponentItems extends ComponentItemsBase {
  options: TransferOptions[];
}

export interface PackageComponent<T> {
  id: number;
  name: string;
  type: PackageComponentType;
  items: T;
}

export interface PackageSearchQuery {
  destinationId: number;
  productId: number;
  origin: string;
  checkIn: string;
  duration: number;
  rooms: PackageRoomGuests[];
}

export interface PackageSearch<T> {
  id: number;
  components: PackageComponent<T>[];
}
