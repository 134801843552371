import * as S from "./ScrollIndicator.styled";

export enum ScrollIndicatorTheme {
  Light = "light",
  Dark = "dark",
}

export interface ScrollIndicatorProps {
  variant?: ScrollIndicatorTheme;
  title?: string;
}

const ScrollIndicator = ({
  variant = ScrollIndicatorTheme.Light,
  title,
}: ScrollIndicatorProps) => {
  return (
    <S.Wrapper>
      <S.Mouse $variant={variant}>
        <S.Move $variant={variant} />
      </S.Mouse>
      {title && <S.Title $variant={variant}>{title}</S.Title>}
    </S.Wrapper>
  );
};

export default ScrollIndicator;
