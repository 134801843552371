import { TransferOutbound, TransferReturn } from "@hotelspoint/api";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";

import * as S from "./TransferContent.styled";

interface TransferContentProps {
  outboundTransfer: TransferOutbound;
  returnTransfer: TransferReturn;
  isLoading?: boolean;
}

const TransferContent = ({
  outboundTransfer,
  returnTransfer,
}: TransferContentProps) => {
  const { t } = useTranslation();
  // todo: implement loading state
  return (
    <>
      <S.Transfer>
        {t("searchTransfersResults.detailsModal.direction.outboundTransfer")}
      </S.Transfer>
      {outboundTransfer.remarks.map((remark, index) => (
        <div key={index}>
          <span
            key={index}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(remark),
            }}
          />
        </div>
      ))}
      {returnTransfer?.remarks?.length ? (
        <>
          <S.Divider />
          <S.Transfer>
            {t("searchTransfersResults.detailsModal.direction.returnTransfer")}
          </S.Transfer>
          {returnTransfer.remarks.map((remark, index) => (
            <div key={index}>
              <span
                key={index}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(remark),
                }}
              />
            </div>
          ))}
        </>
      ) : null}
    </>
  );
};

export default TransferContent;
