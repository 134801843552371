import { useModalContainerStore } from "@hotelspoint/store";
import { getImagePathFromCDN, ImageType } from "@hotelspoint/utils";
import { IconMapPinFilled } from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import Button from "../../elements/Button";
import Price from "../../misc/Price";
import ActivityDetailsModal from "../ActivityDetailsModal";
import ActivityOptionsModal from "../ActivityOptionsModal";
import GalleryOverlay from "../GalleryOverlay";
import MarkupPrice from "../MarkupPrice";
import * as S from "./ActivityListing.styled";
import thumbPlaceholder from "./assets/thumb-placeholder.jpg";

interface ActivityListingProps {
  id: number;
  searchId: number;
  name: string;
  location: string;
  summary: string;
  provider: {
    brand: string;
    logo: string;
  };
  thumbnail: string;
  categories: string[];
  price: number;
  priceNet: number;
  images: string[];
  isLoading: boolean;
}

const ActivityListing = ({
  id,
  searchId,
  name,
  location,
  summary,
  provider,
  thumbnail,
  categories,
  price,
  priceNet,
  images,
  isLoading,
}: ActivityListingProps) => {
  const { t } = useTranslation();
  const { search: locationSearch } = useLocation();

  const openModal = useModalContainerStore(state => state.openModal);

  const search = useMemo(() => {
    const params = new URLSearchParams(locationSearch);
    params.delete("useCache");
    params.set("searchId", `${searchId}`);

    return params.toString();
  }, [locationSearch, searchId]);

  return (
    <S.Wrapper $elevation={3}>
      <S.ThumbnailWrapper>
        <S.Thumbnail
          onClick={() =>
            openModal({
              title: t("components.activityListing.gallery"),
              content: (
                <GalleryOverlay
                  images={images}
                  isLoading={isLoading}
                  imageType={ImageType.Activity}
                  initialSlideIndex={0}
                />
              ),
            })
          }
          $src={
            thumbnail
              ? getImagePathFromCDN(thumbnail as string, ImageType.Activity)
              : thumbPlaceholder
          }
        />
      </S.ThumbnailWrapper>
      <S.Body>
        <S.BodyHeader>
          <div>
            <S.TitleLink
              to={{
                pathname: `/search/activities/${id}`,
                search,
              }}
            >
              <h5>{name}</h5>
            </S.TitleLink>
            <S.Location>
              <IconMapPinFilled size={16} />
              <span>{location}</span>
            </S.Location>
          </div>
        </S.BodyHeader>
        <S.Summary>{summary}</S.Summary>
        <S.BodyFooter>
          <Button
            variant="outlined"
            onClick={() =>
              openModal({
                title: t("components.activityListing.activityDetails"),
                content: (
                  <ActivityDetailsModal
                    id={id}
                    searchId={searchId}
                    images={images}
                    name={name}
                    location={location}
                    price={price}
                    priceNet={priceNet}
                    imageType={ImageType.Activity}
                    isLoading={isLoading}
                  />
                ),
                closeButton: true,
              })
            }
          >
            {t("components.activityListing.information")}
          </Button>
          {provider.logo && <S.Provider $src={provider.logo} />}
        </S.BodyFooter>
      </S.Body>
      <S.Aside>
        <S.AsideHeader>{categories.join(", ")}</S.AsideHeader>
        <S.AsideGroup>
          <MarkupPrice
            value={{
              total: price,
              net: priceNet,
            }}
          >
            <S.Emphasized>
              {t("components.activityListing.price.from")}
            </S.Emphasized>
            <S.PriceWrapper>
              <Price value={price} />
            </S.PriceWrapper>
          </MarkupPrice>
          <Button
            variant="tertiary"
            fullWidth={true}
            onClick={() =>
              openModal({
                title: name,
                content: (
                  <ActivityOptionsModal activityId={id} searchId={searchId} />
                ),
              })
            }
          >
            {t("components.activityListing.select")}
          </Button>
        </S.AsideGroup>
      </S.Aside>
    </S.Wrapper>
  );
};

export default ActivityListing;
