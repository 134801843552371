import {
  Destination,
  DestinationGroup,
  Itinerary,
  Origin,
  OriginDates,
  PackageSearch,
  PackageSearchQuery,
  Services,
} from "@hotelspoint/types";

import FetchService from "./base/FetchService";

const PACKAGE_API_PATH = "packages";

export interface PackageProductsListParams {
  destinationId: number;
}

export interface DestinationListResponse extends Array<DestinationGroup> {}

export interface PackageListResponse extends Array<Destination> {}

export interface ProductDetailsResponse {
  id: number;
  name: string;
  category: string;
  images: string[];
  itineraries: Itinerary[];
  services: Services[];
}

export interface ProductOriginsResponse extends Array<Origin> {}

export interface ProductOriginDatesResponse extends Array<OriginDates> {}

export interface DurationResponse {
  min: number;
  max: number;
}

export interface PackageSearchPayload extends PackageSearchQuery {
  useCache?: boolean;
  useAsync?: boolean;
}

export interface PackageSearchResponse<T> extends PackageSearch<T> {}

const PackageService = {
  search: <T>(payload: PackageSearchPayload) => {
    return FetchService.post<PackageSearchResponse<T>>(
      `${PACKAGE_API_PATH}/availability`,
      payload,
    );
  },
  getList: () => {
    return FetchService.get<DestinationListResponse>(
      `${PACKAGE_API_PATH}/destinations`,
    );
  },
  getProductsList: (params: PackageProductsListParams) => {
    return FetchService.get<PackageListResponse>(
      `${PACKAGE_API_PATH}/products`,
      params,
    );
  },
  getProductDetails: (productId: number) => {
    return FetchService.get<ProductDetailsResponse>(
      `${PACKAGE_API_PATH}/products/${productId}`,
    );
  },
  getProductOrigins: (productId: number) => {
    return FetchService.get<ProductOriginsResponse>(
      `${PACKAGE_API_PATH}/products/${productId}/origins`,
    );
  },
  getProductOriginDates: (productId: number, originCode: string) => {
    return FetchService.get<ProductOriginDatesResponse>(
      `${PACKAGE_API_PATH}/products/${productId}/origins/${originCode}/dates`,
    );
  },
  getDurations: (productId: number) => {
    return FetchService.get<DurationResponse>(
      `${PACKAGE_API_PATH}/products/${productId}/durations`,
    );
  },
};

export default PackageService;
