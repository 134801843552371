import { SubAgencyStatementService } from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useSubAgencyStatementOptions = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["sub_agency_statements", "agency_options"],
    queryFn: () => SubAgencyStatementService.getOptions(),
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useSubAgencyStatementOptions;
