import {
  useAgency,
  useFetchAgencies,
  useFetchUsers,
  useUser,
} from "@hotelspoint/api";
import {
  Box,
  Button,
  Chip,
  Flex,
  Form,
  FormAdapter,
  FormAsyncSelect,
  FormClearableInput,
  FormContext,
  FormDatePickerSingle,
  FormLayout,
  FormSelect,
} from "@hotelspoint/components";
import {
  PAYMENT_STATUS_OPTIONS,
  RESERVATION_MESSAGE_FILTER_OPTIONS,
  RESERVATION_STATUS_OPTIONS,
  RESERVATION_TYPE_OPTIONS,
} from "@hotelspoint/constants";
import {
  getPaymentStatusColor,
  getReservationStatusColor,
  mapEntityToOption,
} from "@hotelspoint/utils";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import useProfileReservationsParams from "../useProfileReservationsParams";
import {
  entity2Form,
  form2Entity,
  FormValues,
} from "./ProfileReservationsFilters.form";

interface ProfileReservationsFiltersProps {
  isLoading: boolean;
}

const ProfileReservationsFilters = ({
  isLoading,
}: ProfileReservationsFiltersProps) => {
  const { t } = useTranslation();

  const [query, setQuery] = useProfileReservationsParams();

  const [fetchUsers, isFetchingUsers] = useFetchUsers();
  const [user, isLoadingUser] = useUser(Number(query?.userId));

  const [fetchAgencies, isFetchingAgencies] = useFetchAgencies();
  const [agency, isLoadingAgency] = useAgency(Number(query?.agencyId));

  const defaultUserOptions = useMemo(() => {
    if (!user) return [];

    return [
      {
        value: user.id,
        label: user.name,
      },
    ];
  }, [user]);

  const defaultAgencyOptions = useMemo(() => {
    if (!agency) return [];

    return [
      {
        value: agency.id,
        label: agency.name,
      },
    ];
  }, [agency]);

  const handleLoadUserOptions = useCallback(
    (name: string, callback: any) => {
      fetchUsers({ name }).then(response => {
        if (response && Array.isArray(response.results)) {
          callback(response.results.map(mapEntityToOption));
        }
      });
    },
    [fetchUsers],
  );

  const handleLoadAgencyOptions = useCallback(
    (name: string, callback: any) => {
      fetchAgencies({ name }).then(response => {
        if (response && Array.isArray(response.results)) {
          callback(response.results.map(mapEntityToOption));
        }
      });
    },
    [fetchAgencies],
  );

  const typeOptions = useMemo(
    () =>
      RESERVATION_TYPE_OPTIONS.map(type => ({
        ...type,
        label: t(type.label),
      })),

    [t],
  );

  const statusOptions = useMemo(
    () =>
      RESERVATION_STATUS_OPTIONS.map(status => ({
        ...status,
        label: (
          <Chip $color={getReservationStatusColor(status.value)}>
            {t(status.label)}
          </Chip>
        ),
      })),
    [t],
  );

  const messageFilterOptions = useMemo(
    () =>
      RESERVATION_MESSAGE_FILTER_OPTIONS.map(option => ({
        ...option,
        label: <span>{t(option.label)}</span>,
      })),
    [t],
  );

  const paymentStatusOptions = useMemo(
    () =>
      PAYMENT_STATUS_OPTIONS.map(status => ({
        ...status,
        label: (
          <Chip $color={getPaymentStatusColor(status.value)}>
            {t(status.label)}
          </Chip>
        ),
      })),
    [t],
  );

  const formValues = useMemo(() => {
    return entity2Form(query);
  }, [query]);

  const onSubmit = (formValues: FormValues) => {
    const payload = form2Entity(formValues);

    setQuery(payload);
  };

  return (
    <Form defaultValues={formValues}>
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="id"
              label={t("profileReservations.filters.id.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t("profileReservations.filters.id.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="type"
              label={t("profileReservations.filters.type.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "profileReservations.filters.type.placeholder",
                  )}
                  options={typeOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="dateFrom"
              label={t("profileReservations.filters.dateFrom.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "profileReservations.filters.dateFrom.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="dateTo"
              label={t("profileReservations.filters.dateTo.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "profileReservations.filters.dateTo.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="status"
              label={t("profileReservations.filters.status.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "profileReservations.filters.status.placeholder",
                  )}
                  options={statusOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="paymentStatus"
              label={t("profileReservations.filters.paymentStatus.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "profileReservations.filters.paymentStatus.placeholder",
                  )}
                  options={paymentStatusOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="deadlineFrom"
              label={t("profileReservations.filters.deadlineFrom.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "profileReservations.filters.deadlineFrom.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="deadlineTo"
              label={t("profileReservations.filters.deadlineTo.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "profileReservations.filters.deadlineTo.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="name"
              label={t("profileReservations.filters.name.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t(
                    "profileReservations.filters.name.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="cityName"
              label={t("profileReservations.filters.cityName.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t(
                    "profileReservations.filters.cityName.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="checkInFrom"
              label={t("profileReservations.filters.checkInFrom.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "profileReservations.filters.checkInFrom.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="checkInTo"
              label={t("profileReservations.filters.checkInTo.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "profileReservations.filters.checkInTo.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="messageFilter"
              label={t("profileReservations.filters.messageFilter.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "profileReservations.filters.messageFilter.placeholder",
                  )}
                  options={messageFilterOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="userId"
              label={t("profileReservations.filters.userId.label")}
            >
              {props => (
                <FormAsyncSelect
                  {...props}
                  placeholder={t(
                    "profileReservations.filters.userId.placeholder",
                  )}
                  defaultOptions={defaultUserOptions}
                  loadOptions={handleLoadUserOptions}
                  isLoading={isLoadingUser || isFetchingUsers}
                  isClearable
                  noOptionsMessage={() =>
                    t("profileReservations.filters.userId.noOptions")
                  }
                  loadingMessage={() =>
                    t("profileReservations.filters.userId.loading")
                  }
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="agencyId"
              label={t("profileReservations.filters.agencyId.label")}
            >
              {props => (
                <FormAsyncSelect
                  {...props}
                  placeholder={t(
                    "profileReservations.filters.agencyId.placeholder",
                  )}
                  defaultOptions={defaultAgencyOptions}
                  loadOptions={handleLoadAgencyOptions}
                  isLoading={isLoadingAgency || isFetchingAgencies}
                  isClearable
                  noOptionsMessage={() =>
                    t("profileReservations.filters.agencyId.noOptions")
                  }
                  loadingMessage={() =>
                    t("profileReservations.filters.agencyId.loading")
                  }
                />
              )}
            </FormAdapter>
          </Box>
        </Flex>
        <FormContext<FormValues>
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="secondary"
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              {t("profileReservations.filters.submit")}
            </Button>
          )}
        />
      </FormLayout>
    </Form>
  );
};

export default ProfileReservationsFilters;
