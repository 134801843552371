import {
  handleError,
  PaymentType,
  ReservationStatus,
  ReservationTransferCreatePayload,
  Transfer,
  TransferSearchPayload,
  useCreateReservation,
} from "@hotelspoint/api";
import {
  Box,
  Button,
  ConfirmDialog,
  Flex,
  Form,
  FormAdapter,
  FormContext,
  FormLayout,
  FormRadio,
  MarkupPrice,
  PassengerInfo,
  PassengerType,
  PaymentOptions,
  Price,
} from "@hotelspoint/components";
import { redirectCardPayment } from "@hotelspoint/components/src/composites/PaymentOptions";
import { useUserAgencyStore } from "@hotelspoint/store";
import {
  RateType,
  ReservationType,
  TransferTripType,
} from "@hotelspoint/types";
import {
  IconAddressBook,
  IconManFilled,
  IconPlaneArrival,
  IconPlaneDeparture,
  IconUserFilled,
} from "@tabler/icons-react";
import DOMPurify from "dompurify";
import { useMemo, useRef } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  OutboundTransferInfo,
  ReturnTransferInfo,
  TransferContactsInfo,
} from "../../../components";
import useTransferSearchParams from "../../useTransferSearchParams";
import * as S from "./SearchTransferBookForm.styled";
import {
  defaultValues,
  entity2Form,
  form2Entity,
  FormValues,
  validationSchema,
} from "./SearchTransferBookForm.util";
import { FormType } from "./types";

interface SearchTransferBookFormInner {
  rate?: Transfer;
  query: TransferSearchPayload;
  searchId: number;
  isLoading: boolean;
}

const SearchTransferBookFormInner = ({
  rate,
  query,
  searchId,
  isLoading,
}: SearchTransferBookFormInner) => {
  const { t } = useTranslation();

  const [formType] = useWatch({ name: ["type"] });

  const [createReservation] = useCreateReservation();

  const { agency } = useUserAgencyStore(state => state);

  const navigate = useNavigate();

  const dialogTriggerRef = useRef<HTMLDivElement>(null);

  const isNonrefundable = useMemo(
    () => rate?.rateType === RateType.NonRefundable,
    [rate],
  );

  const paymentTypes = [PaymentType.CreditCard];
  if ((agency?.credit?.limit ?? 0) > 0) {
    paymentTypes.push(PaymentType.CreditLine);
  }

  // Open the confirmation dialog that will create the reservation
  const onSubmit = () => {
    (dialogTriggerRef.current as HTMLElement).click();
  };

  const handleConfirm = async (formValues: FormValues) => {
    try {
      const formPayload = form2Entity(formValues);
      const payload: ReservationTransferCreatePayload = {
        type: ReservationType.Transfer,
        rateId: rate?.id as number,
        searchId: searchId,
        ...formPayload,
      };

      const response = await createReservation(payload);

      if (payload.paymentType === PaymentType.CreditCard) {
        redirectCardPayment(response.paymentUrl, response.paymentUrlParams);
      } else {
        if (response.status === ReservationStatus.PendingConfirmation) {
          toast.warning(t("toast.booking.pending"));
        } else {
          toast.success(t("toast.booking.success"));
        }
        navigate(`/profile/bookings/${response.id}`);
      }
    } catch (error: any) {
      handleError({ t, error });
    }
  };

  return (
    <>
      <FormContext<FormValues>
        render={({ handleSubmit }) => (
          <ConfirmDialog
            title={t("searchHotelBook.content.actions.book.modal.title")}
            description={t(
              "searchHotelBook.content.actions.book.modal.description",
            )}
            onConfirm={handleSubmit(handleConfirm)}
            isLoading={false}
          >
            <div ref={dialogTriggerRef} />
          </ConfirmDialog>
        )}
      />
      <FormLayout>
        <S.RadioGroup>
          {isLoading ? (
            <Skeleton />
          ) : (
            <Flex alignItems={"center"}>
              <Box width={[2 / 14]}>
                <p>{t("searchTransferBook.content.radioGroup.type")}</p>
              </Box>
              <Box width={[12 / 14]}>
                <FormAdapter name="type">
                  {props => (
                    <Flex alignItems={"center"}>
                      <Box width={[6 / 12]}>
                        <FormRadio
                          {...props}
                          id={FormType.LeaderOnly}
                          value={FormType.LeaderOnly}
                          label={t(
                            "searchTransferBook.content.radioGroup.leaderOnly",
                          )}
                          checked={formType === FormType.LeaderOnly}
                        />
                      </Box>
                      <Box width={[6 / 12]} px={[0, 0, 1, 1]}>
                        <FormRadio
                          {...props}
                          id={FormType.AllPassengers}
                          value={FormType.AllPassengers}
                          label={t(
                            "searchTransferBook.content.radioGroup.allPassengers",
                          )}
                          checked={formType === FormType.AllPassengers}
                        />
                      </Box>
                    </Flex>
                  )}
                </FormAdapter>
              </Box>
            </Flex>
          )}
        </S.RadioGroup>
        <S.SectionTitle>
          <IconUserFilled size={18} />
          {isLoading ? (
            <S.FlexGrow>
              <Skeleton />
            </S.FlexGrow>
          ) : (
            <span>
              {t("searchTransferBook.content.adult", {
                count: query.adults as number,
              })}
            </span>
          )}
        </S.SectionTitle>
        {isLoading ? (
          <PassengerInfo isLoading={isLoading} />
        ) : formType === FormType.LeaderOnly ? (
          <PassengerInfo name="leader.0" />
        ) : (
          Array.from({ length: query.adults as number }).map(
            (_, adultIndex) => {
              const name = `adults.${adultIndex}`;
              return <PassengerInfo key={adultIndex} name={name} />;
            },
          )
        )}
        {formType !== FormType.LeaderOnly && query.children.length !== 0 && (
          <>
            <S.SectionTitle>
              <IconManFilled size={18} />
              <span>{t("searchTransferBook.content.children")}</span>
            </S.SectionTitle>
            {query.children.map((_: number, childIndex: number) => {
              const name = `children.${childIndex}`;
              return (
                <PassengerInfo
                  key={childIndex}
                  name={name}
                  type={PassengerType.Children}
                />
              );
            })}
          </>
        )}
        <S.SectionTitle>
          <IconAddressBook size={18} />
          {isLoading ? (
            <S.FlexGrow>
              <Skeleton />
            </S.FlexGrow>
          ) : (
            <span>{t("searchTransferBook.content.contacts")}</span>
          )}
        </S.SectionTitle>
        {isLoading ? (
          <PassengerInfo isLoading={isLoading} />
        ) : (
          <TransferContactsInfo />
        )}
        <S.Divider />
        {rate?.outboundTransfer.remarks.length !== 0 && (
          <>
            <S.Title>{t("searchTransferBook.content.importantInfo")}</S.Title>
            <S.SectionTitle>
              <IconPlaneDeparture size={18} />
              {isLoading ? (
                <S.FlexGrow>
                  <Skeleton />
                </S.FlexGrow>
              ) : (
                <span>{t("searchTransferBook.content.outboundTransfer")}</span>
              )}
            </S.SectionTitle>
            <OutboundTransferInfo />
            {rate?.outboundTransfer?.remarks?.map((remark, index) => (
              <div key={index}>
                <span
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(remark),
                  }}
                />
              </div>
            ))}
          </>
        )}
        {query.type === TransferTripType.RoundTrip && (
          <>
            <S.Divider />
            <S.SectionTitle>
              <IconPlaneArrival size={18} />
              {isLoading ? (
                <S.FlexGrow>
                  <Skeleton />
                </S.FlexGrow>
              ) : (
                <span>{t("searchTransferBook.content.returnTransfer")}</span>
              )}
            </S.SectionTitle>
            <ReturnTransferInfo />
            {rate?.returnTransfer.remarks.length !== 0 && (
              <>
                {rate?.returnTransfer.remarks.map((remark, index) => (
                  <div key={index}>
                    <span
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(remark),
                      }}
                    />
                  </div>
                ))}
              </>
            )}
          </>
        )}
        {isNonrefundable && (
          <>
            <S.SectionTitle>
              {t("searchHotelBook.content.payment.title")}
            </S.SectionTitle>
            <PaymentOptions
              amount={rate?.priceNet as number}
              isDisabled={isLoading}
              activeTypes={paymentTypes}
            />
          </>
        )}
        <S.FormFooter>
          {isLoading ? (
            <S.FlexGrow>
              <Skeleton />
            </S.FlexGrow>
          ) : (
            <S.FormPriceWrap>
              <p>{t("searchTransferBook.content.totalPrice")}</p>
              <MarkupPrice
                value={{
                  total: rate?.price as number,
                  net: rate?.priceNet as number,
                }}
              >
                <S.PriceTotal>
                  <Price value={rate?.price as number} />
                </S.PriceTotal>
              </MarkupPrice>
            </S.FormPriceWrap>
          )}
          <FormContext<FormValues>
            render={({ handleSubmit }) => (
              <Button
                type="submit"
                variant="secondary"
                isLoading={isLoading}
                onClick={handleSubmit(onSubmit)}
              >
                {isNonrefundable
                  ? t("searchTransferBook.content.form.submitAndPay")
                  : t("searchTransferBook.content.form.submit")}
              </Button>
            )}
          />
        </S.FormFooter>
      </FormLayout>
    </>
  );
};

interface SearchTransferBookFormProps {
  searchId: number;
  rate?: Transfer;
  isLoading: boolean;
}

const SearchTransferBookForm = ({
  searchId,
  rate,
  isLoading,
}: SearchTransferBookFormProps) => {
  const [query] = useTransferSearchParams();
  const { agency } = useUserAgencyStore(state => state);
  const formValues = useMemo(() => {
    if (!query) return defaultValues;
    const children = Array.isArray(query.children) ? query.children : [];
    return entity2Form(
      rate as Transfer,
      agency,
      children,
      query as TransferSearchPayload,
    );
  }, [query, agency, rate]);

  return (
    <Form
      defaultValues={formValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {formMethods => (
        <SearchTransferBookFormInner
          rate={rate}
          query={query as TransferSearchPayload}
          searchId={searchId}
          isLoading={isLoading}
          {...formMethods}
        />
      )}
    </Form>
  );
};

export default SearchTransferBookForm;
