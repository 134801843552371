import { TransferOutbound, TransferReturn } from "@hotelspoint/api";

import TransferContent from "../TransferContent";

interface TransferDetailsModalProps {
  outboundTransfer: TransferOutbound;
  returnTransfer: TransferReturn;
  isLoading?: boolean;
}

const TransferDetailsModal = ({
  outboundTransfer,
  returnTransfer,
  isLoading,
}: TransferDetailsModalProps) => {
  return (
    <TransferContent
      outboundTransfer={outboundTransfer}
      returnTransfer={returnTransfer}
      isLoading={isLoading}
    />
  );
};

export default TransferDetailsModal;
