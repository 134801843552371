import { useHotelReviews, useHotelReviewsScore } from "@hotelspoint/api";
import { ReviewListing, ScoreCategory } from "@hotelspoint/components";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import * as S from "./ReviewModal.styled.ts";

interface ReviewModalModalProps {
  id: number;
}

const ReviewModal = ({ id }: ReviewModalModalProps) => {
  const { t } = useTranslation();

  const [reviewsScore, isLoadingReviewsScore] = useHotelReviewsScore(id);
  const [reviews, isLoadingReviews] = useHotelReviews(id);

  const categories = reviewsScore
    ? Object.entries(reviewsScore).map(([key, value]) => ({
        title: t(`components.reviewModal.categories.${key}`),
        score: value.score,
      }))
    : [];

  return (
    <>
      <S.Title>{t("components.reviewModal.categories.title")}</S.Title>
      {isLoadingReviewsScore ? (
        <Skeleton height={40} />
      ) : (
        <S.Categories>
          {categories.map(category => (
            <ScoreCategory
              key={category.title}
              title={category.title}
              score={category.score}
            />
          ))}
        </S.Categories>
      )}
      <S.Divider />
      <S.Title>{t("components.reviewModal.filters")}</S.Title>
      <S.Divider />
      <S.Title>{t("components.reviewModal.guestReviews")}</S.Title>
      {isLoadingReviews ? (
        <Skeleton height={40} />
      ) : (
        reviews?.map((review, index) => (
          <>
            <ReviewListing
              key={index}
              date={review.date}
              negative={review.negative}
              positive={review.positive}
              summary={review.summary}
              reviewer={review.reviewer}
              score={review.score}
            />
            <S.Divider />
          </>
        ))
      )}
    </>
  );
};

export default ReviewModal;
