import { pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { theme } from "styled-tools";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(2)}px;
`;

export const RangeSliderWrapper = styled.div`
  margin-top: ${spacing(1)}px;
  padding: 0 ${spacing(1)}px;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(1)}px;
`;

export const CheckboxLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CheckboxName = styled.span`
  font-size: ${pxToRem(14)};
  line-height: 1;
`;

export const CheckboxCount = styled(CheckboxName)``;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${theme("palette.silver")};
`;
