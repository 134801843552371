import { Dialog, Modal } from "@hotelspoint/components";
import { useTranslation } from "react-i18next";

import ProgramDetailsSection from "../ProgramDetailsSection";

interface ProgramDetailsModalProps {
  id: number;
  isOpen: boolean;
  onClose: () => void;
}

const ProgramDetailsModal = ({
  id,
  isOpen,
  onClose,
}: ProgramDetailsModalProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <Dialog.Content>
        <Modal
          title={t("searchPackages.programDetails.title")}
          onClose={onClose}
        >
          <ProgramDetailsSection productId={id} />
        </Modal>
      </Dialog.Content>
    </Dialog>
  );
};

export default ProgramDetailsModal;
