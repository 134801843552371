import {
  Box,
  FormAdapter,
  FormClearableInput,
  FormSelect,
} from "@hotelspoint/components";
import { TITLE_OPTIONS } from "@hotelspoint/constants";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import AdminReservationFormActivityTickets from "./AdminReservationFormActivityTickets";

const AdminReservationFormActivity = () => {
  const { t } = useTranslation();

  const titleOptions = useMemo(
    () =>
      TITLE_OPTIONS.map(title => ({
        ...title,
        label: t(title.label),
      })),
    [t],
  );

  return (
    <>
      <Box width={1} px={[0, 0, 1, 1]} mt={3}>
        <h5>{t("adminReservation.form.sections.activity")}</h5>
        <hr style={{ margin: "10px 0" }} />
      </Box>
      <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="activityName"
          label={t("adminReservation.form.activityName.label")}
        >
          {props => (
            <FormClearableInput
              {...props}
              placeholder={t("adminReservation.form.activityName.placeholder")}
            />
          )}
        </FormAdapter>
      </Box>
      <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="option"
          label={t("adminReservation.form.option.label")}
        >
          {props => (
            <FormClearableInput
              {...props}
              placeholder={t("adminReservation.form.option.placeholder")}
            />
          )}
        </FormAdapter>
      </Box>
      <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="location"
          label={t("adminReservation.form.location.label")}
        >
          {props => (
            <FormClearableInput
              {...props}
              placeholder={t("adminReservation.form.location.placeholder")}
            />
          )}
        </FormAdapter>
      </Box>
      <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="provider"
          label={t("adminReservation.form.provider.label")}
        >
          {props => (
            <FormClearableInput
              {...props}
              placeholder={t("adminReservation.form.provider.placeholder")}
            />
          )}
        </FormAdapter>
      </Box>
      <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="session"
          label={t("adminReservation.form.session.label")}
        >
          {props => (
            <FormClearableInput
              {...props}
              placeholder={t("adminReservation.form.session.placeholder")}
            />
          )}
        </FormAdapter>
      </Box>
      <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="language"
          label={t("adminReservation.form.language.label")}
        >
          {props => (
            <FormClearableInput
              {...props}
              placeholder={t("adminReservation.form.language.placeholder")}
            />
          )}
        </FormAdapter>
      </Box>
      <Box width={1} px={[0, 0, 1, 1]} mt={3}>
        <h5>{t("adminReservation.form.sections.contacts")}</h5>
        <hr style={{ margin: "10px 0" }} />
      </Box>
      <Box width={[1, 1, 1 / 2, 2 / 10]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="title"
          label={t("adminReservation.form.title.label")}
        >
          {props => (
            <FormSelect
              {...props}
              options={titleOptions}
              isSearchable={false}
            />
          )}
        </FormAdapter>
      </Box>
      <Box width={[1, 1, 1 / 2, 4 / 10]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="contactName"
          label={t("adminReservation.form.contactName.label")}
        >
          {props => (
            <FormClearableInput
              {...props}
              placeholder={t("adminReservation.form.contactName.placeholder")}
            />
          )}
        </FormAdapter>
      </Box>
      <Box width={[1, 1, 1 / 2, 4 / 10]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="contactSurname"
          label={t("adminReservation.form.contactSurname.label")}
        >
          {props => (
            <FormClearableInput
              {...props}
              placeholder={t(
                "adminReservation.form.contactSurname.placeholder",
              )}
            />
          )}
        </FormAdapter>
      </Box>
      <Box width={[1, 1, 1 / 2, 2 / 10]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="contactPhoneCode"
          label={t("adminReservation.form.contactPhoneCode.label")}
        >
          {props => (
            <FormClearableInput
              {...props}
              placeholder={t(
                "adminReservation.form.contactPhoneCode.placeholder",
              )}
            />
          )}
        </FormAdapter>
      </Box>
      <Box width={[1, 1, 1 / 2, 4 / 10]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="contactPhoneNumber"
          label={t("adminReservation.form.contactPhoneNumber.label")}
        >
          {props => (
            <FormClearableInput
              {...props}
              placeholder={t(
                "adminReservation.form.contactPhoneNumber.placeholder",
              )}
            />
          )}
        </FormAdapter>
      </Box>
      <Box width={[1, 1, 1 / 2, 4 / 10]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="contactEmail"
          label={t("adminReservation.form.contactEmail.label")}
        >
          {props => (
            <FormClearableInput
              {...props}
              placeholder={t("adminReservation.form.contactEmail.placeholder")}
            />
          )}
        </FormAdapter>
      </Box>
      <AdminReservationFormActivityTickets />
    </>
  );
};

export default AdminReservationFormActivity;
