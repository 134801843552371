import {
  Transfer,
  TransferOutbound,
  TransferReturn,
  usePlace,
} from "@hotelspoint/api";
import {
  Button,
  MarkupPrice,
  Price,
  TransferDetailsModal,
} from "@hotelspoint/components";
import { useModalContainerStore } from "@hotelspoint/store";
import { PlaceType, TransferTripType } from "@hotelspoint/types";
import {
  formatDateTimeHyphenUtc,
  getImagePathFromCDN,
  ImageType,
} from "@hotelspoint/utils";
import {
  IconCalendar,
  IconCalendarX,
  IconTagFilled,
  IconUsersGroup,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import useTransferSearchParams from "../../useTransferSearchParams";
import thumbPlaceholder from "./assets/thumbPlaceholder.jpg";
import * as S from "./SearchTransferBookSidebar.styled";

interface SearchTransferBookSidebarProps {
  rate?: Transfer;
  isLoading: boolean;
}

const SearchTransferBookSidebar = ({
  rate,
  isLoading,
}: SearchTransferBookSidebarProps) => {
  const { t } = useTranslation();

  const [query] = useTransferSearchParams();

  const openModal = useModalContainerStore(state => state.openModal);

  const [pickUpPlace, isLoadingPickUpPlace] = usePlace({
    id: query.pickUp?.placeId as number,
    type: query.pickUp?.placeType as PlaceType,
  });

  const [dropOffPlace, isLoadingDropOffPlace] = usePlace({
    id: query.dropOff?.placeId as number,
    type: query.dropOff?.placeType as PlaceType,
  });

  const isLoadingPlaces = isLoadingPickUpPlace || isLoadingDropOffPlace;

  return (
    <div>
      <S.Card>
        <S.TransferCardWrapper>
          <S.TransferCardDetails>
            <S.Title>{isLoading ? <Skeleton /> : rate?.name}</S.Title>
            {isLoading ? <Skeleton /> : rate?.vehicle}
            <S.ListItem>
              {isLoading ? (
                <Skeleton />
              ) : (
                <>
                  <IconUsersGroup size={16} />
                  <span>
                    {t(
                      "searchTransferBook.sidebar.transferDetails.maxPassengers",
                      {
                        count: rate?.maxPassengers,
                      },
                    )}
                  </span>
                </>
              )}
            </S.ListItem>
            <div>
              {isLoading ? (
                <Skeleton />
              ) : (
                <Button
                  variant="outlined"
                  onClick={() =>
                    openModal({
                      title: t(
                        "searchTransferBook.sidebar.transferDetails.label",
                      ),
                      content: (
                        <TransferDetailsModal
                          outboundTransfer={
                            rate?.outboundTransfer as TransferOutbound
                          }
                          returnTransfer={
                            rate?.returnTransfer as TransferReturn
                          }
                        />
                      ),
                      closeButton: true,
                    })
                  }
                >
                  {t("searchTransferBook.sidebar.transferDetails.label")}
                </Button>
              )}
            </div>
          </S.TransferCardDetails>
          <S.ThumbnailWrapper>
            <S.Thumbnail
              $src={
                rate?.image
                  ? getImagePathFromCDN(
                      rate?.image as string,
                      ImageType.Transfer,
                    )
                  : thumbPlaceholder
              }
            />
          </S.ThumbnailWrapper>
        </S.TransferCardWrapper>
      </S.Card>
      <S.HeadlineWrapper>
        <IconCalendar size={18} />
        <h6>{t("searchTransferBook.sidebar.outboundTransfer.label")}</h6>
      </S.HeadlineWrapper>{" "}
      {isLoadingPlaces ? (
        <Skeleton />
      ) : (
        <S.Card>
          <S.TransferWrapper>
            <S.TransferDirectionWrapper>
              <S.TransferDirection>
                {t("searchTransferBook.sidebar.outboundTransfer.from")}
              </S.TransferDirection>
              <S.TransferPlaceTitle>{pickUpPlace?.name}</S.TransferPlaceTitle>
            </S.TransferDirectionWrapper>
            <S.Separator />
            <S.TransferDirectionWrapper>
              <S.TransferDirection>
                {t("searchTransferBook.sidebar.outboundTransfer.to")}
              </S.TransferDirection>
              <S.TransferPlaceTitle>{dropOffPlace?.name}</S.TransferPlaceTitle>
            </S.TransferDirectionWrapper>
          </S.TransferWrapper>
          <S.TransferDateWrapper>
            <span>{t("searchTransferBook.sidebar.date")}</span>
            <S.TransferDateTime>
              {query.outboundDate
                ? formatDateTimeHyphenUtc(query.outboundDate as string)
                : undefined}
            </S.TransferDateTime>
          </S.TransferDateWrapper>
        </S.Card>
      )}
      {query.type === TransferTripType.RoundTrip && (
        <>
          <S.HeadlineWrapper>
            <IconCalendar size={18} />
            <h6>{t("searchTransferBook.sidebar.returnTransfer.label")}</h6>
          </S.HeadlineWrapper>
          {isLoadingPlaces ? (
            <Skeleton />
          ) : (
            <S.Card>
              <S.TransferWrapper>
                <S.TransferDirectionWrapper>
                  <S.TransferDirection>
                    {t("searchTransferBook.sidebar.returnTransfer.from")}
                  </S.TransferDirection>
                  <S.TransferPlaceTitle>
                    {dropOffPlace?.name}
                  </S.TransferPlaceTitle>
                </S.TransferDirectionWrapper>
                <S.Separator />
                <S.TransferDirectionWrapper>
                  <S.TransferDirection>
                    {t("searchTransferBook.sidebar.returnTransfer.to")}
                  </S.TransferDirection>
                  <S.TransferPlaceTitle>
                    {pickUpPlace?.name}
                  </S.TransferPlaceTitle>
                </S.TransferDirectionWrapper>
              </S.TransferWrapper>
              <S.TransferDateWrapper>
                <span>{t("searchTransferBook.sidebar.date")}</span>
                <S.TransferDateTime>
                  {query.returnDate
                    ? formatDateTimeHyphenUtc(query.returnDate as string)
                    : undefined}
                </S.TransferDateTime>
              </S.TransferDateWrapper>
            </S.Card>
          )}
        </>
      )}
      <S.HeadlineWrapper>
        <IconCalendarX size={18} />
        <h6>{t("searchTransferBook.sidebar.cancellationPolicy.title")}</h6>
      </S.HeadlineWrapper>
      <S.Card>
        <span>
          {t("searchTransferBook.sidebar.cancellationPolicy.cancellationTime", {
            hours: rate?.cancellationTime,
          })}
        </span>
      </S.Card>
      <S.HeadlineWrapper>
        <IconTagFilled size={18} />
        <h6>{t("searchTransferBook.sidebar.priceBreakdown.title")}</h6>
      </S.HeadlineWrapper>
      <S.Card>
        <S.Flex>
          <S.PriceTitle>
            <p>
              {t(
                "searchTransferBook.sidebar.priceBreakdown.outboundTransferPrice",
              )}
            </p>
            {query.type === TransferTripType.RoundTrip && (
              <p>
                {t(
                  "searchTransferBook.sidebar.priceBreakdown.returnTransferPrice",
                )}
              </p>
            )}
          </S.PriceTitle>
          <S.PriceList>
            <S.PriceItem>
              <Price value={rate?.outboundTransfer.price as number} />
            </S.PriceItem>
            {query.type === TransferTripType.RoundTrip && (
              <S.PriceItem>
                <Price value={rate?.returnTransfer.price as number} />
              </S.PriceItem>
            )}
          </S.PriceList>
        </S.Flex>
        <S.Flex>
          <S.PriceTitle>
            {t("searchTransferBook.sidebar.totalPrice")}
          </S.PriceTitle>
          <MarkupPrice
            value={{
              total: rate?.price as number,
              net: rate?.priceNet as number,
            }}
          >
            <S.Price>
              <Price value={rate?.price as number} />
            </S.Price>
          </MarkupPrice>
        </S.Flex>
      </S.Card>
    </div>
  );
};

export default SearchTransferBookSidebar;
