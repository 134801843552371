import { HotelSearchFilters, Place, usePlace } from "@hotelspoint/api";
import {
  HotelMap,
  LoaderBlock,
  MAP_ZOOM,
  MapLayout,
  useMapContext,
} from "@hotelspoint/components";
import useHotelMapStore from "@hotelspoint/store/src/useHotelMapStore.ts";
import { HotelSearch, PlaceType } from "@hotelspoint/types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SearchHotelsViewState } from "../../types";
import useHotelSearchParams from "../../useHotelSearchParams";
import SearchHotelsFilters from "../SearchHotelsFilters/SearchHotelsFilters";
import SearchPointOfInterestForm from "../SearchPointOfInterestForm";

interface SearchHotelsMapViewProps {
  hotels: HotelSearch[];
  filters: HotelSearchFilters | null;
  setFilters: (filters: HotelSearchFilters) => void;
}

const SearchHotelsMap = ({
  hotels,
  filters,
  setFilters,
}: SearchHotelsMapViewProps) => {
  const { t } = useTranslation();

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [query, setQuery] = useHotelSearchParams();
  const activeHotelId = useHotelMapStore(state => state.hotelId);
  const setActiveHotelId = useHotelMapStore(state => state.set);
  const [activeHotel, setActiveHotel] = useState<HotelSearch | null>(null);

  const [place, isLoadingPlace] = usePlace({
    id: query.placeId as number,
    type: query.placeType as PlaceType,
  });

  const { mapInstance } = useMapContext();

  useEffect(() => {
    if (activeHotelId) {
      const activeHotel = hotels.find(hotel => hotel.id === activeHotelId);
      setActiveHotel(activeHotel || null);
    } else {
      setActiveHotel(null);
    }
  }, [activeHotelId, hotels]);

  useEffect(() => {
    if (activeHotel && mapInstance) {
      mapInstance.flyTo({
        center: [activeHotel.longitude, activeHotel.latitude],
        zoom: MAP_ZOOM.POI,
      });
    }
  }, [activeHotel, mapInstance]);

  if (query.view !== SearchHotelsViewState.Map) {
    return null;
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.code === "Escape") {
      setQuery({
        view: SearchHotelsViewState.List,
      });
      setActiveHotelId(null);
    }
  };

  return (
    <MapLayout
      onKeyDown={handleKeyDown}
      filters={
        filters && (
          <SearchHotelsFilters
            value={filters}
            onChange={value => setFilters(value)}
          />
        )
      }
      isFiltersOpen={isFiltersOpen}
      toggleFilters={() => setIsFiltersOpen(isFiltersOpen => !isFiltersOpen)}
      handleClose={() =>
        setQuery({
          view: SearchHotelsViewState.List,
        })
      }
    >
      {isLoadingPlace ? (
        <LoaderBlock>
          <h6>{t("searchHotelResults.loading")}</h6>
        </LoaderBlock>
      ) : (
        <HotelMap
          hotels={hotels}
          initialPlace={place as Place}
          controls={<SearchPointOfInterestForm />}
          activeHotelId={activeHotelId}
          handleClose={() => {
            setQuery({
              view: SearchHotelsViewState.List,
            });
            setActiveHotelId(null);
          }}
          toggleFilters={() =>
            setIsFiltersOpen(isFiltersOpen => !isFiltersOpen)
          }
        />
      )}
    </MapLayout>
  );
};

export default SearchHotelsMap;
