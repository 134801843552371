import { getImagePathFromCDN, ImageType } from "@hotelspoint/utils";

import Carousel from "../Carousel";
import LoaderBlock from "../LoaderBlock";

interface GalleryOverlayProps {
  images: string[];
  isLoading: boolean;
  imageType: ImageType;
  initialSlideIndex: number;
}

const imageStyles = {
  height: 560,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  backgroundSize: "contain",
  borderRadius: "12px",
};

const GalleryOverlay = ({
  images,
  isLoading,
  imageType,
  initialSlideIndex,
}: GalleryOverlayProps) => {
  return (
    <>
      {isLoading ? (
        <LoaderBlock />
      ) : (
        <Carousel initialSlide={initialSlideIndex}>
          {images?.map((img, index) => (
            <div key={index}>
              <div
                style={{
                  ...imageStyles,
                  backgroundImage: `url(${getImagePathFromCDN(
                    img,
                    imageType as ImageType,
                  )})`,
                }}
              />
            </div>
          ))}
        </Carousel>
      )}
    </>
  );
};

export default GalleryOverlay;
