import {
  Breadcrumb,
  BreadcrumbContainer,
  Panel,
} from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { IconSettings, IconUser } from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import ProfileSubAgenciesRegisterForm from "./ProfileSubAgenciesRegisterForm";

const ProfileSubAgenciesRegister = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("profileSubAgenciesSettings.pageTitle"));

  const breadcrumbs = useMemo(
    () => [
      {
        title: t("profile.pageTitle"),
        href: "/profile",
        icon: IconUser,
      },
      {
        title: t("profileSubAgenciesSettings.pageTitle"),
        href: "/profile/sub-agencies",
        icon: IconSettings,
      },
      {
        title: t("profileSubAgenciesRegister.pageTitle"),
        icon: IconSettings,
      },
    ],
    [t],
  );

  return (
    <>
      <BreadcrumbContainer>
        <Breadcrumb items={breadcrumbs} />
      </BreadcrumbContainer>
      <Panel title={t("profileSubAgenciesRegister.pageTitle")}>
        <ProfileSubAgenciesRegisterForm />
      </Panel>
    </>
  );
};

export default ProfileSubAgenciesRegister;
