import {
  SubAgencyStatementListParams,
  SubAgencyStatementService,
} from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useSubAgencyStatements = (params?: SubAgencyStatementListParams) => {
  const { data, isLoading } = useQuery({
    queryKey: ["sub_agency_statements", params],
    queryFn: () => SubAgencyStatementService.getList(params),
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useSubAgencyStatements;
