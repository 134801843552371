import { usePlace, usePlaces } from "@hotelspoint/api";
import {
  Box,
  Button,
  Flex,
  Form,
  FormAdapter,
  FormAutoComplete,
  FormAutoCompleteOption,
  FormChildrenMethods,
  FormContext,
  FormLayout,
  OptionGroup,
  PlaceTypeIcon,
  useMapContext,
} from "@hotelspoint/components";
import { DEBOUNCE_DELAY_INPUT } from "@hotelspoint/constants";
import { PlaceType } from "@hotelspoint/types";
import { getPlaceTypeName } from "@hotelspoint/utils";
import { useCallback, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";

import * as S from "./SearchPointOfInterestForm.styled";
import {
  defaultValues,
  FormValues,
  validationSchema,
} from "./SearchPointOfInterestForm.util";

interface SearchPointOfInterestFormPropsInnerProps
  extends FormChildrenMethods<FormValues> {}

type PlaceOption = Required<FormAutoCompleteOption<{ type: PlaceType }>>;

const SearchPointOfInterestFormInner = ({
  setValue,
}: SearchPointOfInterestFormPropsInnerProps) => {
  const { t } = useTranslation();

  const { mapInstance } = useMapContext();

  const [search, place] = useWatch({
    name: ["search", "place"],
  });

  const [debouncedSearch] = useDebounce(search, DEBOUNCE_DELAY_INPUT);

  const [places, isLoadingPlaces] = usePlaces({
    types: [PlaceType.PointOfInterest],
    search: debouncedSearch ?? "",
  });

  const [placeDetails, isLoadingPlaceDetails] = usePlace({
    id: place?.id as number,
    type: place?.type as PlaceType,
  });

  const placeGroupOptions: OptionGroup<PlaceOption>[] = useMemo(() => {
    if (!places || isLoadingPlaces) return [];

    return Object.entries(places)
      .map(([type, places]) => {
        return {
          label: t(getPlaceTypeName(type as PlaceType)),
          value: type as PlaceType,
          options: places?.map(p => ({
            value: p.id,
            label: p.name,
            meta: {
              type: type as PlaceType,
            },
          })),
        };
      })
      .filter(group => group.options.length > 0);
  }, [t, places, isLoadingPlaces]);

  const handleSelectPlace = useCallback(
    (option: any) => {
      if (option) {
        setValue("place", {
          id: option.value,
          type: option.meta.type,
        });
      } else {
        setValue("place", {});
      }
    },
    [setValue],
  );

  const onSubmit = useCallback(() => {
    if (!isLoadingPlaceDetails && mapInstance) {
      mapInstance.flyTo({
        center: [
          placeDetails?.position.longitude as number,
          placeDetails?.position.latitude as number,
        ],
        zoom: 16,
        duration: 2000,
      });
    }
  }, [
    isLoadingPlaceDetails,
    mapInstance,
    placeDetails?.position.latitude,
    placeDetails?.position.longitude,
  ]);

  return (
    <FormLayout>
      <Flex mx={[0, 0, -1, -1]}>
        <Box width={3 / 4} px={[0, 1, 1, 1]}>
          <FormAdapter name="search">
            {props => (
              <FormAutoComplete
                {...props}
                placeholder={t("searchHotelResults.poiForm.search.placeholder")}
                options={placeGroupOptions}
                isLoading={isLoadingPlaces}
                renderOption={option => (
                  <S.PlaceOption key={option.value}>
                    <PlaceTypeIcon
                      type={option.meta.type}
                      iconProps={{ size: 22 }}
                    />
                    <span>{option.label}</span>
                  </S.PlaceOption>
                )}
                onSelect={handleSelectPlace}
              />
            )}
          </FormAdapter>
        </Box>
        <Box width={1 / 4} px={[0, 1, 1, 1]}>
          <FormContext<FormValues>
            render={({ handleSubmit }) => (
              <Button
                type="submit"
                variant="secondary"
                onClick={handleSubmit(onSubmit)}
              >
                {t("searchHotelResults.poiForm.submit")}
              </Button>
            )}
          />
        </Box>
      </Flex>
    </FormLayout>
  );
};

const SearchPointOfInterestForm = () => {
  return (
    <Form<FormValues>
      defaultValues={defaultValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {formMethods => <SearchPointOfInterestFormInner {...formMethods} />}
    </Form>
  );
};

export default SearchPointOfInterestForm;
