import {
  Button,
  HotelBaseInfo,
  HotelDetailsModal,
} from "@hotelspoint/components";
import { useModalContainerStore } from "@hotelspoint/store";
import { useTranslation } from "react-i18next";

import * as S from "./ReservationHotelInfo.styled";

interface ReservationHotelInfoProps {
  id: number;
}

const ReservationHotelInfo = ({ id }: ReservationHotelInfoProps) => {
  const { t } = useTranslation();

  const openModal = useModalContainerStore(state => state.openModal);

  return (
    <S.Card>
      <HotelBaseInfo id={id} />
      <Button
        variant="outlined"
        onClick={() =>
          openModal({
            title: t("profileReservation.hotelDetails"),
            content: <HotelDetailsModal id={id} />,
          })
        }
      >
        {t("profileReservation.hotelDetails")}
      </Button>
    </S.Card>
  );
};

export default ReservationHotelInfo;
