import {
  ApiResponseSuccessResultList,
  PaginationOptions,
  ReservationType,
} from "@hotelspoint/types";

import FetchService from "./base/FetchService";
import { ReservationStatus } from "./ReservationService";

const SUB_AGENCY_STATEMENT_API_PATH = "sub-agencies-statements";

export enum SubAgencyStatementStatus {
  Pending = 1,
  Paid = 2,
}

export interface SubAgencyStatementItem {
  id: number;
  reservationId: number;
  reservationStatus: ReservationStatus;
  status: SubAgencyStatementStatus;
  amount: number;
  clientName: string;
  voucherCode: string;
  dateFrom: string;
  dateTo: string;
  dateDeadline: string;
  itemType: ReservationType;
  itemId: number;
  // @todo: use the correct interface from the ReservationService; Unused at the moment
  // itemData: any;
}

export interface SubAgencyStatement {
  id: number;
  agencyId: number;
  agencyName: string;
  status: SubAgencyStatementStatus;
  amount: number;
  paymentsCount: number;
  items?: SubAgencyStatementItem[];
  dateCreated: string;
}

export interface SubAgencyStatementCreatePayload {
  agencyId: number;
  paymentIds: number[];
}

export interface SubAgencyStatementUpdatePayload {
  paidIds?: number[];
  notPaidIds?: number[];
}

export interface SubAgencyStatementListParams extends PaginationOptions {
  id?: number;
  agencyId?: number;
  status?: SubAgencyStatementStatus;
  dateFrom?: string;
  dateTo?: string;
}

export type SubAgencyStatementListResponse = ApiResponseSuccessResultList<
  Omit<SubAgencyStatement, "items">
>;

export type SubAgencyStatementItemListResponse = SubAgencyStatementItem[];

export interface SubAgencyStatementOption {
  id: number;
  name: string;
}

export type SubAgencyStatementOptionListResponse = SubAgencyStatementOption[];

const SubAgencyStatementService = {
  create: (payload: SubAgencyStatementCreatePayload) => {
    return FetchService.post<SubAgencyStatement>(
      SUB_AGENCY_STATEMENT_API_PATH,
      payload,
    );
  },
  get: (id: number) => {
    return FetchService.get<SubAgencyStatement>(
      `${SUB_AGENCY_STATEMENT_API_PATH}/${id}`,
    );
  },
  update: (id: number, payload: SubAgencyStatementUpdatePayload) => {
    return FetchService.patch<SubAgencyStatement>(
      `${SUB_AGENCY_STATEMENT_API_PATH}/${id}`,
      payload,
    );
  },
  getList: (params?: SubAgencyStatementListParams) => {
    return FetchService.get<SubAgencyStatementListResponse>(
      SUB_AGENCY_STATEMENT_API_PATH,
      params,
    );
  },
  getItems: (id: number) => {
    return FetchService.get<SubAgencyStatementItemListResponse>(
      `${SUB_AGENCY_STATEMENT_API_PATH}/agencies/${id}/items`,
    );
  },
  getOptions: () => {
    return FetchService.get<SubAgencyStatementOptionListResponse>(
      `${SUB_AGENCY_STATEMENT_API_PATH}/agencies`,
    );
  },
};

export default SubAgencyStatementService;
