import { HotelSearchFilters, HotelsSearchResponse } from "@hotelspoint/api";
import { HotelSearch } from "@hotelspoint/types";
import { create } from "zustand";

interface HotelsSearchResultsStore {
  id: number | null;
  count: number | null;
  total: number | null;
  results: HotelSearch[];
  filters: HotelSearchFilters | null;
  isLoading: boolean;
  isCompleted: boolean | null;
  setLoading: (isLoading: boolean) => void;
  setCompleted: (isLoading: boolean) => void;
  setResponse: (response: HotelsSearchResponse) => void;
  reset: () => void;
}

const useHotelsSearchResultStore = create<HotelsSearchResultsStore>(set => ({
  id: null,
  count: null,
  total: null,
  results: [],
  filters: null,
  isLoading: false,
  isCompleted: null,
  setLoading: isLoading => set({ isLoading }),
  setCompleted: isCompleted => set({ isCompleted }),
  setResponse: response =>
    set({
      id: response.id,
      count: response.count,
      total: response.total,
      results: response.results,
      filters: response.filters,
      isCompleted: response.isCompleted,
    }),
  reset: () =>
    set({
      id: null,
      count: null,
      total: null,
      results: [],
      filters: null,
      isLoading: false,
      isCompleted: null,
    }),
}));
export default useHotelsSearchResultStore;
