import { timing, zIndex } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { ifProp, theme } from "styled-tools";

import { DrawerPlacement, DrawerProps } from "./types";
import { calculatePosition, getDrawerSize, getShadow } from "./utils";

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndex.drawerBackdrop};
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Wrapper = styled.div<{
  $isOpen: boolean;
  $size: DrawerProps["size"];
  $placement: DrawerProps["placement"];
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  z-index: ${zIndex.navigationOverlay};
  ${({ $isOpen, $placement, $size }) => {
    const { top, bottom, left, right } = calculatePosition(
      $isOpen,
      $placement,
      $size,
    );
    return `
      top: ${top};
      bottom: ${bottom};
      left: ${left};
      right: ${right};
    `;
  }}
  width: ${({ $size, $placement }) =>
    $placement === DrawerPlacement.Left || $placement === DrawerPlacement.Right
      ? getDrawerSize($size, $placement)
      : "100%"};
  height: ${({ $size, $placement }) =>
    $placement === DrawerPlacement.Top || $placement === DrawerPlacement.Bottom
      ? getDrawerSize($size, $placement)
      : "100%"};
  background-color: ${theme("palette.white")};
  transition: all ease ${timing.normal}ms;
  box-shadow: ${ifProp("$isOpen", ({ $placement }) => getShadow($placement))};
  overflow-y: auto;
  overscroll-behavior: contain;
`;

export const Header = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
`;

export const Content = styled.div`
  flex: 1;
`;

export const Footer = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.15);
`;
