import { useAdminMeals, usePlaces } from "@hotelspoint/api";
import {
  Box,
  FormAdapter,
  FormAutoComplete,
  FormAutoCompleteOption,
  FormSelect,
  OptionGroup,
  PlaceTypeIcon,
} from "@hotelspoint/components";
import { HOTEL_CONFIRMATION_STATUS_OPTIONS } from "@hotelspoint/constants";
import { PlaceType } from "@hotelspoint/types";
import { getPlaceTypeName } from "@hotelspoint/utils";
import { useCallback, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";

import { FormValues } from "../AdminReservationForm.util";
import * as S from "./AdminReservationFormHotel.styled";

type PlaceOption = Required<FormAutoCompleteOption<{ type: PlaceType }>>;

const AdminReservationFormHotel = () => {
  const { t } = useTranslation();

  const { setValue } = useFormContext<FormValues>();
  const [hotelName] = useWatch<FormValues>({ name: ["hotelName"] });

  const [debouncedhotelName] = useDebounce(hotelName as string, 350);

  const [hotelPlaces, isLoadingHotelPlaces] = usePlaces({
    types: [PlaceType.Hotel],
    search: debouncedhotelName ?? "",
  });

  // Loading only main meals, using higher page size to load all available
  const [meals, isLoadingMeals] = useAdminMeals({
    isMain: true,
    pageIndex: 1,
    pageSize: 100,
  });

  const mealTypeOptions = useMemo(() => {
    if (!meals || isLoadingMeals) return [];

    return meals.results.map(meal => ({
      value: meal.id,
      label: meal.name,
    }));
  }, [meals, isLoadingMeals]);

  const hotelConfirmationStatusOptions = useMemo(
    () =>
      HOTEL_CONFIRMATION_STATUS_OPTIONS.map(status => ({
        ...status,
        label: t(status.label),
      })),
    [t],
  );

  const hotelOptions: OptionGroup<PlaceOption>[] = useMemo(() => {
    if (!hotelPlaces || isLoadingHotelPlaces) return [];
    return Object.entries(hotelPlaces)
      .map(([type, places]) => {
        return {
          label: t(getPlaceTypeName(type as PlaceType)),
          value: type as PlaceType,
          options: places?.map(p => ({
            value: p.id,
            label: p.name,
            meta: {
              type: type as PlaceType,
            },
          })),
        };
      })
      .filter(group => group.options.length > 0);
  }, [t, hotelPlaces, isLoadingHotelPlaces]);

  const handleSelectHotel = useCallback(
    (option: any) => {
      console.log(option);
      if (option) {
        setValue("hotelPlace", {
          id: option.value,
          type: option.meta.type,
        });
      } else {
        setValue("hotelPlace", {});
      }
    },
    [setValue],
  );

  return (
    <>
      <Box width={1} px={[0, 0, 1, 1]} mt={3}>
        <h5>Hotel</h5>
        <hr style={{ margin: "10px 0" }} />
      </Box>
      <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="hotelName"
          label={t("adminReservation.form.hotelName.label")}
        >
          {props => (
            <FormAutoComplete
              {...props}
              placeholder={t("adminReservation.form.hotelName.placeholder")}
              options={hotelOptions}
              isLoading={isLoadingHotelPlaces}
              renderOption={option => (
                <S.PlaceOption key={option.value}>
                  <PlaceTypeIcon
                    type={option.meta.type}
                    iconProps={{ size: 22 }}
                  />
                  <span>{option.label}</span>
                </S.PlaceOption>
              )}
              onSelect={handleSelectHotel}
            />
          )}
        </FormAdapter>
      </Box>
      <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="mealType"
          label={t("adminReservation.form.mealType.label")}
        >
          {props => (
            <FormSelect
              {...props}
              options={mealTypeOptions}
              isLoading={isLoadingMeals}
            />
          )}
        </FormAdapter>
      </Box>

      <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="confirmationHotelStatus"
          label={t("adminReservation.form.confirmationHotelStatus.label")}
        >
          {props => (
            <FormSelect {...props} options={hotelConfirmationStatusOptions} />
          )}
        </FormAdapter>
      </Box>
    </>
  );
};

export default AdminReservationFormHotel;
