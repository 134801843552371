import {
  BaggageItem,
  BaggageType,
  CabinType,
  FlightSegment,
  FlightStatus,
} from "@hotelspoint/types";
import { formatDate, formatTimeUtc, timeBetween } from "@hotelspoint/utils";
import {
  IconBriefcase2,
  IconCalendarMonthFilled,
  IconLuggage,
} from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import * as S from "./FlightDetails.styled";

interface FlightDetailsProps {
  status: string;
  baggage: BaggageItem[];
  segments: FlightSegment[];
}

export const cabinTypeLabels = {
  [CabinType.FirstClass]: "First class",
};

const FlightDetails = ({ status, baggage, segments }: FlightDetailsProps) => {
  const { t } = useTranslation();

  const getDepartureDetails = useMemo(() => {
    return segments[0].departure;
  }, [segments]);

  const getArrivalDetails = useMemo(() => {
    const lastElement = segments.length - 1;

    return segments[lastElement].arrival;
  }, [segments]);

  const getTravelTime = useMemo(() => {
    const diffMinutes = timeBetween(
      getDepartureDetails.time,
      getArrivalDetails.time,
    );
    const hours = Math.floor(diffMinutes / 60);
    const minutes = diffMinutes % 60;

    return `${hours}h ${minutes}m`;
  }, [getDepartureDetails, getArrivalDetails]);

  const getFlightType = useMemo(() => {
    if (segments.length === 1) {
      return t("components.flightDetails.direct");
    } else {
      return `${segments.length - 1} - ${t("components.flightDetails.transfer", { count: segments.length - 1 })}`;
    }
  }, [segments.length, t]);

  const getBaggageCount = useMemo(() => {
    return baggage.find(
      item => item.included && item.baggageType === BaggageType.Checked,
    );
  }, [baggage]);

  return (
    <S.Wrapper>
      <S.FlightSegments>
        <S.TimeOrigin>
          <h6>{formatTimeUtc(getDepartureDetails.time)}</h6>
          <p>{getDepartureDetails.code}</p>
        </S.TimeOrigin>
        <S.FlightTimelineWrapper>
          <span>{getFlightType}</span>
          <S.FlightTimeline />
          <span>{getTravelTime}</span>
        </S.FlightTimelineWrapper>
        <S.TimeOrigin>
          <h6>{formatTimeUtc(getArrivalDetails.time)}</h6>
          <p>{getArrivalDetails.code}</p>
        </S.TimeOrigin>
      </S.FlightSegments>
      <S.FlightDetailsWrapper>
        <S.FlightDetailsItem>
          <IconCalendarMonthFilled size={16} />
          <S.Label>{t("components.flightDetails.date")}</S.Label>
          <S.Text>{formatDate(getDepartureDetails.time)}</S.Text>
        </S.FlightDetailsItem>
        <S.FlightDetailsItem>
          <IconBriefcase2 size={16} />
          <S.Label>{t("components.flightDetails.class")}</S.Label>
          {/* why there is a flightClass prop */}
          <S.Text>{cabinTypeLabels[segments[0].cabinType as CabinType]}</S.Text>
        </S.FlightDetailsItem>
        <S.FlightDetailsItem>
          <IconLuggage size={16} />
          <S.Label>{t("components.flightDetails.baggage")}</S.Label>
          <S.Text>{getBaggageCount?.quantity}</S.Text>
        </S.FlightDetailsItem>
        <S.FlightDetailsItem>
          <S.Label>{t("components.flightDetails.status")}</S.Label>
          <S.StatusLabel $status={status as FlightStatus}>
            {status}
          </S.StatusLabel>
        </S.FlightDetailsItem>
      </S.FlightDetailsWrapper>
    </S.Wrapper>
  );
};

export default FlightDetails;
