import { formatDateReadableFull } from "@hotelspoint/utils";
import { IconMessage, IconMoodAngry, IconMoodHappy } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

import { NumericRating } from "../../elements";
import { getRatingLabel } from "../CustomersReview/utils";
import * as S from "./ReviewListing.styled";
import { getCountryName, getFirstInitial, getRandomColor } from "./utils";

interface ReviewListingReviewerProps {
  name: string;
  country: string;
}

interface ReviewListingProps {
  date: string;
  negative?: string;
  positive?: string;
  summary?: string;
  reviewer: ReviewListingReviewerProps;
  score: number;
}

const ReviewListing = ({
  date,
  negative,
  positive,
  summary,
  reviewer,
  score,
}: ReviewListingProps) => {
  const { t } = useTranslation();

  return (
    <S.Wrapper>
      <S.Profile>
        <S.Logo style={{ backgroundColor: getRandomColor() }}>
          {getFirstInitial(reviewer.name)}
        </S.Logo>
        <div>
          <S.Name>{reviewer.name}</S.Name>
          <S.Country>
            <img
              src={`https://flagcdn.com/w40/${reviewer.country.toLowerCase()}.png`}
              alt={reviewer.country}
              width={16}
              height={12}
            />
            <span>{getCountryName(reviewer.country)}</span>
          </S.Country>
        </div>
      </S.Profile>
      <S.ReviewWrapper>
        <S.DateWrapper>
          <S.DateLabel>
            <S.Date>
              {t("components.reviewListing.reviewed")}
              {formatDateReadableFull(date)}
            </S.Date>
            <S.RatingLabel>{t(getRatingLabel(score))}</S.RatingLabel>
          </S.DateLabel>
          <NumericRating value={score} />
        </S.DateWrapper>
        {positive && (
          <S.Comment>
            <IconMoodHappy
              size={16}
              color="green"
              style={{ marginTop: "3px" }}
            />
            <span>{positive}</span>
          </S.Comment>
        )}
        {negative && (
          <S.Comment>
            <IconMoodAngry size={16} color="red" style={{ marginTop: "3px" }} />
            <span>{negative}</span>
          </S.Comment>
        )}
        {summary && (
          <S.Comment>
            <IconMessage size={16} style={{ marginTop: "3px" }} />
            <span>{summary}</span>
          </S.Comment>
        )}
      </S.ReviewWrapper>
    </S.Wrapper>
  );
};

export default ReviewListing;
