import {
  handleError,
  useAgency,
  useFetchAgencies,
  useFetchAgency,
  useFetchUsers,
  usePlace,
  useReservation,
  useUpdateReservation,
  useUser,
} from "@hotelspoint/api";
import {
  Box,
  Button,
  Chip,
  CopyToClipboard,
  Flex,
  Form,
  FormAdapter,
  FormAsyncSelect,
  FormChildrenMethods,
  FormContext,
  FormDatePickerSingle,
  FormInput,
  FormLayout,
  FormSelect,
  FormTextArray,
  LoaderBlock,
} from "@hotelspoint/components";
import {
  ACTIVITY_SUPPLIER_OPTIONS,
  assignees,
  CHIP_COLORS,
  HOTEL_SUPPLIER_OPTIONS,
  PAYMENT_STATUS_OPTIONS,
  RESERVATION_STATUS_OPTIONS,
  SUPPLIER_CONFIRMATION_STATUS_OPTIONS,
  TRANSFER_SUPPLIER_OPTIONS,
} from "@hotelspoint/constants";
import {
  PlaceType,
  RateType,
  ReservationType,
  UserCurrency,
} from "@hotelspoint/types";
import {
  formatDateTimeIso,
  getPaymentStatusColor,
  getRateTypeName,
  getReservationStatusColor,
  mapEntityToOption,
} from "@hotelspoint/utils";
import { useCallback, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  entity2Form,
  form2Entity,
  FormValues,
  validationSchema,
} from "./AdminReservationForm.util";
import AdminReservationFormActivity from "./AdminReservationFormActivity";
import AdminReservationFormHotel from "./AdminReservationFormHotel";
import AdminReservationFormTransfer from "./AdminReservationFormTransfer";

const hotelSupplierOptions = HOTEL_SUPPLIER_OPTIONS.map(option => ({
  ...option,
  label: <Chip $color={CHIP_COLORS.SUPPLIER}>{option.label}</Chip>,
}));

const transferSupplierOptions = TRANSFER_SUPPLIER_OPTIONS.map(option => ({
  ...option,
  label: <Chip $color={CHIP_COLORS.SUPPLIER}>{option.label}</Chip>,
}));

const activitySupplierOptions = ACTIVITY_SUPPLIER_OPTIONS.map(option => ({
  ...option,
  label: <Chip $color={CHIP_COLORS.SUPPLIER}>{option.label}</Chip>,
}));

export const ASSIGNEE_OPTIONS = Object.entries(assignees).map(
  ([key, value]) => ({
    value: Number(key),
    label: value,
  }),
);

interface AdminReservationFormProps {
  id: number;
}

interface AdminReservationFormInnerProps
  extends FormChildrenMethods<FormValues> {}

const AdminReservationFormInner = ({
  setValue,
}: AdminReservationFormInnerProps) => {
  const { t } = useTranslation();

  const [id, type, formAgency, formUser] = useWatch<FormValues>({
    name: ["id", "type", "agency", "user"],
  });

  const [agency, isLoadingAgency] = useAgency((formAgency as any)?.value);
  const [fetchAgencies, isFetchingAgencies] = useFetchAgencies();
  const [fetchAgency] = useFetchAgency();

  const [user, isLoadingUser] = useUser((formUser as any)?.value);
  const [fetchUsers, isFetchingUsers] = useFetchUsers();

  const [updateReservation, isUpdatingReservation] = useUpdateReservation(
    id as number,
  );

  const supplierOptions = useMemo(() => {
    switch (type) {
      case ReservationType.Hotel:
        return hotelSupplierOptions;

      case ReservationType.Transfer:
        return transferSupplierOptions;

      case ReservationType.Activity:
        return activitySupplierOptions;

      default:
        return [];
    }
  }, [type]);

  const statusOptions = useMemo(
    () =>
      RESERVATION_STATUS_OPTIONS.map(status => ({
        ...status,
        label: (
          <Chip $color={getReservationStatusColor(status.value)}>
            {t(status.label)}
          </Chip>
        ),
      })),
    [t],
  );

  const paymentStatusOptions = useMemo(
    () =>
      PAYMENT_STATUS_OPTIONS.map(status => ({
        ...status,
        label: (
          <Chip $color={getPaymentStatusColor(status.value)}>
            {t(status.label)}
          </Chip>
        ),
      })),
    [t],
  );

  const defaultAgencyOptions = useMemo(() => {
    if (!agency) return [];

    return [
      {
        value: agency.id,
        label: agency.name,
      },
    ];
  }, [agency]);

  const defaultAgentOptions = useMemo(() => {
    if (agency) {
      return agency.agents.map(agent => ({
        value: agent.id,
        label: agent.name,
      }));
    }

    if (!user) return [];

    return [
      {
        value: user.id,
        label: user.name,
      },
    ];
  }, [user, agency]);

  const rateTypeOptions = useMemo(() => {
    return [RateType.Refundable, RateType.NonRefundable].map(value => ({
      value,
      label: t(getRateTypeName(value)),
    }));
  }, [t]);

  const supplierConfirmationStatusOptions = useMemo(
    () =>
      SUPPLIER_CONFIRMATION_STATUS_OPTIONS.map(status => ({
        ...status,
        label: t(status.label),
      })),
    [t],
  );

  const handleLoadAgencies = useCallback(
    (name: string, callback: any) => {
      fetchAgencies({ name }).then(response => {
        if (response && Array.isArray(response.results)) {
          callback(response.results.map(mapEntityToOption));
        }
      });
    },
    [fetchAgencies],
  );

  const handleLoadUsers = useCallback(
    (name: string, callback: any) => {
      if (!agency?.id) return;

      fetchUsers({ name, agencyId: agency?.id }).then(response => {
        if (response && Array.isArray(response.results)) {
          callback(response.results.map(mapEntityToOption));
        }
      });
    },
    [fetchUsers, agency],
  );

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const now = new Date();
        const dateUpdated = formatDateTimeIso(now);

        const payload = form2Entity(formValues, dateUpdated);

        await updateReservation(payload);

        toast.success(t("toast.adminReservation.update"));
      } catch (error: any) {
        handleError({ t, error });
      }
    },
    [t, updateReservation],
  );

  return (
    <FormLayout>
      <Flex mx={[0, 0, -1, -1]}>
        <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter name="id" label={t("adminReservation.form.id.label")}>
            {props => (
              <FormInput
                {...props}
                readOnly
                endAdornment={
                  <CopyToClipboard value={props.value} displayValue={false} />
                }
              />
            )}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="confirmationId"
            label={t("adminReservation.form.confirmationId.label")}
          >
            {props => (
              <FormInput
                {...props}
                endAdornment={
                  <CopyToClipboard value={props.value} displayValue={false} />
                }
              />
            )}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="status"
            label={t("adminReservation.form.status.label")}
          >
            {props => <FormSelect {...props} options={statusOptions} />}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="paymentStatus"
            label={t("adminReservation.form.paymentStatus.label")}
          >
            {props => <FormSelect {...props} options={paymentStatusOptions} />}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="agency"
            label={t("adminReservation.form.agency.label")}
          >
            {props => (
              <FormAsyncSelect
                {...props}
                placeholder={t("adminReservation.form.agency.placeholder")}
                defaultOptions={defaultAgencyOptions}
                loadOptions={handleLoadAgencies}
                isLoading={isLoadingAgency || isFetchingAgencies}
                onChange={async value => {
                  props.onChange(value);

                  // Set the first agent of the agency in the field
                  if (value) {
                    const { value: id } = value;

                    const agency = await fetchAgency(id);
                    const [agent] = agency?.agents || [];

                    if (agent) {
                      setValue("user", {
                        value: agent.id,
                        label: agent.name,
                      });
                    } else {
                      setValue("user", null);
                    }
                  } else {
                    setValue("user", null);
                  }
                }}
                isClearable
                noOptionsMessage={() =>
                  t("adminReservation.form.agency.noOptions")
                }
                loadingMessage={() => t("adminReservation.form.agency.loading")}
              />
            )}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="user"
            label={t("adminReservation.form.user.label")}
          >
            {props => (
              <FormAsyncSelect
                {...props}
                placeholder={t("adminReservation.form.user.placeholder")}
                defaultOptions={defaultAgentOptions}
                loadOptions={handleLoadUsers}
                isLoading={isLoadingUser || isFetchingUsers}
                isClearable
                noOptionsMessage={() =>
                  t("adminReservation.form.user.noOptions")
                }
                loadingMessage={() => t("adminReservation.form.user.loading")}
              />
            )}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="clientName"
            label={t("adminReservation.form.clientName.label")}
          >
            {props => <FormInput {...props} readOnly />}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="confirmationAssigneeId"
            label={t("adminReservation.form.confirmationAssigneeId.label")}
          >
            {props => <FormSelect {...props} options={ASSIGNEE_OPTIONS} />}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="dateFrom"
            label={t("adminReservation.form.dateFrom.label")}
          >
            {props => <FormDatePickerSingle {...props} />}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="dateTo"
            label={t("adminReservation.form.dateTo.label")}
          >
            {props => <FormDatePickerSingle {...props} />}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="rateType"
            label={t("adminReservation.form.rateType.label")}
          >
            {props => <FormSelect {...props} options={rateTypeOptions} />}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="dateDeadline"
            label={t("adminReservation.form.dateDeadline.label")}
          >
            {props => <FormDatePickerSingle {...props} />}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="priceBase"
            label={t("adminReservation.form.priceBase.label")}
          >
            {props => (
              <FormInput {...props} endAdornment={UserCurrency.EUR} readOnly />
            )}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="priceNet"
            label={t("adminReservation.form.priceNet.label")}
          >
            {props => (
              <FormInput {...props} endAdornment={UserCurrency.EUR} readOnly />
            )}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="price"
            label={t("adminReservation.form.price.label")}
          >
            {props => (
              <FormInput {...props} endAdornment={UserCurrency.EUR} readOnly />
            )}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="agencyMarkup"
            label={t("adminReservation.form.agencyMarkup.label")}
          >
            {props => <FormInput {...props} endAdornment="%" readOnly />}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="voucherCode"
            label={t("adminReservation.form.voucherCode.label")}
          >
            {props => (
              <FormInput
                {...props}
                endAdornment={
                  <CopyToClipboard value={props.value} displayValue={false} />
                }
              />
            )}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="supplierId"
            label={t("adminReservation.form.supplierId.label")}
          >
            {props => <FormSelect {...props} options={supplierOptions} />}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="dateSupplierDeadline"
            label={t("adminReservation.form.dateSupplierDeadline.label")}
          >
            {props => <FormDatePickerSingle {...props} />}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="confirmationSupplierStatus"
            label={t("adminReservation.form.confirmationSupplierStatus.label")}
          >
            {props => (
              <FormSelect
                {...props}
                options={supplierConfirmationStatusOptions}
              />
            )}
          </FormAdapter>
        </Box>

        {type === ReservationType.Hotel && <AdminReservationFormHotel />}

        {type === ReservationType.Transfer && <AdminReservationFormTransfer />}

        {type === ReservationType.Activity && <AdminReservationFormActivity />}

        <Box width={1} px={[0, 0, 1, 1]} mt={3}>
          <h5>{t("adminReservation.form.sections.remarks")}</h5>
          <hr style={{ margin: "10px 0" }} />
        </Box>
        <Box width={1} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter name="remarks" label={undefined}>
            {props => <FormTextArray {...props} />}
          </FormAdapter>
        </Box>
      </Flex>
      <FormContext<FormValues>
        render={({ handleSubmit }) => (
          <Button
            type="submit"
            variant="secondary"
            isLoading={isUpdatingReservation}
            onClick={handleSubmit(onSubmit)}
          >
            {t("adminReservation.form.submit")}
          </Button>
        )}
      />
    </FormLayout>
  );
};

const AdminReservationForm = ({ id }: AdminReservationFormProps) => {
  const [reservation, isLoadingReservation] = useReservation(id);

  const [agency, isLoadingAgency] = useAgency(reservation?.agencyId as number);
  const [hotel, isLoadingHotel] = usePlace({
    id: reservation?.itemId as number,
    type: PlaceType.Hotel,
  });

  const isLoading = isLoadingReservation || isLoadingAgency || isLoadingHotel;

  // @todo: What happens when we don't have an itemId for older reservations?
  // reservation.itemId = null;
  const formValues = useMemo(() => {
    if (
      !reservation ||
      !agency ||
      (reservation?.itemId && !hotel) ||
      isLoading
    ) {
      return {};
    }
    console.log("reservation", reservation);
    return entity2Form({
      reservation,
      agency,
      hotelName: hotel?.name,
    });
  }, [reservation, agency, hotel, isLoading]);

  if (isLoading) {
    return <LoaderBlock />;
  }

  return (
    <Form<FormValues>
      defaultValues={formValues}
      validationSchema={validationSchema}
      enableReinitialize
      showDevTool
    >
      {formMethods => <AdminReservationFormInner {...formMethods} />}
    </Form>
  );
};

export default AdminReservationForm;
