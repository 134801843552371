import { useDestinations } from "@hotelspoint/api";
import {
  useDurations,
  useProductOriginDates,
  useProductOrigins,
  useProductsList,
} from "@hotelspoint/api";
import {
  Box,
  Button,
  Flex,
  Form,
  FormAdapter,
  FormAutoComplete,
  FormChildrenMethods,
  FormContext,
  FormDatePricePicker,
  FormLayout,
  FormRoomSelector,
  FormSelect,
  PlaceTypeIcon,
} from "@hotelspoint/components";
import { useRecentPackagesSearchesStore } from "@hotelspoint/store";
import { PlaceType } from "@hotelspoint/types";
import { IconCalendarCog } from "@tabler/icons-react";
import flatten from "lodash/flatten";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import ProgramDetailsModal from "../components/ProgramDetailsModal";
import { SearchPackagesViewState } from "../types";
import usePackageSearchParams from "../usePackageSearchParams";
import {
  defaultValues,
  form2Entity,
  FormValues,
  validationSchema,
} from "./SearchPackagesForm.form";
import * as S from "./SearchPackagesForm.styled";

const SearchPackagesFormInner = ({
  setValue,
  getValues,
  reset,
}: FormChildrenMethods<FormValues>) => {
  const { t } = useTranslation();

  const [destination, destinationId, product, origin] = useWatch({
    name: ["destination", "destinationId", "product", "origin"],
  });

  const prevDestinationId = useRef(destinationId);
  const productId = product;

  const [, setQuery] = usePackageSearchParams();

  const [isOpen, setIsOpen] = useState(false);

  const [destinations, isLoadingDestinations] = useDestinations();
  const [products, isLoadingProducts] = useProductsList({
    destinationId,
  });
  const [origins, isOriginsLoading] = useProductOrigins(productId);
  const [durations, isDurationsLoading] = useDurations(productId);
  const [originDates, isLoadingOriginDates] = useProductOriginDates(
    productId,
    origin,
  );

  const recentSearchesStore = useRecentPackagesSearchesStore(state => state);

  const destinationGroupOptions = useMemo(() => {
    if (!destinations || isLoadingDestinations) return [];

    const lowerSearch = destination.toLowerCase();

    return destinations
      .map(destination => {
        const isGroupMatching = destination.group
          .toLowerCase()
          .includes(lowerSearch);

        const filteredOptions = destination.destinations
          ?.filter(
            dest =>
              isGroupMatching || dest.name.toLowerCase().includes(lowerSearch),
          )
          .map(dest => ({
            value: dest.id,
            label: dest.name,
            meta: {
              type: destination.group,
            },
          }));

        return {
          label: destination.group,
          value: destination.group,
          options: filteredOptions,
        };
      })
      .filter(group => group.options.length > 0);
  }, [destination, destinations, isLoadingDestinations]);

  const destinationOptionsFlat = useMemo(() => {
    return flatten(destinationGroupOptions.map(group => group.options));
  }, [destinationGroupOptions]);

  const productOptions = useMemo(() => {
    if (!products || isLoadingProducts) return [];

    return products.map(product => ({
      value: product.id,
      label: product.name,
    }));
  }, [isLoadingProducts, products]);

  const originOptions = useMemo(() => {
    if (!origins || isOriginsLoading) return [];

    return origins.map(origin => ({
      value: origin.code,
      label: origin.name,
    }));
  }, [isOriginsLoading, origins]);

  const nightsOptions = useMemo(() => {
    if (!durations || isDurationsLoading) return [];

    return Array.from(
      { length: durations.max - durations.min + 1 },
      (_, i) => ({
        value: durations.min + i,
        label: `${durations.min + i} ${t("searchPackages.nights")}`,
      }),
    );
  }, [durations, isDurationsLoading, t]);

  const handleSelectDestination = useCallback(
    (option: any) => {
      if (option) {
        setValue("destinationId", option.value);
      } else {
        setValue("destinationId", undefined);
      }
    },
    [setValue],
  );

  const handleReset = useCallback(
    (preserveFields: (keyof FormValues)[]) => {
      const currentValues = getValues();

      const preservedData = preserveFields.reduce(
        (acc, field) => {
          acc[field] = currentValues[field];
          return acc;
        },
        {} as Record<string, any>,
      );

      reset(preservedData);
    },
    [getValues, reset],
  );

  const onProductChange = useCallback(
    (value: any) => {
      handleReset(["destination", "rooms", "destinationId"]);
      setValue("product", value);
    },
    [setValue, handleReset],
  );

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      const payload = form2Entity(formValues);

      const query = {
        ...payload,
        view: SearchPackagesViewState.List,
      };

      const destinationOption = destinationOptionsFlat.find(
        d => d.value === payload.destinationId,
      );

      const programOption = productOptions.find(
        p => p.value === payload.productId,
      );

      if (destinationOption && programOption) {
        recentSearchesStore.add({
          ...query,
          meta: {
            destinationName: destinationOption.label,
            programName: programOption.label,
          },
        });
      }

      setQuery(query);
    },
    [destinationOptionsFlat, productOptions, recentSearchesStore, setQuery],
  );

  useEffect(() => {
    if (prevDestinationId.current !== destinationId) {
      handleReset(["destination", "rooms", "destinationId"]);
      prevDestinationId.current = destinationId;
    }
  }, [destinationId, handleReset]);

  return (
    <>
      <ProgramDetailsModal
        id={productId}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="destination"
              label={t("searchPackages.destination.label")}
            >
              {props => (
                <FormAutoComplete
                  {...props}
                  placeholder={t("searchPackages.destination.placeholder")}
                  options={destinationGroupOptions}
                  isLoading={false}
                  minCharacters={0}
                  renderOption={option => (
                    <S.PlaceOption key={option.value}>
                      <PlaceTypeIcon
                        type={PlaceType.Destination}
                        iconProps={{ size: 22 }}
                      />
                      <span>{option.label}</span>
                    </S.PlaceOption>
                  )}
                  onSelect={handleSelectDestination}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="product"
              label={t("searchPackages.product.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t("searchPackages.product.placeholder")}
                  options={productOptions}
                  isClearable
                  readOnly={!destinationId || !products || isLoadingProducts}
                  onChange={onProductChange}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter name="origin" label={t("searchPackages.origin.label")}>
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t("searchPackages.origin.placeholder")}
                  options={originOptions}
                  isClearable
                  readOnly={!origins || isOriginsLoading}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="duration"
              label={t("searchPackages.duration.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t("searchPackages.duration.placeholder")}
                  options={nightsOptions}
                  isClearable
                  readOnly={!durations || isDurationsLoading}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="checkIn"
              label={t("searchPackages.checkIn.label")}
            >
              {props => (
                <FormDatePricePicker
                  {...props}
                  dates={originDates || ([] as any)}
                  placeholder={t("searchPackages.checkIn.placeholder")}
                  readOnly={!originDates || isLoadingOriginDates}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter name="rooms" label={t("searchPackages.rooms.label")}>
              {props => (
                <FormRoomSelector
                  {...props}
                  placeholder={t("searchPackages.rooms.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
            <Button
              variant="outlined"
              fullWidth
              isDisabled={!productId}
              onClick={() => setIsOpen(true)}
            >
              <IconCalendarCog size={18} />
              {t("searchPackages.viewProgram")}
            </Button>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
            <FormContext<FormValues>
              render={({ handleSubmit }) => (
                <Button
                  type="submit"
                  variant="secondary"
                  fullWidth
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("searchPackages.submit")}
                </Button>
              )}
            />
          </Box>
        </Flex>
      </FormLayout>
    </>
  );
};

interface SearchPackagesFormProps {
  initialValues?: Partial<FormValues>;
}

const SearchPackagesForm = ({
  initialValues = {},
}: SearchPackagesFormProps) => {
  const formValues = useMemo(
    () => ({
      ...defaultValues,
      ...initialValues,
    }),
    [initialValues],
  );

  return (
    <Form<FormValues>
      defaultValues={formValues}
      validationSchema={validationSchema}
      showDevTool
    >
      {formMethods => <SearchPackagesFormInner {...formMethods} />}
    </Form>
  );
};

export default SearchPackagesForm;
