import {
  ActivityContent as ActivityContentAPI,
  useActivityDetails,
} from "@hotelspoint/api";
import {
  ActivityContent,
  ActivityOptionsModal,
  Button,
  MarkupPrice,
  MosaicGallery,
  Price,
} from "@hotelspoint/components";
import { useModalContainerStore } from "@hotelspoint/store";
import { ImageType } from "@hotelspoint/utils";
import { IconMapPinFilled } from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import * as S from "./ActivityDetailsModal.styled";

interface ActivityDetailsModalProps {
  id?: number;
  searchId?: number;
  images: string[];
  name: string;
  location: string;
  price: number;
  priceNet: number;
  imageType: ImageType;
  content?: ActivityContentAPI[];
  isLoading: boolean;
}

const ActivityDetailsModal = ({
  id,
  searchId,
  images,
  name,
  location,
  price,
  priceNet,
  imageType,
  content,
  isLoading,
}: ActivityDetailsModalProps) => {
  const { t } = useTranslation();

  const openModal = useModalContainerStore(state => state.openModal);

  const [activityDetails, isLoadingActivityDetails] = useActivityDetails(
    searchId as number,
    id as number,
  );

  const activityContent = useMemo(
    () => activityDetails?.activity.content || content,
    [activityDetails, content],
  );

  const isActivityDetailsLoading = useMemo(
    () => isLoadingActivityDetails || isLoading,
    [isLoadingActivityDetails, isLoading],
  );

  return (
    <>
      <MosaicGallery
        images={images}
        isLoading={isActivityDetailsLoading}
        imageType={imageType}
      />
      <S.BaseInfoWrapper>
        <S.FlexGrow>
          <h3>{isActivityDetailsLoading ? <Skeleton /> : name}</h3>
          <S.LocationWrapper>
            {isActivityDetailsLoading ? (
              <S.FlexGrow>
                <Skeleton />
              </S.FlexGrow>
            ) : (
              <>
                <IconMapPinFilled size={16} />
                <p>{location}</p>
              </>
            )}
          </S.LocationWrapper>
        </S.FlexGrow>
        <S.ActivityPriceWrapper>
          <MarkupPrice
            value={{
              total: price,
              net: priceNet,
            }}
          >
            <S.Emphasized>{t("activityDetailsModal.from")}</S.Emphasized>
            {isActivityDetailsLoading ? (
              <Skeleton />
            ) : (
              <S.Price>
                <Price value={price} />
              </S.Price>
            )}
          </MarkupPrice>
          <Button
            variant="tertiary"
            fullWidth={true}
            isLoading={isActivityDetailsLoading}
            onClick={() =>
              openModal({
                title: name,
                content: (
                  <ActivityOptionsModal
                    activityId={id as number}
                    searchId={searchId as number}
                  />
                ),
              })
            }
          >
            {t("activityDetailsModal.actions.select")}
          </Button>
        </S.ActivityPriceWrapper>
      </S.BaseInfoWrapper>
      <S.Divider />
      <ActivityContent
        content={activityContent as ActivityContentAPI[]}
        isLoading={isActivityDetailsLoading}
      />
    </>
  );
};

export default ActivityDetailsModal;
