import { DrawerPlacement, DrawerProps, DrawerSize } from "./types";

const DRAWER_SIZES = {
  [DrawerSize.Small]: { horizontal: "400px", vertical: "290px" },
  [DrawerSize.Medium]: { horizontal: "600px", vertical: "400px" },
  [DrawerSize.Large]: { horizontal: "800px", vertical: "480px" },
  [DrawerSize.ExtraLarge]: { horizontal: "926px", vertical: "568px" },
  [DrawerSize.FullPage]: { horizontal: "100%", vertical: "100%" },
};

export const getDrawerSize = (size: DrawerSize, placement: DrawerPlacement) => {
  const isHorizontal =
    placement === DrawerPlacement.Left || placement === DrawerPlacement.Right;

  return DRAWER_SIZES[size]?.[isHorizontal ? "horizontal" : "vertical"];
};

export const calculatePosition = (
  isOpen: boolean,
  placement: DrawerProps["placement"],
  size: DrawerProps["size"],
) => {
  const offset = `-${getDrawerSize(size, placement)}`;

  switch (placement) {
    case DrawerPlacement.Top:
      return { top: isOpen ? 0 : offset, bottom: "auto", left: 0, right: 0 };
    case DrawerPlacement.Bottom:
      return { top: "auto", bottom: isOpen ? 0 : offset, left: 0, right: 0 };
    case DrawerPlacement.Left:
      return { top: 0, bottom: 0, left: isOpen ? 0 : offset, right: "auto" };
    case DrawerPlacement.Right:
      return { top: 0, bottom: 0, left: "auto", right: isOpen ? 0 : offset };
    default:
      return { top: 0, bottom: 0, left: 0, right: 0 };
  }
};

export const getShadow = (placement: DrawerPlacement) => {
  switch (placement) {
    case DrawerPlacement.Top:
      return "0px 2px 6px 0px rgba(0, 0, 0, 0.10)";
    case DrawerPlacement.Bottom:
      return "0px -2px 6px 0px rgba(0, 0, 0, 0.10)";
    case DrawerPlacement.Left:
      return "2px 0px 6px 0px rgba(0, 0, 0, 0.10)";
    case DrawerPlacement.Right:
      return "-2px 0px 6px 0px rgba(0, 0, 0, 0.10)";
    default:
      return "none";
  }
};
