import { createContext, useContext, useState } from "react";
import { MapRef } from "react-map-gl";

interface MapContextType {
  mapInstance: MapRef | null;
  setMapInstance: (map: MapRef | null) => void;
}

interface MapProviderProps {
  children: React.ReactNode;
}

const MapContext = createContext<MapContextType | undefined>(undefined);

export const MapProvider = ({ children }: MapProviderProps) => {
  const [mapInstance, setMapInstance] = useState<MapRef | null>(null);

  return (
    <MapContext.Provider value={{ mapInstance, setMapInstance }}>
      {children}
    </MapContext.Provider>
  );
};

export const useMapContext = () => {
  const context = useContext(MapContext);
  if (!context) {
    throw new Error("useMapContext must be used within a MapProvider");
  }
  return context;
};
