import { borderRadius, pxToRem, spacing } from "@hotelspoint/theme";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ifProp, prop, theme } from "styled-tools";

import Paper from "../../elements/Paper";

export const Wrapper = styled(Paper)`
  display: flex;
  width: 380px;
  column-gap: ${spacing(1.5)}px;
  border-radius: ${borderRadius.medium}px;
  padding: ${spacing(1)}px;
`;

export const ThumbnailWrapper = styled.div`
  position: relative;
  min-width: 100px;
  width: 100px;
  height: auto;
  border-radius: ${borderRadius.medium}px;
  background: ${theme("primary.bg")};
  overflow: hidden;
`;

export const Thumbnail = styled.div<{ $src: string }>`
  width: 100%;
  height: 100%;
  background: url(${prop("$src")}) no-repeat;
  background-size: cover;
  background-position: center center;
  cursor: ${ifProp("$src", "pointer", "default")};
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing(1)}px;
`;

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(0.5)}px;
`;

export const HotelName = styled.h6`
  font-weight: 600;
`;

export const RoomName = styled.p`
  margin-bottom: ${spacing(0.5)}px;
  font-weight: 500;
`;

export const Footer = styled.div`
  align-self: flex-end;
  margin-top: ${spacing(4)}px;
`;

export const MarkupPriceWrapper = styled.div`
  margin-bottom: ${spacing(1)}px;
  text-align: right;
`;

export const PriceWrapper = styled.span`
  font-weight: 500;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  column-gap: ${spacing(1)}px;
`;

export const Emphasized = styled.em`
  margin-right: ${spacing(0.5)}px;
  font-size: ${pxToRem(16)};
`;

export const ThumbnailActions = styled.div`
  position: absolute;
  top: ${spacing(1)}px;
  left: ${spacing(1)}px;
`;

export const TitleLink = styled(Link)`
  color: ${theme("palette.ocean")};
`;
