import { TransferService } from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useTransferRate = (searchId: number, rateId: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ["transfers", searchId, rateId, "rate"],
    queryFn: () => TransferService.getRate(searchId, rateId),
    enabled: !!searchId && !!rateId,
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useTransferRate;
