import {
  handleError,
  useAgency,
  useFetchAgency,
  useUpdateAgency,
} from "@hotelspoint/api";
import {
  Box,
  Button,
  Chip,
  Flex,
  Form,
  FormAdapter,
  FormChapter,
  FormCheckbox,
  FormClearableInput,
  FormContext,
  FormLayout,
  FormSelect,
  LoaderBlock,
  OptionGroup,
} from "@hotelspoint/components";
import {
  ACTIVITY_SUPPLIER_OPTIONS,
  AGENCY_STATUS_OPTIONS,
  CHIP_COLORS,
  countryGroups,
  HOTEL_SUPPLIER_OPTIONS,
  TRANSFER_SUPPLIER_OPTIONS,
} from "@hotelspoint/constants";
import { useUserAgencyStore } from "@hotelspoint/store";
import { UserCurrency } from "@hotelspoint/types";
import { getAgencyStatusColor } from "@hotelspoint/utils";
import { IconPercentage } from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  defaultValues,
  entity2Form,
  form2Entity,
  FormValues,
  validationSchema,
} from "./AdminSubAgencyForm.util";

const hotelSupplierOptions = HOTEL_SUPPLIER_OPTIONS.map(option => ({
  ...option,
  label: <Chip $color={CHIP_COLORS.SUPPLIER}>{option.label}</Chip>,
}));

const transferSupplierOptions = TRANSFER_SUPPLIER_OPTIONS.map(option => ({
  ...option,
  label: <Chip $color={CHIP_COLORS.SUPPLIER}>{option.label}</Chip>,
}));

const activitySupplierOptions = ACTIVITY_SUPPLIER_OPTIONS.map(option => ({
  ...option,
  label: <Chip $color={CHIP_COLORS.SUPPLIER}>{option.label}</Chip>,
}));

interface AdminSubAgencyFormProps {
  id: number;
}

const AdminSubAgencyForm = ({ id }: AdminSubAgencyFormProps) => {
  const { t } = useTranslation();

  const { set: setUserAgency, agency: userAgency } = useUserAgencyStore(
    state => state,
  );

  const [fetchAgency] = useFetchAgency();

  const [subAgency, isLoadingSubAgency] = useAgency(id);
  const [updateSubAgency, isUpdatingAgency] = useUpdateAgency(id);

  const countryOptions: OptionGroup[] = useMemo(
    () =>
      countryGroups.map(group => ({
        label: t(`countries.groups.${group.label}`),
        options: group.options.map(value => ({
          value,
          label: t(`countries.${value}`),
        })),
      })),
    [t],
  );

  const statusOptions = useMemo(
    () =>
      AGENCY_STATUS_OPTIONS.map(status => ({
        ...status,
        label: (
          <Chip $color={getAgencyStatusColor(status.value)}>
            {t(status.label)}
          </Chip>
        ),
      })),
    [t],
  );

  const formValues = useMemo(() => {
    if (!subAgency) return defaultValues;

    return entity2Form(subAgency);
  }, [subAgency]);

  const onSubmit = async (formValues: FormValues) => {
    try {
      const payload = form2Entity(formValues);

      await updateSubAgency(payload);

      // Update user agency if it's the target agency
      if (id === userAgency?.id) {
        const agency = await fetchAgency(id);
        setUserAgency(agency);
      }

      toast.success(t("toast.adminSubAgency.update"));
    } catch (error: any) {
      handleError({ t, error });
    }
  };

  if (isLoadingSubAgency) {
    return <LoaderBlock />;
  }

  return (
    <Form defaultValues={formValues} validationSchema={validationSchema}>
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="name"
              label={t("adminSubAgency.form.name.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t("adminSubAgency.form.name.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="email"
              label={t("adminSubAgency.form.email.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t("adminSubAgency.form.email.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="phone"
              label={t("adminSubAgency.form.phone.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t("adminSubAgency.form.phone.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="companyManager"
              label={t("adminSubAgency.form.companyManager.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t(
                    "adminSubAgency.form.companyManager.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="country"
              label={t("adminSubAgency.form.country.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t("adminSubAgency.form.country.placeholder")}
                  options={countryOptions}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="status"
              label={t("adminSubAgency.form.status.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t("adminSubAgency.form.status.placeholder")}
                  options={statusOptions}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="suppliersHotels"
              label={t("adminSubAgency.form.suppliersHotels.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminSubAgency.form.suppliersHotels.placeholder",
                  )}
                  options={hotelSupplierOptions}
                  isMulti
                  closeMenuOnSelect={false}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="suppliersTransfers"
              label={t("adminSubAgency.form.suppliersTransfers.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminSubAgency.form.suppliersTransfers.placeholder",
                  )}
                  options={transferSupplierOptions}
                  isMulti
                  closeMenuOnSelect={false}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="suppliersActivities"
              label={t("adminSubAgency.form.suppliersActivities.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminSubAgency.form.suppliersActivities.placeholder",
                  )}
                  options={activitySupplierOptions}
                  isMulti
                  closeMenuOnSelect={false}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="markupHotels"
              label={t("adminSubAgency.form.markupHotels.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  type="number"
                  endAdornment={<IconPercentage size={18} />}
                  adornmentStyle="filled"
                  placeholder={t(
                    "adminSubAgency.form.markupHotels.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="markupHotelsBase"
              label={t("adminSubAgency.form.markupHotelsBase.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  type="number"
                  endAdornment={<IconPercentage size={18} />}
                  adornmentStyle="filled"
                  placeholder={t(
                    "adminSubAgency.form.markupHotelsBase.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="markupHotelsOperator"
              label={t("adminSubAgency.form.markupHotelsOperator.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  type="number"
                  endAdornment={<IconPercentage size={18} />}
                  adornmentStyle="filled"
                  placeholder={t(
                    "adminSubAgency.form.markupHotelsOperator.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="markupTransfers"
              label={t("adminSubAgency.form.markupTransfers.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  type="number"
                  endAdornment={<IconPercentage size={18} />}
                  adornmentStyle="filled"
                  placeholder={t(
                    "adminSubAgency.form.markupTransfers.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="markupTransfersBase"
              label={t("adminSubAgency.form.markupTransfersBase.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  type="number"
                  endAdornment={<IconPercentage size={18} />}
                  adornmentStyle="filled"
                  placeholder={t(
                    "adminSubAgency.form.markupTransfersBase.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="markupTransfersOperator"
              label={t("adminSubAgency.form.markupTransfersOperator.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  type="number"
                  endAdornment={<IconPercentage size={18} />}
                  adornmentStyle="filled"
                  placeholder={t(
                    "adminSubAgency.form.markupTransfersOperator.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="markupActivities"
              label={t("adminSubAgency.form.markupActivities.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  type="number"
                  endAdornment={<IconPercentage size={18} />}
                  adornmentStyle="filled"
                  placeholder={t(
                    "adminSubAgency.form.markupActivities.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="markupActivitiesBase"
              label={t("adminSubAgency.form.markupActivitiesBase.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  type="number"
                  endAdornment={<IconPercentage size={18} />}
                  adornmentStyle="filled"
                  placeholder={t(
                    "adminSubAgency.form.markupActivitiesBase.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="markupActivitiesOperator"
              label={t("adminSubAgency.form.markupActivitiesOperator.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  type="number"
                  endAdornment={<IconPercentage size={18} />}
                  adornmentStyle="filled"
                  placeholder={t(
                    "adminSubAgency.form.markupActivitiesOperator.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="creditUsed"
              label={t("adminSubAgency.form.creditUsed.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  type="number"
                  readOnly
                  endAdornment={UserCurrency.EUR}
                  adornmentStyle="filled"
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="creditBalance"
              label={t("adminSubAgency.form.creditBalance.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  type="number"
                  readOnly
                  endAdornment={UserCurrency.EUR}
                  adornmentStyle="filled"
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="creditLimit"
              label={t("adminSubAgency.form.creditLimit.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  type="number"
                  endAdornment={UserCurrency.EUR}
                  adornmentStyle="filled"
                  placeholder={t("adminSubAgency.form.creditLimit.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormChapter
              title={t("adminSubAgency.form.canSendRequests.chapterTitle")}
            >
              <FormAdapter
                name="canSendRequests"
                label={t("adminSubAgency.form.canSendRequests.label")}
                formControlProps={{ labelPosition: "inline-end" }}
              >
                {props => <FormCheckbox {...props} />}
              </FormAdapter>
            </FormChapter>
          </Box>
        </Flex>
        <FormContext<FormValues>
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="secondary"
              isLoading={isUpdatingAgency}
              onClick={handleSubmit(onSubmit)}
            >
              {t("adminSubAgency.form.submit")}
            </Button>
          )}
        />
      </FormLayout>
    </Form>
  );
};

export default AdminSubAgencyForm;
