import { pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { theme } from "styled-tools";

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing(1.5)}px;
  background-color: ${theme("palette.white")};
`;

export const Title = styled.h3`
  font-size: ${pxToRem(18)};
  font-weight: 700;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: ${spacing(4)}px;
  padding: ${spacing(2)}px;
`;
