import { useQuery } from "@tanstack/react-query";

import { PackageService } from "../../services";

const useDestinations = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["destinations"],
    queryFn: () => PackageService.getList(),
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useDestinations;
