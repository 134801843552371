import { useUserAgencyStore } from "@hotelspoint/store";
import { zIndex } from "@hotelspoint/theme";
import { toFixedPrecise, withCurrency } from "@hotelspoint/utils";
import { IconCreditCard } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

import HeaderMenu from "../HeaderMenu";
import * as S from "./UserCredit.styled";
import formatBalance from "./utils/formatBalance";

const UserCredit = () => {
  const { t } = useTranslation();
  const agency = useUserAgencyStore(state => state.agency);

  const parseValue = (value: number) => {
    return withCurrency(toFixedPrecise(value, 2));
  };

  if (!agency) return null;

  return (
    <HeaderMenu
      menuButton={
        <S.IconWrapper>
          <IconCreditCard size={24} />
          <S.Superscript>{formatBalance(agency.credit.balance)}</S.Superscript>
        </S.IconWrapper>
      }
      menuStyle={{ zIndex: zIndex.userCredit }}
      withCaret={false}
    >
      <S.Wrapper>
        <S.Item>
          <div>{t("header.userCredit.used")}</div>
          <S.NegativeValue>{parseValue(agency.credit.used)}</S.NegativeValue>
        </S.Item>
        {agency?.isTravelOperator && (
          <S.Item>
            <div>{t("header.userCredit.given")}</div>
            <S.NegativeValue>{parseValue(agency.credit.given)}</S.NegativeValue>
          </S.Item>
        )}
        <S.Item>
          <div>{t("header.userCredit.balance")}</div>
          <S.Value>{parseValue(agency.credit.balance)}</S.Value>
        </S.Item>
      </S.Wrapper>
    </HeaderMenu>
  );
};

export default UserCredit;
