import "react-day-picker/dist/style.css";

import { mediaQuery } from "@hotelspoint/theme";
import {
  daysBetween,
  formatDate,
  isDateValid,
  useMediaQuery,
} from "@hotelspoint/utils";
import { IconCalendar } from "@tabler/icons-react";
import classNames from "classnames";
import { useCallback, useMemo, useState } from "react";
import {
  DayPicker,
  type DayPickerRangeProps,
  type DayPickerSingleProps,
} from "react-day-picker";
import { useTranslation } from "react-i18next";

import Drawer from "../../../composites/Drawer";
import { DrawerPlacement, DrawerSize } from "../../../composites/Drawer/types";
import Popover from "../../../composites/Popover";
import FormClearableInput from "../../FormClearableInput";
import { type FormControlInjectedProps } from "../../FormControl";
import * as S from "./FormDatePicker.styled";

// https://daypicker.dev/v8/using-daypicker/styling
export interface DayPickerTheme {
  cellSize?: string;
}

interface FormDatePickerProps extends FormControlInjectedProps {
  disabled?: boolean;
  readOnly?: boolean;
  dayPickerProps: DayPickerSingleProps | DayPickerRangeProps;
  dayPickerTheme?: DayPickerTheme;
  footer?: React.ReactNode;
}

function FormDatePicker({
  value,
  onChange,
  disabled = false,
  readOnly = false,
  dayPickerProps,
  dayPickerTheme,
  footer,
  ...rest
}: FormDatePickerProps) {
  const { t } = useTranslation();

  const isTablet = useMediaQuery(mediaQuery.tablet);

  const [isOpen, setIsOpen] = useState(false);
  const [hoveredDate, setHoveredDate] = useState<Date | null>(null);
  const [month, setMonth] = useState<Date | undefined>(value?.from);

  const { mode } = dayPickerProps;

  const rangeDays = useMemo(() => {
    if (mode !== "range" || !value?.from || !value?.to) return 0;

    return daysBetween({
      from: value.from,
      to: value.to,
    });
  }, [mode, value]);

  const hoveredRangeDays = useMemo(() => {
    if (mode !== "range" || !value?.from || !hoveredDate) return 0;

    return daysBetween({
      from: value?.from,
      to: hoveredDate,
    });
  }, [hoveredDate, mode, value?.from]);

  const hasValue = useMemo(() => {
    if (mode === "single") return !!value;

    return hoveredRangeDays > 0;
  }, [mode, value, hoveredRangeDays]);

  const displayValue = useMemo(() => {
    switch (mode) {
      case "single":
        return isDateValid(value) ? formatDate(value) : "";

      case "range":
        if (!value?.from && !value?.to) return "";
        if (isDateValid(value?.from) && !value.to) {
          return formatDate(value.from);
        }

        if (isDateValid(value?.from) && isDateValid(value?.to)) {
          return `${formatDate(value.from)} - ${formatDate(value.to)}`;
        }

        return "";

      default:
        return "";
    }
  }, [mode, value]);

  const tooltip = useMemo(() => {
    if (mode !== "range" || !hasValue || value?.to) return undefined;

    return `${hoveredRangeDays} ${t("components.formDatePicker.night", {
      count: hoveredRangeDays,
    })}`;
  }, [value, mode, hasValue, hoveredRangeDays, t]);

  const handleSelect = useCallback(
    (newValue: any, selectedDay: Date) => {
      // @todo: Behaviour will change in the next major version - v9
      // https://github.com/gpbl/react-day-picker/issues/2140#issuecomment-2129196501

      if (mode === "range") {
        if (value?.from && value?.to) {
          onChange({
            from: selectedDay,
          });
        } else {
          onChange(newValue);
          setMonth(value?.from);
        }
      } else {
        onChange(newValue);
      }

      // @todo: Actually idk if this ain't broke something else
      if (newValue && mode === "range" && value?.from && !value?.to) {
        setIsOpen(false);
      }

      // on first date selection the value is undefined and the popup is not closing
      if (newValue && mode === "single") {
        setIsOpen(false);
      }
    },
    [mode, onChange, value, setIsOpen], // Dependencies
  );

  const handleOpen = () => {
    setIsOpen(!disabled && !readOnly);
  };

  const handleDayMouseEnter = useCallback(
    (day: Date) => {
      if (!value?.from || value?.to) return;
      setHoveredDate(day);
    },
    [value?.from, value?.to],
  );

  const DayPickerComponent = useMemo(() => {
    return (
      <>
        <DayPicker
          selected={value}
          onSelect={handleSelect}
          month={month}
          onMonthChange={setMonth}
          onDayMouseEnter={handleDayMouseEnter}
          modifiersClassNames={{
            selected: "hp-selected",
            range_start: "hp-range-start",
            range_end: classNames("hp-range-end", {
              "hp-range-active": rangeDays > 0,
            }),
            disabled: "hp-disabled",
          }}
          {...dayPickerProps}
        />
        {mode === "range" && !footer ? (
          <S.Footer>
            {rangeDays > 0
              ? t("components.formDatePicker.selection", {
                  count: rangeDays,
                  days: rangeDays,
                })
              : t("components.formDatePicker.noSelection")}
          </S.Footer>
        ) : (
          <S.Footer>{footer}</S.Footer>
        )}
      </>
    );
  }, [
    dayPickerProps,
    footer,
    handleDayMouseEnter,
    handleSelect,
    mode,
    month,
    rangeDays,
    t,
    value,
  ]);

  return (
    <>
      <Popover
        open={isOpen && isTablet}
        onOpenChange={setIsOpen}
        placement="bottom-start"
      >
        <Popover.Trigger onClick={handleOpen}>
          <FormClearableInput
            value={displayValue}
            onChange={onChange}
            disabled={disabled}
            readOnly={readOnly}
            startAdornment={<IconCalendar size={18} />}
            style={{ userSelect: "none" }}
            autoComplete="off"
            {...rest}
          />
        </Popover.Trigger>
        {isTablet && (
          <Popover.Content>
            <S.Styles $tooltip={tooltip} $theme={dayPickerTheme}>
              <S.PaperWrapper>{DayPickerComponent}</S.PaperWrapper>
            </S.Styles>
          </Popover.Content>
        )}
      </Popover>
      {!isTablet && (
        <Drawer
          isOpen={isOpen}
          placement={DrawerPlacement.Bottom}
          size={DrawerSize.Large}
          backdrop={true}
          onOpenChange={() => setIsOpen(false)}
        >
          <S.DrawerDayPicker>
            <S.Styles $theme={dayPickerTheme}>{DayPickerComponent}</S.Styles>
          </S.DrawerDayPicker>
        </Drawer>
      )}
    </>
  );
}

export default FormDatePicker;
