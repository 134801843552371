import { Panel } from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";

import AdminAgencyActions from "../../AdminAgencies/AdminAgency/AdminAgencyActions";
import AdminAgencyDocuments from "../../AdminAgencies/AdminAgency/AdminAgencyDocuments";
import AdminSubAgencyForm from "./AdminSubAgencyForm";

interface AdminSubAgencyParams extends Record<string, string> {
  id: string;
}

const AdminSubAgency = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminSubAgency.pageTitle"));

  const { id } = useParams<AdminSubAgencyParams>();
  const subAgencyId = Number(id);

  if (!subAgencyId) {
    return <Navigate to="/404" replace={true} />;
  }

  return (
    <Panel
      title={t("adminSubAgency.pageTitle")}
      actions={<AdminAgencyActions id={subAgencyId} />}
    >
      <AdminSubAgencyForm id={subAgencyId} />
      <AdminAgencyDocuments id={subAgencyId} />
    </Panel>
  );
};

export default AdminSubAgency;
