import { SubAgencyStatement } from "@hotelspoint/api";
import {
  ButtonEdit,
  Chip,
  TABLE_COLUMN_SIZE,
  TableCell,
} from "@hotelspoint/components";
import {
  formatDate,
  getSubAgencyStatementStatusColor,
  getSubAgencyStatementStatusName,
  withCurrency,
} from "@hotelspoint/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";
import { Link } from "react-router-dom";

const columnHelper = createColumnHelper<SubAgencyStatement>();

interface GetColumnsParams {
  t: TFunction;
}

const getColumns = ({ t }: GetColumnsParams) => [
  columnHelper.accessor("id", {
    header: t("profileSubAgenciesStatements.columns.id"),
    size: TABLE_COLUMN_SIZE.ID,
  }),
  columnHelper.accessor("agencyName", {
    header: t("profileSubAgenciesStatements.columns.agencyName"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.accessor("status", {
    header: t("profileSubAgenciesStatements.columns.status"),
    size: TABLE_COLUMN_SIZE.STATUS,
    cell: props => {
      const status = props.getValue();

      return (
        <Chip $color={getSubAgencyStatementStatusColor(status)}>
          {t(getSubAgencyStatementStatusName(status))}
        </Chip>
      );
    },
  }),
  columnHelper.accessor("amount", {
    header: t("profileSubAgenciesStatements.columns.amount"),
    size: TABLE_COLUMN_SIZE.AMOUNT,
    cell: props => withCurrency(props.getValue()),
  }),
  columnHelper.accessor("paymentsCount", {
    header: t("profileSubAgenciesStatements.columns.paymentsCount"),
  }),
  columnHelper.accessor("dateCreated", {
    header: t("profileSubAgenciesStatements.columns.dateCreated"),
    size: TABLE_COLUMN_SIZE.DATE,
    cell: props => formatDate(props.getValue()),
  }),
  columnHelper.display({
    id: "actions",
    size: TABLE_COLUMN_SIZE.ACTION,
    cell: props => {
      const id = props.row.original.id;

      return (
        <TableCell>
          <Link to={`/profile/sub-agencies/statements/${id}`}>
            <ButtonEdit />
          </Link>
        </TableCell>
      );
    },
  }),
];

export default getColumns;
