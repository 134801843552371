import { useQuery } from "@tanstack/react-query";

import { PackageService } from "../../services";

const useProductDetails = (productId: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ["product_details", productId],
    queryFn: () => PackageService.getProductDetails(productId),
    enabled: !!productId,
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useProductDetails;
