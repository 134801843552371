import React from "react";
import { create } from "zustand";

interface ModalContainerProps {
  title: string;
  content: React.ReactNode;
  closeButton?: boolean;
}

interface ModalContainerStore {
  isOpen: boolean;
  title: string | null;
  content: React.ReactNode | null;
  closeButton?: boolean;
  openModal: (props: ModalContainerProps) => void;
  closeModal: () => void;
}

const useModalContainerStore = create<ModalContainerStore>(set => ({
  isOpen: false,
  title: null,
  content: null,
  closeButton: false,
  openModal: ({ title, content, closeButton = false }) =>
    set({ isOpen: true, title, content, closeButton }),
  closeModal: () => set({ isOpen: false, content: null, closeButton: false }),
}));

export default useModalContainerStore;
