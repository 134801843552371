import { useReservation } from "@hotelspoint/api";
import {
  Breadcrumb,
  BreadcrumbContainer,
  LoaderBlock,
  PageSplitLayout,
} from "@hotelspoint/components";
import { ReservationType } from "@hotelspoint/types";
import { formatDateIso, useDocumentTitle } from "@hotelspoint/utils";
import { IconCalendarEvent, IconUser, IconZoom } from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";

import * as S from "./ProfileReservation.styled";
import ProfileReservationActivityContent from "./ProfileReservationActivityContent";
import ProfileReservationActivitySidebar from "./ProfileReservationActivitySidebar";
import ProfileReservationHotelContent from "./ProfileReservationHotelContent";
import ProfileReservationHotelSidebar from "./ProfileReservationHotelSidebar";

interface ProfileReservationParams extends Record<string, string> {
  id: string;
}

type SidebarComponentType = React.ComponentType<{ id: number }>;
type ContentComponentType = React.ComponentType<{ id: number }>;

const SidebarComponents: Partial<
  Record<ReservationType, SidebarComponentType>
> = {
  [ReservationType.Hotel]: ProfileReservationHotelSidebar,
  [ReservationType.Activity]: ProfileReservationActivitySidebar,
};

const ContentComponents: Partial<
  Record<ReservationType, ContentComponentType>
> = {
  [ReservationType.Hotel]: ProfileReservationHotelContent,
  [ReservationType.Activity]: ProfileReservationActivityContent,
};

const DefaultContent = () => {
  const { t } = useTranslation();

  // @todo: creating more descriptive default content
  return <div>{t("profileReservation.defaultContent")}</div>;
};

const ProfileReservation = () => {
  const { t } = useTranslation();

  useDocumentTitle(t("profileReservation.pageTitle"));

  const { id } = useParams<ProfileReservationParams>();
  const reservationId = Number(id);
  const [reservation, isLoadingReservation] = useReservation(reservationId);

  const Sidebar =
    SidebarComponents[reservation?.itemType as ReservationType] ||
    DefaultContent;
  const Content =
    ContentComponents[reservation?.itemType as ReservationType] ||
    DefaultContent;

  const dateToday = useMemo(() => formatDateIso(new Date()), []);

  const breadcrumbs = useMemo(
    () => [
      {
        title: t("profile.pageTitle"),
        href: "/profile",
        icon: IconUser,
      },
      {
        title: t("profileReservations.pageTitle"),
        href: `/profile/bookings?checkInFrom=${dateToday}`,
        icon: IconCalendarEvent,
      },
      {
        title: t("profileReservation.pageTitle"),
        icon: IconZoom,
      },
    ],
    [dateToday, t],
  );

  if (!reservationId) {
    return <Navigate to="/404" replace={true} />;
  }

  if (isLoadingReservation || !reservation) {
    return <LoaderBlock />;
  }

  return (
    <>
      <BreadcrumbContainer>
        <Breadcrumb items={breadcrumbs} />
      </BreadcrumbContainer>
      <PageSplitLayout>
        <PageSplitLayout.Sidebar>
          <Sidebar id={reservationId} />
        </PageSplitLayout.Sidebar>
        <PageSplitLayout.Content>
          <S.Wrapper>
            <Content id={reservationId} />
          </S.Wrapper>
        </PageSplitLayout.Content>
      </PageSplitLayout>
    </>
  );
};

export default ProfileReservation;
