const palette = {
  // Primary brand
  ocean: "#123266",
  sun: "#FAB109",
  whiteSand: "#F2F2F0",
  sky: "#0098FF",

  // Secondary brand - not used at the moment
  sunrise: "#F9D2D5",
  sunset: "#F96846",
  sea: "#1DBDD1",

  // @note: should we add the new brand colors?

  // Custom colors named by https://chir.ag/projects/name-that-color

  // Greyscale
  black: "#000",
  mineShaft: "#212121",
  outerSpace: "#2D3436",
  tundora: "#444444",
  boulder: "#777777",
  dustyGrey: "#999999",
  blueBayoux: "#4E6776",
  blueRibbon: "#0F6EEF",
  nevada: "#636E72",
  doveGrey: "#707070",
  silverChalice: "#a2a2a2",
  subMarine: "#B2BEC3",
  silver: "#cccccc",
  geyser: "#DFE6E9",
  alto: "#D9D9D9",
  mercury: "#E2E2E2",
  concrete: "#f2f2f2",
  mischka: "#e6e0e9",
  alabaster: "#F7F7F7",
  white: "#fff",

  // Colors
  waikawaGray: "#597094",
  royalBlue: "#3775DA",
  cinnabar: "#E74C3C",
  malachite: "#07BC0C",
  buttercup: "#F1C40F",
  sorbus: "#fa8909",
  dodgerBlue: "#2684FF",
  bahamaBlue: "#05619E",
  eucalyptus: "#1E9A51",
  red: "#EA0000",
  seaGreen: "#2A7E4D",
  redBerry: "#8E0000",
  frenchPass: "#BBDFFF",
  peppermint: "#CCEDCE",
  yourPink: "#FEC5BB",
  picasso: "#FFEE93",
  buddhaGold: "#c29d0b",
  bush: "#0d3817",
};

export default palette;
