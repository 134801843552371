import { mediaQuery } from "@hotelspoint/theme";
import styled from "styled-components";
import { prop } from "styled-tools";

export const Wrapper = styled.div<{ $src: string; $height?: number }>`
  position: relative;
  height: ${props => (props.$height ? `${props.$height}px` : "550px")};
  background: url(${prop("$src")}) no-repeat;
  background-position: center center;
  background-size: cover;

  @media ${mediaQuery.tablet} {
    height: 750px;
  }

  @media only screen and (min-width: 1920px) {
    height: 900px;
  }
`;
