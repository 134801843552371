import { useMutation, useQueryClient } from "@tanstack/react-query";

import { UserCreatePayload, UserService } from "../../services";

const useCreateUser = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["users", "create"],
    mutationFn: (payload: UserCreatePayload) => {
      return UserService.create(payload);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ["users"],
      });
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useCreateUser;
