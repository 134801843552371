import { useQuery } from "@tanstack/react-query";

import { PackageService } from "../../services";

const useProductOriginDates = (productId: number, originCode: string) => {
  const { data, isLoading } = useQuery({
    queryKey: ["product_origin_dates", productId, originCode],
    queryFn: () => PackageService.getProductOriginDates(productId, originCode),
    enabled: !!productId && !!originCode,
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useProductOriginDates;
