import { Button, Drawer } from "@hotelspoint/components";
import {
  DrawerPlacement,
  DrawerSize,
} from "@hotelspoint/components/src/composites/Drawer/types";
import { zIndex } from "@hotelspoint/theme";
import { IconAdjustmentsHorizontal, IconX } from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import * as S from "./SearchHotelsFilterDrawer.styled";

interface SearchHotelsFilterDrawerProps {
  children: React.ReactNode;
}

const SearchHotelsFilterDrawer = ({
  children,
}: SearchHotelsFilterDrawerProps) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement={DrawerPlacement.Left}
        size={DrawerSize.FullPage}
        onOpenChange={() => setIsOpen(false)}
        style={{
          top: theme.custom.header.heightS,
          paddingBottom: theme.custom.header.heightS,
        }}
      >
        <Drawer.Header
          style={{ position: "sticky", top: 0, zIndex: zIndex.base }}
        >
          <S.HeaderWrapper>
            <S.Title>{t("searchHotelResults.filters.title")}</S.Title>
            <Button
              variant="outlined"
              onClick={() => setIsOpen(false)}
              small
              style={{ padding: "0 8px" }}
            >
              <IconX size={18} />
            </Button>
          </S.HeaderWrapper>
        </Drawer.Header>
        <Drawer.Content>
          <S.Wrapper>
            {children}
            <Button
              variant="secondary"
              onClick={() => setIsOpen(false)}
              fullWidth
            >
              {t("searchHotelResults.filters.applyFilters")}
            </Button>
          </S.Wrapper>
        </Drawer.Content>
      </Drawer>
      <Button onClick={() => setIsOpen(true)} small>
        <IconAdjustmentsHorizontal size={18} />
        {t("searchHotelResults.filters.title")}
      </Button>
    </>
  );
};

export default SearchHotelsFilterDrawer;
