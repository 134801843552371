import { useHotelDetails } from "@hotelspoint/api";
import {
  HotelAmenities,
  HotelBaseInfo,
  HotelSummary,
  MosaicGallery,
} from "@hotelspoint/components";
import { ImageType } from "@hotelspoint/utils";

import * as S from "../SearchHotelPage.styled.ts";

interface SearchHotelDetailsProps {
  hotelId: number;
}

const SearchHotelDetails = ({ hotelId }: SearchHotelDetailsProps) => {
  const [hotelDetails, isLoadingHotelDetails] = useHotelDetails(hotelId);

  return (
    <>
      <MosaicGallery
        images={hotelDetails?.images ?? []}
        isLoading={isLoadingHotelDetails}
        imageType={ImageType.Hotel}
      />
      <HotelBaseInfo id={hotelId} />
      <S.Divider />
      <HotelSummary id={hotelId} />
      <S.Divider />
      <HotelAmenities id={hotelId} />
    </>
  );
};

export default SearchHotelDetails;
