import { SubAgencyStatementService } from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useSubAgencyStatementItems = (agencyId: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ["sub_agency_statements", "agency_items", agencyId],
    queryFn: () => SubAgencyStatementService.getItems(agencyId),
    enabled: !!agencyId,
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useSubAgencyStatementItems;
