import {
  handleError,
  useAddHotelToFavorites,
  useRemoveHotelFromFavorites,
} from "@hotelspoint/api";
import { Button, HotelListing, TableType } from "@hotelspoint/components";
import useHotelMapStore from "@hotelspoint/store/src/useHotelMapStore.ts";
import useHotelsSearchResultStore from "@hotelspoint/store/src/useHotelsSearchResultStore.ts";
import { IconReload } from "@tabler/icons-react";
import { t } from "i18next";
import { useCallback, useMemo, useState } from "react";

import { SearchHotelsViewState } from "../../types.ts";
import useHotelSearchParams from "../../useHotelSearchParams.ts";
import * as S from "../SearchHotelsResults.styled.tsx";

const PAGE_SIZE = 8;

const SearchHotelsList = () => {
  const [, setQuery] = useHotelSearchParams();
  const [addToFavorites] = useAddHotelToFavorites();
  const [removeFromFavorites] = useRemoveHotelFromFavorites();
  const [visibleHotelsCount, setVisibleHotelsCount] = useState(PAGE_SIZE);

  const hotels = useHotelsSearchResultStore(state => state.results);
  const hotelsCount = useHotelsSearchResultStore(state => state.count);
  const isLoading = useHotelsSearchResultStore(state => state.isLoading);
  const setActiveHotelId = useHotelMapStore(state => state.set);

  const handleShowOnMap = useCallback(
    (id: number) => {
      setQuery({
        view: SearchHotelsViewState.Map,
      });
      setActiveHotelId(id);
    },
    [setActiveHotelId, setQuery],
  );

  const visibleHotels = useMemo(
    () => hotels.slice(0, visibleHotelsCount),
    [hotels, visibleHotelsCount],
  );

  const handleFavoriteChange =
    (hotelId: number) => async (isFavorite: boolean) => {
      try {
        if (isFavorite) {
          await removeFromFavorites(hotelId);
        } else {
          await addToFavorites(hotelId);
        }
      } catch (error: any) {
        handleError({ t, error });
      }
    };

  const canLoadMore = useMemo(() => {
    return visibleHotelsCount <= (hotelsCount ?? 0);
  }, [hotelsCount, visibleHotelsCount]);

  return (
    <>
      {visibleHotels.map(hotel => (
        <HotelListing
          key={hotel.id}
          id={hotel.id}
          name={hotel.name}
          cityName={hotel.cityName}
          thumbnail={hotel.thumbnail}
          rating={hotel.rating}
          isFavorite={hotel.isFavorite}
          address={hotel.address}
          rate={hotel.cheapestRate}
          bookingRating={hotel.score}
          reviews={hotel.reviews}
          roomTableType={TableType.HOTELS}
          handleShowOnMap={() => handleShowOnMap(hotel.id)}
          handleFavorite={handleFavoriteChange(hotel.id)}
        />
      ))}
      {canLoadMore && (
        <S.LoadMoreWrapper>
          <Button
            variant="outlined"
            onClick={() =>
              setVisibleHotelsCount(prevCount => prevCount + PAGE_SIZE)
            }
            isLoading={isLoading}
          >
            {!isLoading && <IconReload size={18} />}
            <span>{t("searchHotelResults.loadMore")}</span>
          </Button>
        </S.LoadMoreWrapper>
      )}
    </>
  );
};

export default SearchHotelsList;
