import {
  ButtonEdit,
  StatusChip,
  TABLE_COLUMN_SIZE,
  TableCell,
} from "@hotelspoint/components";
import { AgencyAgent } from "@hotelspoint/types";
import { formatDate, getUserStatusColor } from "@hotelspoint/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";

const columnHelper = createColumnHelper<AgencyAgent>();

interface GetColumnsParams {
  t: TFunction;
  handleOpenDialog: (id: number) => void;
}

const getColumns = ({ t, handleOpenDialog }: GetColumnsParams) => [
  columnHelper.accessor("name", {
    header: t("profileSubAgencySettings.columns.name"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.accessor("email", {
    header: t("profileSubAgencySettings.columns.email"),
    size: TABLE_COLUMN_SIZE.EMAIL,
  }),
  columnHelper.accessor("isActive", {
    header: t("profileSubAgencySettings.columns.isActive"),
    size: TABLE_COLUMN_SIZE.NAME,
    cell: props => {
      const isActive = props.getValue();

      return (
        <StatusChip $variant={getUserStatusColor(isActive)}>
          {isActive ? t("userStatus.active") : t("userStatus.inactive")}
        </StatusChip>
      );
    },
  }),
  columnHelper.accessor("dateCreated", {
    header: t("profileSubAgencySettings.columns.dateCreated"),
    size: TABLE_COLUMN_SIZE.DATE,
    cell: props => formatDate(props.getValue()),
  }),
  columnHelper.display({
    id: "actions",
    size: TABLE_COLUMN_SIZE.ACTION,
    cell: props => {
      const id = props.row.original.id;

      return (
        <TableCell>
          <ButtonEdit onClick={() => handleOpenDialog(id)} />
        </TableCell>
      );
    },
  }),
];

export default getColumns;
