import { useQuery } from "@tanstack/react-query";

import { HotelService } from "../../services";

const useHotelReviews = (id: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ["hotels", id, "reviews"],
    queryFn: () => HotelService.getReviews(id),
    enabled: !!id,
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useHotelReviews;
