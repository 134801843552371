import {
  IconCreditCardPay,
  IconFileInvoice,
  IconSettings,
} from "@tabler/icons-react";

import { NavItem } from "./navItems";

const subAgencyNavItems: NavItem[] = [
  {
    icon: IconSettings,
    href: "/profile/sub-agencies/settings",
    title: "profileSubAgenciesSettings.pageTitle",
  },
  {
    icon: IconCreditCardPay,
    href: "/profile/sub-agencies/payments",
    title: "profileSubAgenciesPayments.pageTitle",
  },
  {
    icon: IconFileInvoice,
    href: "/profile/sub-agencies/statements",
    title: "profileSubAgenciesStatements.pageTitle",
  },
];

export default subAgencyNavItems;
