import { mediaQuery, spacing, zIndex } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { theme } from "styled-tools";

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: ${theme("custom.header.heightS")}px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${theme("palette.white")};
  z-index: ${zIndex.base};
  @media ${mediaQuery.tablet} {
    top: ${theme("custom.header.heightL")}px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing(1.5)}px;
  border-bottom: 1px solid ${theme("palette.silver")};
`;

export const Title = styled.h5`
  font-weight: 700;
`;

export const InnerWrapper = styled.div`
  @media ${mediaQuery.tablet} {
    display: flex;
  }
`;

export const FilterWrapper = styled.aside`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(2.5)}px;
  padding: ${spacing(2)}px;
`;

export const Sidebar = styled(FilterWrapper)`
  width: ${theme("custom.sidebar.width")}px;
  height: 100vh;
  padding-bottom: calc(${theme("custom.header.heightL")}px + 16px);
  overflow-y: auto;
  overscroll-behavior: contain;
`;

export const Content = styled.section`
  position: relative;

  @media ${mediaQuery.tablet} {
    display: block;
    width: calc(100% - ${theme("custom.sidebar.width")}px);
  }
`;
