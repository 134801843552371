import { useHotelRooms } from "@hotelspoint/api";
import { HotelRoom } from "@hotelspoint/types";

import { TableSkeleton } from "../../table";
import HotelRoomsTable from "../HotelRoomsTable";

export enum TableType {
  HOTELS = "hotels",
  PACKAGES = "packages",
}

interface HotelRoomsModalProps {
  hotelId: number;
  searchId: number;
  tableType: TableType;
}

const HotelRoomsModal = ({
  hotelId,
  searchId,
  tableType,
}: HotelRoomsModalProps) => {
  const [data, isLoadingData] = useHotelRooms(searchId, hotelId);

  if (tableType === TableType.PACKAGES) {
    return <p>Packages Rooms Table</p>;
  }

  if (isLoadingData) {
    return <TableSkeleton />;
  }

  return (
    <HotelRoomsTable
      searchId={searchId}
      hotelId={hotelId}
      rooms={data?.rooms as HotelRoom[]}
    />
  );
};

export default HotelRoomsModal;
