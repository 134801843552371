import { Paper } from "@hotelspoint/components";
import { mediaQuery, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { theme } from "styled-tools";

export const SidebarCard = styled(Paper)`
  padding: ${spacing(1.5)}px;
`;

export const HotelListingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(2)}px;
  margin-top: ${spacing(3)}px;
`;

export const LoadMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${spacing(2)}px;
`;

export const VisibleUntilDesktop = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(2)}px;
  margin-bottom: ${spacing(2)}px;
  @media ${mediaQuery.desktop} {
    display: none;
  }
`;

export const LoaderBar = styled.div`
  background: ${theme("palette.silverChalice")};
  border-radius: ${spacing(0.5)}px;
  margin-top: ${spacing(2)}px;
`;

export const LoaderBarLine = styled.div`
  height: ${spacing(1)}px;
  border-radius: ${spacing(0.5)}px;
  background: ${theme("palette.ocean")};
  animation: loading 10s infinite;
  @keyframes loading {
    from {
      width: 1%;
    }
    to {
      width: 98%;
    }
  }
`;
