import { pxToRem, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { theme } from "styled-tools";

export const Wrapper = styled.div<{ onClick?: () => void }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: fit-content;
  min-width: 88px;
  column-gap: ${spacing(2)}px;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
`;

export const Head = styled.div`
  text-align: right;
`;

export const Reviews = styled.span`
  text-align: right;
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(12)};
  color: ${theme("palette.black")};
`;

export const ReviewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
