import { PaymentType } from "@hotelspoint/api";
import { useUserAgencyStore } from "@hotelspoint/store";
import { UserCurrency } from "@hotelspoint/types";
import { getPaymentTypeName } from "@hotelspoint/utils";
import { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { StatusFlavour } from "../../common";
import StatusBlock from "../../elements/StatusBlock";
import FormAdapter from "../../form/FormAdapter";
import FormCheckbox from "../../form/FormCheckbox";
import FormDropZone from "../../form/FormDropZone";
import FormRadio from "../../form/FormRadio";
import * as S from "./PaymentOptions.styled";

export interface PaymentOptionsProps {
  amount: number;
  activeTypes?: PaymentType[];
  isDisabled?: boolean;
}

const PaymentOptions = ({
  amount,
  activeTypes,
  isDisabled = false,
}: PaymentOptionsProps) => {
  const { t } = useTranslation();

  const [typeInForm] = useWatch({ name: ["paymentType"] });
  const type = Number(typeInForm);
  const agency = useUserAgencyStore(state => state.agency);
  const balance = agency?.credit?.balance ?? 0;

  const hasInsufficientBalance = useMemo(
    () => balance < amount,
    [amount, balance],
  );

  return (
    <S.Wrapper>
      <FormAdapter
        name="paymentType"
        label={t("components.paymentOptions.type.label")}
      >
        {props => (
          <S.RadioGroup>
            {[
              PaymentType.CreditLine,
              PaymentType.BankTransfer,
              PaymentType.CreditCard,
            ]
              .filter(type => !activeTypes || activeTypes.includes(type))
              .map(type => (
                <FormRadio
                  {...props}
                  key={type}
                  id={`${type}`}
                  value={type}
                  label={t(
                    `components.paymentOptions.${getPaymentTypeName(type)}`,
                  )}
                  defaultChecked={props.value === type}
                  disabled={isDisabled}
                />
              ))}
          </S.RadioGroup>
        )}
      </FormAdapter>
      {type === PaymentType.CreditLine && (
        <>
          <StatusBlock status={StatusFlavour.Info}>
            {t("components.paymentOptions.creditLine.info", {
              value: balance,
              currency: UserCurrency.EUR,
            })}
          </StatusBlock>
          {hasInsufficientBalance && (
            <StatusBlock status={StatusFlavour.Error}>
              {t("components.paymentOptions.creditLine.insufficient")}
            </StatusBlock>
          )}
          <FormAdapter
            name="confirmCreditLine"
            label={t("components.paymentOptions.confirmCreditLine.label")}
            formControlProps={{ labelPosition: "inline-end" }}
          >
            {props => (
              <FormCheckbox
                {...props}
                disabled={isDisabled || hasInsufficientBalance}
              />
            )}
          </FormAdapter>
        </>
      )}
      {type === PaymentType.BankTransfer && (
        <FormAdapter
          name="file"
          label={t("components.paymentOptions.file.label")}
        >
          {props => (
            <FormDropZone
              {...props}
              placeholder={t("components.paymentOptions.file.placeholder")}
              disabled={isDisabled}
              dropZoneProps={{ multiple: false }}
            />
          )}
        </FormAdapter>
      )}
      {type === PaymentType.CreditCard && (
        <>
          <StatusBlock status={StatusFlavour.Info}>
            {t("components.paymentOptions.creditCard.info")}
          </StatusBlock>
          <FormAdapter
            name="currency"
            label={t("components.paymentOptions.currency.label")}
          >
            {props => (
              <S.RadioGroup>
                {[UserCurrency.EUR, UserCurrency.BGN].map(currency => (
                  <FormRadio
                    {...props}
                    key={currency}
                    id={currency}
                    value={currency}
                    label={currency}
                    defaultChecked={props.value === currency}
                    disabled={isDisabled}
                  />
                ))}
              </S.RadioGroup>
            )}
          </FormAdapter>
        </>
      )}
    </S.Wrapper>
  );
};

export default PaymentOptions;
