import * as S from "./MapLabel.styled";

interface MapLabelProps {
  children: React.ReactNode;
  isActive: boolean;
}

const MapLabel = ({ children, isActive }: MapLabelProps) => {
  return <S.Wrapper $isActive={isActive}>{children}</S.Wrapper>;
};

export default MapLabel;
