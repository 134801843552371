import { borderRadius, pxToRem, spacing } from "@hotelspoint/theme";
import { css, styled } from "styled-components";
import { theme } from "styled-tools";

import Paper from "../../../elements/Paper";
import TextItalic from "../../../elements/TextItalic";
import { DayPickerTheme } from "./FormDatePicker";

export const PaperWrapper = styled(Paper)`
  padding: ${spacing(2)}px ${spacing(1)}px ${spacing(1)}px;
`;

export const Styles = styled.div<{
  $tooltip?: string;
  $theme?: DayPickerTheme;
}>`
  .rdp {
    --rdp-cell-size: ${({ $theme }) => $theme?.cellSize || "30px"};

    margin: 0;
  }

  .rdp-table {
    max-width: calc(var(--rdp-cell-size) * 7 + 70px);
  }

  .rdp-cell {
    width: calc(var(--rdp-cell-size) + 10px);
    height: calc(var(--rdp-cell-size) + 10px);
  }

  .rdp-day {
    position: relative;
    overflow: visible;
    background-color: ${theme("palette.alabaster")};
    border-radius: ${borderRadius.medium}px;
    display: inline-block;
    text-align: center;

    ${props =>
      props.$tooltip &&
      css`
        &:hover {
          &:before {
            content: "${props.$tooltip}";
            position: absolute;
            width: max-content;
            padding: ${spacing(0.25)}px ${spacing(0.5)}px;
            border-radius: ${borderRadius.normal}px;
            top: -30px;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            font-size: ${pxToRem(11)};
            font-weight: 600;
            color: ${theme("palette.white")};
            background-color: ${theme("palette.black")};
          }

          &:after {
            content: "";
            position: absolute;
            top: -50%;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
            border-top: 6px solid ${theme("palette.black")};
            border-bottom: 0;
          }
        }
      `}
  }

  .package-day {
    background-color: rgba(40, 167, 69, 0.15);
    color: ${theme("palette.bush")};
    &.rdp-day_selected {
      background-color: rgba(40, 167, 69, 0.8);
      color: ${theme("palette.white")};
    }

    &:hover {
      &:not([disabled]):not(.rdp-day_selected) {
        background-color: rgba(40, 167, 69, 0.4);
      }
    }
  }

  .last-packages-day {
    background-color: rgba(251, 237, 20, 0.35);
    color: ${theme("palette.bush")};
    &.rdp-day_selected {
      background-color: rgba(251, 237, 20, 0.8);
      color: ${theme("palette.bush")};
    }

    &:hover {
      &:not([disabled]):not(.rdp-day_selected) {
        background-color: rgba(251, 237, 20, 0.7);
      }
    }
  }

  .not-available-packages-day {
    background-color: rgba(255, 26, 26, 0.6);
  }

  .hp-selected {
    background-color: ${theme("palette.frenchPass")};
  }

  .hp-disabled {
    cursor: not-allowed;
  }

  .hp-range-start,
  .hp-range-end {
    color: ${theme("palette.white")};
    background-color: ${theme("palette.sky")};

    &:hover {
      &:not([disabled]):not(.rdp-day_selected) {
        background-color: ${theme("palette.bahamaBlue")};
      }
    }
  }
`;

export const Footer = styled(TextItalic)`
  display: flex;
  justify-content: flex-end;
  font-size: ${pxToRem(14)};
  font-weight: 600;
  margin-top: ${spacing(2)}px;
`;

export const DrawerDayPicker = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
