import { spacing, zIndex } from "@hotelspoint/theme";
import { styled } from "styled-components";

export const UpperControls = styled.div`
  position: absolute;
  top: ${spacing(2)}px;
  right: ${spacing(2)}px;
  left: ${spacing(2)}px;
  z-index: ${zIndex.base};
`;

export const UpperMapControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
