import {
  PaymentType,
  ReservationName,
  ReservationNameChildren,
  Titles,
  Transfer,
  TransferSearchPayload,
} from "@hotelspoint/api";
import { COUNTRY_CODE_BG } from "@hotelspoint/constants";
import { Agency, RateType, UserCurrency } from "@hotelspoint/types";
import { formatTimeUtc } from "@hotelspoint/utils";
import {
  array,
  boolean,
  date,
  InferType,
  mixed,
  number,
  object,
  string,
} from "yup";

import { FormType } from "./types";

const personSchema = object({
  title: string().required(
    "searchTransferBook.content.form.title.validation.required",
  ),
  name: string().required(
    "searchTransferBook.content.form.firstName.validation.required",
  ),
  surname: string().required(
    "searchTransferBook.content.form.lastName.validation.required",
  ),
  age: number(),
}).required();

export const defaultValues = {
  type: FormType.AllPassengers,
  leader: [],
  adults: [],
  children: [],
  phoneCode: "+359",
  phoneNumber: "",
  outboundFlightNumber: "",
  returnFlightNumber: "",
  outboundDate: undefined,
  outboundTime: "",
  returnDate: undefined,
  returnTime: "",
  paymentType: PaymentType.CreditLine,
  confirmCreditLine: false,
  currency: UserCurrency.EUR,
};

export const validationSchema = object()
  .shape({
    type: mixed<FormType>().oneOf(
      Object.values(FormType).map(e => e as FormType),
    ),
    leader: array().when("type", {
      is: FormType.LeaderOnly,
      then: schema => schema.of(personSchema).required(),
    }),
    adults: array().when("type", {
      is: FormType.AllPassengers,
      then: schema => schema.of(personSchema).required(),
    }),
    children: array().when("type", {
      is: FormType.AllPassengers,
      then: schema => schema.of(personSchema).required(),
    }),
    phoneCode: string().required(
      "searchTransferBook.content.form.phoneCode.validation.required",
    ),
    phoneNumber: string().required(
      "searchTransferBook.content.form.phoneNumber.validation.required",
    ),
    outboundFlightNumber: string().required(
      "searchTransferBook.content.form.outboundFlightNumber.validation.required",
    ),
    returnFlightNumber: string().required(
      "searchTransferBook.content.form.returnFlightNumber.validation.required",
    ),
    outboundDate: date(),
    outboundTime: string(),
    returnDate: date(),
    returnTime: string(),
    paymentType: number().when("isNonrefundable", {
      is: true,
      then: schema =>
        schema.required("components.paymentOptions.type.validation.required"),
    }),
    confirmCreditLine: boolean().when("paymentType", {
      is: (type: string) => Number(type) === PaymentType.CreditLine,
      then: schema => {
        return schema
          .required()
          .oneOf(
            [true],
            "components.paymentOptions.confirmCreditLine.validation.required",
          );
      },
    }),
    currency: string().when("paymentType", {
      is: (type: string) => Number(type) === PaymentType.CreditCard,
      then: schema => {
        return schema.required(
          "components.paymentOptions.currency.validation.required",
        );
      },
    }),
  })
  .required();

export type FormValues = InferType<typeof validationSchema>;

export const form2Entity = (values: FormValues) => {
  return {
    adults:
      values.type === FormType.LeaderOnly
        ? (values.leader as ReservationName[])
        : (values.adults as ReservationName[]),
    children:
      values.type === FormType.LeaderOnly
        ? []
        : (values?.children as ReservationNameChildren[]),
    phoneCode: values.phoneCode,
    phoneNumber: values.phoneNumber,
    flights: {
      arrival: values.outboundFlightNumber,
      departure: values.returnFlightNumber,
    },
    paymentType: values.paymentType,
    paymentCurrency: values.currency,
  };
};

export const entity2Form = (
  rate: Transfer,
  agency: Agency | null,
  children: number[],
  formValues: TransferSearchPayload,
): FormValues => {
  return {
    type: FormType.AllPassengers,
    leader: [
      {
        title: Titles.Mr,
        name: "",
        surname: "",
      },
    ],
    adults: Array.from({ length: formValues.adults }).map(
      (_value: any, index: number) => ({
        title: index % 2 !== 0 ? Titles.Mrs : Titles.Mr,
        name: "",
        surname: "",
      }),
    ),
    children: children.map((child: number, index: number) => {
      return {
        title: index % 2 !== 0 ? Titles.Mrs : Titles.Mr,
        name: "",
        surname: "",
        age: child,
      };
    }),
    phoneCode: defaultValues.phoneCode,
    phoneNumber: "",
    outboundFlightNumber: "",
    returnFlightNumber: "",
    outboundDate: new Date(formValues.outboundDate),
    outboundTime: formValues.outboundDate
      ? formatTimeUtc(formValues.outboundDate)
      : undefined,
    returnDate: formValues.returnDate
      ? new Date(formValues.returnDate)
      : undefined,
    returnTime: formValues.returnDate
      ? formatTimeUtc(formValues.returnDate)
      : undefined,
    paymentType:
      rate?.rateType === RateType.NonRefundable
        ? (agency?.credit?.limit ?? 0) > 0
          ? PaymentType.CreditLine
          : PaymentType.CreditCard
        : 0,
    confirmCreditLine: false,
    currency:
      `${agency?.countryCode}`.toLowerCase() === COUNTRY_CODE_BG
        ? UserCurrency.BGN
        : UserCurrency.EUR,
  };
};
