import { borderRadius, mediaQuery, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { switchProp, theme } from "styled-tools";

import { ModalSize } from "../../common";
import Paper from "../../elements/Paper";

export const Wrapper = styled(Paper)<{ $size: ModalSize }>`
  width: 100vw;
  height: 100vh;
  border-radius: 0;
  box-shadow: none;
  overflow: auto;

  @media ${mediaQuery.desktop} {
    width: ${switchProp("$size", {
      [ModalSize.Small]: theme("custom.modal.widthS"),
      [ModalSize.Large]: theme("custom.modal.widthL"),
    })}px;
    height: auto;
    margin: 0 auto;
    border-radius: ${borderRadius.normal}px;
    box-shadow:
      0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing(1.5)}px;
  border-bottom: 1px solid ${theme("palette.silver")};

  @media ${mediaQuery.desktop} {
    padding: ${spacing(1.5)}px ${spacing(2)}px;
  }
`;

export const ModalTitle = styled.h5`
  font-weight: 700;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${spacing(3)}px;
  column-gap: ${spacing(4)}px;
`;

export const Content = styled.div`
  padding: ${spacing(2)}px ${spacing(1.5)}px;

  @media ${mediaQuery.desktop} {
    padding: ${spacing(2.5)}px ${spacing(2)}px;
    max-height: calc(100vh - 280px);
    overflow-y: auto;
  }
`;
