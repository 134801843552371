import {
  Box,
  Container,
  EmptyRecentSearchState,
  Flex,
  HeaderImage,
  PackageSearchCard,
  PageWrapper,
  Tooltip,
} from "@hotelspoint/components";
import {
  RecentPackageSearchQuery,
  useRecentPackagesSearchesStore,
} from "@hotelspoint/store";
import {
  formatDateIso,
  isDateBefore,
  randomIntFromInterval,
  useDocumentTitle,
} from "@hotelspoint/utils";
import omit from "lodash/omit";
import sumBy from "lodash/sumBy";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import headerImages from "./headerImages";
import * as S from "./SearchPackages.styled";
import SearchPackagesForm from "./SearchPackagesForm";
import SearchPackagesResults from "./SearchPackagesResults";
import usePackageSearchParams from "./usePackageSearchParams";

const SearchPackages = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("searchPackages.pageTitle"));

  const [initialSearch, setInitialSearch] = useState<any>(undefined);

  const [query, setQuery] = usePackageSearchParams();

  const dateToday = useMemo(() => new Date(), []);
  const recentSearches = useRecentPackagesSearchesStore(state => state.queries);

  const recentSearchesOptions = useMemo(() => {
    return recentSearches.map(query => ({
      ...query,
      checkIn: query.checkIn || formatDateIso(dateToday),
    }));
  }, [dateToday, recentSearches]);

  // Randomize an integer for the header image
  const randomInteger = useMemo(() => {
    return randomIntFromInterval(0, headerImages.length - 1);
  }, []);

  const headerImage = useMemo(
    () => headerImages[randomInteger],
    [randomInteger],
  );

  const handleRecentSearch = useCallback(
    (query: RecentPackageSearchQuery) => {
      const isCheckInOutdated = isDateBefore(
        new Date(query.checkIn),
        dateToday,
      );

      // Outdated queries are partially loaded into the form as initial values
      if (isCheckInOutdated) {
        setInitialSearch({
          destination: query.meta.destinationName,
          rooms: query.rooms,
          destinationId: query.destinationId,
        });

        // Scroll to the top of the page
        window.scrollTo(0, 0);
      } else {
        setQuery(omit(query, "meta"));
      }
    },
    [dateToday, setQuery],
  );

  if (query.destinationId !== undefined && query.productId) {
    return <SearchPackagesResults />;
  }

  return (
    <PageWrapper>
      <HeaderImage src={headerImage}>
        <Container>
          <S.HeaderWrapper>
            <S.HeaderCard>
              <SearchPackagesForm initialValues={initialSearch} />
            </S.HeaderCard>
          </S.HeaderWrapper>
        </Container>
      </HeaderImage>
      <Container>
        <div style={{ marginTop: 48 }}>
          {recentSearches?.length !== 0 ? (
            <>
              <Tooltip>
                <Tooltip.Trigger>
                  <h4 style={{ marginBottom: 16 }}>
                    <span>{t("searchPackages.recentSearches.title")}</span>
                  </h4>
                </Tooltip.Trigger>
                <Tooltip.Content>
                  {t("searchPackages.recentSearches.tooltip")}
                </Tooltip.Content>
              </Tooltip>
              <Flex mx={[0, -1, -1, -1]} my={[-1, -1, -1, -1]}>
                {recentSearchesOptions.map((query, index) => (
                  <Box
                    key={`recent-search-${index}`}
                    width={[1, 1 / 2, 1 / 2, 1 / 3]}
                    px={[0, 1, 1, 1]}
                    py={[1, 1, 1, 1]}
                  >
                    <button
                      type="button"
                      onClick={() => handleRecentSearch(query)}
                      style={{ width: "100%" }}
                    >
                      <PackageSearchCard
                        name={query.meta.destinationName}
                        program={query.meta.programName}
                        date={query.checkIn}
                        nights={query.duration}
                        origin={query.origin}
                        adults={sumBy(query.rooms, "adults")}
                        children={sumBy(query.rooms, "children.length")}
                      />
                    </button>
                  </Box>
                ))}
              </Flex>
            </>
          ) : (
            <EmptyRecentSearchState
              title={t("searchPackages.recentSearches.emptyState.title")}
              description={t(
                "searchPackages.recentSearches.emptyState.description",
              )}
            />
          )}
        </div>
      </Container>
    </PageWrapper>
  );
};

export default SearchPackages;
