import { PaymentRecipient, usePayments } from "@hotelspoint/api";
import {
  Breadcrumb,
  BreadcrumbContainer,
  Pagination,
  Panel,
  PaymentDialog,
  TABLE_PAGE_SIZE_DEFAULT,
  TableFooter,
  TableLayout,
  TablePageSize,
  TableResultsCount,
} from "@hotelspoint/components";
import { Table } from "@hotelspoint/components";
import { pickFilterValues, useDocumentTitle } from "@hotelspoint/utils";
import { IconCreditCardPay, IconUser } from "@tabler/icons-react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import getColumns from "./ProfilePayments.columns";
import ProfilePaymentsFilters from "./ProfilePaymentsFilters";
import useProfilePaymentsParams from "./useProfilePaymentsParams";

const ProfilePayments = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("profilePayments.pageTitle"));

  const [targetId, setTargetId] = useState<number | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZE_DEFAULT);
  const [pageIndex, setPageIndex] = useState(1);

  const [query] = useProfilePaymentsParams();
  const filters = useMemo(() => pickFilterValues(query), [query]);

  const [payments, isLoading] = usePayments({
    pageIndex,
    pageSize,
    recipient: PaymentRecipient.System,
    ...filters,
  });

  const handleOpenDialog = (id: number) => {
    setTargetId(id);
    setIsDialogOpen(true);
  };

  const data = useMemo(() => payments?.results ?? [], [payments]);

  const columns = useMemo(() => {
    return getColumns({
      t,
      handleOpenDialog,
    });
  }, [t]);

  const breadcrumbs = useMemo(
    () => [
      {
        title: t("profile.pageTitle"),
        href: "/profile",
        icon: IconUser,
      },
      {
        title: t("profilePayments.pageTitle"),
        icon: IconCreditCardPay,
      },
    ],
    [t],
  );

  useEffect(() => {
    if (!isDialogOpen && targetId) {
      setTargetId(null);
    }
  }, [isDialogOpen, targetId]);

  return (
    <>
      <PaymentDialog
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        id={targetId as number}
      />
      <BreadcrumbContainer>
        <Breadcrumb items={breadcrumbs} />
      </BreadcrumbContainer>
      <Panel title={t("profilePayments.pageTitle")}>
        <ProfilePaymentsFilters isLoading={isLoading} />
        <TableLayout>
          <Table
            data={data}
            columns={columns}
            isLoading={isLoading}
            useReactTableProps={{
              state: {
                pagination: {
                  pageIndex,
                  pageSize: TABLE_PAGE_SIZE_DEFAULT,
                },
                columnPinning: {
                  left: ["id"],
                  right: ["actions"],
                },
              },
              manualPagination: true,
            }}
          />
          <TableFooter>
            <div>
              <TableResultsCount value={payments?.count} />
            </div>
            <div>
              <Pagination
                pageIndex={pageIndex}
                pageSize={pageSize}
                setPageIndex={setPageIndex}
                totalCount={payments?.count ?? 0}
              />
            </div>
            <TablePageSize value={pageSize} onChange={setPageSize} />
          </TableFooter>
        </TableLayout>
      </Panel>
    </>
  );
};

export default ProfilePayments;
