import { mediaQuery } from "@hotelspoint/theme";
import { OriginDates, OriginDatesStatus } from "@hotelspoint/types";
import {
  formatDateIso,
  formDay,
  isDateBefore,
  useMediaQuery,
} from "@hotelspoint/utils";
import { useCallback, useMemo } from "react";
import { DayContentProps, DayPickerSingleProps } from "react-day-picker";
import { useTranslation } from "react-i18next";

import { FormControlInjectedProps } from "../../FormControl";
import FormDatePicker from "../FormDatePicker";
import * as S from "./FormDatePricePicker.styled";

interface FormDatePricePickerProps
  extends FormControlInjectedProps,
    Omit<
      React.InputHTMLAttributes<HTMLInputElement>,
      "id" | "name" | "value" | "onChange"
    > {
  dates: OriginDates;
  dayPickerProps?: Omit<DayPickerSingleProps, "mode">;
}

const FormDatePricePicker = ({
  value,
  onChange,
  dates,
  dayPickerProps,
  ...rest
}: FormDatePricePickerProps) => {
  const { t } = useTranslation();

  const isDesktop = useMediaQuery(mediaQuery.desktop);
  const dateToday = useMemo(() => new Date(), []);

  const { packageDates, lastPackagesDates, notAvailableDates } = useMemo(
    () =>
      Object.keys(dates).reduce<{
        packageDates: Date[];
        lastPackagesDates: Date[];
        notAvailableDates: Date[];
      }>(
        (acc, date) => {
          const parsedDate = new Date(date);
          const status = dates[date].status;

          switch (status) {
            case OriginDatesStatus.Available:
              acc.packageDates.push(parsedDate);
              break;
            case OriginDatesStatus.LastPackages:
              acc.lastPackagesDates.push(parsedDate);
              break;
            case OriginDatesStatus.NotAvailable:
              acc.notAvailableDates.push(parsedDate);
              break;
            default:
              break;
          }

          return acc;
        },
        { packageDates: [], lastPackagesDates: [], notAvailableDates: [] },
      ),
    [dates],
  );

  const isDayDisabled = useCallback(
    (day: Date) => {
      const formattedDay = formatDateIso(day);
      const isBeforeToday = isDateBefore(day, dateToday);
      const isStatusNA =
        dates[formattedDay]?.status === OriginDatesStatus.NotAvailable;
      const isAmountNull = !dates[formattedDay]?.amount;

      return isBeforeToday || isStatusNA || isAmountNull;
    },
    [dateToday, dates],
  );

  const renderDay = useCallback(
    (day: DayContentProps) => {
      const dayStr = formDay(day.date);
      const dateIso = formatDateIso(day.date);

      return (
        <S.DayWrapper>
          <span>{dayStr}</span>
          {dates[dateIso] && (
            <S.Price>
              {dates[dateIso].amount &&
                Math.round(parseFloat(dates[dateIso].amount))}{" "}
              €
            </S.Price>
          )}
        </S.DayWrapper>
      );
    },
    [dates],
  );

  const renderFooter = useCallback(() => {
    return (
      <S.LegendWrapper>
        <S.LegendItemAvailable>
          {t("components.formDatePricePicker.legend.available")}
        </S.LegendItemAvailable>
        <S.LegendItemLimited>
          {t("components.formDatePricePicker.legend.limitedProduct")}
        </S.LegendItemLimited>
        <S.LegendItemNotAvailable>
          {t("components.formDatePricePicker.legend.unavailable")}
        </S.LegendItemNotAvailable>
      </S.LegendWrapper>
    );
  }, [t]);

  return (
    <FormDatePicker
      value={value}
      onChange={onChange}
      footer={renderFooter()}
      dayPickerProps={{
        mode: "single",
        fixedWeeks: true,
        showOutsideDays: false,
        components: { DayContent: renderDay },
        numberOfMonths: isDesktop ? 2 : 1,
        disabled: isDayDisabled,
        modifiers: {
          package: packageDates,
          lastPackages: lastPackagesDates,
          notAvailablePackages: notAvailableDates,
        },
        modifiersClassNames: {
          package: "package-day",
          lastPackages: "last-packages-day",
          notAvailablePackages: "not-available-packages-day",
        },
        ...dayPickerProps,
      }}
      dayPickerTheme={{
        cellSize: "46px",
      }}
      {...rest}
    />
  );
};

export default FormDatePricePicker;
