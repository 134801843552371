import { NamesType, useReservation } from "@hotelspoint/api";
import {
  Box,
  Flex,
  FormAdapter,
  FormClearableInput,
  FormDatePickerSingle,
  FormRadio,
  FormSelect,
  FormTextArray,
  FormTimePicker,
} from "@hotelspoint/components";
import {
  CONFIRMATION_TRANSFER_STATUS_OPTIONS,
  TRANSFER_TYPE_OPTIONS,
} from "@hotelspoint/constants";
import { FormType, TransferTripType } from "@hotelspoint/types";
import { getTransferTripTypeName } from "@hotelspoint/utils";
import { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { TransferContactsInfo } from "../../../../../Search/SearchTransfers/components";
import AdminReservationFormTransferPassengers from "./AdminReservationFormTransferPassengers";
import { PassengerType } from "./AdminReservationFormTransferPassengers/AdminReservationFormTransferPassengers";

interface AdminReservationFormTransferParams extends Record<string, string> {
  id: string;
}

const AdminReservationFormTransfer = () => {
  const { t } = useTranslation();

  const [formType, tripType] = useWatch({ name: ["namesType", "tripType"] });

  const { id } = useParams<AdminReservationFormTransferParams>();
  const reservationId = Number(id);

  const [reservation, isLoadingReservation] = useReservation(reservationId);

  const tripTypeOptions = useMemo(() => {
    return [
      TransferTripType.RoundTrip,
      TransferTripType.OneWay,
      TransferTripType.Airports,
    ].map(value => ({
      value,
      label: t(getTransferTripTypeName(value)),
    }));
  }, [t]);

  const typeOptions = useMemo(
    () =>
      TRANSFER_TYPE_OPTIONS.map(type => ({
        ...type,
        label: t(type.label),
      })),
    [t],
  );

  const confirmationTransferStatusOptions = useMemo(
    () =>
      CONFIRMATION_TRANSFER_STATUS_OPTIONS.map(status => ({
        ...status,
        label: t(status.label),
      })),
    [t],
  );

  const peopleOptions = Array.from({ length: 60 }, (_, num) => ({
    label: String(num + 0),
    value: num + 0,
  }));

  return (
    <>
      <Box width={1} px={[0, 0, 1, 1]} mt={3}>
        <h5>{t("adminReservation.form.sections.transfer")}</h5>
        <hr style={{ margin: "10px 0" }} />
      </Box>
      <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="tripType"
          label={t("adminReservation.form.tripType.label")}
        >
          {props => <FormSelect {...props} options={tripTypeOptions} />}
        </FormAdapter>
      </Box>
      <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="transferType"
          label={t("adminReservation.form.transferType.label")}
        >
          {props => <FormSelect {...props} options={typeOptions} />}
        </FormAdapter>
      </Box>
      <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="adults"
          label={t("adminReservation.form.adults.label")}
        >
          {props => <FormSelect {...props} options={peopleOptions} />}
        </FormAdapter>
      </Box>
      <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="children"
          label={t("adminReservation.form.children.label")}
        >
          {props => <FormSelect {...props} options={peopleOptions} />}
        </FormAdapter>
      </Box>
      <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="pickUp"
          label={t("adminReservation.form.pickUp.label")}
        >
          {props => (
            <FormClearableInput
              {...props}
              placeholder={t("adminReservation.form.pickUp.placeholder")}
            />
          )}
        </FormAdapter>
      </Box>
      <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="dropOff"
          label={t("adminReservation.form.dropOff.label")}
        >
          {props => (
            <FormClearableInput
              {...props}
              placeholder={t("adminReservation.form.dropOff.placeholder")}
            />
          )}
        </FormAdapter>
      </Box>
      <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="transferName"
          label={t("adminReservation.form.transferName.label")}
        >
          {props => (
            <FormClearableInput
              {...props}
              placeholder={t("adminReservation.form.transferName.placeholder")}
            />
          )}
        </FormAdapter>
      </Box>
      <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="vehicle"
          label={t("adminReservation.form.vehicle.label")}
        >
          {props => (
            <FormClearableInput
              {...props}
              placeholder={t("adminReservation.form.vehicle.placeholder")}
            />
          )}
        </FormAdapter>
      </Box>
      <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="flightArrival"
          label={t("adminReservation.form.flightArrival.label")}
        >
          {props => (
            <FormClearableInput
              {...props}
              placeholder={t("adminReservation.form.flightArrival.placeholder")}
            />
          )}
        </FormAdapter>
      </Box>
      {tripType !== TransferTripType.OneWay && (
        <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="flightDeparture"
            label={t("adminReservation.form.flightDeparture.label")}
          >
            {props => (
              <FormClearableInput
                {...props}
                placeholder={t(
                  "adminReservation.form.flightDeparture.placeholder",
                )}
              />
            )}
          </FormAdapter>
        </Box>
      )}
      <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="confirmationOutboundTransferStatus"
          label={t(
            "adminReservation.form.confirmationOutboundTransferStatus.label",
          )}
        >
          {props => (
            <FormSelect
              {...props}
              options={confirmationTransferStatusOptions}
            />
          )}
        </FormAdapter>
      </Box>
      {tripType !== TransferTripType.OneWay && (
        <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="confirmationReturnTransferStatus"
            label={t(
              "adminReservation.form.confirmationReturnTransferStatus.label",
            )}
          >
            {props => (
              <FormSelect
                {...props}
                options={confirmationTransferStatusOptions}
              />
            )}
          </FormAdapter>
        </Box>
      )}
      <Box width={1} px={[0, 0, 1, 1]} mt={3}>
        <h5>{t("adminReservation.form.sections.outboundTransfer")}</h5>
        <hr style={{ margin: "10px 0" }} />
      </Box>
      <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="outboundDate"
          label={t("adminReservation.form.outboundDate.label")}
        >
          {props => <FormDatePickerSingle {...props} />}
        </FormAdapter>
      </Box>
      <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="outboundTime"
          label={t("adminReservation.form.outboundTime.label")}
        >
          {props => <FormTimePicker {...props} />}
        </FormAdapter>
      </Box>
      <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="pickUpDate"
          label={t("adminReservation.form.pickUpDate.label")}
        >
          {props => <FormDatePickerSingle {...props} />}
        </FormAdapter>
      </Box>
      <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter
          name="pickUpTime"
          label={t("adminReservation.form.pickUpTime.label")}
        >
          {props => <FormTimePicker {...props} />}
        </FormAdapter>
      </Box>
      <Box width={1} px={[0, 0, 1, 1]} mt={3}>
        <h5>{t("adminReservation.form.sections.importantInformation")}</h5>
        <hr style={{ margin: "10px 0" }} />
      </Box>
      <Box width={1} px={[0, 0, 1, 1]} py={1}>
        <FormAdapter name="outboundRemarks" label={undefined}>
          {props => <FormTextArray {...props} />}
        </FormAdapter>
      </Box>
      {tripType !== TransferTripType.OneWay && (
        <>
          <Box width={1} px={[0, 0, 1, 1]} mt={3}>
            <h5>{t("adminReservation.form.sections.returnTransfer")}</h5>
            <hr style={{ margin: "10px 0" }} />
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="returnDate"
              label={t("adminReservation.form.returnDate.label")}
            >
              {props => <FormDatePickerSingle {...props} />}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="returnTime"
              label={t("adminReservation.form.returnTime.label")}
            >
              {props => <FormTimePicker {...props} />}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="dropOffDate"
              label={t("adminReservation.form.dropOffDate.label")}
            >
              {props => <FormDatePickerSingle {...props} />}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="dropOffTime"
              label={t("adminReservation.form.dropOffTime.label")}
            >
              {props => <FormTimePicker {...props} />}
            </FormAdapter>
          </Box>
          <Box width={1} px={[0, 0, 1, 1]} mt={3}>
            <h5>{t("adminReservation.form.sections.importantInformation")}</h5>
            <hr style={{ margin: "10px 0" }} />
          </Box>
          <Box width={1} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter name="returnRemarks" label={undefined}>
              {props => <FormTextArray {...props} />}
            </FormAdapter>
          </Box>
        </>
      )}
      <Box width={1} px={[0, 0, 1, 1]} mt={3}>
        <h5>{t("adminReservation.form.sections.passengers")}</h5>
        <hr style={{ margin: "10px 0" }} />
      </Box>
      <Flex alignItems={"center"} width={1}>
        <Box
          width={[1, 1, 1 / 2, 1 / 3]}
          px={2}
          display={"flex"}
          alignItems={"center"}
          pl={1}
        >
          <FormAdapter name="namesType">
            {props => (
              <Flex>
                <Flex>
                  <FormRadio
                    {...props}
                    id={FormType.LeaderOnly}
                    value={NamesType.LeaderOnly}
                    label={t("adminReservation.form.radioGroup.leaderOnly")}
                    checked={formType === NamesType.LeaderOnly}
                    onChange={() => props.onChange(NamesType.LeaderOnly)}
                  />
                </Flex>
                <Flex ml={1}>
                  <FormRadio
                    {...props}
                    id={FormType.AllPassengers}
                    value={NamesType.AllPassengers}
                    label={t("adminReservation.form.radioGroup.allPassengers")}
                    checked={formType === NamesType.AllPassengers}
                    onChange={() => props.onChange(NamesType.AllPassengers)}
                  />
                </Flex>
              </Flex>
            )}
          </FormAdapter>
        </Box>
        <Flex flexGrow={1} />
        <Box width={[1, 1, 1 / 7, 1 / 8]} px={2}>
          <FormAdapter
            name="minPassengers"
            label={t("adminReservation.form.minPassengers.label")}
          >
            {props => <FormClearableInput {...props} />}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 7, 1 / 8]} px={2}>
          <FormAdapter
            name="maxPassengers"
            label={t("adminReservation.form.maxPassengers.label")}
          >
            {props => <FormClearableInput {...props} />}
          </FormAdapter>
        </Box>
      </Flex>
      <Box width={1} px={[0, 0, 1, 1]} mt={3}>
        <h5>{t("adminReservation.form.sections.adults")}</h5>
        <hr style={{ margin: "10px 0" }} />
      </Box>
      {isLoadingReservation ? (
        <AdminReservationFormTransferPassengers
          isLoading={isLoadingReservation}
        />
      ) : formType === NamesType.LeaderOnly ? (
        <AdminReservationFormTransferPassengers name="passengerAdults.0" />
      ) : (
        Array.from({
          length: reservation?.itemData.names.adults?.length as number,
        }).map((_, adultIndex) => {
          const name = `passengerAdults.${adultIndex}`;
          return (
            <AdminReservationFormTransferPassengers
              key={adultIndex}
              name={name}
            />
          );
        })
      )}
      {formType !== NamesType.LeaderOnly &&
        reservation?.itemData.names?.children?.length !== 0 && (
          <>
            <Box width={1} px={[0, 0, 1, 1]} mt={3}>
              <h5>{t("adminReservation.form.sections.children")}</h5>
              <hr style={{ margin: "10px 0" }} />
            </Box>
            {reservation?.itemData.names.children.map((_, childIndex) => {
              const name = `passengerChildren.${childIndex}`;
              return (
                <AdminReservationFormTransferPassengers
                  key={childIndex}
                  name={name}
                  type={PassengerType.Children}
                />
              );
            })}
          </>
        )}
      <Box width={1} px={[0, 0, 1, 1]} mt={3}>
        <h5>{t("adminReservation.form.sections.contacts")}</h5>
        <hr style={{ margin: "10px 0" }} />
        <TransferContactsInfo />
      </Box>
    </>
  );
};

export default AdminReservationFormTransfer;
