import { IconPlus } from "@tabler/icons-react";
import { useCallback } from "react";

import Button from "../../elements/Button";
import ButtonDelete from "../../elements/ButtonDelete";
import FormTextArea from "../FormTextArea";
import * as S from "./FormTextArray.styled";

interface FormTextArrayProps {
  value: string[];
  onChange: (value: string[]) => void;
}

const FormTextArray = ({ value = [], onChange }: FormTextArrayProps) => {
  const handleItemChange = useCallback(
    (targetIndex: number, changedValue: string) => {
      const updatedArray = value.map((item, index) =>
        index === targetIndex ? changedValue : item,
      );

      onChange(updatedArray);
    },
    [value, onChange],
  );

  const handleDelete = useCallback(
    (targetIndex: number) => {
      const updatedArray = value.filter((_, index) => index !== targetIndex);

      onChange(updatedArray);
    },
    [value, onChange],
  );

  const handleAddItem = useCallback(() => {
    const updatedArray = [...value, ""];

    onChange(updatedArray);
  }, [value, onChange]);

  return (
    <S.Wrapper>
      {value.map((val, index) => (
        <S.ItemWrapper key={index}>
          <FormTextArea
            value={val}
            onChange={value => handleItemChange(index, value.target.value)}
          />
          <ButtonDelete onClick={() => handleDelete(index)} />
        </S.ItemWrapper>
      ))}
      <Button small variant="outlined" onClick={handleAddItem}>
        <IconPlus size={16} />
      </Button>
    </S.Wrapper>
  );
};

export default FormTextArray;
