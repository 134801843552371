import {
  NumericArrayParam,
  StringParam,
  useQueryParams,
} from "use-query-params";

const useActivitiesFilterParams = () => {
  const [query, setQuery] = useQueryParams({
    name: StringParam,
    price: NumericArrayParam,
    category: NumericArrayParam,
  });

  const ret: [typeof query, typeof setQuery] = [query, setQuery];

  return ret;
};
export default useActivitiesFilterParams;
