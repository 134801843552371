import { borderRadius, pxToRem, spacing } from "@hotelspoint/theme";
import { FlightStatus } from "@hotelspoint/types";
import styled from "styled-components";
import { switchProp, theme } from "styled-tools";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(2)}px;
`;

export const FlightSegments = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FlightDetailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: ${spacing(2)}px;
  row-gap: ${spacing(1)}px;
`;

export const FlightDetailsItem = styled.div`
  display: flex;
  align-items: center;
`;

export const TimeOrigin = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlightTimelineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: ${spacing(1)}px;
  flex-basis: 44%;
`;

export const FlightTimeline = styled.div`
  position: relative;
  width: 100%;
  height: 2px;
  background-color: ${theme("palette.silverChalice")};

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: ${borderRadius.circle};
  }

  &::before {
    width: 10px;
    height: 10px;
    left: -14px;
    border: 2px solid ${theme("palette.ocean")};
  }

  &::after {
    width: 14px;
    height: 14px;
    right: -14px;
    background-color: ${theme("palette.ocean")};
  }
`;

export const Label = styled.p`
  margin-left: ${spacing(0.5)}px;
  margin-right: ${spacing(0.75)}px;
  font-size: ${pxToRem(14)};
  font-weight: 500;
  line-height: 1;
`;

export const Text = styled.p`
  font-size: ${pxToRem(14)};
  font-weight: 400;
  line-height: 1;
`;

export const StatusLabel = styled.div<{ $status: FlightStatus }>`
  padding: ${spacing(0.5)}px;
  font-size: ${pxToRem(12)};
  font-weight: 400;
  line-height: 1;
  color: ${theme("palette.white")};
  background-color: ${switchProp("$status", {
    [FlightStatus.Ok]: theme("success.primary"),
  })};
  border-radius: ${borderRadius.normal}px;
`;
