import { QueryClient } from "@tanstack/react-query";

const MAX_QUERY_RETRIES = 3;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 0,
      staleTime: 0,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      retry: (failureCount: number, error: any) => {
        if (failureCount > MAX_QUERY_RETRIES) {
          return false;
        }
        // Do not retry client side errors - 4XX
        return !(error?.status >= 400 && error.status < 500);
      },
    },
  },
});

export default queryClient;
