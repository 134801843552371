import { useHotelDetails } from "@hotelspoint/api";
import {
  HotelAmenities,
  HotelBaseInfo,
  HotelSummary,
  MosaicGallery,
} from "@hotelspoint/components";
import { ImageType } from "@hotelspoint/utils";

import * as S from "./HotelDetailsModal.styled";

interface HotelDetailsModalProps {
  id: number;
}

const HotelDetailsModal = ({ id }: HotelDetailsModalProps) => {
  const [hotelDetails, isLoadingHotelDetails] = useHotelDetails(id as number);

  return (
    <>
      <MosaicGallery
        images={hotelDetails?.images ?? []}
        isLoading={isLoadingHotelDetails}
        imageType={ImageType.Hotel}
      />
      <HotelBaseInfo id={id as number} />
      <S.Divider />
      <HotelSummary id={id as number} />
      <S.Divider />
      <HotelAmenities id={id as number} />
    </>
  );
};

export default HotelDetailsModal;
