import { borderRadius, mediaQuery, pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { theme } from "styled-tools";

export const Wrapper = styled.div`
  display: block;
  padding: ${spacing(1)}px;

  @media ${mediaQuery.tablet} {
    display: flex;
    justify-content: flex-start;
    gap: ${spacing(10)}px;
  }
`;

export const Logo = styled.div`
  width: 34px;
  height: 34px;
  border-radius: ${borderRadius.circle};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme("palette.white")};
  text-transform: uppercase;
  flex-shrink: 0;
`;

export const Profile = styled.div`
  display: flex;
  gap: ${spacing(1.25)}px;
  padding-bottom: ${spacing(1)}px;
  min-width: 130px;
`;

export const Country = styled.div`
  display: flex;
  gap: ${spacing(1)}px;
  align-items: center;
  white-space: nowrap;
`;

export const ReviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const RatingLabel = styled.div`
  color: ${theme("palette.ocean")};
  font-weight: 600;
  font-size: ${pxToRem(20)};
`;

export const Date = styled.div`
  color: ${theme("palette.silverChalice")};
  margin-bottom: ${spacing(0.5)}px;
  font-size: ${pxToRem(14)};
  font-weight: 400;
`;

export const DateLabel = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DateWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
`;

export const Comment = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${spacing(1)}px;
  margin-top: ${spacing(1)}px;
  font-weight: 400;
  font-size: ${pxToRem(14)};
`;

export const Name = styled.h6`
  font-weight: 600;
  color: ${theme("palette.black")};
`;
