import { useModalContainerStore } from "@hotelspoint/store";
import { getImagePathFromCDN, ImageType } from "@hotelspoint/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import GalleryOverlay from "../GalleryOverlay";
import MosaicLayout from "../MosaicLayout";
import * as S from "./MosaicGallery.styled";

interface MosaicGalleryProps {
  images: string[];
  isLoading: boolean;
  imageType: ImageType;
  mosaicMaxImages?: number;
}

const MosaicGallery = ({
  images,
  isLoading,
  imageType,
  mosaicMaxImages = 3,
}: MosaicGalleryProps) => {
  const { t } = useTranslation();

  const openModal = useModalContainerStore(state => state.openModal);

  const slicedImages = useMemo(() => {
    if (!images) return [];

    return images.slice(0, mosaicMaxImages).map(img => ({
      image: getImagePathFromCDN(img, imageType),
      alt: `Image ${img}`,
    }));
  }, [imageType, images, mosaicMaxImages]);

  if (images?.length === 0) return null;

  return (
    <S.MosaicWrapper>
      <MosaicLayout
        value={slicedImages}
        height={120}
        isLoading={isLoading}
        onClick={index =>
          openModal({
            title: t("components.mosaicGallery.title"),
            content: (
              <GalleryOverlay
                images={images}
                isLoading={isLoading}
                imageType={imageType}
                initialSlideIndex={index}
              />
            ),
          })
        }
      />
    </S.MosaicWrapper>
  );
};

export default MosaicGallery;
