import { FORM_ROOM_SELECTOR_INITIAL_STATE } from "@hotelspoint/components";
import { formatDateIso } from "@hotelspoint/utils";
import { array, date, InferType, number, object, string } from "yup";

export const validationSchema = object({
  destination: string()
    .required("searchPackages.destination.validation.required")
    .test("destination", "__ctx__", (_, ctx) => {
      const destination = ctx.parent.destinationId;

      if (!destination) {
        return ctx.createError({
          message: "searchPackages.destination.validation.destination",
        });
      }

      return true;
    }),
  product: number().test("product", "__ctx__", (_, ctx) => {
    const destination = ctx.parent.destinationId;
    const product = ctx.parent.product;

    if (destination && !product) {
      return ctx.createError({
        message: "searchPackages.product.validation.required",
      });
    }

    return true;
  }),
  origin: string().test("origin", "__ctx__", (_, ctx) => {
    const product = ctx.parent.product;
    const origin = ctx.parent.origin;

    if (product && !origin) {
      return ctx.createError({
        message: "searchPackages.origin.validation.required",
      });
    }

    return true;
  }),
  duration: number().test("duration", "__ctx__", (_, ctx) => {
    const destination = ctx.parent.destinationId;
    const product = ctx.parent.product;
    const duration = ctx.parent.duration;

    if (destination && product && !duration) {
      return ctx.createError({
        message: "searchPackages.duration.validation.required",
      });
    }

    return true;
  }),
  checkIn: date().test("checkIn", "__ctx__", (_, ctx) => {
    const product = ctx.parent.product;
    const origin = ctx.parent.origin;
    const checkIn = ctx.parent.checkIn;

    if (product && origin && !checkIn) {
      return ctx.createError({
        message: "searchPackages.checkIn.validation.required",
      });
    }

    return true;
  }),
  rooms: array()
    .of(
      object({
        adults: number(),
        children: array().of(number().required()),
      }),
    )
    .required()
    .test("adult", "searchHotels.rooms.validation.adult", value => {
      return value.every(room => room.adults !== 0);
    })
    .test("child age", "searchHotels.rooms.validation.childAge", value => {
      return value.every(room => {
        return room.children?.every(child => child !== 0);
      });
    }),

  // Read-only - set programmatically when selecting a destination
  destinationId: number(),
  productId: number(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: Partial<FormValues> = {
  destination: "",
  product: undefined,
  origin: "",
  duration: undefined,
  checkIn: undefined,
  rooms: [FORM_ROOM_SELECTOR_INITIAL_STATE],

  destinationId: undefined,
  productId: undefined,
};

export const form2Entity = (formValues: FormValues): any => ({
  productId: formValues.product,
  destinationId: formValues.destinationId,
  origin: formValues.origin,
  duration: formValues.duration,
  checkIn: formatDateIso(formValues.checkIn as Date),
  rooms: formValues.rooms,
});
