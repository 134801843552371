import { Button, Drawer } from "@hotelspoint/components";
import {
  DrawerPlacement,
  DrawerSize,
} from "@hotelspoint/components/src/composites/Drawer/types";
import { IconAdjustmentsHorizontal, IconX } from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import * as S from "./SearchActivitiesFilterDrawer.styled";

interface SearchActivitiesFilterDrawerProps {
  children: React.ReactNode;
}

const SearchActivitiesFilterDrawer = ({
  children,
}: SearchActivitiesFilterDrawerProps) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement={DrawerPlacement.Left}
        size={DrawerSize.FullPage}
        onOpenChange={() => setIsOpen(false)}
      >
        <Drawer.Header>
          <S.HeaderWrapper>
            <S.Title>{t("searchTransfersResults.filters.title")}</S.Title>
            <Button
              variant="outlined"
              onClick={() => setIsOpen(false)}
              small
              style={{ padding: "0 8px" }}
            >
              <IconX size={18} />
            </Button>
          </S.HeaderWrapper>
        </Drawer.Header>
        <Drawer.Content>
          <S.Wrapper>
            {children}
            <Button
              variant="secondary"
              onClick={() => setIsOpen(false)}
              fullWidth
            >
              {t("searchActivitiesResults.filters.applyFilters")}
            </Button>
          </S.Wrapper>
        </Drawer.Content>
      </Drawer>
      <Button onClick={() => setIsOpen(true)} small>
        <IconAdjustmentsHorizontal size={18} />
        {t("searchActivitiesResults.filters.title")}
      </Button>
    </>
  );
};

export default SearchActivitiesFilterDrawer;
