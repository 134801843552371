import {
  handleError,
  useAddHotelToFavorites,
  useRemoveHotelFromFavorites,
} from "@hotelspoint/api";
import {
  Button,
  EmptySearchState,
  HotelListing,
  LoaderBlock,
  TableType,
} from "@hotelspoint/components";
import { HotelComponentItems, PackageComponent } from "@hotelspoint/types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import * as S from "./SearchPackagesResultsContent.styled";

interface SearchPackagesResultsContentProps<T> {
  packageComponent: PackageComponent<T>;
  isLoading: boolean;
}

const PAGE_SIZE = 8;

const SearchPackagesResultsContent = ({
  packageComponent,
  isLoading,
}: SearchPackagesResultsContentProps<HotelComponentItems>) => {
  const { t } = useTranslation();

  const [visibleHotelsCount, setVisibleHotelsCount] = useState(PAGE_SIZE);

  const [addToFavorites] = useAddHotelToFavorites();
  const [removeFromFavorites] = useRemoveHotelFromFavorites();

  const visibleHotels = useMemo(() => {
    if (!packageComponent) return [];

    return packageComponent.items.hotels.slice(0, visibleHotelsCount);
  }, [packageComponent, visibleHotelsCount]);

  const canLoadMore = useMemo(() => {
    if (!packageComponent) return false;
    return visibleHotelsCount <= packageComponent.items.total;
  }, [packageComponent, visibleHotelsCount]);

  const handleFavoriteChange =
    (hotelId: number) => async (isFavorite: boolean) => {
      try {
        if (isFavorite) {
          await removeFromFavorites(hotelId);
        } else {
          await addToFavorites(hotelId);
        }
      } catch (error: any) {
        handleError({ t, error });
      }
    };
  return (
    <>
      <h3>
        {isLoading ? (
          <p>is loading</p>
        ) : (
          t("searchHotelResults.count", {
            count: packageComponent.items.total,
            place: packageComponent.name,
          })
        )}
      </h3>
      <S.HotelListingWrapper>
        {isLoading ? (
          <LoaderBlock>
            <h6>{t("searchHotelResults.loading")}</h6>
          </LoaderBlock>
        ) : packageComponent.items.total === 0 ? (
          <EmptySearchState
            title={t("searchHotelResults.emptyStateMessage.title")}
            description={t("searchHotelResults.emptyStateMessage.description")}
          />
        ) : (
          <>
            {visibleHotels.map(hotel => (
              <HotelListing
                key={hotel.id}
                id={hotel.id}
                name={hotel.name}
                cityName={hotel.cityName}
                thumbnail={hotel.thumbnail}
                rating={hotel.rating}
                isFavorite={hotel.isFavorite}
                address={hotel.address}
                rate={hotel.cheapestRate}
                bookingRating={hotel.score}
                reviews={hotel.reviews}
                roomTableType={TableType.PACKAGES}
                handleFavorite={handleFavoriteChange(hotel.id)}
                handleShowOnMap={() => {
                  console.log("Show on map");
                }}
              />
            ))}
            {canLoadMore && (
              <S.LoadMoreWrapper>
                <Button
                  variant="outlined"
                  onClick={() =>
                    setVisibleHotelsCount(prevCount => prevCount + PAGE_SIZE)
                  }
                >
                  <span>{t("searchHotelResults.loadMore")}</span>
                </Button>
              </S.LoadMoreWrapper>
            )}
          </>
        )}
      </S.HotelListingWrapper>
    </>
  );
};

export default SearchPackagesResultsContent;
