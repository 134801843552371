import { pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { theme } from "styled-tools";

export const VouchersWrapper = styled.div`
  display: flex;
  column-gap: ${spacing(2)}px;
`;

export const Title = styled.h6`
  margin-bottom: ${spacing(2)}px;
  color: rgba(18, 50, 102, 0.6);
  font-weight: 700;
  font-size: ${pxToRem(14)};
  text-transform: uppercase;
`;

export const VerticalDivider = styled.div`
  flex-basis: 1px;
  height: auto;
  background: ${theme("palette.silver")};
`;

export const VoucherActions = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(2)}px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${theme("palette.silver")};
`;

export const InformationWrapper = styled.div`
  @container container (min-width: 600px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const InformationBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(2)}px;
  margin-bottom: ${spacing(2)}px;
  @container container (min-width: 600px) {
    flex-direction: row;
    row-gap: 0;
    column-gap: ${spacing(2)}px;
    margin-bottom: 0;
  }
`;

export const InformationBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(1)}px;
`;

export const InformationBlockRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: ${spacing(2)}px;
`;

export const FlexGrow = styled.div`
  flex: 1;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: ${spacing(1)}px;
  margin-top: ${spacing(2)}px;
`;
