import "rc-slider/assets/index.css";

import { spacing } from "@hotelspoint/theme";
import Slider, { SliderProps as RcSliderProps } from "rc-slider";
import { useTheme } from "styled-components";

interface SliderProps
  extends Pick<
    RcSliderProps,
    | "value"
    | "min"
    | "max"
    | "disabled"
    | "range"
    | "dots"
    | "step"
    | "defaultValue"
    | "allowCross"
  > {
  onChange: (value: number | number[]) => void;
}

const RangeSlider = (props: SliderProps) => {
  const theme = useTheme();
  return (
    <Slider
      {...props}
      styles={{
        track: {
          backgroundColor: theme.primary.hover,
        },
        rail: {
          backgroundColor: theme.palette.mischka,
        },
        handle: {
          width: "20px",
          height: "20px",
          marginTop: -spacing(1),
          border: "none",
          backgroundColor: theme.primary.hover,
          opacity: 1,
          boxShadow:
            "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15) ",
        },
      }}
    />
  );
};

export default RangeSlider;
