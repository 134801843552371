import { borderRadius, spacing } from "@hotelspoint/theme";
import { keyframes, styled } from "styled-components";
import { theme } from "styled-tools";

import { ScrollIndicatorTheme } from "./ScrollIndicator";

const move = keyframes`
  0% {
    transform: translate(-50%,6px);
    opacity: 0;
  }
  50% {
    transform: translate(-50%,28px);
    opacity: 1;
  }
  100% {
    transform: translate(-50%,54px);
    opacity: 0;
  }
`;

export const Wrapper = styled.div``;

export const Mouse = styled.span<{ $variant: ScrollIndicatorTheme }>`
  position: relative;
  display: block;
  height: 68px;
  width: 34px;
  margin: 0 auto;
  border-radius: 50px;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ $variant }) =>
    $variant === ScrollIndicatorTheme.Dark
      ? theme("palette.black")
      : theme("palette.white")};
`;

export const Move = styled.span<{ $variant: ScrollIndicatorTheme }>`
  position: absolute;
  background-color: ${({ $variant }) =>
    $variant === ScrollIndicatorTheme.Dark
      ? theme("palette.black")
      : theme("palette.white")};
  height: 8px;
  width: 8px;
  border-radius: ${borderRadius.circle};
  left: 50%;
  transform: translateX(-50%);
  animation: ${move} 2s linear infinite;
`;

export const Title = styled.h6<{ $variant: ScrollIndicatorTheme }>`
  margin-top: ${spacing(1.5)}px;
  color: ${({ $variant }) =>
    $variant === ScrollIndicatorTheme.Dark
      ? theme("palette.black")
      : theme("palette.white")};
`;
