import { useTranslation } from "react-i18next";

import { NumericRating } from "../../elements";
import * as S from "./CustomersReview.styled";
import { getRatingLabel } from "./utils";

export interface CustomersReviewProps {
  reviewsCount?: number;
  bookingRating: number;
  onClick?: () => void;
}

const CustomersReview = ({
  reviewsCount,
  bookingRating,
  onClick,
}: CustomersReviewProps) => {
  const { t } = useTranslation();

  return (
    <S.Wrapper onClick={onClick}>
      <S.ReviewsWrapper>
        <S.Head>{t(getRatingLabel(bookingRating))}</S.Head>
        <S.Reviews>
          {[
            reviewsCount,
            t("components.customersReview.review", {
              count: reviewsCount,
            }),
          ].join(" ")}
        </S.Reviews>
      </S.ReviewsWrapper>
      <NumericRating value={bookingRating} />
    </S.Wrapper>
  );
};

export default CustomersReview;
