import { HotelSearchFilters, HotelSearchResponse } from "@hotelspoint/api";
import { HotelRoom } from "@hotelspoint/types";
import { create } from "zustand";

interface HotelSearchResultsStore {
  id: number | null;
  count: number | null;
  total: number | null;
  rooms: HotelRoom[];
  filters: HotelSearchFilters | null;
  isLoading: boolean;
  isCompleted: boolean | null;
  setLoading: (isLoading: boolean) => void;
  setResponse: (response: HotelSearchResponse) => void;
  reset: () => void;
}

const useHotelSearchResultStore = create<HotelSearchResultsStore>(set => ({
  id: null,
  count: null,
  total: null,
  rooms: [],
  filters: null,
  isLoading: false,
  isCompleted: null,
  setLoading: isLoading => set({ isLoading }),
  setResponse: response =>
    set({
      id: response.id,
      count: response.count,
      total: response.total,
      rooms: response.results,
      filters: response.filters,
      isCompleted: response.isCompleted,
    }),
  reset: () =>
    set({
      id: null,
      count: null,
      total: null,
      rooms: [],
      filters: null,
      isLoading: false,
      isCompleted: null,
    }),
}));

export default useHotelSearchResultStore;
