import { IconSearch, IconX } from "@tabler/icons-react";
import { useCombobox } from "downshift";
import flatten from "lodash/flatten";
import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { OptionGroup } from "../../common";
import FormInput, { type FormInputProps } from "../FormInput";
import { VISIBLE_OPTIONS } from "./constants";
import * as S from "./FormAutoComplete.styled";

export interface FormAutoCompleteOption<M = any> {
  value: any;
  label: string;
  meta: M;
}

interface FormAutoCompleteProps extends Omit<FormInputProps, "onSelect"> {
  onSelect: (option: FormAutoCompleteOption | null) => void;
  options: OptionGroup<FormAutoCompleteOption>[];
  renderOption: (option: FormAutoCompleteOption) => React.ReactNode;
  isLoading?: boolean;
  minCharacters?: number;
}

const FormAutoComplete = ({
  value,
  onChange,
  onSelect,
  options,
  renderOption,
  isLoading = false,
  minCharacters = 2,
  ...inputProps
}: FormAutoCompleteProps) => {
  const flatOptions = useMemo(
    () => flatten(options.map(group => group.options)),
    [options],
  );

  const {
    isOpen,
    getInputProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
    selectedItem,
    reset,
  } = useCombobox<FormAutoCompleteOption>({
    items: flatOptions,
    itemToString: item => item?.label ?? "",
    inputValue: value,
    onInputValueChange: ({ inputValue }) => {
      onChange({
        target: { value: inputValue },
      });
    },
    onSelectedItemChange: ({ selectedItem }) => {
      onSelect(selectedItem);
    },
  });

  return (
    <S.Wrapper>
      <FormInput
        {...inputProps}
        {...getInputProps()}
        startAdornment={<IconSearch size={18} />}
        endAdornment={
          value && (
            <button type="button" onClick={() => reset()}>
              <IconX size={18} />
            </button>
          )
        }
        autoComplete="off"
      />
      <S.Options
        $isVisible={isOpen && value.length >= minCharacters}
        {...getMenuProps()}
      >
        {isLoading || options.length === 0 ? (
          <ul>
            {Array(VISIBLE_OPTIONS)
              .fill(undefined)
              .map((_, index) => (
                <S.BaseOption key={index}>
                  <Skeleton width="100%" />
                </S.BaseOption>
              ))}
          </ul>
        ) : (
          options.map((group, groupIndex) => {
            return (
              <S.OptionGroup key={groupIndex}>
                <S.OptionGroupTitle>{group.label}</S.OptionGroupTitle>
                {group.options.map(option => {
                  // In order to work, downshift needs a unique index for each option (e.g. flat list of options)
                  const index = flatOptions.findIndex(
                    opt => opt.value === option.value,
                  );

                  const itemProps = getItemProps({
                    item: option,
                    index,
                    isActive: highlightedIndex === index,
                    isSelected: selectedItem === option,
                  });

                  return (
                    <S.Option
                      key={index}
                      $isActive={itemProps.isActive}
                      {...itemProps}
                    >
                      {renderOption(option)}
                    </S.Option>
                  );
                })}
              </S.OptionGroup>
            );
          })
        )}
      </S.Options>
    </S.Wrapper>
  );
};

export default FormAutoComplete;
