import { usePlace } from "@hotelspoint/api";
import { Breadcrumb, PlaceTypeIcon } from "@hotelspoint/components";
import { PlaceType } from "@hotelspoint/types";
import { IconBed } from "@tabler/icons-react";
import { t } from "i18next";
import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import useHotelSearchParams from "../../useHotelSearchParams.ts";

const SearchHotelsBreadcrumbs = () => {
  const [query] = useHotelSearchParams();

  const [place, isLoadingPlace] = usePlace({
    id: query.placeId as number,
    type: query.placeType as PlaceType,
  });

  const breadcrumbs = useMemo(
    () => [
      {
        title: t("searchHotels.pageTitle"),
        href: "/search/hotels",
        icon: () => <IconBed size={18} />,
      },
      ...(place?.parents
        ? place.parents.map(placeParent => ({
            title: placeParent.name as string,
            icon: () => (
              <PlaceTypeIcon
                type={placeParent.type as PlaceType}
                iconProps={{ size: 18 }}
              />
            ),
          }))
        : []),
      {
        title: place?.name as string,
        icon: () => (
          <PlaceTypeIcon
            type={place?.type as PlaceType}
            iconProps={{ size: 18 }}
          />
        ),
      },
    ],
    [place],
  );

  if (isLoadingPlace) {
    return <Skeleton width={120} height={10} />;
  }

  return <Breadcrumb items={breadcrumbs} />;
};

export default SearchHotelsBreadcrumbs;
