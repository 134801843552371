interface Entity {
  id: unknown;
  name: unknown;
}

const mapEntityToOption = (entity: Entity) => ({
  value: entity.id,
  label: entity.name,
});

export default mapEntityToOption;
