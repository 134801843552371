export enum AgencyStatus {
  Created = 1,
  Pending = 2,
  Verified = 3,
  Rejected = 4,
}

export interface AgencyCredit {
  used: number;
  balance: number;
  given: number;
  limit: number;
}

export interface AgencyAgent {
  id: number;
  name: string;
  isActive: boolean;
  email: string;
  dateCreated: string;
}

export interface AgencyDocument {
  id: number;
  name: string;
  date: string;
}

export interface Agency {
  id: number;
  name: string;
  email: string;
  logo: string | null;
  status: AgencyStatus;
  countryCode: string;
  companyLicense: string;
  companyPhone: string;
  companyAddress: string;
  companyManager: string;
  uicNumber: string;
  markupHotels: number;
  markupHotelsBase: number;
  markupHotelsOperator: number;
  markupTransfers: number;
  markupTransfersBase: number;
  markupTransfersOperator: number;
  markupActivities: number;
  markupActivitiesBase: number;
  markupActivitiesOperator: number;
  credit: AgencyCredit;
  agents: AgencyAgent[];
  documents: AgencyDocument[];
  suppliersHotels: number[];
  suppliersTransfers: number[];
  suppliersActivities: number[];
  phoneCode: string;
  isTravelOperator: boolean;
  canSendRequests: boolean;
  operatorAgencyId?: number;
  operatorAgencyName?: string;
}

export interface AgencyInList extends Agency {
  verifyFiles: string[] | null;
  dateCreated: string;
}
