import {
  Transfer,
  TransferSearchFiltersState,
  TransferSearchPayload,
  usePlace,
  useTransferAvailabilitySearch,
} from "@hotelspoint/api";
import {
  Breadcrumb,
  BreadcrumbContainer,
  Button,
  Container,
  LoaderBlock,
  PageSpacer,
  PageSplitLayout,
  PageWrapper,
  TransferListing,
} from "@hotelspoint/components";
import { mediaQuery } from "@hotelspoint/theme";
import { PlaceType } from "@hotelspoint/types";
import { useDocumentTitle, useMediaQuery } from "@hotelspoint/utils";
import { IconCar, IconReload, IconSearch } from "@tabler/icons-react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate } from "react-router-dom";

import useTransferFilterParams from "../useTransferFilterParams";
import useTransferSearchParams from "../useTransferSearchParams";
import SearchTransfersFilterDrawer from "./SearchTransfersFilterDrawer";
import SearchTransfersFilters from "./SearchTransfersFilters";
import SearchTransfersForm from "./SearchTransfersForm";
import * as S from "./SearchTransfersResults.styled";

const SearchTransfersResults = () => {
  const { t } = useTranslation();

  useDocumentTitle(t("searchTransfersResults.pageTitle"));
  const navigate = useNavigate();

  const isDesktop = useMediaQuery(mediaQuery.desktop);

  const [query] = useTransferSearchParams();
  const [pageIndex, setPageIndex] = useState(1);

  const [results, setResults] = useState<Transfer[]>([]);
  const [resultsTotal, setResultsTotal] = useState<number | undefined>(
    undefined,
  );
  const [resultsCount, setResultsCount] = useState<number | undefined>(
    undefined,
  );

  const [filters, setFilters] = useTransferFilterParams();
  const [filtersState, setFiltersState] =
    useState<TransferSearchFiltersState | null>(null);

  const filterUndefinedValues = useMemo(() => {
    return Object.fromEntries(
      Object.entries(filters).filter(([, value]) => value !== undefined),
    );
  }, [filters]);

  const [search, isLoadingSearch] = useTransferAvailabilitySearch({
    ...query,
    ...filterUndefinedValues,
  } as TransferSearchPayload);

  const [pickUpPlace, isLoadingPickUpPlace] = usePlace({
    id: query.pickUp.placeId as number,
    type: query.pickUp.placeType as PlaceType,
  });

  const [dropOffPlace, isLoadingDropOffPlace] = usePlace({
    id: query.dropOff.placeId as number,
    type: query.dropOff.placeType as PlaceType,
  });

  const isLoadingPlaces = isLoadingPickUpPlace || isLoadingDropOffPlace;

  const canLoadMore = useMemo(() => {
    return results?.length !== 0 && results?.length !== resultsCount;
  }, [results, resultsCount]);

  const breadcrumbs = useMemo(
    () => [
      {
        title: t("searchTransfers.pageTitle"),
        href: "/search/transfers",
        icon: () => <IconCar size={18} />,
      },
      {
        title: t("searchTransfersResults.search"),
        icon: () => <IconSearch size={18} />,
      },
    ],
    [t],
  );

  const { search: locationSearch } = useLocation();

  const bookSearch = useMemo(() => {
    const params = new URLSearchParams(locationSearch);
    params.delete("useCache");

    return params.toString();
  }, [locationSearch]);

  const shouldDisplayFilters = useMemo(() => {
    return resultsTotal !== 0 && filtersState;
  }, [resultsTotal, filtersState]);

  useEffect(() => {
    if (search) {
      const { results, total, filters } = search;

      // Update the initial set of transfers, count and filters state
      setResults(results);
      setResultsCount(total);
      setFiltersState(filters);

      // Reset the page index when a new search is made
      setPageIndex(1);
    }
  }, [search]);

  useEffect(() => {
    if (search) {
      const { results, filters, count } = search;

      setFiltersState(filters);

      if (pageIndex === 1) {
        setResults(results);
      } else {
        setResults(prevResults => [...prevResults, ...results]);
      }

      setResultsTotal(search.total);
      setResultsCount(count);
    }
  }, [setResults, pageIndex, search]);

  return (
    <PageWrapper>
      <Container>
        <PageSpacer>
          <BreadcrumbContainer>
            <Breadcrumb items={breadcrumbs} />
          </BreadcrumbContainer>
          <S.VisibleUntilDesktop>
            <S.SidebarCard>
              <SearchTransfersForm />
            </S.SidebarCard>
            {shouldDisplayFilters && !isDesktop && (
              <SearchTransfersFilterDrawer>
                <SearchTransfersFilters
                  value={filtersState}
                  // @ts-expect-error - Problem with the types
                  onChange={value => setFilters(value)}
                />
              </SearchTransfersFilterDrawer>
            )}
          </S.VisibleUntilDesktop>
          <PageSplitLayout>
            <PageSplitLayout.Sidebar>
              <S.SidebarCard>
                <SearchTransfersForm />
              </S.SidebarCard>
              {shouldDisplayFilters && isDesktop && (
                <div>
                  <h5 style={{ marginBottom: 12 }}>
                    {t("searchTransfersResults.filters.title")}
                  </h5>
                  <S.SidebarCard>
                    <SearchTransfersFilters
                      value={filtersState}
                      // @ts-expect-error - Problem with the types
                      onChange={value => setFilters(value)}
                    />
                  </S.SidebarCard>
                </div>
              )}
            </PageSplitLayout.Sidebar>
            <PageSplitLayout.Content>
              <h3>
                {isLoadingSearch || isLoadingPlaces ? (
                  <Skeleton height={20} />
                ) : (
                  t("searchTransfersResults.count", {
                    count: resultsCount,
                    pickUpPlace: pickUpPlace?.name,
                    dropOffPlace: dropOffPlace?.name,
                  })
                )}
              </h3>
              <S.TransferListingWrapper>
                {isLoadingSearch ? (
                  <LoaderBlock>
                    <h6>{t("searchTransfersResults.loading")}</h6>
                  </LoaderBlock>
                ) : (
                  <>
                    {results.map(transfer => (
                      <TransferListing
                        key={transfer.id}
                        id={transfer.id}
                        searchId={search?.id as number}
                        name={transfer.name}
                        vehicle={transfer.vehicle}
                        type={transfer.type}
                        maxPassengers={transfer.maxPassengers}
                        provider={transfer.provider}
                        modificationTime={transfer.modificationTime}
                        cancellationTime={transfer.cancellationTime}
                        image={transfer.image}
                        price={transfer.price}
                        priceNet={transfer.priceNet}
                        outboundTransfer={transfer.outboundTransfer}
                        returnTransfer={transfer.returnTransfer}
                        handleBookClick={() => {
                          navigate({
                            pathname: `/search/transfers/${search?.id}/${transfer.id}/book`,
                            search: bookSearch,
                          });
                        }}
                      />
                    ))}
                    {canLoadMore && (
                      <S.LoadMoreWrapper>
                        <Button
                          variant="outlined"
                          onClick={() => setPageIndex(pageIndex + 1)}
                          isLoading={isLoadingSearch}
                        >
                          {!isLoadingSearch && <IconReload size={18} />}
                          <span>{t("searchTransfersResults.loadMore")}</span>
                        </Button>
                      </S.LoadMoreWrapper>
                    )}
                  </>
                )}
              </S.TransferListingWrapper>
            </PageSplitLayout.Content>
          </PageSplitLayout>
        </PageSpacer>
      </Container>
    </PageWrapper>
  );
};

export default SearchTransfersResults;
