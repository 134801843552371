import {
  TransferOutbound,
  TransferReturn,
  TransferType,
} from "@hotelspoint/api";
import { useModalContainerStore } from "@hotelspoint/store";
import theme from "@hotelspoint/theme";
import {
  getImagePathFromCDN,
  getTransferTypeName,
  ImageType,
} from "@hotelspoint/utils";
import { IconCircleCheckFilled, IconUsersGroup } from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import Button from "../../elements/Button";
import Price from "../../misc/Price";
import MarkupPrice from "../MarkupPrice";
import TransferDetailsModal from "../TransferDetailsModal";
import thumbPlaceholder from "./assets/thumb-placeholder.jpg";
import * as S from "./TransferListing.styled";

interface TransferListingProps {
  id: number;
  searchId: number;
  name: string;
  type: TransferType;
  vehicle: string;
  provider: string;
  maxPassengers: number;
  modificationTime: number;
  cancellationTime: number;
  image: string | null;
  price: number;
  priceNet: number;
  outboundTransfer: TransferOutbound;
  returnTransfer: TransferReturn;
  handleThumbnailClick?: () => void;
  handleBookClick: () => void;
}

const TransferListing = ({
  id,
  searchId,
  name,
  type,
  vehicle,
  provider,
  maxPassengers,
  modificationTime,
  cancellationTime,
  image,
  price,
  priceNet,
  outboundTransfer,
  returnTransfer,
  handleThumbnailClick,
  handleBookClick,
}: TransferListingProps) => {
  const { t } = useTranslation();
  const { search: locationSearch } = useLocation();

  const openModal = useModalContainerStore(state => state.openModal);

  const search = useMemo(() => {
    const params = new URLSearchParams(locationSearch);
    params.delete("useCache");

    return params.toString();
  }, [locationSearch]);

  return (
    <S.Wrapper $elevation={3}>
      <S.ThumbnailWrapper>
        <S.Thumbnail
          onClick={handleThumbnailClick}
          $src={
            image
              ? getImagePathFromCDN(image as string, ImageType.Transfer)
              : thumbPlaceholder
          }
        />
      </S.ThumbnailWrapper>
      <S.Body>
        <S.BodyHeader>
          <S.TitleLink
            to={{ pathname: `/search/transfers/${searchId}/${id}`, search }}
          >
            <h5>{name}</h5>
          </S.TitleLink>
          <span>{vehicle}</span>
        </S.BodyHeader>
        <S.Passengers>
          <IconUsersGroup size={16} />
          <span>
            {t("components.transferListing.maxPassengers", {
              count: maxPassengers,
            })}
          </span>
        </S.Passengers>
        <S.BodyFooter>
          <Button
            variant="outlined"
            onClick={() =>
              openModal({
                title: t("components.transferListing.information"),
                content: (
                  <TransferDetailsModal
                    outboundTransfer={outboundTransfer}
                    returnTransfer={returnTransfer}
                  />
                ),
                closeButton: true,
              })
            }
          >
            {t("components.transferListing.information")}
          </Button>
          <S.Provider>{provider}</S.Provider>
        </S.BodyFooter>
      </S.Body>
      <S.Aside>
        <S.AsideGroup>
          <S.AsideHeader>{t(getTransferTypeName(type))}</S.AsideHeader>
          <S.AsideList>
            <S.AsideItem>
              <IconCircleCheckFilled
                size={16}
                style={{ color: theme.palette.eucalyptus }}
              />
              <span>
                {t("components.transferListing.modificationTime", {
                  hours: modificationTime,
                })}
              </span>
            </S.AsideItem>
            <S.AsideItem>
              <IconCircleCheckFilled
                size={16}
                style={{ color: theme.palette.eucalyptus }}
              />
              <span>
                {t("components.transferListing.cancellationTime", {
                  hours: cancellationTime,
                })}
              </span>
            </S.AsideItem>
          </S.AsideList>
        </S.AsideGroup>
        <S.AsideGroup>
          <S.PriceWrapper>
            <MarkupPrice
              value={{
                total: price,
                net: priceNet,
              }}
            >
              <S.PriceWrapper>
                <Price value={price} />
              </S.PriceWrapper>
            </MarkupPrice>
          </S.PriceWrapper>
          <Button variant="tertiary" fullWidth={true} onClick={handleBookClick}>
            {t("components.transferListing.book")}
          </Button>
        </S.AsideGroup>
      </S.Aside>
    </S.Wrapper>
  );
};

export default TransferListing;
