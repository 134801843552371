import { useAgency } from "@hotelspoint/api";
import {
  Breadcrumb,
  BreadcrumbContainer,
  Button,
  Panel,
  Table,
  TableFooter,
  TableLayout,
} from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { IconPlus } from "@tabler/icons-react";
import { IconSettings, IconUser } from "@tabler/icons-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";

import ProfileSubAgenciesRegisterForm from "../ProfileSubAgenciesRegister/ProfileSubAgenciesRegisterForm";
import getColumns from "./ProfileSubAgencySettings.columns";
import ProfileSubAgencySettingsAgentDialog from "./ProfileSubAgencySettingsAgentDialog";

interface ProfileSubAgencyParams extends Record<string, string> {
  id: string;
}

const ProfileSubAgencySettings = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("profileSubAgencySettings.pageTitle"));

  const { id } = useParams<ProfileSubAgencyParams>();
  const subAgencyId = Number(id);

  const [subAgency, isLoadingSubAgency] = useAgency(subAgencyId);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [targetAgentId, setTargetAgentId] = useState<number | null>(null);

  const handleOpenDialog = useCallback((agentId?: number) => {
    setTargetAgentId(agentId ?? null);
    setIsDialogOpen(true);
  }, []);

  useEffect(() => {
    if (!isDialogOpen) {
      setTargetAgentId(null);
    }
  }, [isDialogOpen]);

  const columns = useMemo(() => {
    return getColumns({ t, handleOpenDialog });
  }, [handleOpenDialog, t]);

  const data = useMemo(() => subAgency?.agents ?? [], [subAgency]);

  const breadcrumbs = useMemo(
    () => [
      {
        title: t("profile.pageTitle"),
        href: "/profile",
        icon: IconUser,
      },
      {
        title: t("profileSubAgenciesSettings.pageTitle"),
        href: "/profile/sub-agencies",
        icon: IconSettings,
      },
      {
        title: t("profileSubAgencySettings.pageTitle"),
        icon: IconSettings,
      },
    ],
    [t],
  );

  if (!subAgencyId) {
    return <Navigate to="/404" replace={true} />;
  }

  return (
    <>
      <BreadcrumbContainer>
        <Breadcrumb items={breadcrumbs} />
      </BreadcrumbContainer>
      <Panel title={t("profileSubAgencySettings.pageTitle")}>
        <ProfileSubAgenciesRegisterForm id={subAgencyId} />
        <TableLayout>
          <Table data={data} columns={columns} isLoading={isLoadingSubAgency} />
          <TableFooter>
            <div style={{ display: "flex" }}>
              <Button
                variant="secondary"
                small
                onClick={() => setIsDialogOpen(true)}
              >
                <IconPlus size={12} />
                <span>{t("profileSubAgencySettings.actions.add")}</span>
              </Button>
              <ProfileSubAgencySettingsAgentDialog
                id={subAgencyId}
                isOpen={isDialogOpen}
                setIsOpen={setIsDialogOpen}
                agentId={targetAgentId}
              />
            </div>
          </TableFooter>
        </TableLayout>
      </Panel>
    </>
  );
};

export default ProfileSubAgencySettings;
