import {
  handleError,
  useFetchAgency,
  useFetchCurrentOffer,
  useFetchProfile,
  useLogin,
} from "@hotelspoint/api";
import {
  Button,
  ButtonText,
  Form,
  FormAdapter,
  FormContext,
  FormInput,
  FormLayout,
  LinkUnderline,
} from "@hotelspoint/components";
import {
  useUserAgencyStore,
  useUserCurrentOfferStore,
  useUserProfileStore,
  useUserTokenStore,
  useUserVerifyTokenStore,
} from "@hotelspoint/store";
import { IconLock, IconMail } from "@tabler/icons-react";
import qs from "query-string";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { FormStage } from "../constants";
import * as S from "./LoginForm.styled";
import {
  defaultValues,
  type FormValues,
  validationSchema,
} from "./LoginForm.util";

interface LoginFormProps {
  setFormStage: (stage: FormStage) => void;
}

const LoginForm = ({ setFormStage }: LoginFormProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = qs.parse(location.search);

  const [isUserLoggingIn, setIsUserLoggingIn] = useState(false);
  const [loginUser] = useLogin();
  const [fetchProfile] = useFetchProfile();
  const [fetchAgency] = useFetchAgency();
  const [fetchCurrentOffer] = useFetchCurrentOffer();

  const setTempToken = useUserVerifyTokenStore(state => state.set);
  const setToken = useUserTokenStore(state => state.set);
  const setProfile = useUserProfileStore(state => state.set);
  const setAgency = useUserAgencyStore(state => state.set);
  const setCurrentOffer = useUserCurrentOfferStore(state => state.set);

  const onSubmit = async (formValues: FormValues) => {
    try {
      setIsUserLoggingIn(true);
      const response = await loginUser(formValues);

      if (response.requireOTP) {
        setTempToken(response.token);
        setFormStage(FormStage.LoginVerify);
        return;
      }

      if (response.token) {
        setToken(response.token);

        const profile = await fetchProfile();
        setProfile(profile);

        const agency = await fetchAgency(profile.agencyId);
        setAgency(agency);

        const offer = await fetchCurrentOffer();
        setCurrentOffer(offer);

        if (queryParams.redirectTo) {
          navigate(queryParams.redirectTo as string);
        } else {
          navigate("/hotels");
        }

        toast.dismiss();
      }
    } catch (error: any) {
      handleError({ t, error });
      setIsUserLoggingIn(false);
    }
  };

  return (
    <>
      <Form<FormValues>
        defaultValues={defaultValues}
        validationSchema={validationSchema}
      >
        <FormLayout>
          <S.Title>{t("home.login.title")}</S.Title>
          <FormAdapter name="email" label={t("home.login.email.label")}>
            {props => (
              <FormInput
                {...props}
                autoComplete="username"
                placeholder={t("home.login.email.placeholder")}
                startAdornment={<IconMail size={18} />}
                disabled={isUserLoggingIn}
              />
            )}
          </FormAdapter>
          <FormAdapter name="password" label={t("home.login.password.label")}>
            {props => (
              <FormInput
                {...props}
                type="password"
                autoComplete="current-password"
                placeholder={t("home.login.password.placeholder")}
                startAdornment={<IconLock size={18} />}
                disabled={isUserLoggingIn}
              />
            )}
          </FormAdapter>
          <S.ResetPassword>
            <ButtonText onClick={() => setFormStage(FormStage.ResetPassword)}>
              {t("home.resetPassword.title")}
            </ButtonText>
          </S.ResetPassword>
          <FormContext<FormValues>
            render={({ handleSubmit }) => (
              <Button
                type="submit"
                variant="secondary"
                fullWidth
                isLoading={isUserLoggingIn}
                onClick={handleSubmit(onSubmit)}
              >
                {t("home.login.submit")}
              </Button>
            )}
          />
        </FormLayout>
      </Form>
      <S.Register>
        <Trans i18nKey="home.login.register">
          Not a member?{" "}
          <LinkUnderline to="/register">Register here</LinkUnderline>
        </Trans>
      </S.Register>
    </>
  );
};

export default LoginForm;
