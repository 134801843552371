import * as S from "./ScoreCategory.styled";
interface ScoreCategoryProps {
  title: string;
  score: number;
}

const ScoreCategory = ({ title, score }: ScoreCategoryProps) => {
  return (
    <S.Wrapper>
      <S.Header>
        <h6>{title}</h6>
        <span>{score.toFixed(1)}</span>
      </S.Header>
      <S.ProgressBarContainer>
        <S.ProgressBar
          score={score}
          style={{ width: `${(score / 10) * 100}%` }}
        />
      </S.ProgressBarContainer>
    </S.Wrapper>
  );
};

export default ScoreCategory;
