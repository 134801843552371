import { MosaicGallery } from "@hotelspoint/components";
import { ImageType } from "@hotelspoint/utils";

interface ProgramDetailsGalleryProps {
  images: string[];
  isLoading: boolean;
}

const ProgramDetailsGallery = ({
  images,
  isLoading,
}: ProgramDetailsGalleryProps) => {
  return (
    <MosaicGallery
      images={images}
      imageType={ImageType.Package}
      isLoading={isLoading}
    />
  );
};

export default ProgramDetailsGallery;
