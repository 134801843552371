import { useHotelDetails } from "@hotelspoint/api";
import { useModalContainerStore } from "@hotelspoint/store";
import useHotelsSearchResultStore from "@hotelspoint/store/src/useHotelsSearchResultStore.ts";
import { mediaQuery } from "@hotelspoint/theme";
import { HotelRate, RateType } from "@hotelspoint/types";
import { getRateTypeName, useMediaQuery } from "@hotelspoint/utils";
import { getImagePathFromCDN, ImageType } from "@hotelspoint/utils";
import { IconCircleCheckFilled, IconMapPinFilled } from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import Button from "../../elements/Button";
import ButtonFavorite from "../../elements/ButtonFavorite";
import ButtonText from "../../elements/ButtonText";
import Checklist from "../../elements/Checklist";
import Rating from "../../elements/Rating";
import TripAdvisorRating, {
  TripAdvisorRatingProps,
} from "../../elements/TripAdvisorRating";
import Price from "../../misc/Price";
import CustomersReview from "../CustomersReview";
import GalleryOverlay from "../GalleryOverlay";
import HotelDetailsModal from "../HotelDetailsModal";
import HotelRoomsModal, { TableType } from "../HotelRoomsModal";
import MarkupPrice from "../MarkupPrice";
import ReviewModal from "../ReviewModal";
import thumbPlaceholder from "./assets/thumb-placeholder.jpg";
import * as S from "./HotelListing.styled";

interface HotelListingProps {
  id: number;
  isActive?: boolean;
  name: string;
  cityName: string;
  thumbnail: string | null;
  rating: number;
  isFavorite: boolean;
  address: string;
  tripAdvisorRating?: TripAdvisorRatingProps;
  bookingRating: number;
  reviews: number;
  rate: HotelRate;
  roomTableType: TableType;
  handleFavorite: (state: boolean) => void;
  handleShowOnMap: () => void;
}

const HotelListing = ({
  id,
  isActive = false,
  name,
  cityName,
  address,
  rating,
  thumbnail,
  isFavorite,
  rate,
  tripAdvisorRating,
  bookingRating,
  reviews,
  roomTableType,
  handleFavorite,
  handleShowOnMap,
}: HotelListingProps) => {
  const { t } = useTranslation();
  const isTablet = useMediaQuery(mediaQuery.tablet);
  const { search: locationSearch } = useLocation();

  const [hotelDetails, isLoadingHotelDetails] = useHotelDetails(id);

  const searchId = useHotelsSearchResultStore(state => state.id);
  const openModal = useModalContainerStore(state => state.openModal);

  const rateOptions = useMemo(
    () => [
      {
        id: `${id}:rate_meal`,
        title: rate.mealTypeName,
        available: true,
      },
      {
        id: `${id}:rate_type`,
        title: t(getRateTypeName(rate.rateType)),
        available: rate.rateType === RateType.Refundable,
      },
    ],
    [t, id, rate.mealTypeName, rate.rateType],
  );

  const search = useMemo(() => {
    const params = new URLSearchParams(locationSearch);
    params.delete("view");
    params.delete("useCache");

    return params.toString();
  }, [locationSearch]);

  return (
    <S.Wrapper $elevation={3} $isActive={isActive}>
      <S.ThumbnailWrapper>
        <S.Thumbnail
          onClick={() =>
            openModal({
              title: t("components.hotelListing.gallery"),
              content: (
                <GalleryOverlay
                  images={hotelDetails?.images ?? []}
                  isLoading={isLoadingHotelDetails}
                  imageType={ImageType.Hotel}
                  initialSlideIndex={0}
                />
              ),
            })
          }
          $src={
            thumbnail
              ? getImagePathFromCDN(thumbnail as string, ImageType.Hotel)
              : thumbPlaceholder
          }
        />
        <S.ThumbnailActions>
          <ButtonFavorite
            active={isFavorite}
            onClick={() => handleFavorite(isFavorite)}
          />
        </S.ThumbnailActions>
      </S.ThumbnailWrapper>
      <S.Body>
        <S.BodyHeader>
          <S.NameWrapper>
            <S.TitleLink to={{ pathname: `/hotels/search/${id}`, search }}>
              <h5>{name}</h5>
            </S.TitleLink>
            <Rating value={rating} />
          </S.NameWrapper>
          <S.AddressWrapper>
            <span>{[address, cityName].join(", ")}</span>
            <ButtonText onClick={() => handleShowOnMap()}>
              <IconMapPinFilled size={18} />
              <span>{t("components.hotelListing.showOnMap")}</span>
            </ButtonText>
          </S.AddressWrapper>
        </S.BodyHeader>
        <S.BodyFooter>
          <Button
            variant="outlined"
            onClick={() =>
              openModal({
                title: t("components.hotelListing.showDetails"),
                content: <HotelDetailsModal id={id} />,
              })
            }
          >
            {t("components.hotelListing.showDetails")}
          </Button>
          {(tripAdvisorRating || bookingRating) && (
            <S.ReviewsWrapper>
              {tripAdvisorRating && (
                <TripAdvisorRating
                  score={tripAdvisorRating.score}
                  reviewsCount={tripAdvisorRating.reviewsCount}
                />
              )}
              <CustomersReview
                bookingRating={bookingRating}
                reviewsCount={reviews}
                onClick={() =>
                  openModal({
                    title: `${t("components.reviewModal.name")} ${name}`,
                    content: <ReviewModal id={id} />,
                    closeButton: true,
                  })
                }
              />
            </S.ReviewsWrapper>
          )}
        </S.BodyFooter>
      </S.Body>
      <S.Aside>
        <S.AsideGroup>
          {rate.rooms.map(room => (
            <S.AsideHeader key={`${id}:${room.id}`}>
              {[room.numRooms, "x", room.roomName].join(" ")}
            </S.AsideHeader>
          ))}
          <Checklist
            items={rateOptions}
            orientation={isTablet ? "vertical" : "horizontal"}
            components={{
              unchecked: IconCircleCheckFilled,
            }}
          />
        </S.AsideGroup>
        <S.AsideGroup>
          <MarkupPrice
            value={{
              total: rate.price,
              net: rate.priceNet,
            }}
          >
            <S.Emphasized>
              {t("components.hotelListing.price.from")}
            </S.Emphasized>
            <S.PriceWrapper>
              <Price value={rate.price} />
            </S.PriceWrapper>
          </MarkupPrice>
          <Button
            variant="tertiary"
            fullWidth={true}
            onClick={() =>
              openModal({
                title: t("components.hotelListing.showRooms"),
                content: (
                  <HotelRoomsModal
                    hotelId={id}
                    searchId={searchId as number}
                    tableType={roomTableType}
                  />
                ),
              })
            }
          >
            {t("components.hotelListing.showRooms")}
          </Button>
        </S.AsideGroup>
      </S.Aside>
    </S.Wrapper>
  );
};

export default HotelListing;
