import { useModalContainerStore } from "@hotelspoint/store";
import { t } from "i18next";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Button from "../../elements/Button";
import Dialog from "../Dialog";
import Modal from "../Modal";

const ModalContainer = () => {
  const location = useLocation();

  const isOpen = useModalContainerStore(state => state.isOpen);
  const closeModal = useModalContainerStore(state => state.closeModal);
  const title = useModalContainerStore(state => state.title);
  const content = useModalContainerStore(state => state.content);
  const closeButton = useModalContainerStore(state => state.closeButton);

  useEffect(() => {
    if (isOpen) {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, closeModal]);

  return (
    <Dialog open={isOpen} onOpenChange={closeModal}>
      <Dialog.Content>
        <Modal title={title} onClose={closeModal}>
          {content}
          {closeButton && (
            <Modal.Actions style={{ justifyContent: "flex-end" }}>
              <Button onClick={closeModal}>
                {t(
                  "searchActivitiesResults.activityOptionsModal.actions.close",
                )}
              </Button>
            </Modal.Actions>
          )}
        </Modal>
      </Dialog.Content>
    </Dialog>
  );
};

export default ModalContainer;
