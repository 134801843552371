import {
  ApiResponseSearchResults,
  PlaceType,
  RateType,
  TransferSearchQuery,
  TransferTripType,
} from "@hotelspoint/types";

import FetchService from "./base/FetchService";

const TRANSFER_API_PATH = "transfers";

export enum TransferDirection {
  Outbound = 1,
  Return = 2,
}

// @todo: can we move to a numbers enum?
export enum TransferType {
  Private = "private",
  Shared = "shared",
}

export enum TransferConfirmationStatus {
  NotConfirmed = 1,
  ConfirmedWithNumber = 2,
  ConfirmedWithoutNumber = 3,
  NoFlightTracking = 4,
}

export enum NamesType {
  LeaderOnly = 1,
  AllPassengers = 2,
}

export interface TransferInner {
  price: number;
  priceNet: number;
  priceBase: number;
  remarks: string[];
  provider: string;
  // @note: The whole model is returned, commented out on purpose
  date: string;
  time: string;
  pickup: {
    date: string;
    time: string;
    images: null;
    remarks: string[];
  };
  // emergencyNumber: null;
}

export interface TransferOutbound extends TransferInner {
  direction: TransferDirection.Outbound;
}

export interface TransferReturn extends TransferInner {
  direction: TransferDirection.Return;
}

export interface Transfer {
  id: number;
  name: string;
  type: TransferType;
  tripType: TransferTripType;
  image: string;
  vehicle: string;
  deadline: string;
  provider: string;
  rateType: RateType;
  modificationTime: number;
  cancellationTime: number;
  minPassengers: number;
  maxPassengers: number;
  price: number;
  priceNet: number;
  priceBase: number;
  outboundTransfer: TransferOutbound;
  returnTransfer: TransferReturn;
  // @note: The whole model is returned, commented out on purpose
  pickUp: string;
  dropOff: string;
  adults: number;
  children: number[];
  names: TransferNames;
  flights: TransferFlights;
  namesType: NamesType;
  phoneCode: string;
  phoneNumber: string;
  // search: null;
}

export interface TransferFlights {
  arrival: string;
  departure: string;
}

export interface TransferNames {
  adults: {
    name: string;
    surname: string;
    title: number;
  }[];
  children: {
    name: string;
    surname: string;
    title: number;
    age: number;
  }[];
}

export interface TransferLocation {
  placeId: number;
  placeType: PlaceType;
}

export interface TransferSearchFilters {
  price: [number, number];
}

export interface TransferSearchFilter {
  id: string;
  count: number;
}

export interface TransferSearchFilterName
  extends TransferSearchFilter,
    TransferSearchFilters {
  name: string;
}

export interface TransferSearchFiltersState {
  price: {
    min: number;
    max: number;
  };
  category?: TransferSearchFilterName[];
  vehicle?: TransferSearchFilterName[];
}

export interface TransferSearchPayload extends TransferSearchQuery {
  filters?: TransferSearchFilters;
  useCache?: boolean;
}

export interface TransferSearchResponse
  extends ApiResponseSearchResults<Transfer> {
  filters: TransferSearchFiltersState;
}

export interface SearchInfoResponse extends TransferSearchPayload {}

const TransferService = {
  search: (payload: TransferSearchPayload) => {
    return FetchService.post<TransferSearchResponse>(
      `${TRANSFER_API_PATH}/availability`,
      payload,
    );
  },
  getSearchInfo: (searchId: number) => {
    return FetchService.get<SearchInfoResponse>(
      `${TRANSFER_API_PATH}/availability/${searchId}`,
    );
  },
  getRate: (searchId: number, rateId: number) => {
    return FetchService.get<Transfer>(
      `${TRANSFER_API_PATH}/availability/${searchId}/rates/${rateId}`,
    );
  },
  getDetails: (id: string) => {
    return FetchService.get<Transfer>(`${TRANSFER_API_PATH}/${id}`);
  },
};

export default TransferService;
