import useHotelsSearchResultStore from "@hotelspoint/store/src/useHotelsSearchResultStore.ts";
import { PaginationOptions } from "@hotelspoint/types";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import { HotelService, HotelsSearchResponse } from "../../services";

const INTERVAL_REFETCH = 1_000;

const useHotelsAvailabilityResult = (params?: PaginationOptions) => {
  const searchId = useHotelsSearchResultStore(state => state.id);
  const hotelsCount = useHotelsSearchResultStore(state => state.count);
  const setResult = useHotelsSearchResultStore(state => state.setResponse);
  const setLoading = useHotelsSearchResultStore(state => state.setLoading);
  const setCompleted = useHotelsSearchResultStore(state => state.setCompleted);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["hotels_search", searchId, params],
    queryFn: () => HotelService.getResultBySearchId(searchId as number, params),
    enabled: searchId !== null,
    refetchInterval: query => {
      const data = query.state.data;

      if (data?.isCompleted) return false;

      return INTERVAL_REFETCH;
    },
  });

  useEffect(() => {
    if (data && data.count !== hotelsCount) {
      setResult(data as HotelsSearchResponse);
    }
    if (data && data.count === hotelsCount && data.isCompleted) {
      setCompleted(data.isCompleted);
    }
  }, [hotelsCount, data, setResult, setCompleted]);

  useEffect(() => {
    if (searchId !== null) {
      setLoading(isLoading || isFetching);
    }
  }, [searchId, isLoading, isFetching, setLoading]);
};

export default useHotelsAvailabilityResult;
