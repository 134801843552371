import {
  HotelSearchInfoResponse,
  PaymentType,
  ReservationHotelCreatePayload,
  ReservationNames,
  Titles,
} from "@hotelspoint/api";
import { COUNTRY_CODE_BG } from "@hotelspoint/constants";
import { Agency, HotelRate, RateType, UserCurrency } from "@hotelspoint/types";
import { formatDateTimeIso, setTimeInDate } from "@hotelspoint/utils";
import { array, boolean, InferType, mixed, number, object, string } from "yup";

import { FormType } from "./types";

const personSchema = object({
  title: string().required(
    "searchHotelBook.content.form.title.validation.required",
  ),
  name: string().required(
    "searchHotelBook.content.form.firstName.validation.required",
  ),
  surname: string().required(
    "searchHotelBook.content.form.lastName.validation.required",
  ),
  age: number(),
}).required();

const requiredIfHasTransfers = (message: string) => {
  return string().when("hasTransfers", {
    is: true,
    then: schema => schema.required(message),
  });
};

export const defaultValues = {
  type: FormType.AllPassengers,
  leader: [],
  guests: [],
  children: [],
  // Read-only
  hasTransfers: false,
  isNonrefundable: false,
  arrivalFlightNumber: "",
  arrivalFlightDate: undefined,
  arrivalFlightTime: "",
  returnFlightNumber: "",
  returnFlightDate: undefined,
  returnFlightTime: "",
  paymentType: PaymentType.CreditLine,
  confirmCreditLine: false,
  currency: UserCurrency.EUR,
};

export const validationSchema = object()
  .shape({
    type: mixed<FormType>().oneOf(
      Object.values(FormType).map(e => e as FormType),
    ),
    leader: array().when("type", {
      is: FormType.LeaderOnly,
      then: schema => schema.of(array().of(personSchema)).required(),
    }),
    guests: array().when("type", {
      is: FormType.AllPassengers,
      then: schema => schema.of(array().of(personSchema)).required(),
    }),
    children: array().when("type", {
      is: FormType.AllPassengers,
      then: schema => schema.of(array().of(personSchema)).required(),
    }),
    // Read-only
    hasTransfers: boolean(),
    isNonrefundable: boolean(),
    arrivalFlightNumber: requiredIfHasTransfers(
      "searchHotelBook.content.form.arrivalFlightNumber.validation.required",
    ),
    arrivalFlightDate: requiredIfHasTransfers(
      "searchHotelBook.content.form.arrivalFlightDate.validation.required",
    ),
    arrivalFlightTime: requiredIfHasTransfers(
      "searchHotelBook.content.form.arrivalFlightTime.validation.required",
    ),
    returnFlightNumber: requiredIfHasTransfers(
      "searchHotelBook.content.form.returnFlightNumber.validation.required",
    ),
    returnFlightDate: requiredIfHasTransfers(
      "searchHotelBook.content.form.returnFlightDate.validation.required",
    ),
    returnFlightTime: requiredIfHasTransfers(
      "searchHotelBook.content.form.returnFlightTime.validation.required",
    ),
    paymentType: number().when("isNonrefundable", {
      is: true,
      then: schema =>
        schema.required("components.paymentOptions.type.validation.required"),
    }),
    confirmCreditLine: boolean().when("paymentType", {
      is: (type: string) => Number(type) === PaymentType.CreditLine,
      then: schema => {
        return schema
          .required()
          .oneOf(
            [true],
            "components.paymentOptions.confirmCreditLine.validation.required",
          );
      },
    }),
    currency: string().when("paymentType", {
      is: (type: string) => Number(type) === PaymentType.CreditCard,
      then: schema => {
        return schema.required(
          "components.paymentOptions.currency.validation.required",
        );
      },
    }),
  })
  .required();

export type FormValues = InferType<typeof validationSchema>;

export const form2Entity = (
  values: FormValues,
  roomIds: number[],
): Pick<
  ReservationHotelCreatePayload,
  | "names"
  | "paymentType"
  | "paymentCurrency"
  | "arrivalFlightNumber"
  | "arrivalFlightDate"
  | "returnFlightNumber"
  | "returnFlightDate"
> => {
  const flightDetails = values.hasTransfers
    ? {
        arrivalFlightNumber: values.arrivalFlightNumber,
        arrivalFlightDate: formatDateTimeIso(
          setTimeInDate(
            new Date(values.arrivalFlightDate as string),
            values.arrivalFlightTime as string,
          ),
        ),
        returnFlightNumber: values.returnFlightNumber,
        returnFlightDate: formatDateTimeIso(
          setTimeInDate(
            new Date(values.returnFlightDate as string),
            values.returnFlightTime as string,
          ),
        ),
      }
    : {};

  if (values.type === FormType.LeaderOnly && values.leader) {
    return {
      names: values.leader.map((leader, roomId) => ({
        roomId: roomIds[roomId],
        adults: leader,
        children: [],
      })),
      ...flightDetails,
      paymentType: values.paymentType,
      paymentCurrency: values.currency,
    };
  }

  return {
    names: values.guests?.map((adults, roomId) => ({
      roomId: roomIds[roomId],
      adults,
      children: values.children?.[roomId] || [],
    })) as ReservationNames[],
    ...flightDetails,
    paymentType: values.paymentType,
    paymentCurrency: values.currency,
  };
};

export const entity2Form = (
  hotelRate: HotelRate,
  searchInfo: HotelSearchInfoResponse,
  agency: Agency | null,
): FormValues => {
  return {
    type: FormType.AllPassengers,
    leader: [],
    guests: hotelRate.rooms
      .map((room: any) =>
        Array.from({ length: room.numRooms }).map(() =>
          Array.from({ length: room.adults }).map(
            (_value: any, index: number) => ({
              title: index % 2 !== 0 ? Titles.Mrs : Titles.Mr,
              name: "",
              surname: "",
            }),
          ),
        ),
      )
      .flat(),
    children: hotelRate.rooms
      .map((room: any) =>
        Array.from({ length: room.numRooms }).map(() =>
          room.children.map((child: any, index: number) => ({
            title: index % 2 !== 0 ? Titles.Mrs : Titles.Mr,
            name: "",
            surname: "",
            age: child,
          })),
        ),
      )
      .flat(),
    hasTransfers: hotelRate.hasTransfers,
    isNonrefundable: hotelRate.rateType === RateType.NonRefundable,
    arrivalFlightNumber: "",
    arrivalFlightDate: searchInfo.search.checkIn,
    arrivalFlightTime: "",
    returnFlightNumber: "",
    returnFlightDate: searchInfo.search.checkOut,
    returnFlightTime: "",
    paymentType:
      hotelRate.rateType === RateType.NonRefundable
        ? (agency?.credit?.limit ?? 0) > 0
          ? PaymentType.CreditLine
          : PaymentType.CreditCard
        : 0,
    confirmCreditLine: false,
    currency:
      `${agency?.countryCode}`.toLowerCase() === COUNTRY_CODE_BG
        ? UserCurrency.BGN
        : UserCurrency.EUR,
  };
};
