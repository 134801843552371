import { usePlace, usePlaces } from "@hotelspoint/api";
import {
  Button,
  Form,
  FormAdapter,
  FormAutoComplete,
  FormAutoCompleteOption,
  FormChildrenMethods,
  FormContext,
  FormDatePickerRange,
  FormLayout,
  FormPersonSelector,
  OptionGroup,
  PlaceTypeIcon,
} from "@hotelspoint/components";
import { DEBOUNCE_DELAY_INPUT } from "@hotelspoint/constants";
import { useRecentActivitiesSearchesStore } from "@hotelspoint/store";
import { ActivitySearchQuery, PlaceType } from "@hotelspoint/types";
import { getPlaceTypeName } from "@hotelspoint/utils";
import flatten from "lodash/flatten";
import isEqual from "lodash/isEqual";
import qs from "query-string";
import { useCallback, useEffect, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";

import { PERSON_SELECTOR_CONFIG, PLACE_TYPE_ORDER } from "../../constants";
import {
  form2Entity,
  FormValues,
  validationSchema,
} from "../../SearchActivitiesForm/SearchActivitiesForm.form";
import * as S from "../../SearchActivitiesForm/SearchActivitiesForm.styled";
import useActivitySearchParams from "../../useActivitySearchParams";
import { entity2Form } from "./SearchActivitiesForm.util";

type PlaceOption = Required<FormAutoCompleteOption<{ type: PlaceType }>>;

interface SearchActivitiesFormParams extends Record<string, string> {
  id: string;
}

interface SearchActivitiesFormProps {
  refetch: () => Promise<any>;
}

interface SearchActivitiesFormInnerProps
  extends SearchActivitiesFormProps,
    FormChildrenMethods<FormValues> {}

const SearchActivitiesFormInner = ({
  refetch,
  setValue,
  getValues,
}: SearchActivitiesFormInnerProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id } = useParams<SearchActivitiesFormParams>();

  const [query, setQuery] = useActivitySearchParams();

  const dateToday = useMemo(() => new Date(), []);
  const currentYear = useMemo(() => dateToday.getFullYear(), [dateToday]);

  const [search, place, dates] = useWatch({
    name: ["search", "place", "dates"],
  });

  const [debouncedSearch] = useDebounce(search, DEBOUNCE_DELAY_INPUT);

  const [places, isLoadingPlaces] = usePlaces({
    types: [PlaceType.Area, PlaceType.Destination, PlaceType.City],
    search: debouncedSearch ?? "",
  });

  const [placeDetails, isLoadingPlaceDetails] = usePlace({
    id: place?.id as number,
    type: place?.type as PlaceType,
  });

  const recentSearchesStore = useRecentActivitiesSearchesStore(state => state);

  const placeGroupOptions: OptionGroup<PlaceOption>[] = useMemo(() => {
    if (!places || isLoadingPlaces) return [];

    const groups = Object.entries(places)
      .map(([type, places]) => {
        return {
          label: t(getPlaceTypeName(type as PlaceType)),
          value: type as PlaceType,
          options: places?.map(p => ({
            value: p.id,
            label: p.name,
            meta: {
              type: type as PlaceType,
            },
          })),
        };
      })
      .filter(group => group.options.length > 0);

    return groups.sort((a, b) => {
      return (
        PLACE_TYPE_ORDER.indexOf(a.value) - PLACE_TYPE_ORDER.indexOf(b.value)
      );
    });
  }, [t, places, isLoadingPlaces]);

  const placeOptionsFlat = useMemo(() => {
    return flatten(placeGroupOptions.map(group => group.options));
  }, [placeGroupOptions]);

  const defaultMonth = useMemo(() => {
    const initialDates = getValues("dates") || dates;

    if (initialDates?.from) {
      const { from } = initialDates;
      return new Date(from.getFullYear(), from.getMonth());
    }

    return new Date(currentYear, dateToday.getMonth());
  }, [currentYear, dateToday, dates, getValues]);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      const payload = form2Entity(formValues);

      const newQuery = {
        ...payload,
        useCache: false,
      };

      const placeOption = placeOptionsFlat.find(
        p => p?.value === payload.placeId,
      );

      if (placeOption) {
        recentSearchesStore.add({
          ...newQuery,
          meta: {
            placeName: placeOption?.label as string,
          },
        });
      }

      if (isEqual(query, newQuery)) {
        refetch();
      } else {
        if (id) {
          const search = qs.stringify({
            ...payload,
            children: JSON.stringify(payload.children),
          });
          navigate({
            pathname: "/search/activities",
            search,
          });
        } else {
          setQuery(payload);
        }
      }
    },
    [
      id,
      navigate,
      placeOptionsFlat,
      query,
      recentSearchesStore,
      refetch,
      setQuery,
    ],
  );

  const handleSelectPlace = useCallback(
    (option: any) => {
      if (option) {
        setValue("place", {
          id: option.value,
          type: option.meta.type,
        });
      } else {
        setValue("place", {});
      }
    },
    [setValue],
  );

  useEffect(() => {
    if (place?.id && placeDetails?.name) {
      setValue("search", placeDetails?.name);
    }
  }, [place?.id, placeDetails?.name, setValue]);

  return (
    <FormLayout>
      <FormAdapter name="search" label={t("searchActivities.search.label")}>
        {props => (
          <FormAutoComplete
            {...props}
            placeholder={t("searchActivities.search.placeholder")}
            options={placeGroupOptions}
            isLoading={isLoadingPlaces || isLoadingPlaceDetails}
            renderOption={option => (
              <S.PlaceOption key={option.value}>
                <PlaceTypeIcon
                  type={option.meta.type}
                  iconProps={{ size: 22 }}
                />
                <span>{option.label}</span>
              </S.PlaceOption>
            )}
            onSelect={handleSelectPlace}
          />
        )}
      </FormAdapter>
      <FormAdapter name="dates" label={t("searchActivities.dates.label")}>
        {props => (
          <FormDatePickerRange
            {...props}
            placeholder={t("searchActivities.dates.placeholder")}
            dayPickerProps={{
              disabled: [{ before: dateToday }],
              defaultMonth: defaultMonth,
              fromYear: currentYear,
            }}
          />
        )}
      </FormAdapter>
      <FormAdapter
        name="travelers"
        label={t("searchActivities.travelers.label")}
      >
        {props => (
          <FormPersonSelector
            {...props}
            placeholder={t("searchActivities.travelers.placeholder")}
            config={PERSON_SELECTOR_CONFIG}
          />
        )}
      </FormAdapter>
      <FormContext<FormValues>
        render={({ handleSubmit }) => (
          <Button
            type="submit"
            variant="secondary"
            fullWidth
            onClick={handleSubmit(onSubmit)}
          >
            {t("searchActivities.submit")}
          </Button>
        )}
      />
    </FormLayout>
  );
};

const SearchActivitiesForm = (props: SearchActivitiesFormProps) => {
  const [searchParams] = useActivitySearchParams();

  const formValues = useMemo(
    () => entity2Form(searchParams as ActivitySearchQuery),
    [searchParams],
  );

  return (
    <Form<FormValues>
      defaultValues={formValues}
      validationSchema={validationSchema}
    >
      {formMethods => <SearchActivitiesFormInner {...props} {...formMethods} />}
    </Form>
  );
};

export default SearchActivitiesForm;
