import { useProductDetails } from "@hotelspoint/api";
import { StatusFlavour, StatusText } from "@hotelspoint/components";
import { Services, ServicesType } from "@hotelspoint/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import { ProgramDetailsGallery } from "./components";
import * as S from "./ProgramDetailsSection.styled";

interface ProgramDetailsSectionProps {
  productId: number;
}

const ProgramDetailsSection = ({ productId }: ProgramDetailsSectionProps) => {
  const { t } = useTranslation();

  const [productDetails, isLoadingProductDetails] =
    useProductDetails(productId);

  const filterServicesByType = (type: ServicesType, services?: Services[]) => {
    return services?.filter(service => service.type === type);
  };

  const includedServices = useMemo(
    () => filterServicesByType(ServicesType.Included, productDetails?.services),
    [productDetails?.services],
  );

  const optionalServices = useMemo(
    () => filterServicesByType(ServicesType.Optional, productDetails?.services),
    [productDetails?.services],
  );

  const renderSkeletonList = () => (
    <>
      <S.ServiceHeadline>
        <Skeleton />
      </S.ServiceHeadline>
      <S.ServiceList>
        {[...Array(3)].map((_, index) => (
          <S.FlexGrow key={index}>
            <Skeleton />
          </S.FlexGrow>
        ))}
      </S.ServiceList>
    </>
  );

  const renderSkeletonDay = () => (
    <>
      {[...Array(3)].map((_, index) => (
        <S.DayWrapper key={index}>
          <S.DayHeader>
            <S.DayBadge>
              <S.FlexGrow>
                <Skeleton />
              </S.FlexGrow>
            </S.DayBadge>
            <h6>
              <Skeleton />
            </h6>
          </S.DayHeader>
          <p>
            <Skeleton count={3} />
          </p>
        </S.DayWrapper>
      ))}
    </>
  );

  return (
    <>
      <S.Title>
        {t("searchPackages.programDetails.stayIn")} {productDetails?.name}
      </S.Title>
      <S.Wrapper>
        <S.Subtitle>{t("searchPackages.programDetails.services")}</S.Subtitle>
        {isLoadingProductDetails ? (
          renderSkeletonList()
        ) : includedServices && includedServices.length > 0 ? (
          <>
            <S.ServiceHeadline>
              {t("searchPackages.programDetails.thePriceIncludes")}
            </S.ServiceHeadline>
            <S.ServiceList>
              {includedServices.map(service => (
                <StatusText key={service.id} status={StatusFlavour.Success}>
                  {service.name}
                </StatusText>
              ))}
            </S.ServiceList>
          </>
        ) : null}

        {isLoadingProductDetails ? (
          renderSkeletonList()
        ) : includedServices && includedServices.length > 0 ? (
          <>
            <S.ServiceHeadline>
              {t("searchPackages.programDetails.optionalServices")}
            </S.ServiceHeadline>
            <S.ServiceList>
              {optionalServices?.map(service => (
                <StatusText key={service.id} status={StatusFlavour.Optional}>
                  {service.name}
                </StatusText>
              ))}
            </S.ServiceList>
          </>
        ) : null}
        <S.Divider />
        <S.Subtitle>{t("searchPackages.programDetails.itinerary")}</S.Subtitle>
        {isLoadingProductDetails ? (
          renderSkeletonDay()
        ) : productDetails && productDetails?.itineraries.length > 0 ? (
          <>
            {productDetails?.itineraries.map((day, dayIndex) => (
              <S.DayWrapper key={dayIndex}>
                <S.DayHeader>
                  <S.DayBadge>
                    {t("searchPackages.programDetails.day")} {day.day}
                  </S.DayBadge>
                  <h6>{day.title}</h6>
                </S.DayHeader>
                <p>{day.description}</p>
              </S.DayWrapper>
            ))}
          </>
        ) : null}
      </S.Wrapper>
      <S.Title>{t("searchPackages.programDetails.tripImages")}</S.Title>
      <ProgramDetailsGallery
        images={productDetails?.images ?? []}
        isLoading={isLoadingProductDetails}
      />
    </>
  );
};

export default ProgramDetailsSection;
