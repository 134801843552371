import FormInput, { FormInputProps } from "../FormInput";
import * as S from "./FormInputRange.styled";

type FormInputRangeProps = FormInputProps;

const FormInputRange = ({
  name,
  value,
  onChange,
  ...props
}: FormInputRangeProps) => {
  const [start, end] = value ?? [];

  return (
    <S.Wrapper>
      <FormInput
        {...props}
        id={`${name}-start`}
        name={`${name}-start`}
        type="number"
        style={{ borderRadius: "3px 0 0 3px" }}
        value={start}
        onChange={e => onChange([Number(e.target.value), end])}
      />
      <FormInput
        {...props}
        id={`${name}-end`}
        name={`${name}-end`}
        type="number"
        style={{
          borderRadius: "0 3px 3px 0",
          borderLeftColor: "transparent",
        }}
        value={end}
        onChange={e => onChange([start, Number(e.target.value)])}
      />
    </S.Wrapper>
  );
};

export default FormInputRange;
