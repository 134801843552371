import { toFixedPrecise } from "@hotelspoint/utils";
import { toDecimal } from "dinero.js";

function createFormatter(transformer: any): any {
  return function formatter(dineroObject: any, enablePriceRounding: boolean) {
    return toDecimal(dineroObject, data =>
      transformer(data, enablePriceRounding),
    );
  };
}

export const format = createFormatter(
  ({ value, currency }: any, enablePriceRounding: boolean) => {
    return enablePriceRounding
      ? `${Math.floor(value)} ${currency.code}`
      : `${toFixedPrecise(value, 2)} ${currency.code}`;
  },
);
