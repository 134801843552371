import { useQuery } from "@tanstack/react-query";

import { type PackageProductsListParams, PackageService } from "../../services";

const useProductsList = (params: PackageProductsListParams) => {
  const { data, isLoading } = useQuery({
    queryKey: ["products", params],
    queryFn: () => PackageService.getProductsList(params),
    enabled: !!params.destinationId,
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useProductsList;
