import { Paper } from "@hotelspoint/components";
import { mediaQuery, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

export const TransferListingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(2)}px;
  margin-top: ${spacing(3)}px;
`;

export const LoadMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${spacing(2)}px;
`;

export const SidebarCard = styled(Paper)`
  padding: ${spacing(1.5)}px;
`;

export const VisibleUntilDesktop = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(2)}px;
  margin-bottom: ${spacing(2)}px;
  @media ${mediaQuery.desktop} {
    display: none;
  }
`;
