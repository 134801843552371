import { PaymentCreatePayload, PaymentType } from "@hotelspoint/api";
import { COUNTRY_CODE_BG } from "@hotelspoint/constants";
import { Agency, UserCurrency } from "@hotelspoint/types";
import { createSubmittableForm } from "@hotelspoint/utils";
import { boolean, InferType, mixed, number, object, string } from "yup";

const FILE_MAX_SIZE = 20971520; // 20MB

const FILE_ALLOWED_TYPES = ["application/pdf"];

export const validationSchema = object({
  paymentType: number().required(
    "components.paymentOptions.type.validation.required",
  ),
  confirmCreditLine: boolean().when("paymentType", {
    is: (type: string) => Number(type) === PaymentType.CreditLine,
    then: schema => {
      return schema
        .required()
        .oneOf(
          [true],
          "components.paymentOptions.confirmCreditLine.validation.required",
        );
    },
  }),
  file: mixed<File>().when("paymentType", {
    is: (type: string) => Number(type) === PaymentType.BankTransfer,
    then: schema => {
      return schema
        .test(
          "size",
          "components.paymentOptions.file.validation.size",
          (file?: File) => {
            if (!file) return true;

            return file.size <= FILE_MAX_SIZE;
          },
        )
        .test(
          "format",
          "components.paymentOptions.file.validation.format",
          (file?: File) => {
            if (!file) return true;

            return FILE_ALLOWED_TYPES.includes(file.type);
          },
        )
        .required("components.paymentOptions.file.validation.required");
    },
  }),
  currency: string().when("paymentType", {
    is: (type: string) => Number(type) === PaymentType.CreditCard,
    then: schema => {
      return schema.required(
        "components.paymentOptions.currency.validation.required",
      );
    },
  }),
  fileId: number(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: Partial<FormValues> = {
  paymentType: PaymentType.CreditLine,
  confirmCreditLine: false,
  file: undefined,
  currency: undefined,
};

export const entity2Form = ({ countryCode }: Pick<Agency, "countryCode">) => ({
  ...defaultValues,
  currency:
    `${countryCode}`.toLowerCase() === COUNTRY_CODE_BG
      ? UserCurrency.BGN
      : UserCurrency.EUR,
});

export const form2Entity = (
  formValues: FormValues,
): Omit<PaymentCreatePayload, "reservationId"> => {
  switch (formValues.paymentType) {
    case PaymentType.CreditLine:
      return {
        paymentType: formValues.paymentType,
      };

    case PaymentType.BankTransfer:
      return {
        paymentType: formValues.paymentType,
        paymentFileId: formValues.fileId,
      };

    case PaymentType.CreditCard:
      return {
        paymentType: formValues.paymentType,
        paymentCurrency: formValues.currency as UserCurrency,
      };

    default:
      throw new Error("Invalid payment type");
  }
};

export const redirectCardPayment = (
  paymentUrl: string,
  paymentUrlParams: any,
): void => {
  const form = createSubmittableForm(paymentUrl, paymentUrlParams);
  document.body.appendChild(form);
  form.submit();
};
