import { pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { theme } from "styled-tools";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 220px;
  gap: ${spacing(1.25)}px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: ${pxToRem(14)};
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 8px;
  background: ${theme("palette.mischka")};
  border-radius: 4px;
  overflow: hidden;
`;

export const ProgressBar = styled.div<{ score: number }>`
  height: 100%;
  background: ${({ score }) =>
    score >= 7 ? theme("palette.sky") : theme("palette.red")};
`;
