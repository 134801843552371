import { useActivityOptions } from "@hotelspoint/api";
import { useState } from "react";

import CalendarSelector from "../../elements/CalendarSelector";
import ActivityTicketsSection from "../ActivityTicketsSection";
import * as S from "./ActivityOptionsModal.styled";
import useActivitySearchParams from "./useActivitySearchParams";

interface ActivityOptionsModalProps {
  activityId: number;
  searchId: number;
}

const ActivityOptionsModal = ({
  activityId,
  searchId,
}: ActivityOptionsModalProps) => {
  const [query] = useActivitySearchParams();

  const [selectedDate, setSelectedDate] = useState<string>(
    query.dateIn as string,
  );

  const [activityOptions, isLoadingActivityOptions] = useActivityOptions({
    searchId: searchId,
    id: activityId,
    date: selectedDate,
  });

  return (
    <>
      <CalendarSelector
        value={selectedDate}
        onChange={setSelectedDate}
        range={{
          from: new Date(query.dateIn as string),
          to: new Date(query.dateOut as string),
        }}
        calendar={activityOptions?.calendar as Record<string, number>}
        isLoading={isLoadingActivityOptions}
      />
      {activityOptions && (
        <>
          <S.Divider />
          <ActivityTicketsSection
            id={activityId}
            searchId={searchId}
            options={activityOptions}
            selectedDate={selectedDate}
          />
        </>
      )}
    </>
  );
};

export default ActivityOptionsModal;
