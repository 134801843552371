import { useHotelRateFees } from "@hotelspoint/api";
import { zIndex } from "@hotelspoint/theme";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Tooltip from "../../Tooltip";
import * as S from "./CancellationFeesTooltip.styled";

interface CancellationFeesTooltipProps {
  searchId?: number;
  hotelId?: number;
  rateId: number;
  refundable: boolean;
  children: React.ReactNode;
}

const CancellationFeesTooltip = ({
  searchId,
  hotelId,
  rateId,
  refundable,
  children,
}: CancellationFeesTooltipProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const isEnabled = isOpen && refundable;
  const [hotelRateFees, isLoadingHotelRateFees] = useHotelRateFees(
    isEnabled ? searchId : undefined,
    isEnabled ? hotelId : undefined,
    isEnabled ? rateId : undefined,
  );

  return (
    <Tooltip open={isOpen} onOpenChange={setIsOpen}>
      <Tooltip.Trigger>{children}</Tooltip.Trigger>
      <Tooltip.Content style={{ zIndex: zIndex.cancellationFeesTooltip }}>
        {refundable ? (
          isLoadingHotelRateFees ? (
            <S.LoadingIcon size={16} />
          ) : (
            t(
              "searchHotelResults.cancellationFeesTooltip.freeCancelationUntil",
              {
                date: hotelRateFees?.deadline,
              },
            )
          )
        ) : (
          t("searchHotelResults.cancellationFeesTooltip.nonRefundable")
        )}
      </Tooltip.Content>
    </Tooltip>
  );
};

export default CancellationFeesTooltip;
