import {
  handleError,
  useCreateSubAgencyStatement,
  useSubAgencyStatementItems,
  useSubAgencyStatementOptions,
} from "@hotelspoint/api";
import {
  add,
  Box,
  Breadcrumb,
  BreadcrumbContainer,
  Button,
  Chip,
  Flex,
  Form,
  FormAdapter,
  FormChildrenMethods,
  FormContext,
  FormErrorMessage,
  FormInput,
  FormLayout,
  FormSelect,
  LoaderBlock,
  Panel,
  parseRowSelectionToValue,
  parseValueToRowSelection,
  priceToDecimal,
} from "@hotelspoint/components";
import { AGENCY_STATEMENTS_STATUS_OPTIONS } from "@hotelspoint/constants";
import { UserCurrency } from "@hotelspoint/types";
import {
  getAgencyStatementStatusColor,
  useDocumentTitle,
} from "@hotelspoint/utils";
import { IconFileInvoice, IconUser } from "@tabler/icons-react";
import { RowSelectionState } from "@tanstack/react-table";
import isEmpty from "lodash/isEmpty";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import ProfileSubAgenciesStatementsItems from "../ProfileSubAgenciesStatementsItems";
import {
  defaultValues,
  form2Entity,
  FormValues,
  validationSchema,
} from "./ProfileSubAgenciesStatementsCreate.util";

const ProfileSubAgenciesStatementsCreateInner = ({
  setValue,
}: FormChildrenMethods<FormValues>) => {
  const { t } = useTranslation();
  useDocumentTitle(t("profileSubAgenciesStatementsCreate.pageTitle"));

  const navigate = useNavigate();

  const [agencyId] = useWatch({ name: ["agencyId"] });

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const [createStatement, isCreating] = useCreateSubAgencyStatement();
  const [agencies, isLoadingAgencies] = useSubAgencyStatementOptions();

  const [items, isLoadingItems] = useSubAgencyStatementItems(agencyId);

  const agencyOptions = useMemo(() => {
    if (!agencies || isLoadingAgencies) return [];

    return agencies.map(agency => ({
      value: agency.id,
      label: agency.name,
    }));
  }, [agencies, isLoadingAgencies]);

  const statusOptions = useMemo(
    () =>
      AGENCY_STATEMENTS_STATUS_OPTIONS.map(status => ({
        ...status,
        label: (
          <Chip $color={getAgencyStatementStatusColor(status.value)}>
            {t(status.label)}
          </Chip>
        ),
      })),
    [t],
  );

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const payload = form2Entity(formValues);

        const { id } = await createStatement(payload);
        toast.success(
          t("toast.profileSubAgenciesStatementsCreate.createStatement"),
        );

        navigate(`/profile/sub-agencies-statements/agencies/${id}`);
      } catch (error: any) {
        handleError({ t, error });
      }
    },
    [t, createStatement, navigate],
  );

  // Based on the row selection, update the form values
  useEffect(() => {
    if (isEmpty(rowSelection)) {
      setValue("paymentIds", []);
      setValue("amount", 0);
    } else {
      const ids = parseRowSelectionToValue(rowSelection);
      setValue("paymentIds", ids);

      if (Array.isArray(items)) {
        const filteredItems = items.filter(item => ids.includes(item.id));

        if (filteredItems.length !== 0) {
          const totalSum = add(
            ...(filteredItems.map(item => item.amount) as number[]),
          );

          setValue("amount", priceToDecimal(totalSum));
        }
      }
    }
  }, [rowSelection, setValue, items]);

  useEffect(() => {
    if (items) {
      // All items are selected by default
      setRowSelection(
        parseValueToRowSelection(items?.map(item => `${item.id}`)),
      );
    } else {
      setValue("paymentIds", []);
    }
  }, [items, setValue]);

  const breadcrumbs = useMemo(
    () => [
      {
        title: t("profile.pageTitle"),
        href: "/profile",
        icon: IconUser,
      },
      {
        title: t("profileSubAgenciesStatements.pageTitle"),
        href: "/profile/offers",
        icon: IconFileInvoice,
      },
      {
        title: t("profileSubAgenciesStatementsCreate.pageTitle"),
        icon: IconFileInvoice,
      },
    ],
    [t],
  );

  if (isLoadingAgencies) {
    return <LoaderBlock />;
  }

  return (
    <>
      <BreadcrumbContainer>
        <Breadcrumb items={breadcrumbs} />
      </BreadcrumbContainer>

      <Panel title={t("profileSubAgenciesStatementsCreate.pageTitle")}>
        <FormLayout>
          <Flex mx={[0, 0, -1, -1]}>
            <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="agencyId"
                label={t(
                  "profileSubAgenciesStatementsCreate.form.agencyId.label",
                )}
              >
                {props => (
                  <FormSelect
                    {...props}
                    placeholder={t(
                      "profileSubAgenciesStatementsCreate.form.agencyId.placeholder",
                    )}
                    options={agencyOptions}
                    isClearable
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="amount"
                label={t(
                  "profileSubAgenciesStatementsCreate.form.amount.label",
                )}
              >
                {props => (
                  <FormInput
                    {...props}
                    readOnly
                    adornmentStyle="filled"
                    endAdornment={UserCurrency.EUR}
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="status"
                label={t(
                  "profileSubAgenciesStatementsCreate.form.status.label",
                )}
              >
                {props => (
                  <FormSelect {...props} options={statusOptions} readOnly />
                )}
              </FormAdapter>
            </Box>
          </Flex>
          <FormContext<FormValues>
            render={({ handleSubmit }) => (
              <Button
                type="submit"
                variant="secondary"
                isLoading={isCreating}
                onClick={handleSubmit(onSubmit)}
              >
                {t("profileSubAgenciesStatementsCreate.submit")}
              </Button>
            )}
          />
          <FormErrorMessage name="paymentIds" />
          <ProfileSubAgenciesStatementsItems
            data={items ?? []}
            isLoading={isLoadingItems}
            useReactTableProps={{
              enableRowSelection: true,
              onRowSelectionChange: setRowSelection,
              state: {
                rowSelection,
              },
            }}
          />
        </FormLayout>
      </Panel>
    </>
  );
};

const ProfileSubAgenciesStatementsCreate = () => {
  return (
    <Form<FormValues>
      defaultValues={defaultValues}
      validationSchema={validationSchema}
    >
      {formMethods => (
        <ProfileSubAgenciesStatementsCreateInner {...formMethods} />
      )}
    </Form>
  );
};

export default ProfileSubAgenciesStatementsCreate;
