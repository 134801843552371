import { Marker } from "react-map-gl";

import * as S from "./MapCluster.styled";

interface MapClusterProps {
  latitude: number;
  longitude: number;
  count: number;
  totalCount: number;
  onClick: () => void;
}

const MapCluster = ({
  latitude,
  longitude,
  count,
  totalCount,
  onClick,
}: MapClusterProps) => {
  return (
    <Marker latitude={latitude} longitude={longitude}>
      <S.Cluster $count={count} $totalCount={totalCount} onClick={onClick}>
        <span>{count}</span>
      </S.Cluster>
    </Marker>
  );
};

export default MapCluster;
