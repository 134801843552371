import { usePlace } from "@hotelspoint/api";
import useHotelsSearchResultStore from "@hotelspoint/store/src/useHotelsSearchResultStore.ts";
import { PlaceType } from "@hotelspoint/types";
import { t } from "i18next";
import Skeleton from "react-loading-skeleton";

import useHotelSearchParams from "../../useHotelSearchParams.ts";

const SearchHotelsTitle = () => {
  const [query] = useHotelSearchParams();

  const count = useHotelsSearchResultStore(state => state.count);
  const isCompleted = useHotelsSearchResultStore(state => state.isCompleted);

  const [place, isLoadingPlace] = usePlace({
    id: query.placeId as number,
    type: query.placeType as PlaceType,
  });

  if (isLoadingPlace) {
    return <Skeleton width={120} height={10} />;
  }

  if (isCompleted) {
    return (
      <h3>
        {t("searchHotelResults.count", {
          count: count as number,
          place: place?.name,
        })}
      </h3>
    );
  }

  if (!count) {
    return (
      <h3>
        {t("searchHotelResults.title", {
          count: count as number,
          place: place?.name,
        })}
      </h3>
    );
  }

  return (
    <h3>
      {t("searchHotelResults.countAndLoading", {
        count: count as number,
        place: place?.name,
      })}
    </h3>
  );
};

export default SearchHotelsTitle;
