import { useSubAgencyStatement } from "@hotelspoint/api";
import { Panel } from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { type RowSelectionState } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import ProfileSubAgenciesStatementsItems from "../ProfileSubAgenciesStatementsItems";
import ProfileSubAgenciesStatementsItemsActions from "../ProfileSubAgenciesStatementsItems/ProfileSubAgenciesStatementsItemsActions";
import ProfileSubAgenciesStatementActions from "./ProfileSubAgenciesStatementActions";
import ProfileSubAgenciesStatementForm from "./ProfileSubAgenciesStatementForm";

interface ProfileSubAgenciesStatementParams extends Record<string, string> {
  id: string;
}

const ProfileSubAgenciesStatement = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("profileSubAgenciesStatement.pageTitle"));

  const { id } = useParams<ProfileSubAgenciesStatementParams>();
  const subAgencyStatementId = Number(id);
  const [statement, isLoading] = useSubAgencyStatement(subAgencyStatementId);

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const data = useMemo(() => statement?.items ?? [], [statement]);

  return (
    <Panel
      title={t("profileSubAgenciesStatement.pageTitle")}
      actions={<ProfileSubAgenciesStatementActions id={subAgencyStatementId} />}
    >
      <ProfileSubAgenciesStatementForm id={subAgencyStatementId} />
      <ProfileSubAgenciesStatementsItems
        data={data}
        isLoading={isLoading}
        useReactTableProps={{
          enableRowSelection: true,
          onRowSelectionChange: setRowSelection,
          state: {
            rowSelection,
          },
        }}
      />
      <ProfileSubAgenciesStatementsItemsActions
        id={subAgencyStatementId}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />
    </Panel>
  );
};

export default ProfileSubAgenciesStatement;
