import { useQuery } from "@tanstack/react-query";

import { PackageService } from "../../services";

const useDurations = (productId: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ["durations", productId],
    queryFn: () => PackageService.getDurations(productId),
    enabled: !!productId,
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useDurations;
