import { PlaceType } from "@hotelspoint/types";

export const PLACE_TYPES: PlaceType[] = [
  PlaceType.Area,
  PlaceType.Destination,
  PlaceType.City,
  PlaceType.Hotel,
  PlaceType.PointOfInterest,
  PlaceType.Address,
];

export const PLACE_TYPE_ORDER: PlaceType[] = [
  PlaceType.Area,
  PlaceType.Destination,
  PlaceType.City,
  PlaceType.Hotel,
  PlaceType.PointOfInterest,
  PlaceType.Address,
];

export const PLACE_TYPES_HOTEL_SEARCH: PlaceType[] = [
  PlaceType.Area,
  PlaceType.Destination,
  PlaceType.City,
  PlaceType.Hotel,
];
