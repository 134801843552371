import useTransferRate from "@hotelspoint/api/src/hooks/transfer/useTransferRate.ts";
import {
  Container,
  PageSpacer,
  PageSplitLayout,
  PageWrapper,
} from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";

import SearchTransferBookContent from "./SearchTranferBookContent";
import SearchTransferBookSidebar from "./SearchTransferBookSidebar";

interface SearchTransferBookParams extends Record<string, string> {
  rateId: string;
  searchId: string;
}

const SearchTransferBook = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("searchTransferBook.pageTitle"));

  const params = useParams<SearchTransferBookParams>();
  const searchId = Number(params.searchId);
  const rateId = Number(params.rateId);

  const [rate, isLoading] = useTransferRate(searchId, rateId);

  if (!searchId || !rateId) {
    return <Navigate to="/404" replace={true} />;
  }

  return (
    <PageWrapper>
      <Container>
        <PageSpacer>
          <PageSplitLayout wide>
            <PageSplitLayout.Sidebar>
              <SearchTransferBookSidebar rate={rate} isLoading={isLoading} />
            </PageSplitLayout.Sidebar>
            <PageSplitLayout.Content>
              <SearchTransferBookContent
                rate={rate}
                searchId={searchId}
                isLoading={isLoading}
              />
            </PageSplitLayout.Content>
          </PageSplitLayout>
        </PageSpacer>
      </Container>
    </PageWrapper>
  );
};

export default SearchTransferBook;
