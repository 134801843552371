import {
  NumericArrayParam,
  StringParam,
  useQueryParams,
} from "use-query-params";

const useHotelsFilterParams = () => {
  // @todo: Fix any type
  const [query, setQuery] = useQueryParams<any>({
    name: StringParam,
    room: StringParam,
    price: NumericArrayParam,
    rating: NumericArrayParam,
    meal: NumericArrayParam,
    rate: NumericArrayParam,
  });

  const ret: [typeof query, typeof setQuery] = [query, setQuery];

  return ret;
};

export default useHotelsFilterParams;
