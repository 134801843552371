import {
  Box,
  Button,
  Chip,
  Flex,
  Form,
  FormAdapter,
  FormClearableInput,
  FormContext,
  FormDatePickerSingle,
  FormLayout,
  FormSelect,
} from "@hotelspoint/components";
import { AGENCY_STATUS_OPTIONS } from "@hotelspoint/constants";
import { getAgencyStatusColor } from "@hotelspoint/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useAdminAgenciesParams from "../useAdminAgenciesParams";
import {
  entity2Form,
  form2Entity,
  FormValues,
} from "./AdminAgenciesFilters.form";

interface AdminAgenciesFiltersProps {
  isLoading: boolean;
}

const AdminAgenciesFilters = ({ isLoading }: AdminAgenciesFiltersProps) => {
  const { t } = useTranslation();

  const [query, setQuery] = useAdminAgenciesParams();

  const formValues = useMemo(() => {
    return entity2Form(query);
  }, [query]);

  const statusOptions = useMemo(
    () =>
      AGENCY_STATUS_OPTIONS.map(status => ({
        ...status,
        label: (
          <Chip $color={getAgencyStatusColor(status.value)}>
            {t(status.label)}
          </Chip>
        ),
      })),
    [t],
  );

  const onSubmit = (formValues: FormValues) => {
    const payload = form2Entity(formValues);

    setQuery(payload);
  };
  return (
    <Form defaultValues={formValues}>
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter name="id" label={t("adminAgencies.filters.id.label")}>
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t("adminAgencies.filters.id.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="name"
              label={t("adminAgencies.filters.name.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t("adminAgencies.filters.name.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="email"
              label={t("adminAgencies.filters.email.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t("adminAgencies.filters.email.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="status"
              label={t("adminAgencies.filters.status.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t("adminAgencies.filters.status.placeholder")}
                  options={statusOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="registerFrom"
              label={t("adminAgencies.filters.registerFrom.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "adminAgencies.filters.registerFrom.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="registerTo"
              label={t("adminAgencies.filters.registerTo.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "adminAgencies.filters.registerTo.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
        </Flex>

        <FormContext<FormValues>
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="secondary"
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              {t("adminAgencies.filters.submit")}
            </Button>
          )}
        />
      </FormLayout>
    </Form>
  );
};

export default AdminAgenciesFilters;
