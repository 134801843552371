import { borderRadius, mediaQuery, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { theme } from "styled-tools";

export const Wrapper = styled.div`
  margin-bottom: ${spacing(3)}px;
`;

export const Title = styled.h3`
  margin-bottom: ${spacing(3)}px;
`;

export const Subtitle = styled.h5`
  margin-bottom: ${spacing(3)}px;
  font-weight: 600;
`;

export const ServiceHeadline = styled.p`
  margin-bottom: ${spacing(1)}px;
  font-weight: 500;
`;

export const ServiceList = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: ${spacing(1)}px;
  row-gap: ${spacing(1)}px;
  margin-bottom: ${spacing(2)}px;
  @media ${mediaQuery.mobileXL} {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
  }
`;

export const Divider = styled.hr`
  margin: ${spacing(3)}px 0;
  background-color: rgba(0, 0, 0, 0.15);
`;

export const DayWrapper = styled.div`
  margin-bottom: ${spacing(4)}px;
`;

export const DayHeader = styled.div`
  margin-bottom: ${spacing(2)}px;

  @media ${mediaQuery.mobileL} {
    display: flex;
    align-items: center;
    column-gap: ${spacing(2)}px;
  }
`;

export const DayBadge = styled.div`
  display: inline-block;
  min-width: 64px;
  margin-bottom: ${spacing(1)}px;
  border: 1px solid ${theme("palette.blueBayoux")};
  border-radius: ${borderRadius.normal}px;
  font-weight: 500;
  text-align: center;
  @media ${mediaQuery.mobileL} {
    min-width: 136px;
    margin-bottom: 0;
  }
`;

export const FlexGrow = styled.div`
  flex: 1;
`;
