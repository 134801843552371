import { useSubAgencyStatements } from "@hotelspoint/api";
import {
  Breadcrumb,
  BreadcrumbContainer,
  Button,
  Pagination,
  Panel,
  Table,
  TABLE_PAGE_SIZE_DEFAULT,
  TableFooter,
  TableLayout,
  TablePageSize,
  TableResultsCount,
} from "@hotelspoint/components";
import { pickFilterValues, useDocumentTitle } from "@hotelspoint/utils";
import { IconFileInvoice, IconUser } from "@tabler/icons-react";
import omit from "lodash/omit";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// @todo: look into making these as shared components
import AdminAgencyStatementsFilters from "../../../Admin/AdminStatements/AdminAgencyStatements/AdminAgencyStatementsFilters";
import useAdminAgencyStatementsParams from "../../../Admin/AdminStatements/AdminAgencyStatements/useAdminAgencyStatementsParams";
import getColumns from "./ProfileSubAgenciesStatements.columns";

const ProfileSubAgenciesStatements = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("profileSubAgenciesStatements.pageTitle"));

  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZE_DEFAULT);
  const [pageIndex, setPageIndex] = useState(1);

  const [query] = useAdminAgencyStatementsParams();
  const filters = useMemo(
    () => omit(pickFilterValues(query), "agencyName"),
    [query],
  );

  const [statements, isLoading] = useSubAgencyStatements({
    pageIndex,
    pageSize,
    ...filters,
  });

  const data = useMemo(() => statements?.results ?? [], [statements]);

  const breadcrumbs = useMemo(
    () => [
      {
        title: t("profile.pageTitle"),
        href: "/profile",
        icon: IconUser,
      },
      {
        title: t("profileSubAgenciesStatements.pageTitle"),
        icon: IconFileInvoice,
      },
    ],
    [t],
  );

  const columns = useMemo(() => {
    return getColumns({ t });
  }, [t]);

  const stringifiedFilters = useMemo(() => JSON.stringify(filters), [filters]);

  useEffect(() => {
    setPageIndex(1);
  }, [pageSize, stringifiedFilters]);

  return (
    <>
      <BreadcrumbContainer>
        <Breadcrumb items={breadcrumbs} />
      </BreadcrumbContainer>
      <Panel
        title={t("profileSubAgenciesStatements.pageTitle")}
        actions={
          <Link to="/profile/sub-agencies/statements/create">
            <Button small variant="secondary">
              {t("profileSubAgenciesStatements.actions.create")}
            </Button>
          </Link>
        }
      >
        <AdminAgencyStatementsFilters isLoading={isLoading} />
        <TableLayout>
          <Table
            data={data}
            columns={columns}
            isLoading={isLoading}
            useReactTableProps={{
              state: {
                pagination: { pageIndex, pageSize: TABLE_PAGE_SIZE_DEFAULT },
                columnPinning: { left: ["id"], right: ["actions"] },
              },
              manualPagination: true,
            }}
          />
          <TableFooter>
            <div>
              <TableResultsCount value={statements?.count} />
            </div>
            <div>
              <Pagination
                pageIndex={pageIndex}
                pageSize={pageSize}
                setPageIndex={setPageIndex}
                totalCount={statements?.count ?? 0}
              />
            </div>
            <TablePageSize value={pageSize} onChange={setPageSize} />
          </TableFooter>
        </TableLayout>
      </Panel>
    </>
  );
};

export default ProfileSubAgenciesStatements;
