import { PlaceType } from "./place";

export enum TransferTripType {
  OneWay = 1,
  RoundTrip = 2,
  Airports = 3,
}

export interface TransferSearchQuery {
  type: TransferTripType;
  pickUp: {
    placeId: number;
    placeType: PlaceType;
  };
  dropOff: {
    placeId: number;
    placeType: PlaceType;
  };
  outboundDate: string;
  returnDate?: string;
  adults: number;
  children: number[];
}

export enum FormType {
  LeaderOnly = "leader-only",
  AllPassengers = "all-passengers",
}
