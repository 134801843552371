import { ActivityOption } from "@hotelspoint/api";
import { isDateBefore, isDateToday } from "@hotelspoint/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Price, { add, multiply, priceToDecimal } from "../../misc/Price";
import * as S from "./ActivityPriceDetails.styled";

interface ActivityPriceDetailsProps {
  option: ActivityOption;
}

const ActivityPriceDetails = ({ option }: ActivityPriceDetailsProps) => {
  const { t } = useTranslation();

  const dateToday = useMemo(() => new Date(), []);

  const isDeadlineOutdated = useMemo(() => {
    return (
      isDateBefore(new Date(option.deadline), dateToday) ||
      isDateToday(option.deadline)
    );
  }, [dateToday, option.deadline]);

  const totalPrice = useMemo(() => {
    return add(
      ...(option.prices
        .filter(option => option.numTickets)
        .map(option =>
          priceToDecimal(multiply(option.price, option.numTickets)),
        ) as number[]),
    );
  }, [option.prices]);

  const selectedPrices = useMemo(() => {
    return option.prices.filter(item => item.numTickets);
  }, [option.prices]);

  return (
    <div>
      {selectedPrices.length !== 0 ? (
        selectedPrices.map(price => (
          <S.TicketPrice key={price.key}>
            {price.numTickets}x {price.name}: <Price value={price.price} />
          </S.TicketPrice>
        ))
      ) : (
        <p>{t("searchActivityResult.priceDetails.noTicketsSelected")}</p>
      )}
      <S.PriceWrapper>
        <p>{t("searchActivityResult.priceDetails.totalPrice")}</p>
        <S.Price>
          <Price value={totalPrice} />
        </S.Price>
      </S.PriceWrapper>
      <S.Description>
        {isDeadlineOutdated
          ? t("searchActivityResult.priceDetails.nonRefundable")
          : t("searchActivityResult.priceDetails.freeCancelationUntil", {
              date: option.deadline,
            })}
      </S.Description>
    </div>
  );
};
export default ActivityPriceDetails;
