import { ReservationTickets } from "@hotelspoint/api";
import {
  Box,
  Button,
  ButtonDelete,
  FormAdapter,
  FormClearableInput,
  FormDatePickerSingle,
  FormInput,
  FormSelect,
} from "@hotelspoint/components";
import { TITLE_OPTIONS } from "@hotelspoint/constants";
import { UserCurrency } from "@hotelspoint/types";
import { IconPlus } from "@tabler/icons-react";
import { useCallback, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormValues } from "../AdminReservationForm.util";
import * as S from "./AdminReservationFormActivityTickets.styled";

const AdminReservationFormActivityTickets = () => {
  const { t } = useTranslation();

  const { setValue } = useFormContext<FormValues>();
  const [tickets, passengers] = useWatch({
    name: ["tickets", "passengers"],
  });

  const dateToday = useMemo(() => new Date(), []);

  const titleOptions = useMemo(
    () =>
      TITLE_OPTIONS.map(title => ({
        ...title,
        label: t(title.label),
      })),
    [t],
  );

  const TICKETS_OPTIONS = useMemo(() => {
    return Array.from({ length: 12 }, (_, i) => ({
      value: i + 1,
      label: i + 1,
    }));
  }, []);

  const handleTicketDelete = useCallback(
    (targetIndex: number) => {
      const updatedTicketsArray = tickets
        ?.filter((_: any, index: number) => index !== targetIndex)
        ?.map((ticket: any, newIndex: any) => ({
          ...ticket,
          key: newIndex,
        }));
      const updatedPassengersArray = passengers?.filter(
        (_: any, index: number) => index !== targetIndex,
      );

      setValue("tickets", updatedTicketsArray);
      setValue("passengers", updatedPassengersArray);
    },
    [passengers, setValue, tickets],
  );

  const handleAddTicket = useCallback(() => {
    setValue("tickets", [
      ...tickets,
      {
        key: tickets[tickets.length - 1].key + 1,
        name: "Ticket",
        numTickets: 1,
        priceBase: 0,
        priceNet: 0,
        price: 0,
      },
    ]);
  }, [setValue, tickets]);

  return (
    <>
      <Box width={1} px={[0, 0, 1, 1]} mt={3}>
        <h5>{t("adminReservation.form.sections.tickets")}</h5>
        <hr style={{ margin: "10px 0" }} />
      </Box>
      {tickets &&
        tickets.map((ticket: ReservationTickets, index: number) => (
          <>
            <Box width={[1, 1, 1 / 2, 1 / 6]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name={`tickets.${index}.name`}
                label={t("adminReservation.form.ticketName.label")}
              >
                {props => (
                  <FormClearableInput
                    {...props}
                    placeholder={t(
                      "adminReservation.form.ticketName.placeholder",
                    )}
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 6]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name={`tickets.${index}.numTickets`}
                label={t("adminReservation.form.numTickets.label")}
              >
                {props => <FormSelect {...props} options={TICKETS_OPTIONS} />}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 6]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name={`tickets.${index}.priceBase`}
                label={t("adminReservation.form.activityPriceBase.label")}
              >
                {props => (
                  <FormInput
                    {...props}
                    endAdornment={UserCurrency.EUR}
                    type="number"
                    placeholder={t(
                      "adminReservation.form.activityPriceBase.placeholder",
                    )}
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 6]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name={`tickets.${index}.priceNet`}
                label={t("adminReservation.form.activityPriceNet.label")}
              >
                {props => (
                  <FormInput
                    {...props}
                    endAdornment={UserCurrency.EUR}
                    type="number"
                    placeholder={t(
                      "adminReservation.form.activityPriceNet.placeholder",
                    )}
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 6]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name={`tickets.${index}.price`}
                label={t("adminReservation.form.salePrice.label")}
              >
                {props => (
                  <FormInput
                    {...props}
                    endAdornment={UserCurrency.EUR}
                    type="number"
                    placeholder={t(
                      "adminReservation.form.salePrice.placeholder",
                    )}
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 6]} px={[0, 0, 1, 1]} py={1}>
              <S.ButtonDeleteWrapper>
                <ButtonDelete
                  small={false}
                  onClick={() => handleTicketDelete(index)}
                  isDisabled={tickets.length <= 1}
                />
              </S.ButtonDeleteWrapper>
            </Box>
            {Array.from({ length: ticket.numTickets }).map(
              (_, indexNumTickets) => (
                <>
                  <Box width={[1, 1, 1 / 2, 2 / 12]} px={[0, 0, 1, 1]} py={1}>
                    <FormAdapter
                      name={`passengers.${index}.${indexNumTickets}.title`}
                      label={t("adminReservation.form.passengerTitle.label")}
                    >
                      {props => (
                        <FormSelect
                          {...props}
                          options={titleOptions}
                          isSearchable={false}
                          placeholder={t(
                            "adminReservation.form.passengerTitle.placeholder",
                          )}
                        />
                      )}
                    </FormAdapter>
                  </Box>
                  <Box width={[1, 1, 1 / 2, 3 / 12]} px={[0, 0, 1, 1]} py={1}>
                    <FormAdapter
                      name={`passengers.${index}.${indexNumTickets}.name`}
                      label={t("adminReservation.form.passengerName.label")}
                    >
                      {props => (
                        <FormClearableInput
                          {...props}
                          placeholder={t(
                            "adminReservation.form.passengerName.placeholder",
                          )}
                        />
                      )}
                    </FormAdapter>
                  </Box>
                  <Box width={[1, 1, 1 / 2, 3 / 12]} px={[0, 0, 1, 1]} py={1}>
                    <FormAdapter
                      name={`passengers.${index}.${indexNumTickets}.surname`}
                      label={t("adminReservation.form.passengerSurname.label")}
                    >
                      {props => (
                        <FormClearableInput
                          {...props}
                          placeholder={t(
                            "adminReservation.form.passengerSurname.placeholder",
                          )}
                        />
                      )}
                    </FormAdapter>
                  </Box>
                  <Box width={[1, 1, 1 / 2, 2 / 12]} px={[0, 0, 1, 1]} py={1}>
                    <FormAdapter
                      name={`passengers.${index}.${indexNumTickets}.birthday`}
                      label={t("adminReservation.form.passengerBirthday.label")}
                    >
                      {props => (
                        <FormDatePickerSingle
                          {...props}
                          dayPickerProps={{
                            fromYear: 1900,
                            toYear: new Date().getFullYear(),
                            captionLayout: "dropdown",
                            disabled: { after: dateToday },
                          }}
                          placeholder={t(
                            "adminReservation.form.passengerBirthday.placeholder",
                          )}
                        />
                      )}
                    </FormAdapter>
                  </Box>
                  <Box width={[1, 1, 1 / 2, 2 / 12]} px={[0, 0, 1, 1]} py={1}>
                    <FormAdapter
                      name={`passengers.${index}.${indexNumTickets}.ages`}
                      label={t("adminReservation.form.passengerAges.label")}
                    >
                      {props => (
                        <FormClearableInput
                          {...props}
                          placeholder={t(
                            "adminReservation.form.passengerAges.placeholder",
                          )}
                        />
                      )}
                    </FormAdapter>
                  </Box>
                </>
              ),
            )}
            <Box width={[1, 1, 1, 1]} px={[0, 0, 1, 1]} py={1}>
              <hr style={{ margin: "10px 0" }} />
            </Box>
          </>
        ))}
      <Box width={[1, 1, 1, 1]} px={[0, 0, 1, 1]} py={1}>
        <Button small variant="outlined" onClick={handleAddTicket}>
          <IconPlus size={16} /> {t("adminReservation.form.ticketBtn")}
        </Button>
      </Box>
    </>
  );
};

export default AdminReservationFormActivityTickets;
