import {
  ActivitiesFilterParams,
  ActivitySearchFilters,
} from "@hotelspoint/api";
import { array, boolean, InferType, number, object, string } from "yup";

const filterSchema = object({
  id: number().required(),
  name: string().required(),
  count: number().required(),
});

export const validationSchema = object({
  name: string(),
  price: array().of(number()),
  priceRange: array().of(number()),
  category: array().of(boolean()),
  categoryFilter: array().of(filterSchema),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  name: "",
  price: [],
  priceRange: [],
  category: [],
  categoryFilter: [],
};

export const entity2Form = (
  entity: ActivitySearchFilters,
  filters: ActivitiesFilterParams,
) => {
  const { price, category } = entity;

  return {
    name: filters?.name ?? undefined,
    price: filters?.price ? filters.price : [price.min, price.max],
    priceRange: [price.min, price.max],
    category: filters?.category
      ? category?.map(({ id }) => filters.category?.includes(id))
      : category?.map(() => false),
    categoryFilter: category?.sort((a, b) => b.count - a.count),
  };
};

export const form2Entity = (formValues: FormValues) => {
  const { name, category, categoryFilter, price } = formValues;
  const [min, max] = price ?? [];

  return {
    name,
    price: [min as number, max as number],
    category: category
      ?.map((value, index) =>
        value && categoryFilter?.[index] ? categoryFilter[index].id : null,
      )
      .filter(Boolean),
  };
};
