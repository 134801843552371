import { pxToRem, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { switchProp, theme } from "styled-tools";

import { FormControlLabelPosition } from "./types";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelWrapper = styled.div<{ $position: FormControlLabelPosition }>`
  display: flex;
  flex-direction: ${switchProp("$position", {
    start: "column",
    end: "column-reverse",
    "inline-start": "row",
    "inline-end": "row-reverse",
  })};
  row-gap: ${switchProp("$position", {
    start: `${spacing(0.5)}px`,
    end: `${spacing(0.5)}px`,
  })};
  column-gap: ${switchProp("$position", {
    "inline-start": `${spacing(1)}px`,
    "inline-end": `${spacing(1)}px`,
  })};
  justify-content: ${switchProp("$position", {
    "inline-end": "flex-end",
  })};
  align-items: ${switchProp("$position", {
    "inline-start": "center",
    "inline-end": "center",
  })};
`;

export const Required = styled.span`
  color: ${theme("error.primary")};
  margin-left: ${spacing(0.5)}px;
`;

export const Error = styled.div`
  color: ${theme("error.primary")};
  font-size: ${pxToRem(15)};
  margin-top: ${spacing(0.5)}px;
`;
