import { UserCreatePayload, UserUpdatePayload } from "@hotelspoint/api";
import { SCHEMA_EMAIL, SCHEMA_NAMES } from "@hotelspoint/utils";
import { boolean, InferType, object } from "yup";

export const validationSchema = object({
  name: SCHEMA_NAMES,
  email: SCHEMA_EMAIL,
  isActive: boolean().required(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  name: "",
  email: "",
  isActive: true,
};

export const form2Entity = (
  formValues: FormValues,
): Omit<UserCreatePayload, "agencyId"> => ({
  name: formValues.name,
  email: formValues.email,
  isActive: formValues.isActive,
});

export const entity2Form = (entity: Omit<UserCreatePayload, "agencyId">) => ({
  name: entity.name,
  email: entity.email,
  isActive: entity.isActive,
});

export const form2EntityUpdate = (
  formValues: FormValues,
): UserUpdatePayload => ({
  name: formValues.name,
  email: formValues.email,
  isActive: formValues.isActive,
});
