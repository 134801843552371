import { PlaceType } from "@hotelspoint/types";
import { InferType, mixed, number, object, string } from "yup";

export const validationSchema = object({
  search: string()
    .required("searchHotels.search.validation.required")
    .test("place", "__ctx__", (_, ctx) => {
      const place = ctx.parent.place;

      if (!place || !place.id || !place.type) {
        return ctx.createError({
          message: "searchHotels.search.validation.place",
        });
      }

      return true;
    }),

  // Read-only - set programmatically when selecting a place
  place: object({
    id: number(),
    type: mixed<PlaceType>().oneOf(Object.values(PlaceType)),
  }),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: Partial<FormValues> = {
  search: "",
  place: undefined,
};
