import { spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

export const Divider = styled.hr`
  margin: ${spacing(2)}px 0;
  background-color: rgba(0, 0, 0, 0.15);
`;

export const Title = styled.h4`
  font-weight: 600;
  margin-bottom: ${spacing(3.75)}px;
`;

export const Categories = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${spacing(3)}px;
  margin-bottom: ${spacing(4)}px;
`;
