import { Hotel } from "@hotelspoint/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { HotelService, HotelsSearchResponse } from "../../services";

const useAddHotelToFavorites = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["hotels", "add_to_favorites"],
    mutationFn: (id: number) => {
      return HotelService.addToFavorites(id);
    },
    onSuccess: (_, id) => {
      queryClient.setQueriesData<Hotel>(
        { queryKey: ["hotels", id] },
        oldData => {
          if (!oldData) return;

          return {
            ...oldData,
            isFavorite: true,
          };
        },
      );

      queryClient.setQueriesData<HotelsSearchResponse>(
        { queryKey: ["hotels_search"] },
        oldData => {
          if (!oldData) return;

          return {
            ...oldData,
            results: oldData.results.map(hotel => {
              if (hotel.id === id) {
                return {
                  ...hotel,
                  isFavorite: true,
                };
              }

              return hotel;
            }),
          };
        },
      );
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useAddHotelToFavorites;
