import {
  type Icon,
  IconAlertCircle,
  IconAlertTriangle,
  IconCircleCheck,
  IconFileUnknown,
  IconInfoCircle,
  type IconProps,
} from "@tabler/icons-react";

export enum StatusFlavour {
  Success,
  Info,
  Warning,
  Error,
  Optional,
}

export enum ModalSize {
  Small,
  Large,
}

export type IconType = React.ForwardRefExoticComponent<
  Omit<IconProps, "ref"> & React.RefAttributes<Icon>
>;

export const getStatusIconComponent = (status: StatusFlavour) => {
  switch (status) {
    case StatusFlavour.Success:
      return IconCircleCheck;

    case StatusFlavour.Info:
      return IconInfoCircle;

    case StatusFlavour.Warning:
      return IconAlertCircle;

    case StatusFlavour.Error:
      return IconAlertTriangle;

    case StatusFlavour.Optional:
      return IconCircleCheck;

    default:
      return IconFileUnknown;
  }
};

export interface Option<M = any> {
  value: any;
  label: string | React.ReactNode;
  meta?: M;
}

export interface OptionGroup<T = Option> {
  value?: any;
  label: string;
  options: T[];
}

export interface NavItemBase {
  href: string;
  title: string;
}
