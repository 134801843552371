import {
  useHotelAvailabilitySearch,
  useHotelSearchResults,
} from "@hotelspoint/api";
import {
  BreadcrumbContainer,
  Button,
  Container,
  EmptySearchState,
  LoaderBlock,
  MapProvider,
  Minimap,
  PageSpacer,
  PageSplitLayout,
  PageWrapper,
} from "@hotelspoint/components";
import useHotelsSearchResultStore from "@hotelspoint/store/src/useHotelsSearchResultStore.ts";
import { mediaQuery } from "@hotelspoint/theme";
import { useMediaQuery } from "@hotelspoint/utils";
import { IconMapPinFilled } from "@tabler/icons-react";
import { t } from "i18next";
import omit from "lodash/omit";
import { useEffect, useMemo } from "react";

import { SearchHotelsViewState } from "../types";
import useHotelSearchParams from "../useHotelSearchParams";
import SearchHotelsBreadcrumbs from "./SearchHotelsBreadcrumbs";
import SearchHotelsFilterDrawer from "./SearchHotelsFilterDrawer";
import SearchHotelsFilters from "./SearchHotelsFilters/SearchHotelsFilters";
import useHotelsFilterParams from "./SearchHotelsFilters/useHotelsFilterParams";
import SearchHotelsForm from "./SearchHotelsForm";
import SearchHotelsList from "./SearchHotelsList";
import SearchHotelsMap from "./SearchHotelsMap";
import * as S from "./SearchHotelsResults.styled";
import { LoaderBar, LoaderBarLine } from "./SearchHotelsResults.styled";
import SearchHotelsTitle from "./SearchHotelsTitle";

const SearchHotelsResults = () => {
  const isDesktop = useMediaQuery(mediaQuery.desktop);

  const hotels = useHotelsSearchResultStore(state => state.results);
  const filtersState = useHotelsSearchResultStore(state => state.filters);
  const hotelsTotal = useHotelsSearchResultStore(state => state.total);
  const hotelsCount = useHotelsSearchResultStore(state => state.count);
  const isLoading = useHotelsSearchResultStore(state => state.isLoading);
  const isCompleted = useHotelsSearchResultStore(state => state.isCompleted);
  const resetResult = useHotelsSearchResultStore(state => state.reset);

  // Reset state on unmount
  useEffect(() => {
    return () => {
      resetResult();
    };
  }, [resetResult]);

  const [query, setQuery] = useHotelSearchParams();

  const [filters, setFilters] = useHotelsFilterParams();

  const searchParams = omit(query, "view");

  const filterUndefinedValues = useMemo(() => {
    return Object.fromEntries(
      Object.entries(filters).filter(([, value]) => value !== undefined),
    );
  }, [filters]);

  // @ts-expect-error - Problem with the types
  useHotelAvailabilitySearch(searchParams, {
    ...searchParams,
    ...filterUndefinedValues,
  });

  useHotelSearchResults({
    ...filterUndefinedValues,
  });

  const isEmptyState = useMemo(() => {
    return isCompleted && hotelsCount === 0;
  }, [hotelsCount, isCompleted]);

  const minimap = useMemo(
    () => (
      <Minimap>
        <Button
          onClick={() => setQuery({ view: SearchHotelsViewState.Map })}
          isDisabled={isEmptyState as boolean}
        >
          <IconMapPinFilled size={18} /> {t("searchHotelResults.showMap")}
        </Button>
      </Minimap>
    ),
    [isEmptyState, setQuery],
  );

  const canShowFilters = useMemo(() => {
    return (
      hotelsTotal !== 0 &&
      filtersState &&
      query.view !== SearchHotelsViewState.Map
    );
  }, [hotelsTotal, filtersState, query.view]);

  return (
    <PageWrapper>
      <Container>
        <PageSpacer>
          <BreadcrumbContainer>
            <SearchHotelsBreadcrumbs />
          </BreadcrumbContainer>
          <S.VisibleUntilDesktop>
            {minimap}
            <S.SidebarCard>
              <SearchHotelsForm isLoading={isLoading} />
            </S.SidebarCard>
            {canShowFilters && !isDesktop && (
              <SearchHotelsFilterDrawer>
                <SearchHotelsFilters
                  value={filtersState}
                  onChange={value => setFilters(value)}
                />
              </SearchHotelsFilterDrawer>
            )}
          </S.VisibleUntilDesktop>
          <PageSplitLayout>
            <PageSplitLayout.Sidebar>
              <div>{minimap}</div>
              <S.SidebarCard>
                <SearchHotelsForm isLoading={isLoading} />
              </S.SidebarCard>
              {canShowFilters && isDesktop && (
                <div>
                  <h5 style={{ marginBottom: 12 }}>
                    {t("searchHotelResults.filters.title")}
                  </h5>
                  <S.SidebarCard>
                    <div>
                      <SearchHotelsFilters
                        value={filtersState}
                        onChange={value => setFilters(value)}
                      />
                    </div>
                  </S.SidebarCard>
                </div>
              )}
            </PageSplitLayout.Sidebar>
            <PageSplitLayout.Content>
              <SearchHotelsTitle />
              {(!isCompleted || isLoading) && (
                <LoaderBar>
                  <LoaderBarLine />
                </LoaderBar>
              )}
              <S.HotelListingWrapper>
                {isEmptyState && (
                  <EmptySearchState
                    title={t("searchHotelResults.emptyStateMessage.title")}
                    description={t(
                      "searchHotelResults.emptyStateMessage.description",
                    )}
                  />
                )}
                {!isCompleted && !hotelsCount ? (
                  <LoaderBlock>
                    <h6>{t("searchHotelResults.loading")}</h6>
                  </LoaderBlock>
                ) : (
                  <SearchHotelsList />
                )}
              </S.HotelListingWrapper>
            </PageSplitLayout.Content>
          </PageSplitLayout>
        </PageSpacer>
      </Container>
      <MapProvider>
        <SearchHotelsMap
          hotels={hotels}
          filters={filtersState}
          setFilters={setFilters}
        />
      </MapProvider>
    </PageWrapper>
  );
};
export default SearchHotelsResults;
