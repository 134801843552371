import {
  handleError,
  useAgency,
  useCreateAgency,
  useUpdateAgency,
} from "@hotelspoint/api";
import {
  Box,
  Button,
  Flex,
  Form,
  FormAdapter,
  FormContext,
  FormInput,
  FormLayout,
  FormSelect,
  LoaderBlock,
  OptionGroup,
} from "@hotelspoint/components";
import { countryGroups } from "@hotelspoint/constants";
import { UserCurrency } from "@hotelspoint/types";
import { useDocumentTitle } from "@hotelspoint/utils";
import { IconMail, IconPercentage, IconUser } from "@tabler/icons-react";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  defaultValues,
  entity2Form,
  form2Entity,
  form2EntityUpdate,
  FormValues,
  validationSchema,
} from "./ProfileSubAgenciesRegisterForm.form";
import * as S from "./ProfileSubAgenciesRegisterForm.styled";

interface ProfileSubAgenciesRegisterFormProps {
  id?: number;
}

const ProfileSubAgenciesRegisterForm = ({
  id,
}: ProfileSubAgenciesRegisterFormProps) => {
  const { t } = useTranslation();
  useDocumentTitle(t("profileSubAgenciesRegister.pageTitle"));

  const isEditing = !!id;
  const navigate = useNavigate();

  const [createAgency, isCreatingAgency] = useCreateAgency();
  const [updateAgency, isUpdatingAgency] = useUpdateAgency(id as number);
  const [subAgency, isLoadingSubAgency] = useAgency(id as number);

  const countryOptions: OptionGroup[] = useMemo(
    () =>
      countryGroups.map(group => ({
        label: t(`countries.groups.${group.label}`),
        options: group.options.map(value => ({
          value,
          label: t(`countries.${value}`),
        })),
      })),
    [t],
  );

  const formValues = useMemo(() => {
    if (!subAgency) return defaultValues;

    return entity2Form(subAgency);
  }, [subAgency]);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        if (id) {
          const payload = form2Entity(formValues);

          const response = await createAgency(payload);
          toast.success(t("toast.profileSubAgencyRegister.registerSubAgency"));
          navigate(`/profile/sub-agencies/${response.id}`);
        } else {
          const payload = form2EntityUpdate(formValues);

          await updateAgency(payload);
          toast.success(t("toast.profileSubAgencySettings.updateSubAgency"));
        }
      } catch (error: any) {
        handleError({ t, error });
      }
    },
    [id, createAgency, t, navigate, updateAgency],
  );

  if (isLoadingSubAgency) {
    return <LoaderBlock />;
  }

  return (
    <>
      <Form<FormValues>
        defaultValues={formValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <FormLayout>
          <Flex mx={[0, 0, -1, -1]}>
            <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="email"
                label={t("profileSubAgenciesRegister.form.email.label")}
              >
                {props => (
                  <FormInput
                    {...props}
                    placeholder={t(
                      "profileSubAgenciesRegister.form.email.placeholder",
                    )}
                    startAdornment={<IconMail size={18} />}
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="name"
                label={t("profileSubAgenciesRegister.form.name.label")}
              >
                {props => (
                  <FormInput
                    {...props}
                    placeholder={t(
                      "profileSubAgenciesRegister.form.name.placeholder",
                    )}
                    startAdornment={<IconUser size={18} />}
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="companyManager"
                label={t(
                  "profileSubAgenciesRegister.form.companyManager.label",
                )}
              >
                {props => (
                  <FormInput
                    {...props}
                    placeholder={t(
                      "profileSubAgenciesRegister.form.companyManager.placeholder",
                    )}
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="companyPhone"
                label={t("profileSubAgenciesRegister.form.companyPhone.label")}
              >
                {props => (
                  <FormInput
                    {...props}
                    placeholder={t(
                      "profileSubAgenciesRegister.form.companyPhone.placeholder",
                    )}
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 1, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="countryCode"
                label={t("profileSubAgenciesRegister.form.countryCode.label")}
              >
                {props => (
                  <FormSelect
                    {...props}
                    placeholder={t(
                      "profileSubAgenciesRegister.form.countryCode.placeholder",
                    )}
                    disabled={
                      isEditing
                        ? isCreatingAgency
                        : isUpdatingAgency || isLoadingSubAgency
                    }
                    options={countryOptions}
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 3, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="markupHotelsOperator"
                label={t(
                  "profileSubAgenciesRegister.form.markupHotelsOperator.label",
                )}
              >
                {props => (
                  <FormInput
                    {...props}
                    type="number"
                    disabled={
                      isEditing
                        ? isCreatingAgency
                        : isUpdatingAgency || isLoadingSubAgency
                    }
                    endAdornment={<IconPercentage size={18} />}
                    adornmentStyle="filled"
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 3, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="markupTransfersOperator"
                label={t(
                  "profileSubAgenciesRegister.form.markupTransfersOperator.label",
                )}
              >
                {props => (
                  <FormInput
                    {...props}
                    type="number"
                    disabled={
                      isEditing
                        ? isCreatingAgency
                        : isUpdatingAgency || isLoadingSubAgency
                    }
                    endAdornment={<IconPercentage size={18} />}
                    adornmentStyle="filled"
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 3, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="markupActivitiesOperator"
                label={t(
                  "profileSubAgenciesRegister.form.markupActivitiesOperator.label",
                )}
              >
                {props => (
                  <FormInput
                    {...props}
                    type="number"
                    disabled={
                      isEditing
                        ? isCreatingAgency
                        : isUpdatingAgency || isLoadingSubAgency
                    }
                    endAdornment={<IconPercentage size={18} />}
                    adornmentStyle="filled"
                  />
                )}
              </FormAdapter>
            </Box>
            <S.Separator />
            <Box width={[1, 1, 1 / 3, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="creditUsed"
                label={t("profileSubAgenciesRegister.form.creditUsed.label")}
              >
                {props => (
                  <FormInput
                    {...props}
                    type="number"
                    readOnly
                    endAdornment={UserCurrency.EUR}
                    adornmentStyle="filled"
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 3, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="creditBalance"
                label={t("profileSubAgenciesRegister.form.creditBalance.label")}
              >
                {props => (
                  <FormInput
                    {...props}
                    type="number"
                    readOnly
                    endAdornment={UserCurrency.EUR}
                    adornmentStyle="filled"
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 3, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="creditLimit"
                label={t("profileSubAgenciesRegister.form.creditLimit.label")}
              >
                {props => (
                  <FormInput
                    {...props}
                    type="number"
                    endAdornment={UserCurrency.EUR}
                    adornmentStyle="filled"
                  />
                )}
              </FormAdapter>
            </Box>
          </Flex>
          <FormContext<FormValues>
            render={({ handleSubmit }) => (
              <Button
                type="submit"
                variant="secondary"
                isLoading={
                  isEditing
                    ? isCreatingAgency
                    : isUpdatingAgency || isLoadingSubAgency
                }
                onClick={handleSubmit(onSubmit)}
              >
                {isEditing
                  ? t("profileSubAgenciesRegister.submit")
                  : t("profileSubAgencySettings.submit")}
              </Button>
            )}
          />
        </FormLayout>
      </Form>
    </>
  );
};

export default ProfileSubAgenciesRegisterForm;
