import {
  ArrayParam,
  NumericArrayParam,
  useQueryParams,
} from "use-query-params";

const useTransfersFilterParams = () => {
  const [query, setQuery] = useQueryParams({
    price: NumericArrayParam,
    category: ArrayParam,
    vehicle: ArrayParam,
  });

  const ret: [typeof query, typeof setQuery] = [query, setQuery];

  return ret;
};

export default useTransfersFilterParams;
