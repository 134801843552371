export const getRatingLabel = (bookingRating: number) => {
  if (bookingRating < 6) {
    return "components.customersReview.reviews.poor";
  }
  if (bookingRating < 7) {
    return "components.customersReview.reviews.pleasant";
  }
  if (bookingRating < 8) {
    return "components.customersReview.reviews.good";
  }
  if (bookingRating < 9) {
    return "components.customersReview.reviews.veryGood";
  }
  return "components.customersReview.reviews.wonderful";
};
