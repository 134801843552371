import {
  HotelSearchFilters,
  HotelsFilterParams,
  SearchFilter,
  SearchFilterName,
} from "@hotelspoint/api";
import {
  Button,
  Form,
  FormAdapter,
  FormCheckbox,
  FormChildrenMethods,
  FormClearableInput,
  FormInputRange,
  FormLabel,
  RangeSlider,
  Rating,
} from "@hotelspoint/components";
import { PlaceType } from "@hotelspoint/types";
import {
  IconCurrencyEuro,
  IconHeartFilled,
  IconSearch,
} from "@tabler/icons-react";
import { useEffect, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";

import {
  defaultValues,
  entity2Form,
  form2Entity,
  FormValues,
} from "./SearchHotelsFilters.form";
import * as S from "./SearchHotelsFilters.styled";
import useHotelsFilterParams from "./useHotelsFilterParams";

interface SearchHotelsFiltersProps {
  context?: PlaceType;
  value: HotelSearchFilters | null;
  onChange: (value: HotelSearchFilters) => void;
}

interface SearchHotelsFiltersInnerProps
  extends FormChildrenMethods<FormValues>,
    SearchHotelsFiltersProps {}

const SearchHotelsFiltersInner = ({
  watch,
  context,
  onChange,
}: SearchHotelsFiltersInnerProps) => {
  const { t } = useTranslation();

  const [priceRange, ratingFilter, mealFilter, rateFilter] = useWatch({
    name: ["priceRange", "ratingFilter", "mealFilter", "rateFilter"],
  });

  const [priceMin, priceMax] = priceRange as number[];

  const debouncedCb = useDebouncedCallback(values => {
    const payload = form2Entity(values);

    // @ts-expect-error - Problem with the types
    onChange(payload);
  }, 350);

  useEffect(() => {
    const subscription = watch(debouncedCb);

    return () => subscription.unsubscribe();
  }, [debouncedCb, watch]);

  return (
    <S.Wrapper>
      {context !== PlaceType.Hotel && (
        <>
          <Button variant="outlined" fullWidth>
            <IconHeartFilled size={18} style={{ color: "#F96846" }} />
            My Favorites
          </Button>
          <FormAdapter
            name="name"
            label={t("searchHotelResults.filters.hotelName.label")}
          >
            {props => (
              <FormClearableInput
                {...props}
                startAdornment={<IconSearch size={18} />}
                placeholder={t(
                  "searchHotelResults.filters.hotelName.placeholder",
                )}
              />
            )}
          </FormAdapter>
          <S.Divider />
        </>
      )}
      <FormAdapter
        name="room"
        label={t("searchHotelResults.filters.roomName.label")}
      >
        {props => (
          <FormClearableInput
            {...props}
            startAdornment={<IconSearch size={18} />}
            placeholder={t("searchHotelResults.filters.roomName.placeholder")}
          />
        )}
      </FormAdapter>
      <S.Divider />
      <FormAdapter
        name="price"
        label={t("searchHotelResults.filters.price.label")}
      >
        {props => (
          <>
            <FormInputRange
              {...props}
              startAdornment={<IconCurrencyEuro size={18} />}
            />
            <S.RangeSliderWrapper>
              <RangeSlider
                range
                min={priceMin}
                max={priceMax}
                step={1}
                allowCross={false}
                value={props.value}
                defaultValue={props.value}
                onChange={range => props.onChange(range)}
              />
            </S.RangeSliderWrapper>
          </>
        )}
      </FormAdapter>
      <S.Divider />
      {context !== PlaceType.Hotel && (
        <>
          <S.CheckboxWrapper>
            <FormLabel id="rating">
              {t("searchHotelResults.filters.rating.label")}
            </FormLabel>
            {ratingFilter?.map((rating: SearchFilter, index: number) => (
              <FormAdapter
                key={rating.id}
                name={`rating.${index}`}
                label={
                  <S.CheckboxLabelWrapper>
                    <Rating value={rating.id} size={14} />
                    <S.CheckboxCount>{rating.count}</S.CheckboxCount>
                  </S.CheckboxLabelWrapper>
                }
                formControlProps={{
                  labelPosition: "inline-end",
                  style: { flex: 1 },
                }}
              >
                {props => <FormCheckbox {...props} />}
              </FormAdapter>
            ))}
          </S.CheckboxWrapper>
          <S.Divider />
        </>
      )}
      <S.CheckboxWrapper>
        <FormLabel id="meal">
          {t("searchHotelResults.filters.meal.label")}
        </FormLabel>
        {mealFilter?.map((meal: SearchFilterName, index: number) => (
          <FormAdapter
            key={meal.id}
            name={`meal.${index}`}
            label={
              <S.CheckboxLabelWrapper>
                <S.CheckboxName>{meal.name}</S.CheckboxName>
                <S.CheckboxCount>{meal.count}</S.CheckboxCount>
              </S.CheckboxLabelWrapper>
            }
            formControlProps={{
              labelPosition: "inline-end",
              style: { flex: 1 },
            }}
          >
            {props => <FormCheckbox {...props} />}
          </FormAdapter>
        ))}
      </S.CheckboxWrapper>
      <S.Divider />
      <S.CheckboxWrapper>
        <FormLabel id="rate">
          {t("searchHotelResults.filters.rate.label")}
        </FormLabel>
        {rateFilter?.map((rate: SearchFilterName, index: number) => (
          <FormAdapter
            key={rate.id}
            name={`rate.${index}`}
            label={
              <S.CheckboxLabelWrapper>
                <S.CheckboxName>{rate.name}</S.CheckboxName>
                <S.CheckboxCount>{rate.count}</S.CheckboxCount>
              </S.CheckboxLabelWrapper>
            }
            formControlProps={{
              labelPosition: "inline-end",
              style: { flex: 1 },
            }}
          >
            {props => <FormCheckbox {...props} />}
          </FormAdapter>
        ))}
      </S.CheckboxWrapper>
    </S.Wrapper>
  );
};

const SearchHotelsFilters = (props: SearchHotelsFiltersProps) => {
  const { value } = props;

  const [filters] = useHotelsFilterParams() as [
    HotelsFilterParams,
    (filters: HotelsFilterParams) => void,
  ];

  const formValues = useMemo(() => {
    if (!value) return defaultValues;

    return entity2Form(value, filters);
  }, [filters, value]);

  return (
    <Form defaultValues={formValues} showDevTool>
      {formMethods => <SearchHotelsFiltersInner {...formMethods} {...props} />}
    </Form>
  );
};

export default SearchHotelsFilters;
