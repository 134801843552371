import { borderRadius, pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { ifProp, theme } from "styled-tools";

import { Paper } from "../../elements";

export const Container = styled(Paper)`
  container-type: inline-size;
  container-name: container;

  position: relative;
  padding: ${spacing(1)}px ${spacing(2)}px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  list-style: none;
  @container container (min-width: 600px) {
    width: 70%;
    justify-content: center;
    margin: 0 auto;
  }
`;

export const StepWrapper = styled.div<{ $isActive: boolean }>`
  display: ${ifProp("$isActive", "flex", "none")};
  column-gap: ${spacing(1)}px;
  align-items: center;
  &:last-child {
    div {
      &::after {
        display: none;
      }
    }
  }
  &:not(:last-child) {
    width: 100%;
  }
  @container container (min-width: 600px) {
    display: flex;
    column-gap: 0;
    align-items: unset;
    flex-direction: column;
    row-gap: ${spacing(1)}px;
  }
`;

export const Step = styled.div<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  @container container (min-width: 600px) {
    &::after {
      content: "";
      width: 100%;
      height: 3px;
      background-color: ${ifProp(
        "$isActive",
        ({ theme }) => ` ${theme.palette.blueRibbon}`,
        ({ theme }) => ` ${theme.palette.mercury}`,
      )};
    }
  }
`;

export const Circle = styled.span<{
  $isActive: boolean;
  $isDone: boolean;
  $currentStep: number;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  min-height: 24px;
  border-radius: ${borderRadius.circle};
  border: 3px solid ${theme("palette.blueRibbon")};
  background-color: ${theme("palette.white")};
  svg {
    display: none;
  }
  &::after {
    content: "${({ $currentStep }) => $currentStep}";
    position: absolute;
    font-size: ${pxToRem(14)};
    font-weight: 600;
    line-height: 1;
  }
  @container container (min-width: 600px) {
    border: ${ifProp(
      "$isActive",
      ({ theme }) => `3px solid ${theme.palette.mercury}`,
      ({ theme }) => `3px solid ${theme.palette.blueRibbon}`,
    )};
    background-color: ${ifProp(
      "$isDone",
      ({ theme }) => ` ${theme.palette.blueRibbon}`,
      ({ theme }) => ` ${theme.palette.white}`,
    )};
    svg {
      display: inline-block;
      color: ${theme("palette.white")};
    }
    &::after {
      content: "";
    }
  }
`;

export const TextWrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const Title = styled.p<{ $isActive: boolean }>`
  font-size: ${pxToRem(16)};
  font-weight: ${ifProp("$isActive", "600", "400")};
  line-height: 1;
  color: ${theme("palette.mineShaft")};
`;

export const Description = styled.span`
  font-size: ${pxToRem(14)};
  color: ${theme("palette.boulder")};
`;

export const ProgressBarWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: ${theme("palette.alto")};
  @container container (min-width: 600px) {
    display: none;
  }
`;

export const ProgressBar = styled.div`
  height: 100%;
  background: ${theme("palette.blueRibbon")};
  @container container (min-width: 600px) {
    display: none;
  }
`;
