import {
  handleError,
  useAgency,
  useDeleteAgencyLogo,
  useUploadAgencyLogo,
} from "@hotelspoint/api";
import {
  Box,
  Flex,
  Form,
  FormAdapter,
  FormAvatarUpload,
  FormContext,
  FormLayout,
} from "@hotelspoint/components";
import { useUserProfileStore } from "@hotelspoint/store";
import { UserProfile } from "@hotelspoint/types";
import {
  createFormData,
  getImagePathFromCDN,
  ImageType,
} from "@hotelspoint/utils";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  defaultValues,
  FormValues,
  validationSchema,
} from "./ProfileAgencyLogo.form";

const ProfileAgencyLogo = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [imageBlob, setImageBlob] = useState<Blob | null>(null);

  const profile = useUserProfileStore(state => state.profile) as UserProfile;
  const agencyId = profile?.agencyId as number;

  const [agency] = useAgency(agencyId);
  const [uploadLogo, isUploadingLogo] = useUploadAgencyLogo(agencyId);
  const [deleteLogo, isDeletingLogo] = useDeleteAgencyLogo(agencyId);

  const imagePath = useMemo(() => {
    if (!agency?.logo) return null;

    return getImagePathFromCDN(agency?.logo as string, ImageType.Agency);
  }, [agency]);

  const formValues = useMemo(() => {
    if (!agency?.logo) return defaultValues;

    if (imageBlob) {
      return {
        logo: new File([imageBlob], imagePath as string, {
          type: imageBlob.type,
        }),
      };
    }

    return defaultValues;
  }, [agency, imageBlob, imagePath]);

  const onSubmit = async (formValues: FormValues) => {
    try {
      const { logo } = formValues;

      if (logo) {
        const formData = createFormData(logo);

        await uploadLogo(formData);
      } else {
        await deleteLogo();

        setImageBlob(null);
      }

      toast.success(t("toast.profileAgencySettings.logo"));
    } catch (error: any) {
      handleError({ t, error });
    }
  };

  // Fetch the agency image if the path is present
  useEffect(() => {
    const fetchImage = async () => {
      setIsLoading(true);
      const response = await fetch(imagePath as string);
      const data = await response.blob();

      setImageBlob(data);
      setIsLoading(false);
    };

    if (imagePath) {
      fetchImage();
    }
  }, [imagePath, agency]);

  return (
    <Form<FormValues>
      defaultValues={formValues}
      validationSchema={validationSchema}
      useFormProps={{ mode: "onChange" }}
      enableReinitialize
    >
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box
            width={[1]}
            px={[0, 0, 1, 1]}
            py={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <FormAdapter name="logo">
              {props => (
                <FormContext<FormValues>
                  render={({ handleSubmit }) => (
                    <FormAvatarUpload
                      {...props}
                      onChange={(...args) => {
                        props.onChange(...args);
                        handleSubmit(onSubmit)();
                      }}
                      isLoading={isLoading}
                      isUploading={isUploadingLogo}
                      isDeleting={isDeletingLogo}
                    />
                  )}
                />
              )}
            </FormAdapter>
          </Box>
        </Flex>
      </FormLayout>
    </Form>
  );
};

export default ProfileAgencyLogo;
