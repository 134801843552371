import {
  TransferSearchFilterName,
  TransferSearchFiltersState,
} from "@hotelspoint/api";
import {
  Form,
  FormAdapter,
  FormCheckbox,
  FormChildrenMethods,
  FormInputRange,
  FormLabel,
  RangeSlider,
} from "@hotelspoint/components";
import { DEBOUNCE_DELAY_INPUT } from "@hotelspoint/constants";
import { IconCurrencyEuro } from "@tabler/icons-react";
import { useEffect, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";

import {
  defaultValues,
  entity2Form,
  form2Entity,
  FormValues,
} from "./SearchTransfersFilters.form";
import * as S from "./SearchTransfersFilters.styled";

interface SearchTransfersFiltersProps {
  value: TransferSearchFiltersState | null;
  onChange: (value: TransferSearchFiltersState) => void;
}

interface SearchTransfersFiltersInnerProps
  extends FormChildrenMethods<FormValues>,
    SearchTransfersFiltersProps {}

const SearchTransfersFiltersInner = ({
  watch,
  onChange,
}: SearchTransfersFiltersInnerProps) => {
  const { t } = useTranslation();

  const [priceRange, categoryFilter, vehicleFilter] = useWatch<FormValues>({
    name: ["priceRange", "categoryFilter", "vehicleFilter"],
  }) as [number[], TransferSearchFilterName[], TransferSearchFilterName[]];

  const [priceMin, priceMax] = priceRange as number[];

  const debouncedCb = useDebouncedCallback(values => {
    const payload = form2Entity(values);
    // @ts-expect-error - Problem with the types
    onChange(payload);
  }, DEBOUNCE_DELAY_INPUT);

  useEffect(() => {
    const subscription = watch(debouncedCb);

    return () => subscription.unsubscribe();
  }, [debouncedCb, watch]);

  return (
    <S.Wrapper>
      <FormAdapter
        name="price"
        label={t("searchTransfersResults.filters.price.label")}
      >
        {props => (
          <>
            <FormInputRange
              {...props}
              startAdornment={<IconCurrencyEuro size={18} />}
            />
            <S.RangeSliderWrapper>
              <RangeSlider
                range
                min={priceMin}
                max={priceMax}
                step={1}
                allowCross={false}
                value={props.value}
                onChange={range => props.onChange(range)}
              />
            </S.RangeSliderWrapper>
          </>
        )}
      </FormAdapter>
      <S.Divider />
      <S.CheckboxWrapper>
        <FormLabel id="category">
          {t("searchTransfersResults.filters.category.label")}
        </FormLabel>
        {categoryFilter?.map(
          (category: TransferSearchFilterName, index: number) => (
            <FormAdapter
              key={category.id}
              name={`category.${index}`}
              label={
                <S.CheckboxLabelWrapper>
                  <S.CheckboxName>{category.name}</S.CheckboxName>
                  <S.CheckboxCount>{category.count}</S.CheckboxCount>
                </S.CheckboxLabelWrapper>
              }
              formControlProps={{
                labelPosition: "inline-end",
                style: { flex: 1 },
              }}
            >
              {props => <FormCheckbox {...props} />}
            </FormAdapter>
          ),
        )}
      </S.CheckboxWrapper>
      <S.Divider />
      <S.CheckboxWrapper>
        <FormLabel id="vehicle">
          {t("searchTransfersResults.filters.vehicle.label")}
        </FormLabel>
        {vehicleFilter?.map(
          (vehicle: TransferSearchFilterName, index: number) => (
            <FormAdapter
              key={vehicle.id}
              name={`vehicle.${index}`}
              label={
                <S.CheckboxLabelWrapper>
                  <S.CheckboxName>{vehicle.name}</S.CheckboxName>
                  <S.CheckboxCount>{vehicle.count}</S.CheckboxCount>
                </S.CheckboxLabelWrapper>
              }
              formControlProps={{
                labelPosition: "inline-end",
                style: { flex: 1 },
              }}
            >
              {props => <FormCheckbox {...props} />}
            </FormAdapter>
          ),
        )}
      </S.CheckboxWrapper>
    </S.Wrapper>
  );
};

const SearchTransfersFilters = (props: SearchTransfersFiltersProps) => {
  const { value } = props;

  const formValues = useMemo(() => {
    if (!value) return defaultValues;

    return entity2Form(value);
  }, [value]);

  return (
    <Form defaultValues={formValues} showDevTool>
      {formMethods => (
        <SearchTransfersFiltersInner {...formMethods} {...props} />
      )}
    </Form>
  );
};

export default SearchTransfersFilters;
