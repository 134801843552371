import { mediaQuery } from "@hotelspoint/theme";
import { useMediaQuery } from "@hotelspoint/utils";
import { type DayPickerRangeProps } from "react-day-picker";

import { type FormControlInjectedProps } from "../../FormControl";
import FormDatePicker from "../FormDatePicker";

interface FormDatePickerRangeProps
  extends FormControlInjectedProps,
    Omit<
      React.InputHTMLAttributes<HTMLInputElement>,
      "id" | "name" | "value" | "onChange"
    > {
  dayPickerProps?: Omit<DayPickerRangeProps, "mode">;
}

const FormDatePickerRange = ({
  value,
  onChange,
  dayPickerProps,
  ...rest
}: FormDatePickerRangeProps) => {
  const isMobileXL = useMediaQuery(mediaQuery.mobileXL);

  return (
    <FormDatePicker
      value={value}
      onChange={onChange}
      dayPickerProps={{
        mode: "range",
        fixedWeeks: true,
        showOutsideDays: false,
        numberOfMonths: isMobileXL ? 2 : 1,
        ...dayPickerProps,
      }}
      {...rest}
    />
  );
};

export default FormDatePickerRange;
