import {
  ActivityOptionPrice,
  handleError,
  useActivityOption,
  useAgency,
  useCreateReservation,
} from "@hotelspoint/api";
import {
  ActivityRequirementsForm,
  Button,
  ConfirmDialog,
  Flex,
  Form,
  FormAdapter,
  FormContext,
  FormInput,
  FormLayout,
  ImportantInfo,
  MarkupPrice,
  PassengerInfo,
  PassengerType,
  Price,
} from "@hotelspoint/components";
import { useUserProfileStore } from "@hotelspoint/store";
import { ReservationType, UserProfile } from "@hotelspoint/types";
import {
  IconAddressBook,
  IconManFilled,
  IconUserFilled,
  IconUserQuestion,
} from "@tabler/icons-react";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

import ActivityContactsInfo from "../../../components/ActivityContactsInfo";
import ActivityPickupDetails from "../../../components/ActivityPickupDetails";
import {
  calculateTotalPrice,
  selectedPrices,
} from "../../SearchActivityBook.util";
import useSearchActivityBookParams from "../../useSearchActivityBookParams";
import * as S from "./SearchActivityBookForm.styled";
import {
  defaultValues,
  entity2Form,
  form2Entity,
  FormValues,
  getDynamicValidationSchema,
} from "./SearchActivityBookForm.util";

interface SearchActivityBookFormParams extends Record<string, string> {
  id: string;
}

const SearchActivityBookForm = () => {
  const { t } = useTranslation();
  const [{ searchId, date, option, tickets }] = useSearchActivityBookParams();

  const profile = useUserProfileStore(state => state.profile) as UserProfile;
  const [agency] = useAgency(profile?.agencyId as number);

  const { id } = useParams<SearchActivityBookFormParams>();
  const activityId = Number(id);

  const dialogTriggerRef = useRef<HTMLDivElement>(null);

  const [activityOption, isLoadingActivityOption] = useActivityOption({
    searchId: searchId as number,
    id: activityId,
    date: date as string,
    option: option as number,
  });

  const [createReservation] = useCreateReservation();

  // Dido is returning all of the available activity options(sometimes more than 10-15), so we need to filter them by the ticket info.
  const filteredPrices = useMemo(() => {
    return selectedPrices(activityOption?.prices, tickets);
  }, [activityOption?.prices, tickets]);

  // Here I am formatting the date so that we can send it to the api
  const mapTicketsData = useMemo(() => {
    return tickets
      ?.split(",")
      .reduce((acc: { [key: string]: number }, pair) => {
        const [key, value] = pair.split(":");
        acc[key] = Number(value);
        return acc;
      }, {});
  }, [tickets]);

  // @todo: Make sure calculation is correct
  const totalPrice = useMemo(() => {
    return calculateTotalPrice(activityOption, tickets);
  }, [activityOption, tickets]);

  const formValues = useMemo(() => {
    if (!filteredPrices) return defaultValues;

    return entity2Form(
      filteredPrices as ActivityOptionPrice[],
      agency?.phoneCode,
    );
  }, [agency?.phoneCode, filteredPrices]);

  const validationSchema = useMemo(() => {
    if (!activityOption) return;

    return getDynamicValidationSchema(activityOption.questions);
  }, [activityOption]);

  const onSubmit = () => {
    (dialogTriggerRef.current as HTMLElement).click();
  };

  const handleConfirm = async (formValues: FormValues) => {
    if (!activityOption) return;

    try {
      const formPayload = form2Entity(formValues, activityOption?.questions);
      const payload = {
        type: ReservationType.Activity,
        searchId: searchId as number,
        offerId: activityId,
        optionId: option as number,
        date: date as string,
        session:
          activityOption.sessions.length !== 0
            ? activityOption.sessions[0]
            : null,
        language:
          activityOption.languages.length !== 0
            ? activityOption.languages[0]
            : null,
        tickets: mapTicketsData,
        ...formPayload,
      };

      // The payloads for hotel booking and activity bookings are different so we need to discuss how we will handle that issue
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const response = await createReservation(payload);

      console.log(response.status);
      // redirect
    } catch (error: any) {
      handleError({ t, error });
    }
  };

  return (
    <Form
      defaultValues={formValues}
      validationSchema={validationSchema}
      enableReinitialize
      showDevTool
    >
      <FormContext<FormValues>
        render={({ handleSubmit }) => (
          <ConfirmDialog
            title={t("searchHotelBook.content.actions.book.modal.title")}
            description={t(
              "searchHotelBook.content.actions.book.modal.description",
            )}
            onConfirm={handleSubmit(handleConfirm)}
            isLoading={false}
          >
            <div ref={dialogTriggerRef} />
          </ConfirmDialog>
        )}
      />
      <FormLayout>
        {isLoadingActivityOption ? (
          <PassengerInfo isLoading={isLoadingActivityOption} />
        ) : (
          <>
            {filteredPrices.map((price, priceIndex) => (
              <div key={priceIndex}>
                <S.SectionTitle>
                  {price?.standalone ? (
                    <IconUserFilled size={18} />
                  ) : (
                    <IconManFilled size={18} />
                  )}
                  <span>{price?.name}</span>
                </S.SectionTitle>

                {Array.from({ length: price?.numTickets as number }).map(
                  (_, adultIndex) => {
                    const name = `passengers.${price?.key}.${adultIndex}`;
                    return (
                      <div key={adultIndex}>
                        <PassengerInfo name={name} type={PassengerType.Adult} />
                        <Flex mx={[0, 0, -1, -1]}>
                          {/* With this component i'm adding the additional fields, which are located in the requirements object */}
                          <ActivityRequirementsForm
                            requirements={price?.requirements}
                            name={name}
                          />
                        </Flex>
                        {(price?.numTickets as number) - 1 > adultIndex && (
                          <S.Divider />
                        )}
                      </div>
                    );
                  },
                )}
              </div>
            ))}
            {/* We can have pickupOptions which is array of options and puckupLocation which is free text, we get the info from the requirements object but we need to show it only once at the end of the form and add it in the first adult passenger */}
            {filteredPrices.length > 0 && (
              <ActivityPickupDetails
                prices={filteredPrices as ActivityOptionPrice[]}
              />
            )}
          </>
        )}
        <S.SectionTitle>
          <IconAddressBook size={18} />
          {isLoadingActivityOption ? (
            <S.FlexGrow>
              <Skeleton />
            </S.FlexGrow>
          ) : (
            <span>{t("searchActivityBook.content.contacts")}</span>
          )}
        </S.SectionTitle>
        {isLoadingActivityOption ? (
          <PassengerInfo isLoading={isLoadingActivityOption} />
        ) : (
          <ActivityContactsInfo />
        )}
        {activityOption?.questions.length !== 0 && (
          <>
            <S.SectionTitle>
              <IconUserQuestion size={18} />
              <span>{t("searchActivityBook.content.questions")}</span>
            </S.SectionTitle>
            {activityOption?.questions.map((question, questionIndex) => (
              <FormAdapter
                key={questionIndex}
                name={question.code}
                label={question.text}
              >
                {props => <FormInput {...props} />}
              </FormAdapter>
            ))}
          </>
        )}
        {activityOption?.remarks.length !== 0 && (
          <ImportantInfo
            remarks={activityOption?.remarks}
            isLoading={isLoadingActivityOption}
          />
        )}
        <S.FormFooter>
          {isLoadingActivityOption ? (
            <S.FlexGrow>
              <Skeleton />
            </S.FlexGrow>
          ) : (
            <S.FormPriceWrap>
              <p>{t("searchActivityBook.content.totalPrice")}</p>
              <MarkupPrice
                value={{
                  total: totalPrice.totalPrice,
                  net: totalPrice.totalPriceNet,
                }}
              >
                <S.PriceTotal>
                  <Price value={totalPrice.totalPrice} />
                </S.PriceTotal>
              </MarkupPrice>
            </S.FormPriceWrap>
          )}
          <FormContext<FormValues>
            render={({ handleSubmit }) => (
              <Button
                type="submit"
                variant="secondary"
                isLoading={isLoadingActivityOption}
                onClick={handleSubmit(onSubmit)}
              >
                {t("searchActivityBook.content.form.submit")}
              </Button>
            )}
          />
        </S.FormFooter>
      </FormLayout>
    </Form>
  );
};

export default SearchActivityBookForm;
