import { usePackageAvailabilitySearch } from "@hotelspoint/api";
import { PackageSearchPayload } from "@hotelspoint/api";
import {
  Container,
  PageSpacer,
  PageSplitLayout,
  PageWrapper,
} from "@hotelspoint/components";
import {
  HotelComponentItems,
  PackageComponent,
  PackageComponentType,
} from "@hotelspoint/types";
import omit from "lodash/omit";
import { useMemo } from "react";

import usePackageSearchParams from "../usePackageSearchParams";
import * as S from "./SearchPackagesResults.styled";
import SearchPackagesResultsContent from "./SearchPackagesResultsContent";

const SearchPackagesResults = () => {
  const [query] = usePackageSearchParams();
  const searchParams = omit(query, "view", "destinationId");

  const [initialSearch, isLoadingInitialSearch] = usePackageAvailabilitySearch(
    searchParams as PackageSearchPayload,
  );

  const getHotelComponent = useMemo(() => {
    if (!initialSearch) return null;
    return initialSearch.components.find(
      component => component.type === PackageComponentType.Hotel,
    );
  }, [initialSearch]);

  return (
    <PageWrapper>
      <Container>
        <PageSpacer>
          <S.VisibleUntilDesktop>
            <p>minimap</p>
          </S.VisibleUntilDesktop>
          <PageSplitLayout>
            <PageSplitLayout.Sidebar>
              <p>minimap</p>
              <S.SidebarCard>
                <p>form</p>
              </S.SidebarCard>
            </PageSplitLayout.Sidebar>
            <PageSplitLayout.Content>
              <SearchPackagesResultsContent
                packageComponent={
                  getHotelComponent as PackageComponent<HotelComponentItems>
                }
                isLoading={isLoadingInitialSearch}
              />
            </PageSplitLayout.Content>
          </PageSplitLayout>
        </PageSpacer>
      </Container>
    </PageWrapper>
  );
};

export default SearchPackagesResults;
