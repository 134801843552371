import { SubAgencyStatement, SubAgencyStatementStatus } from "@hotelspoint/api";
import { InferType, mixed, number, object, string } from "yup";

export const validationSchema = object({
  agencyName: string(),
  amount: number(),
  status: mixed<SubAgencyStatementStatus>()
    .oneOf(
      Object.values(SubAgencyStatementStatus).map(
        e => e as SubAgencyStatementStatus,
      ),
    )
    .required(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: Partial<FormValues> = {
  agencyName: "",
  amount: undefined,
  status: undefined,
};

export const entity2Form = (entity: SubAgencyStatement): FormValues => ({
  agencyName: entity.agencyName,
  amount: entity.amount,
  status: entity.status,
});
