export enum DrawerSize {
  Small,
  Medium,
  Large,
  ExtraLarge,
  FullPage,
}

export enum DrawerPlacement {
  Top,
  Bottom,
  Left,
  Right,
}

export interface DrawerProps {
  isOpen: boolean;
  children: React.ReactNode;
  size: DrawerSize;
  placement: DrawerPlacement;
  backdrop?: boolean;
  style?: React.CSSProperties;
  onOpenChange?: () => void;
}
