import { spacing } from "@hotelspoint/theme";
import styled from "styled-components";

import FormLayout from "../../form/FormLayout";
import FormRadioGroup from "../../form/FormRadioGroup";

export const Wrapper = styled(FormLayout)`
  row-gap: ${spacing(2.5)}px;
`;

export const RadioGroup = styled(FormRadioGroup)`
  flex-direction: row;
  row-gap: 0;
  column-gap: ${spacing(2)}px;
`;
