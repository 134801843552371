import { useAgency, useFetchAgencies } from "@hotelspoint/api";
import {
  Box,
  Button,
  Chip,
  Flex,
  Form,
  FormAdapter,
  FormAsyncSelect,
  FormClearableInput,
  FormContext,
  FormDatePickerSingle,
  FormLayout,
  FormSelect,
} from "@hotelspoint/components";
import { AGENCY_STATEMENTS_STATUS_OPTIONS } from "@hotelspoint/constants";
import {
  getAgencyStatementStatusColor,
  mapEntityToOption,
} from "@hotelspoint/utils";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import useAdminAgencyStatementsParams from "../useAdminAgencyStatementsParams";
import {
  entity2Form,
  form2Entity,
  FormValues,
} from "./AdminAgencyStatementsFilters.form";

interface AdminAgencyStatementsFiltersProps {
  isLoading: boolean;
}

const AdminAgencyStatementsFilters = ({
  isLoading,
}: AdminAgencyStatementsFiltersProps) => {
  const { t } = useTranslation();

  const [query, setQuery] = useAdminAgencyStatementsParams();

  const [fetchAgencies, isFetchingAgencies] = useFetchAgencies();
  const [agency, isLoadingAgency] = useAgency(Number(query?.agencyId));

  const formValues = useMemo(() => {
    return entity2Form(query);
  }, [query]);

  const defaultAgencyOptions = useMemo(() => {
    if (!agency) return [];

    return [
      {
        value: agency.id,
        label: agency.name,
      },
    ];
  }, [agency]);

  const handleLoadAgencyOptions = useCallback(
    (name: string, callback: any) => {
      fetchAgencies({ name }).then(response => {
        if (response && Array.isArray(response.results)) {
          callback(response.results.map(mapEntityToOption));
        }
      });
    },
    [fetchAgencies],
  );

  const statusOptions = useMemo(
    () =>
      AGENCY_STATEMENTS_STATUS_OPTIONS.map(status => ({
        ...status,
        label: (
          <Chip $color={getAgencyStatementStatusColor(status.value)}>
            {t(status.label)}
          </Chip>
        ),
      })),
    [t],
  );

  const onSubmit = (formValues: FormValues) => {
    const payload = form2Entity(formValues);

    setQuery(payload);
  };

  return (
    <Form defaultValues={formValues}>
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="id"
              label={t("adminAgencyStatements.filters.id.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t(
                    "adminAgencyStatements.filters.id.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="agency"
              label={t("adminAgencyStatements.filters.agency.label")}
            >
              {props => (
                <FormAsyncSelect
                  {...props}
                  placeholder={t(
                    "adminAgencyStatements.filters.agency.placeholder",
                  )}
                  defaultOptions={defaultAgencyOptions}
                  loadOptions={handleLoadAgencyOptions}
                  isLoading={isLoadingAgency || isFetchingAgencies}
                  isClearable
                  noOptionsMessage={() =>
                    t("adminAgencyStatements.filters.agency.noOptions")
                  }
                  loadingMessage={() =>
                    t("adminAgencyStatements.filters.agency.loading")
                  }
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="status"
              label={t("adminAgencyStatements.filters.status.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminAgencyStatements.filters.status.placeholder",
                  )}
                  options={statusOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="dateFrom"
              label={t("adminAgencyStatements.filters.dateFrom.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "adminAgencyStatements.filters.dateFrom.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="dateTo"
              label={t("adminAgencyStatements.filters.dateTo.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "adminAgencyStatements.filters.dateTo.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
        </Flex>
        <FormContext<FormValues>
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="secondary"
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              {t("adminAgencyStatements.filters.submit")}
            </Button>
          )}
        />
      </FormLayout>
    </Form>
  );
};

export default AdminAgencyStatementsFilters;
