import { useOnClickOutside } from "@hotelspoint/utils";
import { useRef } from "react";

import * as S from "./Drawer.styled";
import { DrawerProps } from "./types";

const Drawer = ({
  isOpen,
  children,
  size,
  placement,
  backdrop = false,
  style,
  onOpenChange,
}: DrawerProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    if (onOpenChange) {
      onOpenChange();
    }
  });

  return (
    <>
      {backdrop && isOpen && <S.Backdrop />}
      <S.Wrapper
        ref={ref}
        $isOpen={isOpen}
        $size={size}
        $placement={placement}
        style={style}
      >
        {children}
      </S.Wrapper>
    </>
  );
};

Drawer.Header = S.Header;
Drawer.Content = S.Content;
Drawer.Footer = S.Footer;

export default Drawer;
